import { useState } from 'react'
import { webRequest } from '../../types/handle-api'
import {
  Product,
  QrScanAndListProducts,
} from './components/QrScanAndListProducts'
import { Lang } from '../../translations/lang'

export const Return = () => {
  const [showStoringModal, setShowStoringModal] = useState<boolean>(false)
  const [numOfProduct, setNumOfProduct] = useState<number>(0)

  async function submit(products: Product[]) {
    const response = await webRequest('ReusableOrderReturn', {
      serialNos: products.map((p) => p.serialNo),
    })

    if (response.status === 200) {
      setShowStoringModal(true)
      return true
    } else {
      if (response.data.params?.serialNos) {
        if (response.data.params.serialNos[0] === 'no-reusable-order-data')
          return 'Either of products are not lend.'
        else return 'Either of SerialNo Not Found.'
      } else {
        return 'submit failed'
      }
    }
  }

  const StoringModal = () => {
    return (
      <div className=" fixed top-0 bottom-0 right-0 left-0 flex justify-center items-center bg-black bg-opacity-50">
        <div className="p-[24px] flex flex-col w-[280px] items-center rounded-[3px] bg-white">
          <p className="text-[14px]">Completed to return <span style={{fontSize: '20px'}}>{numOfProduct}</span> products.</p>
          <button
            className="cursor-pointer w-[140px] h-[36px] rounded-[100px] bg-[#00BCD4] text-white font-bold text-[14px] mt-[16px] shadow-vixell"
            onClick={() => setShowStoringModal(false)}
          >
            OK
          </button>
        </div>
      </div>
    )
  }

  return (
    <>
      <QrScanAndListProducts
        title={Lang.reusable.sp.returning.title}
        titleColor="#9C27B0"
        submit={submit}
        setNumOfProduct={setNumOfProduct}
      />
      {showStoringModal && <StoringModal />}
    </>
  )
}
