import { FC, ReactElement } from 'react'
import { Header } from '../part/header'
import { Footer } from '../part/footer'
import { TitleWithDescription } from '../part/title-with-description'
import { Link } from 'react-router-dom'

type Props = {
  title: string
  description?: string
  titleRightElement?: ReactElement
  titleBottomElement?: ReactElement
  infiniteLoad?: () => void
  link?: {
    label: string
    link: string
  }
}
export const NoPaddingLayoutWithTitle: FC<Props> = ({
  title,
  description,
  children,
  titleRightElement,
  titleBottomElement,
  infiniteLoad,
  link,
}) => (
  <div className="flex flex-col justify-between min-h-screen bg-[#F9FBFD]">
    <div className="flex flex-1 flex-col">
      <Header />
      <div className="w-[90%] max-w-[1440px] mx-auto pt-[42px]">
        {link && (
          <div className="text-sm underline text-blue-500 pb-24">
            <Link to={link.link}>{link.label}</Link>
          </div>
        )}
        <div className="flex justify-between">
          <TitleWithDescription title={title} description={description}>
            {titleBottomElement}
          </TitleWithDescription>
          {titleRightElement}
        </div>
      </div>
      {children}
    </div>
    <Footer infiniteLoad={infiniteLoad} />
  </div>
)
