import { useEffect, useState } from 'react'
import { OnsiteResultsPressureGraphResponseData } from '../../types/typescript-axios'
import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { Lang } from '../../translations/lang'
import { webRequest } from '../../types/handle-api'
import { SearchButton } from '../../components/part/search-button'
import {
  AdminPressureGraphFilteringModal,
  AdminPressureGraphFilteringModalHideArgs,
} from '../../components/templates/admin-pressure-graph/admin-pressure-graph-filtering-modal'
import { useForm } from 'react-hook-form'
import { AdminPressureGraphEntity } from '../../components/templates/admin-pressure-graph/admin-pressure-graph-entity'

export type PressureGraphYAxisType = 'pressureValue' | 'pressureIncrease'
export type PressureGraphXAxisType = 'processingDate' | 'elapsedDate'

export type PressureGraphData = {
  id: string
  vixellId: number
  firstPressure: number
  stockedDateTime: string | undefined
  lastProcessingDateTime: string | undefined
  data: { x: number; y: number }[]
}

export type SelectedVixell = {
  vixellId: number
  vixellSerialNo: string
}

export const AdminPressureGraph = () => {
  const form = useForm<AdminPressureGraphFilteringModalHideArgs>()
  const [currentFilterValues, setCurrentFilterValues] =
    useState<AdminPressureGraphFilteringModalHideArgs>(form.getValues())
  const [graphData, setGraphData] =
    useState<OnsiteResultsPressureGraphResponseData>()
  const [showFilteringModal, setShowFilteringModal] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const { vixellSerialNo, model, contractNo, stockedFrom, stockedTill } =
        currentFilterValues
      const response = await webRequest('OnsiteResultsPressureGraph', {
        ...(vixellSerialNo && { vixellSerialNo }),
        ...(model && { model }),
        ...(contractNo && { contractNo }),
        ...(stockedFrom && { stockedFrom }),
        ...(stockedTill && { stockedTill }),
      })
      if (response.status === 200) {
        setGraphData(response.data)
      }
    }

    fetchData()
  }, [currentFilterValues])

  function executeFilter(shouldUpdateValues: boolean) {
    if (shouldUpdateValues) setCurrentFilterValues(form.getValues())
    setShowFilteringModal(false)
  }

  const {
    stockedFrom,
    stockedTill,
    model,
    yAxisUpper,
    yAxisLower,
    xAxisUpper,
    xAxisLower,
    xAxisUpperDate,
    xAxisLowerDate,
    xRuledLineRange,
    yRuledLineRange,
  } = currentFilterValues

  return (
    <DefaultWithTitleLayout
      title={Lang.header.pressureGraph}
      titleRightElement={
        <SearchButton
          label={Lang.filtering}
          onClick={() => setShowFilteringModal(true)}
        />
      }
    >
      {graphData && (
        <AdminPressureGraphEntity
          stockedFrom={stockedFrom}
          stockedTill={stockedTill}
          model={model}
          yAxisUpper={yAxisUpper}
          yAxisLower={yAxisLower}
          xAxisUpper={xAxisUpper}
          xAxisLower={xAxisLower}
          xAxisUpperDate={xAxisUpperDate}
          xAxisLowerDate={xAxisLowerDate}
          graphData={graphData}
          yRuledLineRange={yRuledLineRange}
          xRuledLineRange={xRuledLineRange}
        />
      )}
      {showFilteringModal && (
        <AdminPressureGraphFilteringModal form={form} hide={executeFilter} />
      )}
    </DefaultWithTitleLayout>
  )
}
