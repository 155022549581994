import { FC } from 'react'
import { SquareButton } from '../molecules/sqare-button'
import { Lang } from '../../translations/lang'

type Props = {
  title: string
  description?: string
  csvDownload?: () => void
}
export const TitleWithDescriptionAndCSV: FC<Props> = ({
  title,
  description,
  children,
  csvDownload,
}) => (
  <div>
    <h1>
      <div className="flex justify-between">
        {title}
        {csvDownload && (
          <SquareButton
            className="text-sm"
            color="cyan"
            {...(csvDownload && { onClick: csvDownload })}
          >
            {Lang.csv}
          </SquareButton>
        )}
      </div>
      <div className="vixell-gradient w-[460px] h-[1px]"></div>
    </h1>
    <div className="pt-[16px]">
      {description && <p className="text-xs">{description}</p>}
    </div>
    {children}
  </div>
)
