import { SearchSvg } from '../svg/search-svg'
import { Lang } from '../../translations/lang'

type Props = {
  value: string | number
  onChange: (value: string) => void
  onClick?: () => void
}
export const SearchInput = ({
  value,
  onChange,
  onClick = () => undefined,
}: Props) => (
  <span className="flex items-center">
    <input
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="h-[42px] rounded-r-0 rounded-l-[100px] border border-r-0 border-vixell-dark
       shadow-none outline-none px-[16px]"
      placeholder={Lang.search}
    />
    <button
      onClick={onClick}
      className="flex justify-center items-center w-[48px] h-[42px]
      rounded-r-[100px] bg-white border border-vixell-dark"
    >
      <SearchSvg />
    </button>
  </span>
)
