import { SiteType } from '../types/typescript-axios'
import { Lang } from '../translations/lang'
import { ReactNode } from 'react'
import { AdminDashboard } from '../pages/admin/AdminDashboard'
import { Dashboard } from '../pages/Dashboard'
import { AdminOnsiteResults } from '../pages/admin/AdminOnsiteResults'
import { VixellInventories } from '../pages/inventories/VixellInventories'
import { IcePackInventories } from '../pages/inventories/IcePackInventories'
import { AdminSites } from '../pages/admin/AdminSites'
import { Sites } from '../pages/Sites'
import { AdminCustomers } from '../pages/admin/AdminCustomers'
import { AdminTrades } from '../pages/admin/AdminTrades'
import { AdminVixells } from '../pages/admin/AdminVixells'
import { AdminIcePacks } from '../pages/admin/AdminIcePacks'
import { AdminThermalStorageUnits } from '../pages/admin/AdminThermalStorageUnits'
import { AdminTemperatureLoggers } from '../pages/admin/AdminTemperatureLoggers'
import { AdminInspectionBoards } from '../pages/admin/AdminInspectionBoards'
import { AdminClientLogs } from '../pages/admin/AdminClientLogs'
import { ReusableOrders } from '../pages/reusable-orders/ReusableOrders'
import { Stock } from '../pages/sp/Stock'
import { Shipping } from '../pages/sp/Shipping'
import { Lending } from '../pages/sp/Lending'
import { Defective } from '../pages/sp/Defective'
import { LostItem } from '../pages/sp/LostItem'
import { ProductDetail } from '../pages/sp/ProductDetail'
import { Me } from '../pages/Me'
import { AdminVixellDetail } from '../pages/admin/vixells/AdminVixellDetail'
import { AdminIcePackDetail } from '../pages/admin/ice-packs/AdminIcePackDetail'
import { AdminThermalStorageUnitDetail } from '../pages/admin/thermal-storage-units/AdminThermalStorageUnitDetail'
import { AdminTemperatureLoggerDetail } from '../pages/admin/temperature-loggers/AdminTemperatureLoggerDetail'
import { AdminInspectionBoardDetail } from '../pages/admin/inspection-boards/AdminInspectionBoardDetail'
import { ReusableOrdersNew } from '../pages/reusable-orders/ReusableOrdersNew'
import { Menu } from '../pages/sp/Menu'
import { ReusableOrderDetailPage } from '../pages/reusable-orders/ReusableOrderDetailPage'
import { ReusableOrderDashboard } from '../pages/reusable-orders/ReusableOrderDashboard'
import { ReusableOrderEdit } from '../pages/reusable-orders/ReusableOrderEdit'
import { ReusableShippingInstruction } from '../pages/reusable-orders/prints/ReusableShippingInstruction'
import { ReusableDeliverySlip } from '../pages/reusable-orders/prints/ReusableDeliverySlip'
import { ReusableDeliveryList } from '../pages/reusable-orders/prints/ReusableDeliveryList'
import { ReusableDeliveryReceipt } from '../pages/reusable-orders/prints/ReusableDeliveryReceipt'
import { ThermalStorageUnitInventories } from '../pages/inventories/ThermalStorageUnitInventories'
import { TemperatureLoggerInventories } from '../pages/inventories/TemperatureLoggerInventories'
import { ReusableordersTop } from '../pages/reusable-orders/ReusableordersTop'
import { Return } from '../pages/sp/Return'
import { AdminTradeDetail } from '../pages/admin/AdminTradeDetail'
import { AdminPressureGraph } from '../pages/admin/AdminPressureGraph'

const AllReusableSiteTypes = [
  SiteType.ReusableAdmin,
  SiteType.ReusableContract,
  SiteType.ReusableNonResident,
  SiteType.ReusableShipper,
  SiteType.ReusableReturn,
] as const

// Pageのリンクをどこに置くか
type LinkLocation =
  | 'Header'
  | 'Config'
  | 'Masters'
  | 'Inventories'
  | 'ReusableSpMenu'

type PageItem = {
  // これがあるとheaderに表示される
  headerTitle?: string
  url: string
  siteTypes: readonly SiteType[]
  component: ReactNode
  location?: LinkLocation
  onlyJapanese?: boolean
}
export const PageItems = [
  {
    headerTitle: Lang.header.dashboard,
    url: '/dashboard' as const,
    siteTypes: [SiteType.Admin],
    component: <AdminDashboard />,
    location: 'Header',
  },
  {
    headerTitle: Lang.header.dashboard,
    url: '/dashboard' as const,
    siteTypes: [SiteType.Parent, SiteType.Child],
    component: <Dashboard />,
    location: 'Header',
  },
  {
    headerTitle: Lang.header.dashboard,
    url: '/reusable-orders/dashboard' as const,
    siteTypes: AllReusableSiteTypes,
    component: <ReusableOrderDashboard />,
    location: 'Header',
  },
  {
    headerTitle: Lang.header.pressureGraph,
    url: '/pressure-graph' as const,
    siteTypes: [SiteType.Admin],
    component: <AdminPressureGraph />,
    location: 'Header',
  },
  {
    headerTitle: Lang.header.inspectionHistory,
    url: '/onsite-results' as const,
    siteTypes: [
      SiteType.Admin,
      SiteType.Parent,
      SiteType.Child,
      ...AllReusableSiteTypes,
    ],
    component: <AdminOnsiteResults />,
    location: 'Header',
  },
  {
    headerTitle: Lang.header.inventoryVixell,
    url: '/inventories/vixells' as const,
    siteTypes: [SiteType.Parent, SiteType.Child, ...AllReusableSiteTypes],
    component: <VixellInventories />,
    location: 'Inventories',
  },
  {
    headerTitle: Lang.header.inventoryIcePack,
    url: '/inventories/ice-packs' as const,
    siteTypes: [SiteType.Parent, SiteType.Child],
    component: <IcePackInventories />,
    location: 'Inventories',
  },
  {
    headerTitle: Lang.header.inventoryThermalStorageUnit,
    url: '/inventories/thermal-storage-units' as const,
    siteTypes: AllReusableSiteTypes,
    component: <ThermalStorageUnitInventories />,
    location: 'Inventories',
  },
  {
    headerTitle: Lang.header.inventoryTemperatureLogger,
    url: '/inventories/temperature-loggers' as const,
    siteTypes: AllReusableSiteTypes,
    component: <TemperatureLoggerInventories />,
    location: 'Inventories',
  },
  {
    headerTitle: Lang.header.siteList,
    url: '/sites' as const,
    siteTypes: [SiteType.Admin],
    component: <AdminSites />,
    location: 'Masters',
  },
  {
    headerTitle: Lang.header.childrenSiteList,
    url: '/sites' as const,
    siteTypes: [SiteType.Parent],
    component: <Sites />,
    location: 'Masters',
  },
  {
    headerTitle: Lang.header.customerList,
    url: '/customers' as const,
    siteTypes: [SiteType.Admin],
    component: <AdminCustomers />,
    location: 'Masters',
  },
  {
    headerTitle: Lang.header.tradeList,
    url: '/trades' as const,
    siteTypes: [SiteType.Admin],
    component: <AdminTrades />,
    location: 'Masters',
  },
  {
    url: '/trades/:id' as const,
    siteTypes: [SiteType.Admin],
    component: <AdminTradeDetail />,
  },
  {
    headerTitle: Lang.header.vixellList,
    url: '/vixells' as const,
    siteTypes: [
      SiteType.Admin,
      SiteType.ReusableAdmin,
      SiteType.ReusableContract,
    ],
    component: <AdminVixells />,
    location: 'Masters',
  },
  {
    url: '/vixells/:id' as const,
    siteTypes: [
      SiteType.Admin,
      SiteType.ReusableAdmin,
      SiteType.ReusableContract,
    ],
    component: <AdminVixellDetail />,
  },
  {
    headerTitle: Lang.header.icePackList,
    url: '/ice-packs' as const,
    siteTypes: [SiteType.Admin],
    component: <AdminIcePacks />,
    location: 'Masters',
  },
  {
    url: '/ice-packs/:id' as const,
    siteTypes: [SiteType.Admin],
    component: <AdminIcePackDetail />,
  },
  {
    headerTitle: Lang.header.thermalStorageUnitList,
    url: '/thermal-storage-units' as const,
    siteTypes: [
      SiteType.Admin,
      SiteType.ReusableAdmin,
      SiteType.ReusableContract,
    ],
    component: <AdminThermalStorageUnits />,
    location: 'Masters',
  },
  {
    url: '/thermal-storage-units/:id' as const,
    siteTypes: [
      SiteType.Admin,
      SiteType.ReusableAdmin,
      SiteType.ReusableContract,
    ],
    component: <AdminThermalStorageUnitDetail />,
  },
  {
    headerTitle: Lang.header.temperatureLoggerList,
    url: '/temperature-loggers' as const,
    siteTypes: [
      SiteType.Admin,
      SiteType.ReusableAdmin,
      SiteType.ReusableContract,
    ],
    component: <AdminTemperatureLoggers />,
    location: 'Masters',
  },
  {
    url: '/temperature-loggers/:id' as const,
    siteTypes: [
      SiteType.Admin,
      SiteType.ReusableAdmin,
      SiteType.ReusableContract,
    ],
    component: <AdminTemperatureLoggerDetail />,
  },
  {
    headerTitle: Lang.header.inspectionBoardList,
    url: '/inspection-boards' as const,
    siteTypes: [SiteType.Admin],
    component: <AdminInspectionBoards />,
    location: 'Masters',
  },
  {
    url: '/inspection-boards/:id' as const,
    siteTypes: [SiteType.Admin],
    component: <AdminInspectionBoardDetail />,
  },
  {
    headerTitle: Lang.header.appLog,
    url: '/client-logs' as const,
    siteTypes: [SiteType.Admin],
    component: <AdminClientLogs />,
    location: 'Masters',
  },
  {
    headerTitle: Lang.header.reusableOrderList,
    url: '/reusable-orders' as const,
    siteTypes: [SiteType.ReusableAdmin, SiteType.ReusableContract],
    component: <ReusableOrders />,
    location: 'Header',
  },
  {
    headerTitle: Lang.header.reusableOrderShippingList,
    url: '/reusable-orders' as const,
    siteTypes: [SiteType.ReusableShipper],
    component: <ReusableOrders />,
    location: 'Header',
  },
  {
    headerTitle: Lang.header.reusableOrderReturnList,
    url: '/reusable-orders' as const,
    siteTypes: [SiteType.ReusableReturn],
    component: <ReusableOrders />,
    location: 'Header',
  },
  {
    url: '/reusable-orders/new' as const,
    siteTypes: [
      SiteType.ReusableAdmin,
      SiteType.ReusableContract,
      SiteType.ReusableNonResident,
    ],
    component: <ReusableOrdersNew />,
  },
  {
    url: '/reusable-orders/:id' as const,
    siteTypes: AllReusableSiteTypes,
    component: <ReusableOrderDetailPage />,
  },
  {
    url: '/reusable-orders/edit/:id' as const,
    siteTypes: [
      SiteType.ReusableAdmin,
      SiteType.ReusableContract,
      SiteType.ReusableNonResident,
    ],
    component: <ReusableOrderEdit />,
  },
  {
    headerTitle: Lang.header.reusableOrderStock,
    url: '/reusable-orders/stock' as const,
    siteTypes: [
      SiteType.ReusableAdmin,
      SiteType.ReusableNonResident,
      SiteType.ReusableShipper,
      SiteType.ReusableReturn,
    ],
    component: <Stock />,
    location: 'ReusableSpMenu',
  },
  {
    headerTitle: Lang.header.reusableOrderShip,
    url: '/reusable-orders/ship' as const,
    siteTypes: [
      SiteType.ReusableAdmin,
      SiteType.ReusableNonResident,
      SiteType.ReusableShipper,
      SiteType.ReusableReturn,
    ],
    component: <Shipping />,
    location: 'ReusableSpMenu',
    onlyJapanese: true,
  },
  {
    headerTitle: Lang.header.reusableOrderLend,
    url: '/reusable-orders/lend' as const,
    siteTypes: [SiteType.ReusableAdmin, SiteType.ReusableShipper],
    component: <Lending />,
    location: 'ReusableSpMenu',
  },
  {
    headerTitle: Lang.header.reusableOrderReturn,
    url: '/reusable-orders/return' as const,
    siteTypes: [
      SiteType.ReusableAdmin,
      SiteType.ReusableShipper,
      SiteType.ReusableReturn,
    ],
    component: <Return />,
    location: 'ReusableSpMenu',
  },
  {
    headerTitle: Lang.header.reusableOrderDefective,
    url: '/reusable-orders/defective' as const,
    siteTypes: [
      SiteType.ReusableAdmin,
      SiteType.ReusableNonResident,
      SiteType.ReusableShipper,
      SiteType.ReusableReturn,
    ],
    component: <Defective />,
    location: 'ReusableSpMenu',
  },
  {
    headerTitle: Lang.header.reusableOrderLostItem,
    url: '/reusable-orders/lost-item' as const,
    siteTypes: [
      SiteType.ReusableAdmin,
      SiteType.ReusableNonResident,
      SiteType.ReusableShipper,
      SiteType.ReusableReturn,
    ],
    component: <LostItem />,
    location: 'ReusableSpMenu',
  },
  {
    headerTitle: Lang.header.reusableOrderProductDetail,
    url: '/reusable-orders/check-product-detail' as const,
    siteTypes: [
      SiteType.Admin,
      SiteType.ReusableAdmin,
      SiteType.ReusableContract,
    ],
    component: <ProductDetail />,
    location: 'ReusableSpMenu',
  },
  {
    url: '/reusable-orders/menu' as const,
    siteTypes: AllReusableSiteTypes,
    component: <Menu />,
  },
  {
    headerTitle: Lang.header.updateMe,
    url: '/me' as const,
    siteTypes: [
      SiteType.Admin,
      SiteType.Parent,
      SiteType.Child,
      ...AllReusableSiteTypes,
    ],
    component: <Me />,
    location: 'Config',
  },
  {
    url: '/reusable-orders/shipping-instructions/:id' as const,
    siteTypes: AllReusableSiteTypes,
    component: <ReusableShippingInstruction />,
  },
  {
    url: '/reusable-orders/delivery-slips/:id' as const,
    siteTypes: AllReusableSiteTypes,
    component: <ReusableDeliverySlip />,
  },
  {
    url: '/reusable-orders/delivery-lists/:id' as const,
    siteTypes: AllReusableSiteTypes,
    component: <ReusableDeliveryList />,
  },
  {
    url: '/reusable-orders/delivery-receipts/:id' as const,
    siteTypes: AllReusableSiteTypes,
    component: <ReusableDeliveryReceipt />,
  },
  {
    url: '/reusable-orders/reusable-orders-top' as const,
    siteTypes: AllReusableSiteTypes,
    component: <ReusableordersTop />,
  },
] satisfies PageItem[]
type PageUrl = typeof PageItems[number]['url']
export const DefaultPage = {
  [SiteType.Admin]: '/dashboard',
  [SiteType.Parent]: '/dashboard',
  [SiteType.Child]: '/dashboard',
  [SiteType.ReusableAdmin]: '/reusable-orders/reusable-orders-top',
  [SiteType.ReusableContract]: '/reusable-orders/reusable-orders-top',
  [SiteType.ReusableNonResident]: '/reusable-orders/reusable-orders-top',
  [SiteType.ReusableShipper]: '/reusable-orders/reusable-orders-top',
  [SiteType.ReusableReturn]: '/reusable-orders/reusable-orders-top',
}

export const canAccessPage = (url: PageUrl, siteType: SiteType) => {
  const page = PageItems.find((page) => page.url === url)
  if (!page) return false
  return page.siteTypes.some((s) => s === siteType)
}
