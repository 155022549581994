import { useState } from 'react'
import { webRequest } from '../../types/handle-api'
import {
  Product,
  QrScanAndListProducts,
} from './components/QrScanAndListProducts'

export const Defective = () => {
  const [showCompleteModal, setShowCompleteModa] = useState<boolean>(false)
  const [numOfProduct, setNumOfProduct] = useState<number>(0)

  async function submit(products: Product[]) {
    const response = await webRequest('ReusableOrderDefective', {
      serialNos: products.map((p) => p.serialNo),
    })

    if (response.status === 200) {
      setShowCompleteModa(true)
      return true
    } else {
      if (response.data.params) {
        return 'Either of SerialNo Not Found.'
      } else {
        return 'submit failed'
      }
    }
  }

  const CompleteModal = () => {
    return (
      <div className=" fixed top-0 bottom-0 right-0 left-0 flex justify-center items-center bg-black bg-opacity-50">
        <div className="p-[24px] flex flex-col w-[280px] items-center rounded-[3px] bg-white">
          <p className="text-[14px]">
            Completed to set defective <span style={{fontSize: '20px'}}>{numOfProduct}</span> products.
          </p>
          <button
            className="cursor-pointer w-[140px] h-[36px] rounded-[100px] bg-[#00BCD4] text-white font-bold text-[14px] mt-[16px] shadow-vixell"
            onClick={() => setShowCompleteModa(false)}
          >
            OK
          </button>
        </div>
      </div>
    )
  }

  return (
    <>
      <QrScanAndListProducts 
        title="NG" 
        titleColor="#E91E63" 
        submit={submit} 
        setNumOfProduct={setNumOfProduct}
      />
      {showCompleteModal && <CompleteModal />}
    </>
  )
}
