import { Button } from '../molecules/button'
import { SearchSvg } from '../svg/search-svg'
import { Lang } from '../../translations/lang'

type Props = {
  label?: string
  onClick?: () => void
}
export const SearchButton = ({
  label = Lang.search,
  onClick = () => undefined,
}: Props) => (
  <Button color="blue" onClick={onClick}>
    <span className="flex justify-center items-center gap-[4px]">
      {label}
      <SearchSvg />
    </span>
  </Button>
)
