import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  title: string
}
export const PrintLayout = ({ children, title }: Props) => (
  <div className="print-page h-[100vh] bg-red-500">
    <div className="flex justify-center items-center h-[36pt] text-[24pt] font-bold border rounded border-black">
      {title}
    </div>
    {children}
  </div>
)

export const PrintCheckBoxChecked = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" fill="black" />
    </svg>
  )
}
export const PrintCheckBoxUnchecked = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="17" height="17" stroke="#757575" />
    </svg>
  )
}
export const PrintCheckBoxHandWrite = () => {
  return <div className="rounded-[3pt] border border-black w-[18pt] h-[18pt]" />
}
