import { reusable } from './reusable/ja'
import { inventories } from './inventories/ja'

export const Ja = {
  vixell: 'VIXELL',
  thermalStorageUnit: '蓄熱ユニット',
  temperatureLogger: '温度ロガー',
  icePackName: '蓄熱剤',
  inspectionBoard: '検査台',
  serialNo: 'シリアルナンバー',
  siteId: 'サイトID',
  siteName: 'サイト名',
  siteType: 'サイト種別',
  belongedSite: '所属サイト',
  representativeSiteId: '代表サイトID',
  representativeSiteName: '代表サイト名',
  customerId: '顧客ID',
  customerName: '顧客名',
  vixellSerialNo: 'VIXELL-S/N',
  icePackSerialNo: '蓄熱剤S/N',
  inspectionBoardSerialNo: '検査台S/N',
  thermalStorageUnitSerialNo: '蓄熱ユニットS/N',
  temperatureLoggerSerialNo: '温度ロガーS/N',
  model: 'モデル',
  icePackErrorDetail: 'エラー概要',
  contractRepresentativeNo: '契約代表No.',
  contractNo: '契約No.',
  managementNo: '管理No.',
  contractNoAndManagementNo: '契約No./管理No.',
  onsiteVacuumResult: '検査結果(真空度)',
  onsiteIcePackResult: '検査結果(蓄熱剤)',
  inspectionTime: '検査日時',
  inspectionResult: '検査結果',
  inspectionHistory: '検査履歴',
  lastInspectionTime: '最新更新日時',
  lastInspectionResult: '最新検査結果',
  transitionDateTime: '移動日時',
  transitionHistory: '移動履歴',
  icePackPart: '設置箇所',
  tradeType: '取引区分',
  tradeHistory: '取引履歴',
  reusableOrderHistory: '案件履歴',
  notesHistory: 'メモ',
  tradeDateTime: '取引日時',
  destination: '仕向地',
  returnEstimatedDate: '返却予定日',
  returnCompletedDate: '返却完了日',
  stockedDate: '入庫日',
  stockedDateTime: '入庫日時',
  disposedDateTime: '破棄日時',
  accessory: 'アクセサリ',
  productCode: '品目コード',
  shippingCount: '出荷員数',
  returnCount: '返却員数',
  shipping: '出荷',
  return: '返却',
  dispose: '破棄',
  shippingState: '出荷状態',
  createdDate: '作成日',
  updatedDate: '更新日',
  updatedDateTime: '更新日時',
  shippingStateUpdatedDate: '出荷状態更新日',
  ok: 'OK',
  ng: 'NG',
  already: '済',
  notYet: '未',
  all: 'すべて',
  new: '新規作成',
  edit: '編集',
  delete: '削除',
  search: '検索',
  detailedSearch: '詳細検索',
  filtering: 'フィルタ',
  csv: 'CSV',
  stopUsing: '使用停止',
  changePassword: 'パスワード変更',
  cancel: 'キャンセル',
  dateTimeFormat: 'YYYY/MM/DD HH:mm:ss',
  dateFormat: 'YYYY/MM/DD',
  timeFormat: 'HH:mm:ss',
  password: 'パスワード',
  passwordConfirmation: 'パスワード（確認）',
  create: '作成',
  update: '更新',
  register: '登録',
  confirmStop: '本当に使用停止しますか？',
  passwordFormatTitle: '半角英数8~32文字で入力してください',
  restrictSiteIdPattern:
    '4桁以上32桁以下の半角英数、ハイフン、アンダースコアが利用できます',
  searchCondition: '現在の検索条件',
  required: '必須',
  with: '有り',
  without: '無し',
  xAxis: 'X軸',
  yAxis: 'Y軸',
  productName: {
    VIXELL: 'VIXELL',
    THERMAL_STORAGE_UNIT: 'Unit',
    TEMPERATURE_LOGGER: 'Logger',
  },
  siteTypeName: {
    CHILD: '顧客（子）',
    PARENT: '顧客（代表）',
    ADMIN: '管理者',
    // TODO:
    REUSABLE_ADMIN: 'REUSABLE_ADMIN',
    REUSABLE_CONTRACT: 'REUSABLE_CONTRACT',
    REUSABLE_NON_RESIDENT: 'REUSABLE_NON_RESIDENT',
    REUSABLE_SHIPPER: 'REUSABLE_SHIPPER',
    REUSABLE_RETURN: 'REUSABLE_RETURN',
  },
  shippingStateName: {
    STOCKED: '入庫',
    SHIPPED: '出荷中',
    RETURNED: '返却',
    DISPOSED: '破棄済',
    DEFECTIVE: 'NG',
    LOST: '紛失',
    REUSABLE_STOCKED: 'R入庫',
    REUSABLE_SHIPPED: 'R出庫',
    REUSABLE_LEND: 'R貸出',
    REUSABLE_COLLECTED: 'R返却',
  },
  icePack: {
    FRONT: '前面',
    BACK: '後面',
    BOTTOM: '底面',
    TOP: '天面',
    LEFT: '左面',
    RIGHT: '右面',
  },
  appTypeName: {
    onsite: 'オンサイト検査アプリ',
    vacuumProcess: '真空度工程検査アプリ',
    temperatureTagCalibration: '温度タグ校正アプリ',
    shippingManagement: '出荷返却アプリ',
  },
  inventoryState: {
    notYet: '未のみ',
  },
  header: {
    masters: 'マスタと履歴',
    inventories: '拠点の棚卸',
    reusableSpMenu: 'スマホメニュー',
    dashboard: 'ダッシュボード',
    pressureGraph: '内部気圧グラフ',
    customerList: '顧客一覧',
    siteList: 'サイト一覧',
    tradeList: '取引一覧',
    vixellList: 'VIXELL一覧',
    icePackList: '蓄熱剤一覧',
    thermalStorageUnitList: '蓄熱U一覧',
    temperatureLoggerList: '温度ロガー一覧',
    inspectionBoardList: '検査台一覧',
    inspectionHistory: '検査履歴',
    appLog: 'アプリログ',
    inventoryVixell: '棚卸状況(VIXELL)',
    inventoryIcePack: '棚卸状況(蓄熱剤)',
    inventoryThermalStorageUnit: '棚卸状況(蓄熱ユニット)',
    inventoryTemperatureLogger: '棚卸状況(温度ロガー)',
    childrenSiteList: '子アカウント一覧',
    updateMe: 'アカウント情報更新',
    logout: 'ログアウト',
    reusableOrderList: '受注一覧',
    reusableOrderShippingList: '出荷一覧',
    reusableOrderReturnList: '返却予定一覧',
    reusableOrderStock: '(在庫管理)入庫',
    reusableOrderShip: '(在庫管理)出庫',
    reusableOrderLend: '(貸出管理)貸出',
    reusableOrderReturn: '(貸出管理)返却',
    reusableOrderDefective: '(在庫変動)NG品',
    reusableOrderLostItem: '(在庫変動)紛失品',
    reusableOrderProductDetail: '履歴照会',
  },
  footer: {
    privacyPolicy: 'プライバシーポリシー',
  },
  login: {
    login: 'ログイン',
    accountId: 'アカウントID/サイトID',
    password: 'パスワード',
    news: 'お知らせ',
    green: '稼働中',
  },
  me: {
    title: 'アカウント情報更新',
    description: '自分のアカウントの情報の更新を行います。',
    accountName: 'アカウント名',
    passwordUpdateNotice:
      'パスワードを変更する場合のみ、下記を入力してください。（入力しなければ更新されません）',
    newPassword: '新しいパスワード',
    newPasswordConfirmation: '新しいパスワード（確認）',
    passwordAndConfirmationIsDifferent:
      'パスワードとパスワード（確認）が一致しません。',
    successMessage: 'アカウント情報を更新しました。',
  },
  dashboard: {
    title: 'オンサイト(VIXELL断熱性能)検査結果（直近3ヶ月）',
    countPostfix: '件',
    graphMonthDateFormat: 'MM/DD',
    graphMonthFormat: 'MM',
    header: {
      stockCount: '在庫数',
      thirtyDays: '日次検査数推移(直近30日)',
      twelveMonths: '月次検査数推移(直近12ヶ月)',
    },
  },
  sites: {
    title: '子アカウント一覧',
    description: '子アカウントの一覧を表示します',
    createSite: '子アカウント作成',
  },
  onsiteResults: {
    title: 'オンサイト検査履歴',
    description: 'VIXELLごとの最新のオンサイト検査結果の一覧を表示します。',
    headerIcePackErrorDetail: '蓄熱剤エラー概要',
    displayTarget: '表示対象',
    okNg: 'OK/NG',
    onlyNg: 'NGのみ',
    latestOnly: '最新のみ',
  },
  notes: {
    newNote: '新規メモ',
  },
  ...inventories,
  admin: {
    dashboard: {
      title: 'VIXELL稼働状況',
      totalShipping: '出荷累計',
      totalActive: 'VIXELL稼働数',
      totalDisposed: '破棄累計',
      batchDateTime: '集計日時',
      clientAppError: 'クライアントアプリエラー',
      last30days: '直近30日',
      last12months: '直近12ヶ月',
      customer: '顧客',
      stockedCount: '在庫数',
      onsiteVacuum: '真空度検査',
    },
    pressureGraph: {
      title: 'VIXELL内部気圧グラフ',
      yAxisLabel: {
        pressureValue: '圧力値（絶対値）',
        pressureIncrease: '圧力増分値',
      },
      xAxisLabel: {
        processingDate: '検査日時',
        elapsedDate: '経過日数',
      },
      yAxisRange: '縦軸の範囲',
      xAxisRangeForElapsedDate: '横軸の範囲（経過日数の場合）',
      xAxisRangeForProcessingDate: '横軸の範囲（検査日時の場合）',
      onlyLowerError: '上限値も設定してください',
      yRuledLineRange: 'Y軸罫線間隔',
      xRuledLineRange: 'X軸罫線間隔',
      displayedVixellCount: '表示中のVIXELLの数',
    },
    customers: {
      title: '顧客一覧',
      description: '顧客の一覧を表示します。',
    },
    createCustomer: {
      title: '顧客新規作成',
      representativeSitePassword: '代表サイト パスワード',
      representativeSitePasswordConfirmation: '代表サイト パスワード（確認）',
      siteIdError: 'サイトIDはすでに利用されています',
      customerIdError: '顧客IDはすでに利用されています',
    },
    sites: {
      title: 'サイト一覧',
      description: 'サイトの一覧を表示します。',
      createAdmin: '管理者新規作成',
    },
    createSite: {
      title: '管理者新規作成',
      errorTaken: 'サイトIDはすでに利用されています。',
    },
    trades: {
      title: '取引一覧',
      description: '取引の一覧を表示します。',
    },
    tradeDetail: {
      title: '取引情報編集',
      description: '取引情報編集を行います。',
      goBackToTrades: '取引一覧へ戻る',
      addThermalStorageUnit: '蓄熱ユニット追加',
      addAccessory: 'アクセサリ追加',
      relatedLink: '関連リンク',
    },
    vixells: {
      title: 'VIXELL一覧',
      description: 'VIXELLの一覧を表示します。',
      latestSiteName: '最新サイト名',
      latestPressure: '最新センサ圧力',
      latestVoltage: '最新センサ電圧',
      latestInspectionResult: '最新検査結果',
      serial: 'VIXELL S/N',
      contractNo: '契約No./管理No.',
      IDm: 'IDm',
    },
    vixellDetail: {
      title: 'VIXELL詳細',
      goBackToVixells: 'VIXELL一覧へ戻る',
      sensorVoltage: 'センサ電圧',
      sensorPressure: 'センサ圧力',
      vacuumProcessResult: '工程検査結果',
      acceptance: '受入検査',
      vacuumEvacuation: '真空排気',
      leakTest1: 'リーク検査1回目',
      leakTest2: 'リーク検査2回目',
      vacuumCorrection: '真空補正',
      idmIssue: 'IDm付与有無',
      numberOfMoistAdsorbent: '水分吸着剤の個数',
      minimumWeightOfMoistAdsorbent: '水分吸着剤の重量(min)',
      maximumWeightOfMoistAdsorbent: '水分吸着剤の重量(max)',
      weightOfGasAdsorbent: '気体吸着剤の重量',
      componentCheck: '部材確認チェック有無',
      urethaneLotNo: 'ウレタンロットNo.',
      retryCount: '設備番号', // もともとリトライ回数だったが、設備番号になった。
      evacuationPeriod: '排気時間',
      inspectionResult: '検査結果',
      pressureDifferenceFromLeak1: 'リーク検査1回目との圧力差',
      noncorrectedVoltage: 'センサ電圧(温度補正後電圧補正前)',
      correctedVoltage: '補正電圧',
      correctedPressure: '補正圧力',
      environmentalTemperature: '環境温度',
      referenceVoltage: '基準電圧',
      correctionValue: '補正値',
      onsiteTiming: '検査タイミング',
      timing: {
        STOCKED: '入庫',
        SHIPPED: '出荷',
        RETURNED: '返却',
        DISPOSED: '破棄',
        ONSITE: 'オンサイト',
      },
    },
    icePacks: {
      title: '蓄熱剤一覧',
      description: '蓄熱剤の一覧を表示します。',
      calibrationDate: '校正日',
      latestInspectionResult: '最新検査結果',
      serial: '蓄熱剤S/N',
      contractNo: '契約代表No.',
      onlyNg: 'NGのみ',
    },
    icePackDetail: {
      title: '蓄熱剤詳細',
      goBackToIcePacks: '蓄熱剤一覧へ戻る',
      icePackTemperature: 'セ氏温度',
      calibrationResultTitle: '工程検査結果',
      lastInspection: '最終検査',
      calibrationStartDateTime: '校正開始日時',
      referenceTemperatureSensorNo: '温度センサーNo.',
      calibrationResults: '工程測定値',
      referenceTemperature: '平均温度',
      tagTemperatureCode: '平均温度コード',
      rssi: 'RSSI',
      temperatureCorrectionSlope: '温度補正値：傾き',
      temperatureCorrectionIntercept: '温度補正値：切片',
      rssiCorrectionSlope: 'RSSI補正値：傾き',
      rssiCorrectionIntercept: 'RSSI補正値：切片',
      resultTime: {
        1: '1回目',
        2: '2回目',
        3: '3回目',
        4: '4回目',
      },
    },
    onsiteResults: {
      title: 'オンサイト検査履歴',
      description: 'VIXELLごとの最新のオンサイト検査結果の一覧を表示します。',
    },
    onsiteResultDetail: {
      title: 'オンサイト検査詳細',
      target: '対象',
      result: '結果',
      vacuum: '真空度',
    },
    clientLogs: {
      title: 'クライアントアプリログ一覧',
      description: 'クライアントアプリのログの一覧を表示します。',
      timestamp: 'タイムスタンプ',
      app: 'アプリ',
      pcId: 'PcID',
      logLevel: 'ログレベル',
      logCode: 'ログコード',
      csvDownload: 'CSVダウンロード',
    },
    inspectionBoards: {
      title: '検査台一覧',
      description: '検査台の一覧を表示します。',
    },
    inspectionBoardDetail: {
      title: '検査台詳細',
      description: '',
      goBackToInspectionBoards: '検査台一覧へ戻る',
    },
    thermalStorageUnits: {
      title: '蓄熱ユニット一覧',
      description: '蓄熱ユニットの一覧を表示します。',
    },
    thermalStorageUnitDetail: {
      title: '蓄熱ユニット詳細',
      description: '',
      goBackToInspectionBoards: '蓄熱ユニット一覧へ戻る',
    },
    temperatureLoggers: {
      title: '温度ロガー一覧',
      description: '温度ロガーの一覧を表示します。',
    },
    temperatureLoggerDetail: {
      title: '温度ロガー詳細',
      description: '',
      goBackToInspectionBoards: '温度ロガー一覧へ戻る',
    },
    // TODO: 位置を移動
    reusableOrders: {
      title: '受注一覧',
      description: '受注の一覧を表示します。',
    },
    reusableOrdersNew: {
      title: '受注登録',
      description: '受注情報を作成します。',
    },
  },
  reusable,
}
