import { useEffect, useRef, useState } from 'react'
import moment from 'moment'

type InfiniteLoadComponentProps = {
  infiniteLoad: () => void
  loadFrequencyMilliSec?: number
  nextLoadDelayMilliSec?: number
}
export const InfiniteLoadComponent = ({
  infiniteLoad,
  loadFrequencyMilliSec = 300,
  nextLoadDelayMilliSec = 0,
}: InfiniteLoadComponentProps) => {
  const [latestLoadTime, setLatestLoadTime] = useState<Date>(new Date(0))
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) return

    const interval = setInterval(() => {
      if (!ref.current) return

      if (
        moment()
          .subtract(nextLoadDelayMilliSec, 'milliseconds')
          .isAfter(latestLoadTime) &&
        ref.current.getBoundingClientRect()?.y < window.innerHeight
      ) {
        infiniteLoad()
        setLatestLoadTime(new Date())
      }
    }, loadFrequencyMilliSec)
    return () => {
      clearInterval(interval)
    }
  }, [ref, infiniteLoad, loadFrequencyMilliSec, latestLoadTime])

  return <div ref={ref}></div>
}
