import React, { useCallback, useEffect, useRef } from 'react'

type Callback<T extends any[] = any[], R = void> = (...args: T) => R

export function useLatestCallback<T extends any[], R = void>(
  callback: Callback<T, R>,
  deps: React.DependencyList = []
): Callback<T, R> {
  const callbackRef = useRef(callback)

  useEffect(() => {
    callbackRef.current = callback
  }, [callback])

  return useCallback((...args: T) => {
    return callbackRef.current(...args)
  }, deps)
}
