import { Modal } from '../../molecules/modal'
import { Title } from '../../part/title'
import { InputWithLabel } from '../../molecules/input-with-label'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from '../../molecules/button'
import { Lang } from '../../../translations/lang'

type Props = {
  hide: () => void
}
export const AdminTradesSearchModal = ({ hide }: Props) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [contractNo, setContractNo] = useState<string>('')
  const [tradeType, setTradeType] = useState<string>('')
  const [customerName, setCustomerName] = useState<string>('')
  const [destination, setDestination] = useState<string>('')
  const [returnEstimatedDateFrom, setReturnEstimatedDateFrom] =
    useState<string>('')
  const [returnEstimatedDateTill, setReturnEstimatedDateTill] =
    useState<string>('')
  const [returnCompletedDateFrom, setReturnCompletedDateFrom] =
    useState<string>('')
  const [returnCompletedDateTill, setReturnCompletedDateTill] =
    useState<string>('')

  useEffect(() => {
    setContractNo(searchParams.get('contractNo') || '')
    setTradeType(searchParams.get('tradeType') || '')
    setCustomerName(searchParams.get('customerName') || '')
    setDestination(searchParams.get('destination') || '')
    setReturnEstimatedDateFrom(searchParams.get('estimateFrom') || '')
    setReturnEstimatedDateTill(searchParams.get('estimateTill') || '')
    setReturnCompletedDateFrom(searchParams.get('completeFrom') || '')
    setReturnCompletedDateTill(searchParams.get('completeTill') || '')
  }, [searchParams])

  function search() {
    const params = new URLSearchParams()
    if (contractNo) params.set('contractNo', contractNo)
    if (tradeType) params.set('tradeType', tradeType)
    if (customerName) params.set('customerName', customerName)
    if (destination) params.set('destination', destination)
    if (returnEstimatedDateFrom)
      params.set('estimateFrom', returnEstimatedDateFrom)
    if (returnEstimatedDateTill)
      params.set('estimateTill', returnEstimatedDateTill)
    if (returnCompletedDateFrom)
      params.set('completeFrom', returnCompletedDateFrom)
    if (returnCompletedDateTill)
      params.set('completeTill', returnCompletedDateTill)

    hide()
    navigate(`/trades?${params}`)
  }

  return (
    <Modal>
      <Title title={Lang.detailedSearch} />
      <InputWithLabel
        className="mt-32"
        label={Lang.contractRepresentativeNo}
        value={contractNo}
        onChange={setContractNo}
      />
      <InputWithLabel
        className="mt-24"
        label={Lang.tradeType}
        value={tradeType}
        onChange={setTradeType}
      />
      <InputWithLabel
        className="mt-24"
        label={Lang.customerName}
        value={customerName}
        onChange={setCustomerName}
      />
      <InputWithLabel
        className="mt-24"
        label={Lang.destination}
        value={destination}
        onChange={setDestination}
      />

      <div className="mt-24">
        <label>{Lang.returnEstimatedDate}</label>
        <div className="flex items-center gap-[12px] mt-12">
          <input
            type="date"
            className="w-[160px]"
            value={returnEstimatedDateFrom}
            onChange={(e) => setReturnEstimatedDateFrom(e.target.value)}
          />
          <span className="inline-block h-[36px] text-3xl text-gray-300">
            〜
          </span>
          <input
            type="date"
            className="w-[160px]"
            value={returnEstimatedDateTill}
            onChange={(e) => setReturnEstimatedDateTill(e.target.value)}
          />
        </div>
      </div>

      <div className="mt-24">
        <label>{Lang.returnCompletedDate}</label>
        <div className="flex items-center gap-[12px] mt-12">
          <input
            type="date"
            className="w-[160px]"
            value={returnCompletedDateFrom}
            onChange={(e) => setReturnCompletedDateFrom(e.target.value)}
          />
          <span className="inline-block h-[36px] text-3xl text-gray-300">
            〜
          </span>
          <input
            type="date"
            className="w-[160px]"
            value={returnCompletedDateTill}
            onChange={(e) => setReturnCompletedDateTill(e.target.value)}
          />
        </div>
      </div>

      <div className="flex justify-center gap-[32px] mt-40">
        <Button color="red" onClick={hide}>
          {Lang.cancel}
        </Button>
        <Button color="blue" onClick={search}>
          {Lang.search}
        </Button>
      </div>
    </Modal>
  )
}
