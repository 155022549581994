import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  BelongingLog,
  BelongingLogsIndexRequestDataProductTypeEnum,
} from '../../../types/typescript-axios'
import { usePaging } from '../../../contexts/use-paging'
import { webRequest } from '../../../types/handle-api'
import { VixellTable } from '../../part/vixell-table'
import { Lang } from '../../../translations/lang'
import { TableElementDatetime2Line } from '../../part/table-elements/table-element-datetime-2-line'
import { InfiniteLoadComponent } from '../../molecules/infinite-load-component'

type Props = {
  productType: BelongingLogsIndexRequestDataProductTypeEnum
}
export const BelongingLogHistory = ({ productType }: Props) => {
  const { id } = useParams<{ id: string }>()
  const [histories, setHistories] = useState<BelongingLog[]>()
  const { currentPage, setPaging, stopPageLoad } = usePaging()

  useEffect(() => {
    fetchData()
  }, [id])

  async function fetchData() {
    const response = await webRequest('BelongingLogsIndex', {
      productType,
      productId: Number(id),
      page: currentPage + 1,
    })
    if (response.status === 200) {
      setHistories([...(histories || []), ...response.data.belongingLogs])
      setPaging(response.data)
    }
  }

  function infiniteLoad() {
    if (stopPageLoad) return

    fetchData()
  }

  return (
    <div className="my-40 container">
      {histories && (
        <VixellTable
          classNames={['w-200', 'w-200']}
          headers={[Lang.transitionDateTime, Lang.siteName]}
          elements={histories.map((history) => [
            <TableElementDatetime2Line
              dateTime={history.processingDateTime}
              key={history.id}
            />,
            history.site?.name,
          ])}
        />
      )}
      <InfiniteLoadComponent
        infiniteLoad={infiniteLoad}
        nextLoadDelayMilliSec={3000}
      />
    </div>
  )
}
