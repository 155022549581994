import { useEffect, useState } from 'react'
import { AdminOnsiteResult } from '../../types/typescript-axios'

import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { VixellTable } from '../../components/part/vixell-table'
import { TableElementDatetime2Line } from '../../components/part/table-elements/table-element-datetime-2-line'
import { TableHeaderContractAndManagementNo } from '../../components/part/table-headers/table-header-contract-and-management-no'
import { OkIcon } from '../../components/molecules/ok-icon'
import { NgIcon } from '../../components/molecules/ng-icon'
import { AdminOnsiteResultUnitModal } from '../../components/templates/admin-onsite-results/admin-onsite-result-unit-modal'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SearchInput } from '../../components/molecules/search-input'
import { Select } from '../../components/molecules/select'
import moment from 'moment'
import { Lang } from '../../translations/lang'
import { toFormattedDatetime } from '../../utils/to-formatted-datetime'
import { downloadCsv } from '../../utils/download-csv'
import { webRequest } from '../../types/handle-api'
import { usePaging } from '../../contexts/use-paging'

const OkNgs = [
  [Lang.onsiteResults.okNg, ''] as const,
  [Lang.onsiteResults.onlyNg, 'ng'] as const,
]

const LatestOnlies = [
  [Lang.onsiteResults.latestOnly, ''] as const,
  [Lang.all, 'all'] as const,
]

export const AdminOnsiteResults = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [results, setResults] = useState<AdminOnsiteResult[]>([])
  const [showResultUnit, setShowResultUnit] = useState<AdminOnsiteResult>()

  const [searchWord, setSearchWord] = useState<string>('')
  const [searchOkNg, setSearchOkNg] = useState<string>('')
  const [searchLatestOnly, setSearchLatestOnly] = useState<string>('')
  const [searchFrom, setSearchFrom] = useState<string>('')
  const [searchTill, setSearchTill] = useState<string>('')

  const [loadNextPageAt, setLoadNextPageAt] = useState<Date>(new Date())
  const [searchParamsString, setSearchParamsString] = useState<string>()
  const { currentPage, stopPageLoad, setPaging } = usePaging()

  useEffect(() => {
    fetchDataAndSetStates()
  }, [searchParams])

  async function fetchDataAndSetStates(loadNextPage = false) {
    const response = await fetchResults({ loadNextPage })
    if (response.status === 200) {
      if (searchParams.toString() === searchParamsString) {
        setResults((r) => [...r, ...response.data.results])
      } else {
        setResults(response.data.results)
      }
      setPaging(response.data)
      setSearchParamsString(searchParams.toString())
    }
  }

  async function fetchResults({ all = false, loadNextPage = false }) {
    const query = searchParams.get('search')
    const okNg = searchParams.get('ng')
    const latest = searchParams.get('latest')
    const from = searchParams.get('from')
    const till = searchParams.get('till')

    setSearchWord((w) => w || query || '')
    setSearchOkNg(okNg || '')
    setSearchLatestOnly(latest || '')
    setSearchFrom(from || '')
    setSearchTill(till || '')

    return await webRequest('OnsiteResultsIndex', {
      ...(query && { query }),
      ...(okNg && { ngOnly: true }),
      ...(latest !== 'all' && { latestOnly: true }),
      ...(from && { from }),
      ...(till && { till }),
      ...(all && { all: true }),
      ...(!all && loadNextPage && { page: currentPage + 1 }),
    })
  }

  async function csvDownload() {
    const response = await fetchResults({ all: true })
    if (response.status === 200) {
      const csvData = [
        [
          Lang.vixellSerialNo,
          Lang.contractNo,
          Lang.managementNo,
          Lang.siteName,
          Lang.onsiteVacuumResult,
          Lang.onsiteIcePackResult,
          Lang.onsiteResults.headerIcePackErrorDetail,
          Lang.inspectionTime,
        ],
        ...response.data.results.map(
          ({
            vixellSerialNo,
            fullContractNo,
            managementNo,
            onsiteVacuumResult,
            siteName,
            onsiteIcePackResult,
            processingDateTime,
          }) => [
            vixellSerialNo,
            fullContractNo,
            managementNo,
            siteName,
            onsiteVacuumResult.inspectionResult,
            onsiteIcePackResult?.totalInspectionResult,
            onsiteIcePackResult?.totalErrorDetail,
            toFormattedDatetime(processingDateTime),
          ]
        ),
      ]
      downloadCsv(csvData, `onsite-results-${new Date().toISOString()}.csv`)
    }
  }

  function infiniteLoad() {
    if (stopPageLoad) return
    if (moment(loadNextPageAt).isAfter(moment().subtract(3, 'seconds'))) return

    setLoadNextPageAt(new Date())
    fetchDataAndSetStates(true)
  }

  function changeSearchParams(label: string, value: string) {
    if (value) searchParams.set(label, value)
    if (!value) searchParams.delete(label)
    navigate(`/onsite-results?${searchParams.toString()}`)
  }

  const titleRightElement = (
    <div className="flex flex-col items-end">
      <div>
        <SearchInput
          value={searchWord}
          onChange={setSearchWord}
          onClick={() => changeSearchParams('search', searchWord)}
        />
      </div>
      <div className="flex items-end mt-16 gap-[48px]">
        <div className="flex flex-col">
          <label>{Lang.onsiteResults.displayTarget}</label>
          <Select
            className="mt-8"
            value={searchOkNg}
            options={OkNgs}
            onChange={(value) => changeSearchParams('ng', value)}
          />
        </div>
        <div className="flex flex-col">
          <Select
            className="mt-8"
            value={searchLatestOnly}
            options={LatestOnlies}
            onChange={(value) => changeSearchParams('latest', value)}
          />
        </div>
        <div>
          <label>{Lang.admin.clientLogs.timestamp}</label>
          <div className="flex items-center gap-[12px]">
            <input
              type="date"
              className="w-[160px]"
              value={searchFrom}
              onChange={(e) => changeSearchParams('from', e.target.value)}
            />
            <span className="inline-block h-[36px] text-3xl text-gray-300">
              〜
            </span>
            <input
              type="date"
              className="w-[160px]"
              value={searchTill}
              onChange={(e) => changeSearchParams('till', e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <DefaultWithTitleLayout
      title={Lang.admin.onsiteResults.title}
      description={Lang.admin.onsiteResults.description}
      titleRightElement={titleRightElement}
      infiniteLoad={infiniteLoad}
      csvDownload={csvDownload}
    >
      {results && (
        <VixellTable
          rootClassName="my-64"
          classNames={[
            'w-200',
            'w-200',
            'flex-1',
            'w-200',
            'w-200',
            'w-320',
            'w-160',
          ]}
          headers={[
            Lang.vixellSerialNo,
            <TableHeaderContractAndManagementNo key={1} />,
            Lang.siteName,
            Lang.onsiteVacuumResult,
            Lang.onsiteIcePackResult,
            Lang.onsiteResults.headerIcePackErrorDetail,
            Lang.inspectionTime,
          ]}
          elements={results.map((result, index) => [
            result.vixellSerialNo,
            <div key={index} className="flex flex-col items-center">
              <div>{result.fullContractNo}</div>
              <div>{result.managementNo}</div>
            </div>,
            result.siteName,
            result.onsiteVacuumResult &&
            result.onsiteVacuumResult.inspectionResult === 'Pass' ? (
              <OkIcon />
            ) : (
              <NgIcon />
            ),
            (function (result: string | undefined) {
              return result === 'Pass' ? <OkIcon /> : result ? <NgIcon /> : ''
            })(result.onsiteIcePackResult?.totalInspectionResult),
            result.onsiteIcePackResult &&
              result.onsiteIcePackResult.totalErrorDetail,
            <TableElementDatetime2Line
              dateTime={result.processingDateTime}
              key={result.fullContractNo}
            />,
          ])}
          onLineClick={(index) => setShowResultUnit(results[index])}
        />
      )}
      {showResultUnit && (
        <AdminOnsiteResultUnitModal
          result={showResultUnit}
          hide={() => setShowResultUnit(undefined)}
        />
      )}
    </DefaultWithTitleLayout>
  )
}
