import { Modal } from '../../molecules/modal'
import { Title } from '../../part/title'
import { Button } from '../../molecules/button'
import { Lang } from '../../../translations/lang'
import { useFormContext } from 'react-hook-form'
import { ReusableOrdersSearchFieldValues } from '../../../pages/reusable-orders/ReusableOrders'
import { CheckBoxesWithTitleAndLabels } from '../../part/forms/check-boxes-with-title'
import { InputWithLabelAndRegister } from '../../molecules/input-with-label-and-register'

const lang = Lang.reusable.searchModal

type Props = {
  search: () => void
  hide: () => void
}
export const ReusableOrdersSearchModal = ({ search, hide }: Props) => {
  const { register, control } =
    useFormContext<ReusableOrdersSearchFieldValues>()

  function executeSearch() {
    search()
    hide()
  }

  return (
    <Modal>
      <Title title={lang.title} />
      <CheckBoxesWithTitleAndLabels
        title={lang.orderStatus}
        className="mt-32"
        control={control}
        required={false}
        name="orderStatus"
        values={[
          { label: lang.confirmed, value: 'CONFIRMED' },
          { label: lang.nonConfirmed, value: 'NON_CONFIRMED' },
          { label: lang.cancelled, value: 'CANCELLED' },
        ]}
      />

      <div className="mt-24">
        <label>{lang.deliveryDateToConsignor}</label>
        <div className="flex items-center gap-[12px] mt-12">
          <input
            type="date"
            className="w-[160px]"
            {...register('deliveryDateToConsignorFrom')}
          />
          <span className="inline-block h-[36px] text-3xl text-gray-300">
            〜
          </span>
          <input
            type="date"
            className="w-[160px]"
            {...register('deliveryDateToConsignorTill')}
          />
        </div>
      </div>

      <div className="mt-24">
        <label>{lang.deliveryDateToConsignee}</label>
        <div className="flex items-center gap-[12px] mt-12">
          <input
            type="date"
            className="w-[160px]"
            {...register('deliveryDateToConsigneeFrom')}
          />
          <span className="inline-block h-[36px] text-3xl text-gray-300">
            〜
          </span>
          <input
            type="date"
            className="w-[160px]"
            {...register('deliveryDateToConsigneeTill')}
          />
        </div>
      </div>

      <div className="flex flex-col mt-24">
        <CheckBoxesWithTitleAndLabels
          title={lang.box}
          control={control}
          required={false}
          name="box"
          values={[
            { label: 'Type-S', value: 'S' },
            { label: 'Type-L', value: 'L' },
          ]}
        />
      </div>

      <div className="flex flex-col mt-24">
        <CheckBoxesWithTitleAndLabels
          title={lang.unit}
          control={control}
          required={false}
          name="unit"
          values={[
            { label: 'R', value: 'R' },
            { label: 'C', value: 'C' },
            { label: 'F', value: 'F' },
            { label: 'S', value: 'S' },
            { label: 'D', value: 'D' },
          ]}
        />
      </div>

      <div className="flex flex-col mt-24">
        <CheckBoxesWithTitleAndLabels
          title={lang.preCooling}
          control={control}
          required={false}
          name="preCooling"
          values={[
            { label: 'yes', value: true },
            { label: 'no', value: false },
          ]}
        />
      </div>

      <div className="flex flex-col mt-24">
        <CheckBoxesWithTitleAndLabels
          title={lang.logger}
          control={control}
          required={false}
          name="hasLogger"
          values={[
            { label: 'yes', value: true },
            { label: 'no', value: false },
          ]}
        />
      </div>

      <div className="flex flex-col mt-24">
        <CheckBoxesWithTitleAndLabels
          title={lang.shippingStatus}
          control={control}
          required={false}
          name="shippingStatus"
          values={[
            { label: lang.nonShipped, value: 'NON_SHIPPED' },
            { label: lang.shipped, value: 'SHIPPED' },
            { label: lang.returned, value: 'RETURNED' },
          ]}
        />
      </div>

      <div className="mt-24">
        <InputWithLabelAndRegister
          label={lang.query}
          register={register('query')}
        />
      </div>

      <div className="flex justify-center gap-[32px] mt-40">
        <Button color="red" onClick={hide}>
          {Lang.cancel}
        </Button>
        <Button color="blue" onClick={executeSearch}>
          {Lang.search}
        </Button>
      </div>
    </Modal>
  )
}
