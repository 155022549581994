import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { Lang } from '../../translations/lang'
import { useEffect, useState } from 'react'
import { ReusableOrderDashboardResponseComponent } from '../../types/typescript-axios'
import { webRequest } from '../../types/handle-api'
import { VixellTable } from '../../components/part/vixell-table'
import { useLogin } from '../../contexts/login-provider'
import { LineColorProps } from '../../components/part/vixell-table'

export const ReusableOrderDashboard = () => {
  const [dashboard, setDashboard] =
    useState<ReusableOrderDashboardResponseComponent>()
  const [lineColors, setLineColors] = useState<LineColorProps>()
  const lang = Lang.reusable.dashboard
  const mySite = useLogin().name

  useEffect(() => {
    ;(async () => {
      const response = await webRequest('ReusableOrderDashboard')
      if (response.status === 200) {
        setDashboard(response.data)

        const colors: LineColorProps = {}
        response.data.sites.map((dashboard, index) => {
          const siteName = dashboard.site.name
          const color =
            siteName === mySite ? 'yellow'
            : siteName.includes('200') || siteName.includes('400') || siteName.includes('600')
            ? 'gray'
            : null

          if (color !== null) {
            colors[index] = color
          }
        }, [])
        setLineColors(colors)
      }
    })()
  }, [])

  return (
    <DefaultWithTitleLayout title={Lang.reusable.dashboard.title}>
      {dashboard && (
        <VixellTable
          classNames={[
            'min-w-[200px] max-w-[200px]',
            'min-w-[116px] max-w-[116px]',
            'min-w-[420px] max-w-[400px]',
            'min-w-[52px] max-w-[52px]',
            'min-w-[420px] max-w-[400px]',
            'min-w-[52px] max-w-[52px]',
            'min-w-[140px] max-w-[140px]',
            'min-w-[40px] max-w-[40]',
          ]}
          headers={[
            lang.site,
            '',
            <TwoRowTableHeader
              title={lang.typeS}
              childrenTitles={[
                lang.box,
                lang.r5,
                lang.c5,
                lang.s4,
                lang.f4,
                lang.d,
              ]}
            />,
            '',
            <TwoRowTableHeader
              title={lang.typeL}
              childrenTitles={[
                lang.box,
                lang.r5,
                lang.c5,
                lang.s4,
                lang.f4,
                lang.d,
              ]}
            />,
            '',
            <TwoRowTableHeader
              title={lang.logger}
              childrenTitles={[lang.gf, lang.ge]}
            />,
            '',
          ]}
          elements={dashboard.sites.map(({ site, analytics }) => [
            site.name,
            '',
            <div className="flex">
              {[
                analytics.typeS?.box,
                analytics.typeS?.r5,
                analytics.typeS?.c5,
                analytics.typeS?.s4,
                analytics.typeS?.f4,
                analytics.typeS?.d,
              ].map((val, index) => (
                <div key={index} className="w-[70px] text-center">
                  {val}
                </div>
              ))}
            </div>,
            '',
            <div className="flex">
              {[
                analytics.typeL?.box,
                analytics.typeL?.r5,
                analytics.typeL?.c5,
                analytics.typeL?.s4,
                analytics.typeL?.f4,
                analytics.typeL?.d,
              ].map((val, index) => (
                <div key={index} className="w-[70px] text-center">
                  {val}
                </div>
              ))}
            </div>,
            '',
            <div className="flex">
              {[analytics.logger?.gf, analytics.logger?.ge].map(
                (val, index) => (
                  <div key={index} className="w-[70px] text-center">
                    {val}
                  </div>
                )
              )}
            </div>,
          ])}
          options={{lineColors: lineColors}}
        />
      )}
    </DefaultWithTitleLayout>
  )
}

type TwoRowTableHeaderProps = {
  title: string
  childrenTitles: string[]
}
const TwoRowTableHeader = ({
  title,
  childrenTitles,
}: TwoRowTableHeaderProps) => {
  return (
    <div>
      <div className="flex justify-center border-b border-b-vixell-gray-light">
        {title}
      </div>
      <div className="flex">
        {childrenTitles.map((child, index) => (
          <div key={index} className="w-[70px] text-center">
            {child}
          </div>
        ))}
      </div>
    </div>
  )
}
