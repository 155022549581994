import { BrowserQRCodeReader, IScannerControls } from '@zxing/browser'
import { Result } from '@zxing/library'
import { useEffect, useRef, useState } from 'react'
import { useApi } from '../../../contexts/api-provider'
import { useLatestCallback } from '../../../contexts/use-latest-callback'
import { DecodeContinuouslyCallback } from '@zxing/browser/esm/common/DecodeContinuouslyCallback'

type Props = {
  onReadQRCode: (text: Result) => void
}

export const QrCodeReader = ({ onReadQRCode }: Props) => {
  const controlsRef = useRef<IScannerControls | null>()
  const videoRef = useRef<HTMLVideoElement>(null)
  const { isApiRequesting } = useApi()
  const [scanningSuccess, setScanningSuccess] = useState(false)
  const audio = new Audio('/audio/beep.mp3')

  function beep() {
    audio.play()
  }

  useEffect(() => {
    setTimeout(() => {
      setScanningSuccess(false)
    }, 1000)
  }, [scanningSuccess])

  const onReadQrCode: DecodeContinuouslyCallback = useLatestCallback(
    (result, error, controls) => {
      if (result && !isApiRequesting) {
        beep()
        onReadQRCode(result)
        setScanningSuccess(true)
      }
      controlsRef.current = controls
    },
    [isApiRequesting, controlsRef.current]
  )

  useEffect(() => {
    if (!videoRef.current) return
    const codeReader = new BrowserQRCodeReader()
    codeReader.decodeFromVideoDevice(undefined, videoRef.current, onReadQrCode)

    return () => {
      if (controlsRef.current) {
        controlsRef.current.stop()
        controlsRef.current = null
      }
    }
  }, [videoRef.current])

  return (
    <div className="flex justify-center">
      <video
        ref={videoRef}
        className={`h-[240px] w-[240px] border-8 border-[transparent] ${
          scanningSuccess && '!border-green-300'
        }`}
      />
    </div>
  )
}
