import { TradeAccessory } from '../../../types/typescript-axios'
import { Lang } from '../../../translations/lang'

export const TableElementAccessory = ({
  accessory,
}: {
  accessory: TradeAccessory
}) => (
  <div className="flex flex-col items-center">
    <div>{accessory.productCode}</div>
    <div>{`${Lang.shipping}:${accessory.shippingCount}`}</div>
    <div>{`${Lang.return}:${accessory.returnCount}`}</div>
    <div></div>
  </div>
)
