import { ReusableOrder } from '../../types/typescript-axios'
import { Cell } from '../atom/Cell'
import React from 'react'

type Props = React.HTMLAttributes<HTMLDivElement> & {
  order: ReusableOrder
}
export const ConsignorAddress = ({ order, ...props }: Props) => (
  <Cell {...props}>
    {[
      order.addressOfConsignor,
      order.stateOfConsignor,
      order.countryOfConsignor,
      order.zipCodeOfConsignor,
    ].join(' ')}
  </Cell>
)
