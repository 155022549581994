export const ReturnVouchers = {
  'REUSABLE-511_KST': 'JAPAN',
  'REUSABLE-521_BRU': 'SPECIAL',
  'REUSABLE-531_NYC': 'USA_N_NY',
  'REUSABLE-532_LAX': 'USA_N_LA',
  'REUSABLE-599_OTHER': 'SPECIAL',
  R511: 'JAPAN',
  R512: 'JAPAN Domestic',
  R521: 'EU',
  R531: 'USA',
  R599: 'SPECIAL',
}
export type ReturnVoucherSiteName = keyof typeof ReturnVouchers
