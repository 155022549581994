import { useContext, useState } from 'react'
import { SiteType } from '../../types/typescript-axios'
import {
  deleteLoginToken,
  getLoginToken,
} from '../../local-storage/login-token'
import { Link, useNavigate } from 'react-router-dom'
import { AutoFocusDiv } from '../molecules/AutoFocusDiv'
import { LoginContext, useLogin } from '../../contexts/login-provider'
import { Lang } from '../../translations/lang'
import { PageItems } from '../../constants/pages'

type HeaderProps = {
  siteType: SiteType
  logout: () => void
}
export const Header = () => {
  const navigate = useNavigate()
  const [_, setLoginContext] = useContext(LoginContext)

  const [siteType, setSiteType] = useState<SiteType>()

  useState(() => {
    const loginToken = getLoginToken()
    if (!loginToken) return navigate('/')

    setSiteType(loginToken.siteType)
  })

  async function logout() {
    // TODO: Logout
    // const api = new WebCommonApi(apiConfiguration())
    // const response = await handleApi(api.logout())
    // if (response.status === 200) {
    deleteLoginToken()
    setLoginContext(null)
    navigate('/')
    // }
  }

  if (!siteType) return <div></div>

  return (
    <>
      <PcHeader siteType={siteType} logout={logout} />
      <SpHeader siteType={siteType} logout={logout} />
    </>
  )
}

const SpHeader = ({ siteType, logout }: HeaderProps) => {
  const [hiding, setHiding] = useState<boolean>(false)
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [loginToken] = useContext(LoginContext)

  function hideMenu() {
    setHiding(true)
    setTimeout(() => {
      setOpenMenu(false)
      setHiding(false)
    }, 300)
  }

  return (
    <header className="pc:hidden flex justify-between items-center h-[80px] w-full px-16 border-b border-b-vixell-gray-light bg-white">
      <Link to="/">
        <img
          src="/images/header-logo.png"
          width="202px"
          height="46px"
          alt="VIXELL"
        />
      </Link>
      <svg
        onClick={() => setOpenMenu(true)}
        className="cursor-pointer"
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#000000"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
      </svg>
      {openMenu && (
        <div
          onClick={hideMenu}
          className="fixed top-0 left-0 w-screen h-screen flex justify-end bg-modal-background z-10"
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className={[
              'flex flex-col bg-white w-[300px] min-h-screen overflow-scroll',
              hiding ? 'animate-slideOut' : 'animate-slideIn',
            ].join(' ')}
          >
            <div className="flex justify-end text-sm font-bold break-all text-gray-500 py-8 border-b border-b-vixell-gray-light pr-8">
              {loginToken?.name}
            </div>
            {PageItems.filter(
              (i) =>
                i.siteTypes.some((s) => s === siteType) &&
                i.headerTitle &&
                (!i.onlyJapanese || navigator.language === 'ja')
            ).map(({ headerTitle, url }, index) => (
              <Link
                key={index}
                to={url}
                className="flex min-h-[100px] items-center px-16 border-b border-b-vixell-gray-light
                     text-vixell-gray-dark text-xl
                     hover:underline"
              >
                {headerTitle}
              </Link>
            ))}
            <span
              onClick={logout}
              className="flex min-h-[80px] items-center px-16 border-b border-vixell-gray-light
                     text-vixell-gray-dark text-xl cursor-pointer
                     hover:underline"
            >
              {Lang.header.logout}
            </span>
            <div className="min-h-[100px]" />
          </div>
        </div>
      )}
    </header>
  )
}

export const PcHeader = ({ siteType, logout }: HeaderProps) => {
  const navigate = useNavigate()
  const [showPullDown, setShowPullDown] = useState<
    'Config' | 'Masters' | 'Inventories' | 'ReusableSpMenu'
  >()
  const loginToken = useLogin()

  const headerPageList = PageItems.filter((i) =>
    i.siteTypes.some(
      (s) => s === loginToken.siteType && i.location === 'Header'
    )
  )
  const configPageList = PageItems.filter((i) =>
    i.siteTypes.some(
      (s) => s === loginToken.siteType && i.location === 'Config'
    )
  )
  const mastersPageList = PageItems.filter((i) =>
    i.siteTypes.some(
      (s) => s === loginToken.siteType && i.location === 'Masters'
    )
  )
  const inventoriesPageList = PageItems.filter((i) =>
    i.siteTypes.some(
      (s) => s === loginToken.siteType && i.location === 'Inventories'
    )
  )
  const spMenuPageList = PageItems.filter(
    (i) =>
      i.siteTypes.some(
        (s) => s === loginToken.siteType && i.location === 'ReusableSpMenu'
      ) &&
      (!i.onlyJapanese || navigator.language === 'ja')
  )

  return (
    <header className="sp:hidden pc:block h-[80px] w-full border-b border-b-vixell-gray-light bg-white">
      <div className="flex justify-between items-center max-w-[1440px] h-full w-full mx-auto px-[42px]">
        <Link to="/">
          <img
            src="/images/header-logo.png"
            width="202px"
            height="46px"
            alt="VIXELL"
          />
        </Link>
        <div className="flex justify-end items-center gap-[32px]">
          {headerPageList.map(({ headerTitle, url }, index) => (
            <a
              key={index}
              href={url}
              className="text-vixell-gray-dark text-sm hover:underline"
            >
              {headerTitle}
            </a>
          ))}
          {mastersPageList.length > 0 && (
            <div className="relative cursor-pointer">
              <span
                className="text-vixell-gray-dark text-sm hover:underline cursor-pointer"
                onClick={() => setShowPullDown('Masters')}
              >
                {Lang.header.masters}
              </span>
              {showPullDown === 'Masters' && (
                <AutoFocusDiv
                  className="absolute right-0 top-[32px] w-[200px] bg-gray-100 drop-shadow-lg"
                  onBlur={() => setShowPullDown(undefined)}
                >
                  {mastersPageList.map(({ headerTitle, url }, index) => (
                    <span
                      onClick={() => navigate(url)}
                      key={index}
                      className="flex justify-center items-center h-[48px] hover:bg-cyan-50 cursor-pointer"
                    >
                      {headerTitle}
                    </span>
                  ))}
                </AutoFocusDiv>
              )}
            </div>
          )}
          {inventoriesPageList.length > 0 && (
            <div className="relative cursor-pointer">
              <span
                className="text-vixell-gray-dark text-sm hover:underline cursor-pointer"
                onClick={() => setShowPullDown('Inventories')}
              >
                {Lang.header.inventories}
              </span>
              {showPullDown === 'Inventories' && (
                <AutoFocusDiv
                  className="absolute right-0 top-[32px] w-[200px] bg-gray-100 drop-shadow-lg"
                  onBlur={() => setShowPullDown(undefined)}
                >
                  {inventoriesPageList.map(({ headerTitle, url }, index) => (
                    <span
                      onClick={() => navigate(url)}
                      key={index}
                      className="flex justify-center items-center h-[48px] hover:bg-cyan-50 cursor-pointer"
                    >
                      {headerTitle}
                    </span>
                  ))}
                </AutoFocusDiv>
              )}
            </div>
          )}
          {spMenuPageList.length > 0 && (
            <div className="relative cursor-pointer">
              <span
                className="text-vixell-gray-dark text-sm hover:underline cursor-pointer"
                onClick={() => setShowPullDown('ReusableSpMenu')}
              >
                {Lang.header.reusableSpMenu}
              </span>
              {showPullDown === 'ReusableSpMenu' && (
                <AutoFocusDiv
                  className="absolute right-0 top-[32px] w-[200px] bg-gray-100 drop-shadow-lg"
                  onBlur={() => setShowPullDown(undefined)}
                >
                  {spMenuPageList.map(({ headerTitle, url }, index) => (
                    <span
                      onClick={() => navigate(url)}
                      key={index}
                      className="flex justify-center items-center h-[48px] hover:bg-cyan-50 cursor-pointer"
                    >
                      {headerTitle}
                    </span>
                  ))}
                </AutoFocusDiv>
              )}
            </div>
          )}
          <div className="text-xs font-bold break-all max-w-[120px] text-gray-500">
            {loginToken?.name}
          </div>
          <div className="relative cursor-pointer">
            <span onClick={() => setShowPullDown('Config')}>
              <svg
                width="30"
                height="32"
                viewBox="0 0 30 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M26.4693 17.5667C26.5335 17.0667 26.5656 16.55 26.5656 16C26.5656 15.4667 26.5335 14.9333 26.4532 14.4333L29.7141 11.8C30.0032 11.5667 30.0835 11.1167 29.9068 10.7833L26.8227 5.25C26.6299 4.88333 26.2283 4.76667 25.8749 4.88333L22.0358 6.48333C21.2326 5.85 20.3812 5.31667 19.4335 4.91667L18.8552 0.683333C18.791 0.283333 18.4697 0 18.0842 0H11.9158C11.5303 0 11.2251 0.283333 11.1608 0.683333L10.5826 4.91667C9.63483 5.31667 8.7674 5.86667 7.9803 6.48333L4.14114 4.88333C3.78775 4.75 3.38616 4.88333 3.1934 5.25L0.125295 10.7833C-0.0674657 11.1333 -0.0032123 11.5667 0.318056 11.8L3.57893 14.4333C3.49861 14.9333 3.43436 15.4833 3.43436 16C3.43436 16.5167 3.46648 17.0667 3.5468 17.5667L0.285929 20.2C-0.00321228 20.4333 -0.0835291 20.8833 0.0931683 21.2167L3.17734 26.75C3.3701 27.1167 3.77169 27.2333 4.12508 27.1167L7.96423 25.5167C8.7674 26.15 9.61876 26.6833 10.5665 27.0833L11.1448 31.3167C11.2251 31.7167 11.5303 32 11.9158 32H18.0842C18.4697 32 18.791 31.7167 18.8392 31.3167L19.4174 27.0833C20.3652 26.6833 21.2326 26.15 22.0197 25.5167L25.8589 27.1167C26.2123 27.25 26.6138 27.1167 26.8066 26.75L29.8908 21.2167C30.0835 20.85 30.0032 20.4333 29.698 20.2L26.4693 17.5667ZM15 22C11.8194 22 9.21718 19.3 9.21718 16C9.21718 12.7 11.8194 10 15 10C18.1806 10 20.7828 12.7 20.7828 16C20.7828 19.3 18.1806 22 15 22Z"
                  fill="#78909C"
                />
              </svg>
            </span>
            {showPullDown === 'Config' && (
              <AutoFocusDiv
                className="absolute right-0 top-[32px] w-[200px] bg-gray-100 drop-shadow-lg"
                onBlur={() => setShowPullDown(undefined)}
              >
                {configPageList.map(({ headerTitle, url }, index) => (
                  <span
                    onClick={() => navigate(url)}
                    key={index}
                    className="flex justify-center items-center h-[48px] hover:bg-cyan-50 cursor-pointer"
                  >
                    {headerTitle}
                  </span>
                ))}
                <div
                  onClick={logout}
                  className="flex justify-center items-center h-[48px] hover:bg-cyan-50 cursor-pointer"
                >
                  {Lang.header.logout}
                </div>
              </AutoFocusDiv>
            )}
          </div>
        </div>
      </div>
    </header>
  )
}
