import { useEffect, useState } from 'react'
import {
  ShippingStateValue,
  TemperatureLoggersIndexResponseComponent,
} from '../../types/typescript-axios'

import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { VixellTable } from '../../components/part/vixell-table'
import { TableHeaderContractAndManagementNo } from '../../components/part/table-headers/table-header-contract-and-management-no'
import moment from 'moment'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SearchInput } from '../../components/molecules/search-input'
import { Select } from '../../components/molecules/select'
import { Lang } from '../../translations/lang'
import { toFormattedDatetime } from '../../utils/to-formatted-datetime'
import { downloadCsv } from '../../utils/download-csv'
import { webRequest } from '../../types/handle-api'
import { usePaging } from '../../contexts/use-paging'
import { Cell } from '../../components/atom/Cell'
import { TableElementDatetime2Line } from '../../components/part/table-elements/table-element-datetime-2-line'

const ShippingStateSelects = [
  [Lang.all, ''] as const,
  [
    Lang.shippingStateName.SHIPPED,
    ShippingStateValue.Shipped as string,
  ] as const,
  [
    Lang.shippingStateName.RETURNED,
    ShippingStateValue.Returned as string,
  ] as const,
  [
    Lang.shippingStateName.DISPOSED,
    ShippingStateValue.Disposed as string,
  ] as const,
  [
    Lang.shippingStateName.REUSABLE_STOCKED,
    ShippingStateValue.ReusableStocked as string,
  ] as const,
  [
    Lang.shippingStateName.REUSABLE_SHIPPED,
    ShippingStateValue.ReusableShipped as string,
  ] as const,
  [
    Lang.shippingStateName.REUSABLE_LEND,
    ShippingStateValue.ReusableLend as string,
  ] as const,
  [
    Lang.shippingStateName.REUSABLE_COLLECTED,
    ShippingStateValue.ReusableCollected as string,
  ] as const,
  [
    Lang.shippingStateName.DEFECTIVE,
    ShippingStateValue.Defective as string,
  ] as const,
  [Lang.shippingStateName.LOST, ShippingStateValue.Lost as string] as const,
]

export const AdminTemperatureLoggers = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [temperatureLoggers, setTemperatureLoggers] = useState<
    TemperatureLoggersIndexResponseComponent[]
  >([])

  const [searchWord, setSearchWord] = useState<string>('')
  const [searchShippingState, setSearchShippingState] = useState<string>('')
  const [from, setFrom] = useState<string>('')
  const [till, setTill] = useState<string>('')

  const [loadNextPageAt, setLoadNextPageAt] = useState<Date>(new Date())
  const { currentPage, setPaging, stopPageLoad } = usePaging()
  const [searchParamsString, setSearchParamsString] = useState<string>()

  useEffect(() => {
    fetchDataAndSetStates()
  }, [searchParams])

  async function fetchDataAndSetStates(loadNextPage = false) {
    const response = await fetchData({ loadNextPage })
    if (response.status === 200) {
      if (searchParams.toString() === searchParamsString) {
        setTemperatureLoggers((loggers) => [
          ...loggers,
          ...response.data.temperatureLoggers,
        ])
      } else {
        setTemperatureLoggers(response.data.temperatureLoggers)
      }
      setPaging(response.data)
      setSearchParamsString(searchParams.toString())
    }
  }

  async function fetchData({ all = false, loadNextPage = false }) {
    const query = searchParams.get('search')
    const shippingState = searchParams.get('shippingState')
    const latestShippingStateDateTimeFrom = searchParams.get('from')
    const latestShippingStateDateTimeTill = searchParams.get('till')

    setSearchWord((w) => w || query || '')
    setSearchShippingState(shippingState || '')
    setFrom(latestShippingStateDateTimeFrom || '')
    setTill(latestShippingStateDateTimeTill || '')

    return await webRequest('TemperatureLoggersIndex', {
      ...(shippingState && {
        shippingState: shippingState as ShippingStateValue,
      }),
      ...(query && { query }),
      ...(latestShippingStateDateTimeFrom && {
        latestShippingStateDateTimeFrom,
      }),
      ...(latestShippingStateDateTimeTill && {
        latestShippingStateDateTimeTill,
      }),
      ...(all && { all: true }),
      ...(!all && loadNextPage && { page: currentPage + 1 }),
    })
  }

  async function csvDownload() {
    const response = await fetchData({ all: true })
    if (response.status === 200) {
      const csvData = [
        [
          Lang.inspectionBoardSerialNo,
          Lang.model,
          Lang.siteName,
          Lang.contractNo,
          Lang.managementNo,
          Lang.shippingState,
          Lang.shippingStateUpdatedDate,
        ],
        ...response.data.temperatureLoggers.map(
          ({
            serialNo,
            model,
            siteName,
            fullContractNo,
            managementNo,
            shippingState,
            shippingStateUpdatedDateTime,
          }) => [
            serialNo,
            model,
            siteName,
            fullContractNo,
            managementNo,
            shippingState && Lang.shippingStateName[shippingState],
            toFormattedDatetime(shippingStateUpdatedDateTime),
          ]
        ),
      ]
      downloadCsv(csvData, `temperatureLoggers-${new Date().toISOString()}.csv`)
    }
  }

  function infiniteLoad() {
    if (stopPageLoad) return
    if (moment(loadNextPageAt).isAfter(moment().subtract(3, 'seconds'))) return

    setLoadNextPageAt(new Date())
    fetchDataAndSetStates(true)
  }

  function selectShippingState(value: string) {
    if (value) searchParams.set('shippingState', value)
    if (!value) searchParams.delete('shippingState')
    navigate(`/temperature-loggers?${searchParams.toString()}`)
  }

  function changeFrom(value: string) {
    if (value) searchParams.set('from', value)
    if (!value) searchParams.delete('from')
    navigate(`/temperature-loggers?${searchParams.toString()}`)
  }

  function changeTill(value: string) {
    if (value) searchParams.set('till', value)
    if (!value) searchParams.delete('till')
    navigate(`/temperature-loggers?${searchParams.toString()}`)
  }

  function search() {
    if (searchWord) searchParams.set('search', searchWord)
    if (!searchWord) searchParams.delete('search')
    navigate(`/temperature-loggers?${searchParams.toString()}`)
  }

  const titleRightElement = (
    <div className="flex flex-col items-end">
      <div>
        <SearchInput
          value={searchWord}
          onChange={setSearchWord}
          onClick={search}
        />
      </div>
      <div className="flex mt-16 gap-[48px]">
        <div className="flex flex-col">
          <label>{Lang.shippingState}</label>
          <Select
            className="mt-8"
            value={searchShippingState}
            options={ShippingStateSelects}
            onChange={selectShippingState}
          />
        </div>
        <div>
          <label>{Lang.shippingStateUpdatedDate}</label>
          <div className="flex items-center gap-[12px]">
            <input
              type="date"
              className="w-[160px]"
              value={from}
              onChange={(e) => changeFrom(e.target.value)}
            />
            <span className="text-3xl text-gray-300">〜</span>
            <input
              type="date"
              className="w-[160px]"
              value={till}
              onChange={(e) => changeTill(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <DefaultWithTitleLayout
      title={Lang.admin.temperatureLoggers.title}
      description={Lang.admin.temperatureLoggers.description}
      titleRightElement={titleRightElement}
      infiniteLoad={infiniteLoad}
      csvDownload={csvDownload}
    >
      {temperatureLoggers && (
        <VixellTable
          rootClassName="my-64"
          classNames={['w-200', 'w-200', 'w-200', 'w-200', 'w-200']}
          headers={[
            Lang.temperatureLoggerSerialNo,
            Lang.model,
            Lang.siteName,
            <TableHeaderContractAndManagementNo key={1} />,
            Lang.shippingState,
            Lang.shippingState + Lang.updatedDate,
          ]}
          elements={temperatureLoggers.map((logger) => [
            logger.serialNo,
            logger.model,
            logger.siteName,
            <Cell layout="center_center" className="flex-col" key={logger.id}>
              <div>{logger.fullContractNo}</div>
              <div>{logger.managementNo}</div>
            </Cell>,
            logger.shippingState &&
              Lang.shippingStateName[logger.shippingState],
            logger.shippingStateUpdatedDateTime && (
              <TableElementDatetime2Line
                dateTime={logger.shippingStateUpdatedDateTime}
              />
            ),
          ])}
          onLineClick={(index) =>
            navigate(`/temperature-loggers/${temperatureLoggers[index].id}`)
          }
        />
      )}
    </DefaultWithTitleLayout>
  )
}
