export const orderDetail = {
  title: '受注詳細',
  description: '受注の詳細を確認します。',
  print: {
    title: '各種帳票印刷',
    shippingInstruction: '出荷指示書',
    deliverySlip: '個票',
    deliveryList: '一覧表',
    deliveryReceipt: '納品書',
  },
  contractInfo: {
    title: '契約情報',
    status: 'ステータス',
  },
  siteInCharge: {
    title: '担当サイト',
    shipper: '出荷サイト',
    returnee: '返却サイト',
  },
  productInfo: {
    title: '商品情報',
    add: '商品追加',
  },
}
