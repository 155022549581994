import { Button } from '../molecules/button'
import { Lang } from '../../translations/lang'

type Props = {
  title: string
  ok: () => void
  cancel: () => void
}
export const ConfirmModal = ({ title, ok, cancel }: Props) => (
  <div className="fixed top-0 left-0 flex justify-center items-center w-screen h-screen bg-modal-background">
    <div className="rounded-10 p-42 bg-white max-h-[90%] overflow-scroll">
      <p className="text-xl">{title}</p>
      <div className="flex mt-32 gap-[32px]">
        <Button color="red" onClick={cancel}>
          {Lang.cancel}
        </Button>
        <Button color="cyan" onClick={ok}>
          {Lang.ok}
        </Button>
      </div>
    </div>
  </div>
)
