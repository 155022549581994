import { AxiosResponse } from 'axios'
import { WebRequest, WebRequestApi, WebResponse } from './typescript-axios'
import { apiConfiguration } from '../config/api-configuration'
import { generateUniqueId } from '../utils/generate-unique-id'

export type HandleErrorResponse<T> =
  | {
      status: 200
      data: T
    }
  | {
      status: 422 | 401
      data: {
        type: string
        code: string
        params: {
          [key: string]: string[]
        }
      }
    }

type ExtractData<T, X> = X extends { type: T; data: infer U } ? U : never

type WebRequestType = WebRequest['type']

export async function webRequest<T extends WebRequestType>(
  type: T,
  data?: ExtractData<T, WebRequest>
): Promise<HandleErrorResponse<ExtractData<T, WebResponse>>> {
  const api = new WebRequestApi(apiConfiguration())
  const id = generateUniqueId()
  return handleApi(
    api.webRequest({ id, type, ...(data && { data }) } as WebRequest)
  ) as any as HandleErrorResponse<ExtractData<T, WebResponse>>
}

export async function handleApi<T>(
  result: Promise<AxiosResponse<T>>
): Promise<HandleErrorResponse<T>> {
  try {
    const response = await result
    return {
      status: 200,
      data: response.data,
    }
  } catch (e: any) {
    return {
      status: e.response.status,
      data: e.response.data,
    }
  }
}
