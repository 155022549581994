import { useEffect, useState } from 'react'
import { Customer, Site } from '../../types/typescript-axios'

import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { VixellTable } from '../../components/part/vixell-table'
import { NewButton } from '../../components/part/new-button'
import { CreateCustomer } from '../../components/templates/admin-customers/create-customer'
import { ConfirmModal } from '../../components/part/ConfirmModal'
import { Lang } from '../../translations/lang'
import { downloadCsv } from '../../utils/download-csv'
import { EditRepresentativeSitePassword } from '../../components/templates/admin-customers/edit-representative-site-password'
import { webRequest } from '../../types/handle-api'
import { SquareButton } from '../../components/molecules/sqare-button'

export const AdminCustomers = () => {
  const [customers, setCustomers] = useState<Customer[]>([])

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [editPasswordSite, setEditPasswordSite] = useState<Site>()
  const [stopCustomerId, setStopCustomerId] = useState<number>()

  useEffect(() => {
    async function fetchData() {
      const response = await webRequest('CustomersIndex')
      if (response.status === 200) {
        setCustomers(response.data.customers)
      }
    }
    fetchData()
  }, [])

  async function csvDownload() {
    const response = await webRequest('CustomersIndex')
    if (response.status === 200) {
      const csvData = [
        [
          Lang.customerId,
          Lang.customerName,
          Lang.representativeSiteId,
          Lang.representativeSiteName,
        ],
        ...response.data.customers.map((customer) => [
          customer.customerIdentifier,
          customer.name,
          customer.representativeSite?.siteIdentifier,
          customer.representativeSite?.name,
        ]),
      ]
      downloadCsv(csvData, `customers-${new Date().toISOString()}.csv`)
    }
  }

  async function stopCustomer() {
    if (!stopCustomerId) return

    const response = await webRequest('CustomersDestroy', {
      id: stopCustomerId,
    })
    if (response.status === 200) {
      window.location.reload()
    }
    // TODO: impl here
  }

  const titleRightElement = (
    <div>
      <NewButton onClick={() => setShowCreateModal(true)} />
    </div>
  )

  return (
    <DefaultWithTitleLayout
      title={Lang.admin.customers.title}
      description={Lang.admin.customers.description}
      titleRightElement={titleRightElement}
      csvDownload={csvDownload}
    >
      {customers && (
        <VixellTable
          rootClassName="my-64"
          classNames={[
            'w-200',
            'w-200',
            'w-200',
            'w-200',
            'flex-1 flex !justify-end px-[42px]',
          ]}
          headers={[
            Lang.customerId,
            Lang.customerName,
            Lang.representativeSiteId,
            Lang.representativeSiteName,
            '',
          ]}
          elements={customers.map((customer) => [
            customer.customerIdentifier,
            customer.name,
            customer.representativeSite?.siteIdentifier,
            customer.representativeSite?.name,
            <SquareButton
              key={customer.id}
              color="blue"
              onClick={() => setEditPasswordSite(customer.representativeSite)}
            >
              {Lang.changePassword}
            </SquareButton>,
            <SquareButton
              key={customer.id}
              color="red"
              onClick={() => setStopCustomerId(customer.id)}
            >
              {Lang.stopUsing}
            </SquareButton>,
          ])}
        />
      )}
      {showCreateModal && (
        <CreateCustomer hide={() => setShowCreateModal(false)} />
      )}
      {stopCustomerId && (
        <ConfirmModal
          title={Lang.confirmStop}
          ok={stopCustomer}
          cancel={() => setStopCustomerId(undefined)}
        />
      )}
      {editPasswordSite && (
        <EditRepresentativeSitePassword
          representativeSite={editPasswordSite}
          hide={() => setEditPasswordSite(undefined)}
        />
      )}
    </DefaultWithTitleLayout>
  )
}
