import { Modal } from '../../molecules/modal'
import { Title } from '../../part/title'
import { InputWithLabel } from '../../molecules/input-with-label'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Select } from '../../molecules/select'
import { ShippingStateValue } from '../../../types/typescript-axios'
import { Button } from '../../molecules/button'
import { Lang } from '../../../translations/lang'

const ShippingStateSelects = [
  [Lang.all, ''] as const,
  [
    Lang.shippingStateName.STOCKED,
    ShippingStateValue.Stocked as string,
  ] as const,
  [
    Lang.shippingStateName.SHIPPED,
    ShippingStateValue.Shipped as string,
  ] as const,
  [
    Lang.shippingStateName.RETURNED,
    ShippingStateValue.Returned as string,
  ] as const,
  [
    Lang.shippingStateName.DISPOSED,
    ShippingStateValue.Disposed as string,
  ] as const,
]

const OkNgs = [
  [Lang.onsiteResults.okNg, ''] as const,
  [Lang.onsiteResults.onlyNg, 'ng'] as const,
]

const IcePackParts = [
  [Lang.all, ''] as const,
  [Lang.icePack.TOP, 'TOP'] as const,
  [Lang.icePack.BOTTOM, 'BOTTOM'] as const,
  [Lang.icePack.FRONT, 'FRONT'] as const,
  [Lang.icePack.BACK, 'BACK'] as const,
  [Lang.icePack.LEFT, 'LEFT'] as const,
  [Lang.icePack.RIGHT, 'RIGHT'] as const,
]

type Props = {
  hide: () => void
}
export const AdminIcePackSearchModal = ({ hide }: Props) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [temperatureSensorTagEpc, setTemperatureSensorTagEpc] =
    useState<string>('')
  const [siteName, setSiteName] = useState<string>('')
  const [contractNo, setContractNo] = useState<string>('')
  const [shippingState, setShippingState] = useState<string>('')
  const [shippingUpdatedDateFrom, setShippingUpdatedDateFrom] =
    useState<string>('')
  const [shippingUpdatedDateTill, setShippingUpdatedDateTill] =
    useState<string>('')
  const [calibrationFrom, setCalibrationFrom] = useState<string>('')
  const [calibrationTill, setCalibrationTill] = useState<string>('')
  const [part, setPart] = useState<string>('')
  const [onlyNg, setOnlyNg] = useState<string>('')
  const [inspectFrom, setInspectFrom] = useState<string>('')
  const [inspectTill, setInspectTill] = useState<string>('')

  useEffect(() => {
    setTemperatureSensorTagEpc(searchParams.get('serial') || '')
    setSiteName(searchParams.get('siteName') || '')
    setContractNo(searchParams.get('contractNo') || '')
    setShippingState(searchParams.get('shippingState') || '')
    setShippingUpdatedDateFrom(searchParams.get('shippingFrom') || '')
    setShippingUpdatedDateTill(searchParams.get('shippingTill') || '')
    setCalibrationFrom(searchParams.get('calibrationFrom') || '')
    setCalibrationTill(searchParams.get('calibrationTill') || '')
    setPart(searchParams.get('part') || '')
    setOnlyNg(searchParams.get('ng') || '')
    setInspectFrom(searchParams.get('inspectFrom') || '')
    setInspectTill(searchParams.get('inspectTill') || '')
  }, [searchParams])

  function search() {
    const params = new URLSearchParams()
    if (temperatureSensorTagEpc) params.set('serial', temperatureSensorTagEpc)
    if (siteName) params.set('siteName', siteName)
    if (contractNo) params.set('contractNo', contractNo)
    if (shippingState) params.set('shippingState', shippingState)
    if (shippingUpdatedDateFrom)
      params.set('shippingFrom', shippingUpdatedDateFrom)
    if (shippingUpdatedDateTill)
      params.set('shippingTill', shippingUpdatedDateTill)
    if (onlyNg) params.set('ng', onlyNg)
    if (part) params.set('part', part)
    if (calibrationFrom) params.set('calibrationFrom', calibrationFrom)
    if (calibrationTill) params.set('calibrationTill', calibrationTill)
    if (inspectFrom) params.set('inspectFrom', inspectFrom)
    if (inspectTill) params.set('inspectTill', inspectTill)

    hide()
    navigate(`/ice-packs?${params}`)
  }

  return (
    <Modal>
      <Title title={Lang.detailedSearch} />
      <InputWithLabel
        className="mt-32"
        label={Lang.icePackSerialNo}
        value={temperatureSensorTagEpc}
        onChange={setTemperatureSensorTagEpc}
      />
      <InputWithLabel
        className="mt-24"
        label={Lang.contractRepresentativeNo}
        value={contractNo}
        onChange={setContractNo}
      />
      <InputWithLabel
        className="mt-24"
        label={Lang.admin.vixells.latestSiteName}
        value={siteName}
        onChange={setSiteName}
      />

      <div className="flex flex-col mt-24">
        <label>{Lang.shippingState}</label>
        <Select
          className="mt-12"
          value={shippingState}
          options={ShippingStateSelects}
          onChange={setShippingState}
        />
      </div>

      <div className="mt-24">
        <label>{Lang.shippingStateUpdatedDate}</label>
        <div className="flex items-center gap-[12px] mt-12">
          <input
            type="date"
            className="w-[160px]"
            value={shippingUpdatedDateFrom}
            onChange={(e) => setShippingUpdatedDateFrom(e.target.value)}
          />
          <span className="inline-block h-[36px] text-3xl text-gray-300">
            〜
          </span>
          <input
            type="date"
            className="w-[160px]"
            value={shippingUpdatedDateTill}
            onChange={(e) => setShippingUpdatedDateTill(e.target.value)}
          />
        </div>
      </div>

      <div className="mt-24">
        <label>{Lang.admin.icePacks.calibrationDate}</label>
        <div className="flex items-center gap-[12px] mt-12">
          <input
            type="date"
            className="w-[160px]"
            value={calibrationFrom}
            onChange={(e) => setCalibrationFrom(e.target.value)}
          />
          <span className="inline-block h-[36px] text-3xl text-gray-300">
            〜
          </span>
          <input
            type="date"
            className="w-[160px]"
            value={calibrationTill}
            onChange={(e) => setCalibrationTill(e.target.value)}
          />
        </div>
      </div>

      <div className="flex flex-col mt-24">
        <label>{Lang.icePackPart}</label>
        <Select
          className="mt-12"
          value={part}
          options={IcePackParts}
          onChange={setPart}
        />
      </div>

      <div className="flex flex-col mt-24">
        <label>{Lang.lastInspectionResult}</label>
        <Select
          className="mt-12"
          value={onlyNg}
          options={OkNgs}
          onChange={setOnlyNg}
        />
      </div>

      <div className="mt-24">
        <label>{Lang.lastInspectionTime}</label>
        <div className="flex items-center gap-[12px] mt-12">
          <input
            type="date"
            className="w-[160px]"
            value={inspectFrom}
            onChange={(e) => setInspectFrom(e.target.value)}
          />
          <span className="inline-block h-[36px] text-3xl text-gray-300">
            〜
          </span>
          <input
            type="date"
            className="w-[160px]"
            value={inspectTill}
            onChange={(e) => setInspectTill(e.target.value)}
          />
        </div>
      </div>

      <div className="flex justify-center gap-[32px] mt-40">
        <Button color="red" onClick={hide}>
          {Lang.cancel}
        </Button>
        <Button color="blue" onClick={search}>
          {Lang.search}
        </Button>
      </div>
    </Modal>
  )
}
