import { orderList } from './order-list/en'
import { orderDetail } from './order-detail/en'
import { stock } from './sp/stock/en'
import { menu } from './sp/menu/en'
import { ship } from './sp/ship/en'
import { lend } from './sp/lend/en'
import { returning } from './sp/return/en'
import { lostItem } from './sp/lost-item/en'
import { defective } from './sp/defective/en'

export const reusable = {
  dashboard: {
    title: 'Dashboard',
    site: 'Site',
    typeS: 'Type-S',
    typeL: 'Type-L',
    logger: 'Logger',
    box: 'BOX',
    r5: 'R5',
    c5: 'C5',
    s4: 'S4',
    f4: 'F4',
    d: 'D',
    gf: 'GF',
    ge: 'GE',
  },
  state: {
    CONFIRMED: 'Confirmed',
    NON_CONFIRMED: 'Not Confirmed',
    CANCELLED: 'Cancel',
  },
  loggingStartReservationType: {
    WHEN_SHIP_FROM_PANASONIC: 'Recording starts when Panasonic ships',
    MANUAL: 'Manual (starts when the customer presses START)',
    SPECIFIC_TIME: 'Time specification start (yyyy/mm/dd hh:mm:ss(TimeZone))',
  },
  reusableOrder: {
    orderNumber: 'OrderNo',
    status: 'Status',
    customerCompanyName: 'Customer',
    customerCompanyDivision: 'Division',
    customerPhone: 'Contact',
    notesForDistributionCenter: 'Order Memo',
    consignorInfo: 'Delivery(Shipper)',
    deliveryDateToConsignor: 'Delivery date',
    countryOfConsignor: 'Country',
    stateOfConsignor: 'States',
    addressOfConsignor: 'Address',
    zipCodeOfConsignor: 'ZIP Code',
    companyNameOfConsignor: 'Company',
    divisionOfConsignor: 'Division',
    phoneOfConsignor: 'Contact',
    notesForConsignor: 'Delivery causion',
    consigneeInfo: 'Final destination(Consignee)',
    deliveryDateToConsignee: 'Deliver at Final destination',
    countryOfConsignee: 'Country',
    stateOfConsignee: 'States',
    addressOfConsignee: 'Address',
    zipCodeOfConsignee: 'ZIP Code',
    companyNameOfConsignee: 'Company',
    divisionOfConsignee: 'Division',
    phoneOfConsignee: 'Contact',
    preCooling: 'pre-cool',
    preCoolingNotes:
      'Pre-cool Memo : cool keeping period (days) or dry ice quantity (kg)',
    hasLogger: 'Logger',
    loggerModel: 'Model',
    loggerSettingBy: 'In charge of configure',
    loggerSettingByEnum: {
      PANASONIC: 'Panasonic',
      CUSTOMER: 'Customer',
    },
    loggerAlertUpperTemperatureLimit: 'Alert - upper temp.',
    loggerAlertLowerTemperatureLimit: 'Alert - lower temp',
    loggerAlertCapacity: 'Alert - tolerance',
    loggingStartReservationType: 'Logging start',
    loggingStartReservationTime: 'Specified time',
    notesForDelivery: 'Memo for Customer',
    loggerFilePath: 'Logging report',
  },
  searchModal: {
    title: 'Order Search',
    orderStatus: 'Order status',
    deliveryDateToConsignor: 'Delivery date',
    deliveryDateToConsignee: 'Deliver at Final destination',
    box: 'VIXELL',
    unit: 'Unit',
    preCooling: 'pre-cool',
    logger: 'Logger',
    shippingStatus: 'Shipping status',
    freeText: 'Free Text',
    typeS: 'Type-S',
    typeL: 'Type-L',
    confirmed: 'Confirmed',
    nonConfirmed: 'Not Confirmed',
    cancelled: 'Cancel',
    nonShipped: 'Ordered',
    shipped: 'Shipped',
    returned: 'Returned',
    query: 'Search by word',
  },
  reusableOrderFormDetail: {
    productInfo: 'Product information',
    product: 'Product',
    delete: 'Delete',
    boxSize: 'Box size',
    thermalStorageUnitType: 'Unit type',
    preCooling: 'pre-cool',
    preCoolingNotes:
      'Pre-cool Memo : cool keeping period (days) or dry ice quantity (kg)',
    temperatureLogger: 'Logger',
    temperatureLoggerSettingBy: 'In charge of configure',
    temperatureLoggerSettingByPanasonic: 'Panasonic',
    temperatureLoggerSettingByCustomer: 'Customer',
    temperatureLoggerModel: 'Model',
    loggerAlertUpperTemperatureLimit: 'Alert - upper temp.',
    loggerAlertLowerTemperatureLimit: 'Alert - lower temp.',
    loggerAlertCapacity: 'Alert - tolerance',
    loggingStartReservationType: 'Logging start',
    loggingStartReservationTime: 'Specified time',
    notesForDelivery: 'Memo for Customer',
  },
  reusableOrderEdit: {
    title: 'Edit Order',
  },
  reusableOrderHistory: {
    orderNo: 'Order No',
    tradeType: 'Trade Type',
    processingDateTime: 'Date',
    customerCompanyName: 'Customer',
  },
  reusableAdmin: {
    title: 'Order List',
    description: "Check the customer's order and ship the Boxes.",
    headerTitle: [
      'OrderNo',
      'Customer',
      'Delivery',
      'Final dest.',
      'Product1',
      'Settings',
      'Order QTY',
      'Ship QTY',
      'Return QTY',
      'Print',
      'Order Memo',
    ],
  },
  reusableShipper: {
    title: 'Ship List',
    description: "Check the customer's order and ship the Boxes.",
    headerTitle: [
      'OrderNo',
      'Customer',
      'Delivery',
      'Final dest.',
      'Product1',
      'Settings',
      'Order QTY',
      'Ship QTY',
      'Return QTY',
      'Print',
      'Order Memo',
    ],
  },
  reusableReturn: {
    title: 'Return List',
    description: "Check the customer's order and ship the Boxes.",
    headerTitle: [
      'OrderNo',
      'Customer',
      'Delivery',
      'Final dest.',
      'Product1',
      'Settings',
      'Order QTY',
      'Ship QTY',
      'Return QTY',
      'Print',
      'Order Memo',
    ],
  },
  orderList,
  orderDetail,
  sp: {
    menu,
    stock,
    ship,
    lend,
    returning,
    defective,
    lostItem,
  },
}
