import { OnsiteResultsPressureGraphResponseData } from '../../../types/typescript-axios'
import { memo, useMemo, useState } from 'react'
import moment from 'moment/moment'
import { Title } from '../../part/title'
import { Lang } from '../../../translations/lang'
import { Select } from '../../molecules/select'
import { Link } from 'react-router-dom'
import { PressureGraphChart } from './pressure-graph-chart'
import {
  PressureGraphData,
  PressureGraphXAxisType,
  PressureGraphYAxisType,
  SelectedVixell,
} from '../../../pages/admin/AdminPressureGraph'

const PressureGraphYAxisSelectOptions = [
  [Lang.admin.pressureGraph.yAxisLabel.pressureValue, 'pressureValue'] as const,
  [
    Lang.admin.pressureGraph.yAxisLabel.pressureIncrease,
    'pressureIncrease',
  ] as const,
]
const PressureGraphXAxisSelectOptions = [
  [
    Lang.admin.pressureGraph.xAxisLabel.processingDate,
    'processingDate',
  ] as const,
  [Lang.admin.pressureGraph.xAxisLabel.elapsedDate, 'elapsedDate'] as const,
]

type AdminPressureGraaphEntityProps = {
  stockedFrom?: string
  stockedTill?: string
  model?: string
  yAxisUpper?: number
  yAxisLower?: number
  xAxisUpper?: number
  xAxisLower?: number
  xAxisUpperDate?: string
  xAxisLowerDate?: string
  yRuledLineRange?: number
  xRuledLineRange?: number
  graphData: OnsiteResultsPressureGraphResponseData
}
export const AdminPressureGraphEntity = memo(
  ({
    stockedFrom,
    stockedTill,
    model,
    yAxisUpper,
    yAxisLower,
    xAxisUpper,
    xAxisLower,
    xAxisUpperDate,
    xAxisLowerDate,
    yRuledLineRange,
    xRuledLineRange,
    graphData,
  }: AdminPressureGraaphEntityProps) => {
    // TODO: フィルターを設定
    const [yAxisType, setYAxisType] = useState<
      'pressureValue' | 'pressureIncrease'
    >('pressureIncrease')
    const [xAxisType, setXAxisType] = useState<
      'processingDate' | 'elapsedDate'
    >('elapsedDate')
    const [selectedVixell, setSelectedVixell] = useState<SelectedVixell>()

    const stockedFromMoment = stockedFrom && moment(stockedFrom)
    const stockedTillMoment = stockedTill && moment(stockedTill)

    const formatData = (data: OnsiteResultsPressureGraphResponseData) => {
      return Object.keys(data.results)
        .map((id) => {
          const firstPressure = data.results[id][0].pressure
          const stockedDateTime = data.results[id][0].stockedAt
          const lastProcessingDateTime =
            data.results[id][data.results[id].length - 1].processingAt

          if (!stockedDateTime) return null
          if (
            stockedFromMoment &&
            moment(stockedDateTime).isBefore(stockedFromMoment)
          )
            return null
          if (
            stockedTillMoment &&
            moment(stockedDateTime).isAfter(stockedTillMoment)
          )
            return null

          return {
            id: id, // id が serialNo なので注意
            vixellId: data.results[id][0].id,
            firstPressure,
            stockedDateTime,
            lastProcessingDateTime,
            data: data.results[id]
              .map((d) => {
                if (!d.processingAt) return null
                return {
                  x:
                    xAxisType === 'processingDate'
                      ? moment(d.processingAt).startOf('day').valueOf()
                      : moment(d.processingAt).diff(
                          moment(stockedDateTime),
                          'days'
                        ),
                  y:
                    yAxisType === 'pressureValue'
                      ? d.pressure
                      : d.pressure - firstPressure,
                }
              })
              .filter(Boolean) as unknown as { x: number; y: number }[],
          }
        })
        .filter(Boolean) as unknown as PressureGraphData[]
    }

    const formattedData = useMemo(
      () => graphData && formatData(graphData),
      [graphData, xAxisType, yAxisType, stockedFrom, stockedTill, model]
    )
    const vixellCount = formattedData.length

    return (
      formattedData && (
        <>
          <div className="py-42 px-60 bg-white rounded-[10px]">
            <div className="flex justify-between">
              <Title title={Lang.admin.pressureGraph.title} />
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col justify-end">
                <div className="flex flex-col">
                  <label>{Lang.yAxis}</label>
                  <Select
                    value={yAxisType}
                    options={PressureGraphYAxisSelectOptions}
                    onChange={(value) =>
                      setYAxisType(value as PressureGraphYAxisType)
                    }
                  />
                </div>
              </div>
              <div className="flex flex-col items-start rounded bg-gray-100 p-16 w-[180px]">
                <div className="font-bold text-xs">選択中のVIXELL</div>
                {selectedVixell ? (
                  <Link
                    to={`/vixells/${selectedVixell.vixellId}`}
                    target="_blank"
                  >
                    <div className="text-blue-500 underline">
                      {selectedVixell.vixellSerialNo}
                    </div>
                  </Link>
                ) : (
                  <div>-</div>
                )}
              </div>
            </div>
            <div className="flex"></div>
            <PressureGraphChart
              formattedData={formattedData}
              xAxisType={xAxisType}
              selectedVixell={selectedVixell}
              setSelectedVixell={setSelectedVixell}
              yAxisUpper={yAxisUpper}
              yAxisLower={yAxisLower}
              xAxisUpper={xAxisUpper}
              xAxisLower={xAxisLower}
              xAxisUpperDate={xAxisUpperDate}
              xAxisLowerDate={xAxisLowerDate}
              yRuledLineRange={yRuledLineRange}
              xRuledLineRange={xRuledLineRange}
            />
            <div className="flex justify-between pr-[24px]">
              <div className="rounded bg-gray-100 p-8">
                <div className="text-xs font-bold">
                  {Lang.admin.pressureGraph.displayedVixellCount}
                </div>
                <div>{vixellCount}</div>
              </div>
              <div className="flex flex-col">
                <label>{Lang.xAxis}</label>
                <Select
                  value={xAxisType}
                  options={PressureGraphXAxisSelectOptions}
                  onChange={(value) =>
                    setXAxisType(value as PressureGraphXAxisType)
                  }
                />
              </div>
            </div>
          </div>
        </>
      )
    )
  }
)
AdminPressureGraphEntity.displayName = 'AdminPressureGraphEntity'
