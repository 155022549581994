import { QrCodeReader } from './components/QrCodeReader'
import { useEffect, useState } from 'react'
import { DefaultLayout } from '../../components/templates/detaulf-layout'
import {
  TemperatureLogger,
  ThermalStorageUnit,
  Vixell,
  ReusableOrderDetail,
} from '../../types/typescript-axios'
import { Lang } from '../../translations/lang'
import { useApi } from '../../contexts/api-provider'

export const Lending = () => {
  const { webRequest } = useApi()
  const [qrCodeText, setQrCodeText] = useState<string>('')
  const [errorStatement, setErrorStatement] = useState<string>('')
  const [orderAndOrderDetailNo, setOrderAndOrderDetailNo] = useState<string>('')
  const [vixell, setVixell] = useState<Vixell>()
  const [thermalStorageUnit, setThermalStorageUnit] =
    useState<ThermalStorageUnit>()
  const [temperatureLogger, setTemperatureLogger] =
    useState<TemperatureLogger>()
  const [scanMode, setScanMode] = useState<'order' | 'product' | 'stop'>(
    'order'
  )
  const [showLendingModal, setShowLendingModal] = useState<boolean>(false)
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const [isScanInProgress, setIsScanInProgress] = useState<boolean>(false)
  const [reusableOrderDetail, setReusableOrderDetail] = useState<ReusableOrderDetail>()

  useEffect(() => {
    if (qrCodeText) readQrCodeCore()
  }, [qrCodeText])

  async function checkOrderDetailNumber() {
    const response = await webRequest('ReusableOrderFetchOrderDetail', {
      orderAndOrderDetailNo: qrCodeText,
    })
   
    if (response.status === 200) {
      const orderDetail = response.data.orderDetail

      setReusableOrderDetail(orderDetail)
      setOrderAndOrderDetailNo(qrCodeText)
      setVixell(orderDetail?.vixell)
      setThermalStorageUnit(orderDetail?.thermalStorageUnit)
      setTemperatureLogger(orderDetail?.temperatureLogger)
      setErrorStatement('')
      if (
        !orderDetail?.vixell &&
        !orderDetail?.thermalStorageUnit &&
        !orderDetail?.temperatureLogger
      ) {
        setScanMode('product')
      } else {
        setScanMode('stop')
      }

    } else {
      // TODO
      setErrorStatement(`invalid code ${qrCodeText}.`)
      setQrCodeText('')
    }
  }
  

  async function checkProductCode() {
    setErrorStatement('')
    const [model, serialNo] = qrCodeText.split('_')

    const response = await webRequest('SearchWithSerialNo', {
      model,
      serialNo,
    })

    if (response.status === 200) {
      const { type, data } = response.data
      
      switch (type) {
        case 'VIXELL':
          if (data.model === reusableOrderDetail?.vixellModel) {
            setVixell(data)
          } else {
            setErrorStatement('Wrong model')
            setShowErrorModal(true)
          }
          break
        case 'THERMAL_STORAGE_UNIT':
          if (data.model === reusableOrderDetail?.thermalStorageUnitModel) {
            setThermalStorageUnit(data)
          } else {
            setErrorStatement('Wrong model')
            setShowErrorModal(true)
          }
          break
        case 'TEMPERATURE_LOGGER':
          if (data.model === reusableOrderDetail?.loggerModel) {
            setTemperatureLogger(data)
          } else {
            setErrorStatement('Wrong model')
            setShowErrorModal(true)
          }
          break
        default:
          break
      }
    } else {
      // TODO
      setErrorStatement(`invalid code ${qrCodeText}.`)
    }
  }

  async function readQrCodeCore() {
    console.log('readQrCodeCore')
    if (isScanInProgress) return
    setIsScanInProgress(true)
    try {
      switch (scanMode) {
        case 'order':
          await checkOrderDetailNumber()
          setIsScanInProgress(false)
          break
        case 'product':
          await checkProductCode()
          setIsScanInProgress(false)
          break
        default:
          // NOTE： QR読み取りを停止する
          break
      }
    } finally {
      setIsScanInProgress(false)
    }
  }

  function readQrCode(code: string) {
    setQrCodeText(code)
  }

  function cancel() {
    setQrCodeText('')
    setScanMode('order')
    setErrorStatement('')
    setOrderAndOrderDetailNo('')
    setVixell(undefined)
    setThermalStorageUnit(undefined)
    setTemperatureLogger(undefined)
  }

  async function resetSubmit() {
    const response = await webRequest('ReusableOrderResetOrderDetail', {
      orderAndOrderDetailNo: qrCodeText,
    })

    if (response.status === 200) {
      setQrCodeText('')
      setScanMode('product')
      setVixell(undefined)
      setThermalStorageUnit(undefined)
      setTemperatureLogger(undefined)
    } else {
      // TODO
      setErrorStatement('reset failed')
    }
  }

  async function lendingSubmit() {
    if (!vixell) {
      setErrorStatement('VIXELL is required')
      return
    }
    if (!thermalStorageUnit) {
      setErrorStatement('Unit is required')
      return
    }

    const response = await webRequest('ReusableOrderLending', {
      orderAndOrderDetailNo,
      vixellSerialNo: vixell?.serialNo,
      thermalStorageUnitSerialNo: thermalStorageUnit?.serialNo,
      temperatureLoggerSerialNo: temperatureLogger?.serialNo,
    })

    if (response.status === 200) {
      setShowLendingModal(true)
    } else {
      // TODO
      setErrorStatement('lending failed')
    }
  }

  const ReadOrderIdModeHeader = () => (
    <div>
      <div className="p-[16px]">
        <p className="text-[14px] font-bold">Order Detail Number</p>
        <p className="text-[12px]">Scan Order Detail Number QR Code</p>
      </div>
    </div>
  )

  const ReadProductIdModeHeader = () => (
    <div>
      <div className="p-[16px]">
        <p className="text-[16px] font-bold mb-[16px]">Scan products</p>
        <p className="text-[12px] font-bold mb-[8px]">Order Detail Number</p>
        <p className="text-[12px]">{orderAndOrderDetailNo}</p>
      </div>
    </div>
  )

  const ResetNoticeModeHeader = () => (
    <div>
      <div className="p-[16px]">
        <p className="text-[14px] font-bold">
          This Order Detail Number is registered
        </p>
        <p className="text-[12px]">
          This order detail number has been registered. Do you want to delete
          the data and create new one?
        </p>
      </div>
    </div>
  )

  const ReadProductIdDetails = () => (
    <div>
      <div className="p-[16px]">
        <p className="text-[14px] font-bold">Scanned products</p>
        <p className="text-[12px] mt-[8px]">
          {Lang.productName.VIXELL} {vixell?.model} {vixell?.serialNo}
        </p>
        <p className="text-[12px] mt-[8px]">
          {Lang.productName.THERMAL_STORAGE_UNIT} {thermalStorageUnit?.model}{' '}
          {thermalStorageUnit?.serialNo}
        </p>
        <p className="text-[12px] mt-[8px]">
          {Lang.productName.TEMPERATURE_LOGGER} {temperatureLogger?.model}{' '}
          {temperatureLogger?.serialNo}
        </p>
      </div>
      <div className="p-[16px] flex justify-between mb-[20px]">
        <button
          className="cursor-pointer w-[140px] h-[36px] rounded-[100px] bg-[#EB5757] text-white font-bold text-[14px] shadow-vixell"
          onClick={cancel}
        >
          Cancel
        </button>
        <button
          className="cursor-pointer w-[140px] h-[36px] rounded-[100px] bg-[#00BCD4] text-white font-bold text-[14px] shadow-vixell"
          onClick={lendingSubmit}
        >
          Submit
        </button>
      </div>
    </div>
  )
  const ResetNoticeDetails = () => (
    <div>
      <div className="p-[16px]">
        <p className="text-[14px] font-bold">Registered products.</p>
        <p className="text-[12px] mt-[8px]">
          VIXELL {vixell?.model} {vixell?.serialNo}
        </p>
        <p className="text-[12px] mt-[8px]">
          Unit {thermalStorageUnit?.model} {thermalStorageUnit?.serialNo}
        </p>
        <p className="text-[12px] mt-[8px]">
          Logger {temperatureLogger?.model} {temperatureLogger?.serialNo}
        </p>
      </div>
      <div className="p-[16px] flex justify-between mb-[20px]">
        <button
          className="cursor-pointer w-[140px] h-[36px] rounded-[100px] bg-[#00BCD4] text-white font-bold text-[14px] shadow-vixell"
          onClick={cancel}
        >
          Cancel
        </button>
        <button
          className="cursor-pointer w-[140px] h-[36px] rounded-[100px] bg-[#EB5757] text-white font-bold text-[14px] shadow-vixell"
          onClick={resetSubmit}
        >
          Proceed anyway
        </button>
      </div>
    </div>
  )

  const LendingModal = () => {
    return (
      <div className=" fixed top-0 bottom-0 right-0 left-0 flex justify-center items-center bg-black bg-opacity-50">
        <div className="p-[24px] flex flex-col w-[280px] items-center rounded-[3px] bg-white">
          <p className="text-[14px]">Completed to lend products.</p>
          <button
            className="cursor-pointer w-[140px] h-[36px] rounded-[100px] bg-[#00BCD4] text-white font-bold text-[14px] mt-[16px] shadow-vixell"
            onClick={() => {
              cancel()
              setShowLendingModal(false)
            }}
          >
            OK
          </button>
        </div>
      </div>
    )
  }

  const ErrorModal = () => {
    return (
      <div className=" fixed top-0 bottom-0 right-0 left-0 flex justify-center items-center bg-black bg-opacity-50">
        <div className="p-[24px] flex flex-col w-[280px] items-center rounded-[3px] bg-white">
          <p className="text-[14px]">Scanned Wrong model</p>
          <button
            className="cursor-pointer w-[140px] h-[36px] rounded-[100px] bg-[#00BCD4] text-white font-bold text-[14px] mt-[16px] shadow-vixell"
            onClick={() => {
              setShowErrorModal(false)
            }}
          >
            OK
          </button>
        </div>
      </div>
    )
  }

  return (
    <DefaultLayout>
      <div>
        <div className="p-[16px] bg-[#2196F3]">
          <p className="text-[18px] font-bold text-white">
            {Lang.reusable.sp.lend.title}
          </p>
        </div>
        {scanMode == 'order' && <ReadOrderIdModeHeader />}
        {scanMode == 'product' && <ReadProductIdModeHeader />}
        {scanMode == 'stop' && <ResetNoticeModeHeader />}
        <div className={scanMode == 'stop' ? 'hidden' : ''}>
          <QrCodeReader
            onReadQRCode={(result) => {
              readQrCode(result.getText())
            }}
          />
        </div>
        {scanMode == 'product' && <ReadProductIdDetails />}
        {scanMode == 'stop' && <ResetNoticeDetails />}
        {errorStatement && (
          <p className="flex justify-center my-32 text-vixell-red text-sm">
            {errorStatement}
          </p>
        )}
      </div>
      {showLendingModal && <LendingModal />}
      {showErrorModal && <ErrorModal />} 
    </DefaultLayout>
  )
}
