import React, { ReactNode, useContext } from 'react'
import { webRequest } from '../types/handle-api'

type ApiContextType = {
  webRequest: typeof webRequest
  isApiRequesting: boolean
}
export const Context = React.createContext<ApiContextType | null>(null)

type Props = {
  children: ReactNode
}
export const ApiContext = ({ children }: Props) => {
  const [isApiRequesting, setIsApiRequesting] = React.useState<boolean>(false)

  const request: typeof webRequest = async (type, data) => {
    setIsApiRequesting(true)
    const result = await webRequest(type, data)
    setIsApiRequesting(false)
    return result
  }

  return (
    <Context.Provider value={{ webRequest: request, isApiRequesting }}>
      {children}
      {isApiRequesting && (
        <div
          className="fixed flex justify-center items-center top-0 left-0 w-screen h-screen z-50 "
          style={{ background: 'rgba(0,0,0,.2)' }}
        >
          <svg
            width="72"
            height="72"
            viewBox="0 0 38 38"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient
                x1="8.042%"
                y1="0%"
                x2="65.682%"
                y2="23.865%"
                id="a"
              >
                <stop stopColor="#fff" stopOpacity="0" offset="0%" />
                <stop stopColor="#fff" stopOpacity=".631" offset="63.146%" />
                <stop stopColor="#fff" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
              <g transform="translate(1 1)">
                <path
                  d="M36 18c0-9.94-8.06-18-18-18"
                  id="Oval-2"
                  stroke="url(#a)"
                  strokeWidth="2"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="0.9s"
                    repeatCount="indefinite"
                  />
                </path>
                <circle fill="#fff" cx="36" cy="18" r="1">
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    from="0 18 18"
                    to="360 18 18"
                    dur="0.9s"
                    repeatCount="indefinite"
                  />
                </circle>
              </g>
            </g>
          </svg>
        </div>
      )}
    </Context.Provider>
  )
}

export function useApi() {
  return useContext(Context) as ApiContextType
}
