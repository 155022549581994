import { FC } from 'react'
import { Header } from '../part/header'
import { Footer } from '../part/footer'

type Props = {
  infiniteLoad?: () => void
}
export const DefaultLayout: FC<Props> = ({ infiniteLoad, children }) => (
  <div className="flex flex-col justify-between min-h-screen bg-[#F9FBFD]">
    <div>
      <Header />
      <div className="w-[90%] max-w-[1440px] mx-auto py-[42px]">{children}</div>
    </div>
    <Footer infiniteLoad={infiniteLoad} />
  </div>
)
