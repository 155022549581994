import { FC } from 'react'

type Props = {
  fullContractNo?: string
  managementNo?: string
}
export const TableElementFullContractNoAndManagementNo: FC<Props> = ({
  fullContractNo,
  managementNo,
}) => (
  <div className="flex flex-col items-center">
    <div>{fullContractNo}</div>
    <div>{managementNo}</div>
  </div>
)
