import { DefaultWithTitleLayout } from '../components/templates/default-with-title-layout'
import { FormEvent, useEffect, useState } from 'react'
import { Site } from '../types/typescript-axios'
import { Button } from '../components/molecules/button'
import { getLoginToken, setLoginToken } from '../local-storage/login-token'
import { Lang } from '../translations/lang'
import { webRequest } from '../types/handle-api'

export const Me = () => {
  const [site, setSite] = useState<Site>()
  const [name, setName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  const [successStatement, setSuccessStatement] = useState<string>()
  const [errorStatement, setErrorStatement] = useState<string>()

  useEffect(() => {
    async function fetchData() {
      const response = await webRequest('MeShow')
      console.log('response!!!')
      if (response.status === 200) {
        setSite(response.data.site)
        setName(response.data.site.name)
      }
    }

    fetchData()
  }, [])

  async function submit(e: FormEvent) {
    e.preventDefault()
    setErrorStatement(undefined)
    setSuccessStatement(undefined)
    if (password && password !== passwordConfirmation)
      return setErrorStatement(Lang.me.passwordAndConfirmationIsDifferent)

    const response = await webRequest('MeUpdate', {
      name,
      ...(password && { password }),
    })
    if (response.status === 200) {
      setSuccessStatement(Lang.me.successMessage)
      setPassword('')
      setPasswordConfirmation('')

      if (name) {
        const token = getLoginToken()
        if (token) {
          setLoginToken({
            ...token,
            name,
          })
        }
      }
      if (password) window.location.reload()
    }
  }

  return (
    <DefaultWithTitleLayout
      title={Lang.me.title}
      description={Lang.me.description}
    >
      {site && (
        <form onSubmit={submit}>
          <div className="container rounded-[10px] bg-white p-32 shadow-vixell mt-42 min-h-[464px]">
            <div>
              <label>{Lang.me.accountName}</label>
              <div className="my-12">
                <input
                  required={true}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <p className="mt-40 text-sm">{Lang.me.passwordUpdateNotice}</p>
            <div className="mt-12">
              <label>{Lang.me.newPassword}</label>
              <div className="my-12">
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  pattern={'^[a-zA-Z\\d]{8,32}$'}
                  title={Lang.passwordFormatTitle}
                />
              </div>
            </div>
            <div className="mt-24">
              <label>{Lang.me.newPasswordConfirmation}</label>
              <div className="my-12">
                <input
                  type="password"
                  value={passwordConfirmation}
                  onChange={(e) => setPasswordConfirmation(e.target.value)}
                />
              </div>
            </div>
          </div>
          {successStatement && (
            <p className="flex justify-center my-32 text-sm font-bold">
              {successStatement}
            </p>
          )}
          {errorStatement && (
            <p className="flex justify-center my-32 text-vixell-red text-sm">
              {errorStatement}
            </p>
          )}
          <div className="flex justify-center my-32">
            <Button color="cyan">{Lang.update}</Button>
          </div>
        </form>
      )}
    </DefaultWithTitleLayout>
  )
}
