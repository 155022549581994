import { SiteType } from '../types/typescript-axios'

type LOGIN_TOKEN_VERSION = 1

const LOGIN_TOKEN_STRING = 'loginToken'
export type LoginToken = {
  version: LOGIN_TOKEN_VERSION
  token: string
  siteType: SiteType
  siteIdentifier: string
  name: string
}

export function setLoginToken(token: LoginToken) {
  localStorage.setItem(LOGIN_TOKEN_STRING, JSON.stringify(token))
}

export function deleteLoginToken() {
  localStorage.removeItem(LOGIN_TOKEN_STRING)
}

export function getLoginToken(): LoginToken | null {
  const tokenString = localStorage.getItem(LOGIN_TOKEN_STRING)
  if (!tokenString) return null

  return JSON.parse(tokenString) as LoginToken
}

export function isLogin(): boolean {
  return !!getLoginToken()
}
