// title
// ○ label1, ● label2
// みたいなコンポーネント

import { Lang } from '../../../translations/lang'
import { RadioButtonWithLabel } from '../../molecules/radio-button-with-label'
import { Control, FieldValues, Path, PathValue } from 'react-hook-form'
import { HTMLAttributes } from 'react'

type RadioWithLabel<T> = {
  label: string
  value: T
}

type Props<T extends FieldValues, X extends Path<T>> = {
  title: string
  required: boolean
  control: Control<T>
  name: X
  radios: RadioWithLabel<PathValue<T, X>>[]
} & HTMLAttributes<HTMLDivElement>
export const RadioButtonsWithTitleAndLabels = <
  T extends FieldValues,
  X extends Path<T>
>({
  title,
  required,
  control,
  name,
  radios,
  ...props
}: Props<T, X>) => {
  return (
    <div {...props}>
      <div>
        <label>{title}</label>
        {required && <label className="alert ml-8">*{Lang.required}</label>}
      </div>
      <div className="flex mt-8 gap-8">
        {radios.map(({ label, value }, index) => (
          <RadioButtonWithLabel
            key={index}
            label={label}
            control={control}
            name={name}
            value={value}
          />
        ))}
      </div>
    </div>
  )
}
