import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const ReusableordersTop = () => {
  const navigate = useNavigate()

  useEffect(() => {
    console.log('window.innerWidth')
    console.log(window.innerWidth)
    if (window.innerWidth >= 800) {
      navigate(`/reusable-orders/dashboard`)
    } else {
      navigate(`/reusable-orders/menu`)
    }
  }, [])

  return <div />
}
