export const orderDetail = {
  title: 'Order detail',
  description: "Check the customer's order and ship the Boxes.",
  print: {
    title: 'Print documents',
    shippingInstruction: 'Pick list',
    deliverySlip: 'SlipA(Goods)',
    deliveryList: 'SlipB(List)',
    deliveryReceipt: 'SlipC(List)',
  },
  contractInfo: {
    title: 'Contract information',
    status: 'Status',
  },
  siteInCharge: {
    title: 'In charge site',
    shipper: 'Shipping site',
    returnee: 'Returned site',
  },
  productInfo: {
    title: 'Product information',
    add: 'Add product',
  },
}
