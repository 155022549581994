// title
// ○ label1, ● label2
// みたいなコンポーネント

import { Lang } from '../../../translations/lang'
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from 'react-hook-form'
import { HTMLAttributes } from 'react'

type LabeledValue<T> = {
  label: string
  value: T
}
type InsideArray<T extends Array<any>> = T extends Array<infer R> ? R : never

type Props<T extends FieldValues, X extends Path<T>> = {
  title: string
  required: boolean
  control: Control<T>
  name: X
  values: LabeledValue<InsideArray<PathValue<T, X>>>[]
} & HTMLAttributes<HTMLDivElement>
export const CheckBoxesWithTitleAndLabels = <
  T extends FieldValues,
  X extends Path<T>
>({
  title,
  required,
  control,
  name,
  values,
  ...props
}: Props<T, X>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value: currentValues, onChange } }) => (
        <div {...props}>
          <div>
            <label>{title}</label>
            {required && <label className="alert ml-8">*{Lang.required}</label>}
          </div>
          <div className="flex mt-8 gap-16">
            {values.map(({ label, value }, index) => (
              <div
                onClick={() => {
                  if (currentValues.some((v: X) => v === value))
                    onChange(currentValues.filter((v: X) => v !== value))
                  else onChange([...currentValues, value])
                }}
                className="flex items-center"
              >
                <input
                  type="checkbox"
                  className="checkbox"
                  key={index}
                  checked={currentValues.some((v: X) => v === value)}
                  onChange={() => undefined}
                />
                <label className="pl-8 text-base">{label}</label>
              </div>
            ))}
          </div>
        </div>
      )}
    />
  )
}
