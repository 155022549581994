import { useEffect, useState } from 'react'
import {
  IndexVixellsResponseDataComponent,
  ShippingStateValue,
} from '../../types/typescript-axios'

import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { VixellTable } from '../../components/part/vixell-table'
import { TableElementDatetime2Line } from '../../components/part/table-elements/table-element-datetime-2-line'
import { TableHeaderContractAndManagementNo } from '../../components/part/table-headers/table-header-contract-and-management-no'
import { TableElementVixellSerialAndModel } from '../../components/part/table-elements/talbe-element-vixell-serial-and-model'
import { OkIcon } from '../../components/molecules/ok-icon'
import { NgIcon } from '../../components/molecules/ng-icon'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SearchButton } from '../../components/part/search-button'
import { AdminVixellsSearchModal } from '../../components/templates/admin-vixells/admin-vixells-search-modal'
import moment from 'moment'
import { Lang, timezone } from '../../translations/lang'
import { webRequest } from '../../types/handle-api'
import { usePaging } from '../../contexts/use-paging'

export const AdminVixells = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [vixells, setVixells] = useState<IndexVixellsResponseDataComponent[]>(
    []
  )

  const [showSearchModal, setShowSearchModal] = useState<boolean>(false)

  const [loadNextPageAt, setLoadNextPageAt] = useState<Date>(new Date())
  const [searchParamsString, setSearchParamsString] = useState<string>()
  const { currentPage, setPaging, stopPageLoad } = usePaging()

  useEffect(() => {
    fetchVixellsAndSetStates()
  }, [searchParams])

  async function fetchVixellsAndSetStates(loadNextPage = false) {
    const response = await fetchVixells({ loadNextPage })
    if (response.status === 200) {
      const data = response.data
      if (data.type === 'data') {
        if (searchParamsString === searchParams.toString()) {
          setVixells((v) => [...v, ...data.vixells])
        } else {
          setVixells(data.vixells)
        }
        setPaging(data)
        setSearchParamsString(searchParams.toString())
      }
    }
  }

  async function fetchVixells({
    csv = false,
    all = false,
    loadNextPage = false,
  } = {}) {
    const vixellSerialNo = searchParams.get('serial')
    const model = searchParams.get('model')
    const siteName = searchParams.get('siteName')
    const contractNo = searchParams.get('contractNo')
    const shippingState = searchParams.get('shippingState')
    const shippingFrom = searchParams.get('shippingFrom')
    const shippingTill = searchParams.get('shippingTill')
    const onlyNg = searchParams.get('ng')
    const pressureMin = searchParams.get('pressureMin')
    const pressureMax = searchParams.get('pressureMax')
    const inspectFrom = searchParams.get('inspectFrom')
    const inspectTill = searchParams.get('inspectTill')

    return await webRequest('VixellsIndex', {
      ...(vixellSerialNo && { serialNo: vixellSerialNo }),
      ...(model && { model }),
      ...(contractNo && { contractNo }),
      ...(shippingState && {
        shippingStatus: shippingState as ShippingStateValue,
      }),
      ...(siteName && { latestSiteName: siteName }),
      ...(onlyNg && { onlyNg: true }),
      ...(shippingFrom && { latestShippingStateDateTimeFrom: shippingFrom }),
      ...(shippingTill && { latestShippingStateDateTimeTill: shippingTill }),
      ...(pressureMin && { sensorPressureMinimum: Number(pressureMin) }),
      ...(pressureMax && { sensorPressureMaximum: Number(pressureMax) }),
      ...(inspectFrom && { latestBelongingLogDateTimeFrom: inspectFrom }),
      ...(inspectTill && { latestBelongingLogDateTimeTill: inspectTill }),
      ...(all && { all: true }),
      ...(csv && { csv: true, timezone: timezone }),
      ...(!all && loadNextPage && { page: currentPage + 1 }),
    })
  }

  async function csvDownload() {
    const response = await fetchVixells({ csv: true, all: true })
    if (response.status === 200 && response.data.type === 'url') {
      location.href = response.data.url
    }
  }

  function infiniteLoad() {
    if (stopPageLoad) return
    if (moment(loadNextPageAt).isAfter(moment().subtract(3, 'seconds'))) return

    setLoadNextPageAt(new Date())
    fetchVixellsAndSetStates(true)
  }

  const titleRightElement = (
    <div>
      <SearchButton onClick={() => setShowSearchModal(true)} />

      {[
        'serial',
        'contactNo',
        'siteName',
        'shippingState',
        'shippingFrom',
        'shippingTill',
        'calibrationFrom',
        'calibrationTill',
        'ng',
        'part',
        'inspectFrom',
        'inspectTill',
      ]
        .map((param) => searchParams.get(param))
        .filter((x) => x).length > 0 && (
        <p className="flex justify-end text-xs mt-8 font-bold">
          {Lang.searchCondition}
        </p>
      )}
      <div className="absolute flex flex-col content-end flex-wrap  gap-x-[8px] gap-y-[4px] h-[80px] text-xs mt-8">
        {searchParams.get('serial') && (
          <span>
            {Lang.admin.vixells.serial}:{searchParams.get('serial')}
          </span>
        )}
        {searchParams.get('model') && (
          <span>
            {Lang.model}:{searchParams.get('model')}
          </span>
        )}
        {searchParams.get('contractNo') && (
          <span>
            {Lang.admin.vixells.contractNo}:{searchParams.get('contractNo')}
          </span>
        )}
        {searchParams.get('siteName') && (
          <span>
            {Lang.siteName}:{searchParams.get('siteName')}
          </span>
        )}
        {
          // searchParams.get('shippingState') && (
          //   // <span>{Lang.shippingState}:{Lang.shippingStateName[searchParams.get('shippingState') as ShippingStateValue]}</span>
          // )
        }
        {(searchParams.get('shippingFrom') ||
          searchParams.get('shippingTill')) && (
          <span>
            {Lang.shippingStateUpdatedDate}:
            {searchParams.get('shippingFrom') &&
              moment(searchParams.get('shippingFrom')).format(Lang.dateFormat)}
            -
            {searchParams.get('shippingTill') &&
              moment(searchParams.get('shippingTill')).format(Lang.dateFormat)}
          </span>
        )}
        {(searchParams.get('pressureMin') ||
          searchParams.get('pressureMax')) && (
          <span>
            {Lang.admin.vixells.latestPressure}:
            {searchParams.get('pressureMin')}-{searchParams.get('pressureMax')}
          </span>
        )}
        {
          // searchParams.get('onlyNg') && (
          //   <span>{Lang.admin.icePacks.onlyNg}:{Lang.icePack[searchParams.get('part') as IcePackPart]}</span>
          // )
        }

        {(searchParams.get('inspectFrom') ||
          searchParams.get('inspectTill')) && (
          <span>
            {Lang.updatedDateTime}:
            {searchParams.get('inspectFrom') &&
              moment(searchParams.get('inspectFrom')).format(Lang.dateFormat)}
            -
            {searchParams.get('inspectTill') &&
              moment(searchParams.get('inspectTill')).format(Lang.dateFormat)}
          </span>
        )}
      </div>
    </div>
  )

  return (
    <DefaultWithTitleLayout
      title={Lang.admin.vixells.title}
      description={Lang.admin.vixells.description}
      titleRightElement={titleRightElement}
      infiniteLoad={infiniteLoad}
      csvDownload={csvDownload}
    >
      {vixells && (
        <VixellTable
          rootClassName="my-64"
          classNames={[
            'flex-1',
            'w-140',
            'flex-1',
            'w-140',
            'w-120',
            'w-120',
            'flex-1',
            'flex-1',
          ]}
          headers={[
            Lang.vixellSerialNo,
            Lang.siteName,
            <TableHeaderContractAndManagementNo key={1} />,
            Lang.shippingState,
            <div className="flex flex-col items-center text-sm" key={1}>
              <div>{Lang.shippingState}</div>
              <div>{Lang.updatedDate}</div>
            </div>,
            Lang.lastInspectionResult,
            Lang.admin.vixells.latestPressure,
            Lang.updatedDateTime,
          ]}
          elements={vixells.map((vixell) => [
            <TableElementVixellSerialAndModel
              serialNo={vixell.serialNo}
              model={vixell.model}
              key={vixell.id}
            />,
            vixell.siteName,
            <div key={vixell.id}>
              <div>{vixell.fullContractNo}</div>
              <div>{vixell.managementNo}</div>
            </div>,
            vixell.shippingState &&
              Lang.shippingStateName[vixell.shippingState],
            vixell.shippingStateProcessingDateTime && (
              <TableElementDatetime2Line
                dateTime={vixell.shippingStateProcessingDateTime}
              />
            ),
            (function (result) {
              return !result ? '' : result === 'Pass' ? <OkIcon /> : <NgIcon />
            })(vixell.inspectionResult),
            vixell.sensorPressure !== null ? `${vixell.sensorPressure}` : '',
            vixell.belongingLogProcessingDateTime && (
              <TableElementDatetime2Line
                dateTime={vixell.belongingLogProcessingDateTime}
              />
            ),
          ])}
          onLineClick={(index) => navigate(`/vixells/${vixells[index].id}`)}
        />
      )}
      {showSearchModal && (
        <AdminVixellsSearchModal hide={() => setShowSearchModal(false)} />
      )}
    </DefaultWithTitleLayout>
  )
}
