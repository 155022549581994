import { FormEvent, useEffect, useState } from 'react'
import {
  ThermalStorageUnitLast,
  TradeAccessory,
  TradesShowResponseComponent,
} from '../../types/typescript-axios'
import { useParams } from 'react-router-dom'
import { InputWithLabel } from '../../components/molecules/input-with-label'
import { NoPaddingLayoutWithTitle } from '../../components/templates/no-padding-layout-with-title'
import { Button } from '../../components/molecules/button'
import { CloseSvg } from '../../components/svg/close-svg'
import moment from 'moment'
import { useApiFunction } from '../../contexts/login-provider'
import { Lang } from '../../translations/lang'
import { webRequest } from '../../types/handle-api'
import { useNavigate } from 'react-router-dom'

export const AdminTradeDetail = () => {
  const handleApi = useApiFunction()
  const navigate = useNavigate()
  const { id } = useParams()
  const [trade, setTrade] = useState<TradesShowResponseComponent>()

  const [tradeType, setTradeType] = useState<string>('')
  const [destination, setDestination] = useState<string>('')
  const [returnEstimatedDate, setReturnEstimatedDate] = useState<string>('')
  const [returnCompletedDate, setReturnCompletedDate] = useState<string>('')
  const [thermalStorageUnits, setThermalStorageUnits] = useState<
    ThermalStorageUnitLast[]
  >([])
  const [accessories, setAccessories] = useState<TradeAccessory[]>([])

  useEffect(() => {
    async function fetchData() {
      const response = await webRequest('TradesShow', {
        id: Number(id),
      })
      if (response.status === 200) {
        const trade = response.data.trade
        setTrade(trade)
        if (trade?.tradeType) setTradeType(trade.tradeType)
        if (trade?.destination) setDestination(trade.destination)
        if (trade?.returnEstimatedDate) {
          setReturnEstimatedDate(
            moment(trade.returnEstimatedDate).format('YYYY-MM-DD')
          )
        }
        if (trade?.returnCompletedDate) {
          setReturnCompletedDate(
            moment(trade.returnCompletedDate).format('YYYY-MM-DD')
          )
        }
        if (trade?.thermalStorageUnits)
          setThermalStorageUnits(trade.thermalStorageUnits)
        if (trade?.accessories) setAccessories(trade.accessories)
      }
    }

    fetchData()
  }, [id])

  function addThermalStorageUnit() {
    setThermalStorageUnits([
      ...thermalStorageUnits,
      {
        productCode: '',
        returnCount: 0,
        shippingCount: 0,
      },
    ])
  }

  function updateThermalStorageUnit(index: number, unit: TradeAccessory) {
    thermalStorageUnits[index] = unit
    setThermalStorageUnits([...thermalStorageUnits])
  }

  function deleteThermalStorageUnit(index: number) {
    thermalStorageUnits.splice(index, 1)
    setThermalStorageUnits([...thermalStorageUnits])
  }

  function addAccessory() {
    setAccessories([
      ...accessories,
      {
        productCode: '',
        returnCount: 0,
        shippingCount: 0,
      },
    ])
  }

  function updateAccessory(index: number, unit: TradeAccessory) {
    accessories[index] = unit
    setAccessories([...accessories])
  }

  function deleteAccessory(index: number) {
    accessories.splice(index, 1)
    setAccessories([...accessories])
  }

  async function submit(e: FormEvent) {
    e.preventDefault()
    if (!trade) return

    const response = await webRequest('TradesUpdate', {
      id: trade.id,
      tradeType,
      destination,
      returnEstimatedDate,
      returnCompletedDate,
      thermalStorageUnits,
      accessories,
    })
    if (response.status === 200) {
      window.location.reload()
    }
  }

  return (
    <NoPaddingLayoutWithTitle
      title={Lang.admin.tradeDetail.title}
      description={Lang.admin.tradeDetail.description}
      link={{ label: Lang.admin.tradeDetail.goBackToTrades, link: '/trades' }}
    >
      <div className="container flex gap-[24px] mt-32">
        <div>
          <label>{Lang.contractRepresentativeNo}</label>
          <h2>{trade && trade.contractRepresentativeNo}</h2>
        </div>
        <div>
          <label>{Lang.customerName}</label>
          <h2>{trade && trade.customer?.name}</h2>
        </div>
      </div>
      <div className="container mt-32">
        <div>
          <label>関連リンク</label>
        </div>
        <div className='flex gap-[24px] mb-16'>
          <button
            onClick={() => navigate(`/vixells?contractNo=${trade?.contractRepresentativeNo}`)}
            className='text-[24px] text-vixell-blue'
          >
            {Lang.admin.vixells.title}
          </button>
          <button
            onClick={() => navigate(`/ice-packs?contractNo=${trade?.contractRepresentativeNo}`)}
            className='text-[24px] text-vixell-blue'
          >
            {Lang.admin.icePacks.title}
          </button>
          <button
            onClick={() => navigate(`/inspection-boards?search=${trade?.contractRepresentativeNo}`)}
            className='text-[24px] text-vixell-blue'
          >
            {Lang.admin.inspectionBoards.title}
          </button>
        </div>
      </div>
      <form onSubmit={submit}>
        <div className="container mt-24 rounded-10 p-32 bg-white border border-vixell-dark">
          <InputWithLabel
            className="mt-12"
            label={Lang.tradeType}
            value={tradeType}
            onChange={setTradeType}
          />
          <InputWithLabel
            className="mt-12"
            label={Lang.destination}
            value={destination}
            onChange={setDestination}
          />
          <InputWithLabel
            className="mt-12"
            type="date"
            label={Lang.returnEstimatedDate}
            value={returnEstimatedDate}
            onChange={setReturnEstimatedDate}
          />
          <InputWithLabel
            className="mt-12"
            type="date"
            label={Lang.returnCompletedDate}
            value={returnCompletedDate}
            onChange={setReturnCompletedDate}
          />
        </div>

        {thermalStorageUnits?.map((unit, index) => (
          <div className="container mt-32" key={index}>
            <h3>
              {Lang.thermalStorageUnit}
              {index + 1}
            </h3>
            <div className="relative bg-white mt-12 p-32 border border-vixell-dark">
              <div
                onClick={() => deleteThermalStorageUnit(index)}
                className="absolute top-[32px] right-[32px] cursor-pointer"
              >
                <CloseSvg />
              </div>
              <InputWithLabel
                label={Lang.productCode}
                value={unit.productCode || ''}
                onChange={(value) => {
                  unit.productCode = value
                  updateThermalStorageUnit(index, unit)
                }}
                required={true}
              />
              <InputWithLabel
                type="number"
                label={Lang.shippingCount}
                value={
                  unit.shippingCount === undefined ? '' : unit.shippingCount
                }
                onChange={(value) => {
                  unit.shippingCount = Number(value)
                  updateThermalStorageUnit(index, unit)
                }}
                required={true}
              />
              <InputWithLabel
                type="number"
                label={Lang.returnCount}
                value={unit.returnCount === undefined ? '' : unit.returnCount}
                onChange={(value) => {
                  unit.returnCount = Number(value)
                  updateThermalStorageUnit(index, unit)
                }}
                required={true}
              />
            </div>
          </div>
        ))}

        {thermalStorageUnits.length < 5 && (
          <div className="flex justify-center mt-24">
            <Button
              type="button"
              onClick={addThermalStorageUnit}
              className="w-[286px]"
              color="green"
            >
              {Lang.admin.tradeDetail.addThermalStorageUnit}
            </Button>
          </div>
        )}

        {accessories?.map((accessory, index) => (
          <div className="container mt-32" key={index}>
            <h3>
              {Lang.accessory}
              {index + 1}
            </h3>
            <div className="relative bg-white mt-12 p-32 border border-vixell-dark">
              <div
                onClick={() => deleteAccessory(index)}
                className="absolute top-[32px] right-[32px] cursor-pointer"
              >
                <CloseSvg />
              </div>
              <InputWithLabel
                label={Lang.productCode}
                value={accessory.productCode || ''}
                onChange={(value) => {
                  accessory.productCode = value
                  updateAccessory(index, accessory)
                }}
                required={true}
              />
              <InputWithLabel
                type="number"
                label={Lang.shippingCount}
                value={
                  accessory.shippingCount === undefined
                    ? ''
                    : accessory.shippingCount
                }
                onChange={(value) => {
                  accessory.shippingCount = Number(value)
                  updateAccessory(index, accessory)
                }}
                required={true}
              />
              <InputWithLabel
                type="number"
                label={Lang.returnCount}
                value={
                  accessory.returnCount === undefined
                    ? ''
                    : accessory.returnCount
                }
                onChange={(value) => {
                  accessory.returnCount = Number(value)
                  updateAccessory(index, accessory)
                }}
                required={true}
              />
            </div>
          </div>
        ))}

        {accessories.length < 5 && (
          <div className="flex justify-center mt-24">
            <Button
              type="button"
              onClick={addAccessory}
              className="w-[286px]"
              color="green"
            >
              {Lang.admin.tradeDetail.addAccessory}
            </Button>
          </div>
        )}

        <div className="flex justify-center my-42 pt-32 border-t border-t-[#B0BEC5] w-screen">
          <Button color="cyan">{Lang.update}</Button>
        </div>
      </form>
    </NoPaddingLayoutWithTitle>
  )
}
