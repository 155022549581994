import React, { useContext } from 'react'
import { ReusableOrder, ReusableOrderDetail } from '../types/typescript-axios'

type ContextType = {
  order?: ReusableOrder
  orderDetail?: ReusableOrderDetail
}
export const OrderDetailContext = React.createContext<ContextType>({})

export const useOrderDetail = () => {
  return useContext(OrderDetailContext) as Required<ContextType>
}
