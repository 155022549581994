import { reusable } from './reusable/en'
import { inventories } from './inventories/en'

export const En = {
  vixell: 'VIXELL',
  thermalStorageUnit: 'Thermal storage unit',
  temperatureLogger: 'Temperature Logger',
  icePackName: 'Ice pack',
  inspectionBoard: 'Inspection board',
  serialNo: 'Serial number',
  siteId: 'Site ID',
  siteName: 'Site name',
  siteType: 'Site type',
  belongedSite: 'Belonged site',
  representativeSiteId: 'Representative site ID',
  representativeSiteName: 'Representative site name',
  customerId: 'Customer ID',
  customerName: 'Customer name',
  vixellSerialNo: 'VIXELL-S/N',
  icePackSerialNo: 'PCM S/N',
  inspectionBoardSerialNo: 'Inspection board S/N',
  thermalStorageUnitSerialNo: 'Thermal storage unit S/N',
  temperatureLoggerSerialNo: 'Temperature logger S/N',
  model: 'model',
  icePackErrorDetail: 'Ice pack error detail',
  contractRepresentativeNo: 'Contract Representative No.',
  contractNo: 'Contract No.',
  managementNo: 'Management No.',
  contractNoAndManagementNo: 'Contract No./Management No.',
  onsiteVacuumResult: 'Inspection result(vacuum)',
  onsiteIcePackResult: 'Inspection result(PCM)',
  inspectionTime: 'Inspection date time',
  inspectionResult: 'Inspection result',
  inspectionHistory: 'Inspection history',
  lastInspectionTime: 'Latest inspection date time',
  lastInspectionResult: 'Latest inspection result',
  transitionDateTime: 'Transition date time',
  transitionHistory: 'Transition history',
  icePackPart: 'Location',
  tradeType: 'Trade type',
  tradeHistory: 'Trade history',
  reusableOrderHistory: 'Reusable order history',
  notesHistory: 'Note history',
  tradeDateTime: 'Trade date time',
  destination: 'destination',
  returnEstimatedDate: 'Return estimated time',
  returnCompletedDate: 'Return completed time',
  stockedDate: 'Stocked date',
  stockedDateTime: 'Stocked date time',
  disposedDateTime: 'Disposed date time',
  accessory: 'accessory',
  productCode: 'Product code',
  shippingCount: 'Shipping count',
  returnCount: 'Return count',
  shipping: 'Shipping',
  return: 'Return',
  dispose: 'Dispose',
  shippingState: 'Shipping state',
  createdDate: 'Created date',
  updatedDate: 'Updated date',
  updatedDateTime: 'Updated date',
  shippingStateUpdatedDate: 'Shipping state updated time',
  ok: 'OK',
  ng: 'NG',
  already: 'Already',
  notYet: 'Not yet',
  all: 'All',
  new: 'Create new',
  edit: 'Edit',
  delete: 'Delete',
  search: 'Search',
  detailedSearch: 'Search detail',
  filtering: 'Filtering',
  csv: 'CSV',
  stopUsing: 'Stop using',
  changePassword: 'Change Password',
  cancel: 'Cancel',
  dateTimeFormat: 'LL HH:mm:ss',
  dateFormat: 'LL',
  timeFormat: 'HH:mm:SS',
  password: 'Password',
  passwordConfirmation: 'Confirm password',
  create: 'Create',
  update: 'Update',
  register: 'Register',
  confirmStop: 'Do you really want to stop using?',
  passwordFormatTitle:
    'Alphabet, number are allowed.Characters: min 8 and max 32',
  restrictSiteIdPattern:
    'Alphabet, number, hyphen, underscore are allowed. Characters: min 4 and max 32.',
  searchCondition: 'Search condition',
  required: 'required',
  with: 'Yes',
  without: 'No',
  xAxis: 'X Axis',
  yAxis: 'Y Axis',
  productName: {
    VIXELL: 'VIXELL',
    THERMAL_STORAGE_UNIT: 'Unit',
    TEMPERATURE_LOGGER: 'Logger',
  },
  siteTypeName: {
    CHILD: 'Customer（child）',
    PARENT: 'Customer（representative）',
    ADMIN: 'Manager',
    // TODO:
    REUSABLE_ADMIN: 'REUSABLE_ADMIN',
    REUSABLE_CONTRACT: 'REUSABLE_CONTRACT',
    REUSABLE_NON_RESIDENT: 'REUSABLE_NON_RESIDENT',
    REUSABLE_SHIPPER: 'REUSABLE_SHIPPER',
    REUSABLE_RETURN: 'REUSABLE_RETURN',
  },
  shippingStateName: {
    STOCKED: 'Stocked',
    SHIPPED: 'Shipped',
    RETURNED: 'Returned',
    DISPOSED: 'Disposed',
    DEFECTIVE: 'NG',
    LOST: 'Lost',
    REUSABLE_STOCKED: 'Warehouse-in',
    REUSABLE_SHIPPED: 'Warehouse-out',
    REUSABLE_LEND: 'Shipping for customer',
    REUSABLE_COLLECTED: 'Return from customer',
  },
  icePack: {
    FRONT: 'Front',
    BACK: 'Back',
    BOTTOM: 'Bottom',
    TOP: 'Top',
    LEFT: 'Left',
    RIGHT: 'Right',
  },
  appTypeName: {
    onsite: 'Onsite inspection APP',
    vacuumProcess: 'Vacuum process inspection APP',
    temperatureTagCalibration: 'Temperature Tag Calibration APP',
    shippingManagement: 'Shipping management APP',
  },
  inventoryState: {
    notYet: 'Only not yet',
  },
  header: {
    masters: 'masters',
    inventories: 'Inventory',
    reusableSpMenu: 'Smartphone',
    dashboard: 'Dashboard',
    pressureGraph: 'Pressure Graph',
    customerList: 'Customer list',
    siteList: 'Site List',
    tradeList: 'Trade list',
    vixellList: 'VIXELL list',
    icePackList: 'Ice pack list',
    thermalStorageUnitList: 'Thermal storage unit list',
    temperatureLoggerList: 'Temperature logger list',
    inspectionBoardList: 'Inspection board list',
    inspectionHistory: 'Inspection history',
    appLog: 'APP log',
    inventoryVixell: 'Inventory status(VIXELL)',
    inventoryIcePack: 'Inventory status(PCM)',
    inventoryThermalStorageUnit: 'Inventory status(Unit)',
    inventoryTemperatureLogger: 'Inventory status(Logger)',
    childrenSiteList: 'Sub account',
    updateMe: 'Update account information',
    logout: 'Logout',
    reusableOrderList: 'Reusable order list',
    reusableOrderShippingList: 'Shipping list',
    reusableOrderReturnList: 'Return Schedule list',
    reusableOrderStock: 'Warehouse-in(Storing)',
    reusableOrderShip: 'Warehouse-out(Transfer)',
    reusableOrderLend: 'Shipping for customer',
    reusableOrderReturn: 'Return from customer',
    reusableOrderDefective: 'NG Items',
    reusableOrderLostItem: 'Lost Items',
    reusableOrderProductDetail: '履歴照会',
  },
  footer: {
    privacyPolicy: 'Privacy Policy',
  },
  login: {
    login: 'Login',
    accountId: 'Account ID / Site ID',
    password: 'Password',
    news: 'News',
    green: 'Operational',
  },
  me: {
    title: 'Update account information',
    description: 'Update your account information.',
    accountName: 'Account name',
    passwordUpdateNotice:
      "Please enter the following only if you change your password. (If you don't enter, it won't update.)",
    newPassword: 'New password',
    newPasswordConfirmation: 'Confirm new password',
    passwordAndConfirmationIsDifferent: 'Passwords do not match.',
    successMessage: 'Updated account information.',
  },
  dashboard: {
    title: 'Onsite (Insulation Performance) inspection results (last 3 months)',
    countPostfix: '',
    graphMonthDateFormat: 'MMM DD',
    graphMonthFormat: 'MMM',
    header: {
      stockCount: 'Stock quantity',
      thirtyDays: 'History of daily inspections (last 30 days)',
      twelveMonths: 'History of monthly inspections (last 12 months)',
    },
  },
  sites: {
    title: 'Sub account list',
    description: 'Displays a list of child accounts.',
    createSite: 'Create child account',
  },
  onsiteResults: {
    title: 'On-site inspection list',
    description: 'Displays a list of latest inspection results by vixell.',
    headerIcePackErrorDetail: 'Error detail of PCM',
    displayTarget: 'Display target',
    okNg: 'OK/NG',
    onlyNg: 'Only NG',
    latestOnly: 'Only latest',
  },
  notes: {
    newNote: 'New memo',
  },
  ...inventories,
  admin: {
    dashboard: {
      title: 'VIXELL active status',
      totalShipping: 'Total shipping',
      totalActive: 'VIXELL total active',
      totalDisposed: 'Total disposed',
      batchDateTime: 'Batch date time',
      clientAppError: 'Client APP error',
      last30days: 'last 30 days',
      last12months: 'last 12 months',
      customer: 'Customer',
      stockedCount: 'Stock quantity',
      onsiteVacuum: 'Vacuum inspection',
    },
    pressureGraph: {
      title: 'VIXELL Pressure Graph',
      yAxisLabel: {
        pressureValue: 'Pressure（Abs）',
        pressureIncrease: 'Pressure(Inc)',
      },
      xAxisLabel: {
        processingDate: 'Date',
        elapsedDate: 'Elapsed',
      },
      yAxisRange: 'Upper Limit of Y-axis',
      xAxisRangeForElapsedDate: 'Upper Limit of X-axis for Elapsed Date',
      xAxisRangeForProcessingDate: 'Upper Limit of X-axis for Processing Date',
      onlyLowerError: 'Please set upper value',
      yRuledLineRange: 'Y-axis ruled line interval',
      xRuledLineRange: 'X-axis ruled line interval',
      displayedVixellCount: 'displayed VIXELL count',
    },
    customers: {
      title: 'Customer list',
      description: 'Displays a list of customers.',
    },
    createCustomer: {
      title: 'Create a new customer',
      representativeSitePassword: 'Representative site password',
      representativeSitePasswordConfirmation:
        'Confirm representative site password',
      siteIdError: 'This site ID is already used.',
      customerIdError: 'This customer ID is already used.',
    },
    sites: {
      title: 'Site list',
      description: 'Displays a list of sites.',
      createAdmin: 'Create a new manager',
    },
    createSite: {
      title: 'Create a new manager',
      errorTaken: 'This site ID is already used.',
    },
    trades: {
      title: 'Trade list',
      description: 'Displays a list of trades.',
    },
    tradeDetail: {
      title: 'Edit transaction information',
      description: 'Edit transaction information.',
      goBackToTrades: 'Back to the trade list.',
      addThermalStorageUnit: 'Add thermal storage unit.',
      addAccessory: 'Add accessory.',
      relatedLink: 'Related Links',
    },
    vixells: {
      title: 'VIXELL list',
      description: 'Displays a list of vixells.',
      latestSiteName: 'Latest site name',
      latestPressure: 'Latest sensor pressure',
      latestVoltage: 'Latest sensor Voltage',
      latestInspectionResult: 'Latest inspection result',
      serial: 'VIXELL S/N',
      contractNo: 'Contract No./Management No.',
      IDm: 'IDm',
    },
    vixellDetail: {
      title: 'VIXELL detail',
      goBackToVixells: 'Back to the vixell list.',
      sensorVoltage: 'Sensor voltage',
      sensorPressure: 'Sensor pressure',
      vacuumProcessResult: 'Process inspection results',
      acceptance: 'Acceptance test',
      vacuumEvacuation: 'Vacuum evacuation',
      leakTest1: 'Leak test 1',
      leakTest2: 'Leak test 2',
      vacuumCorrection: 'Vacuum correction',
      idmIssue: 'IDm grant existence',
      numberOfMoistAdsorbent: 'Number of moist adsorbent',
      minimumWeightOfMoistAdsorbent: 'Minimum weight of moist adsorbent',
      maximumWeightOfMoistAdsorbent: 'Maximum weight of moist adsorbent',
      weightOfGasAdsorbent: 'Weight of gas adsorbent',
      componentCheck: 'Component check existence',
      urethaneLotNo: 'Urethane lot No.',
      retryCount: 'Equipment No.',
      evacuationPeriod: 'Evacuation period',
      inspectionResult: 'Inspection result',
      pressureDifferenceFromLeak1: 'Pressure difference from leak 1',
      noncorrectedVoltage:
        'Sensor voltage (after temperature correction before voltage correction)',
      correctedVoltage: 'Collected voltage',
      correctedPressure: 'Collected Pressure',
      environmentalTemperature: 'Environmental temperature',
      referenceVoltage: 'Reference voltage',
      correctionValue: 'Correction value',
      onsiteTiming: 'Inspection timing',
      timing: {
        STOCKED: 'Stocked',
        SHIPPED: 'Shipped',
        RETURNED: 'Returned',
        DISPOSED: 'Disposed',
        ONSITE: 'Onsite',
      },
    },
    icePacks: {
      title: 'Ice pack list',
      description: 'Displays a list of ice packs.',
      calibrationDate: 'Calibration date',
      latestInspectionResult: 'Latest inspection result',
      serial: 'Ice pack S/N',
      contractNo: 'Contact No.',
      onlyNg: 'Only NG',
    },
    icePackDetail: {
      title: 'Ice pack detail',
      goBackToIcePacks: 'Back to the ice pack list',
      icePackTemperature: 'Celsius temperature',
      calibrationResultTitle: 'Process inspection results',
      lastInspection: 'Last inspection',
      calibrationStartDateTime: 'Calibration start date time',
      referenceTemperatureSensorNo: 'Temperature sensor No.',
      calibrationResults: 'Process measurements result',
      referenceTemperature: 'Average temperature',
      tagTemperatureCode: 'Average temperature code',
      rssi: 'RSSI',
      temperatureCorrectionSlope: 'Temperature corrections: slope',
      temperatureCorrectionIntercept: 'Temperature corrections: Intercept',
      rssiCorrectionSlope: 'RSSI corrections: slope',
      rssiCorrectionIntercept: 'RSSI corrections: intercept',
      resultTime: {
        1: '1st time',
        2: '2nd time',
        3: '3rd time',
        4: '4th time',
      },
    },
    onsiteResults: {
      title: 'Onsite inspection list',
      description:
        'Displays a list of latest onsite inspection results by vixell.',
    },
    onsiteResultDetail: {
      title: 'Onsite inspection result detail',
      target: 'Target',
      result: 'Result',
      vacuum: 'Vacuum',
    },
    clientLogs: {
      title: 'Client APP log list',
      description: 'Displays a list of client APP logs.',
      timestamp: 'Time-stamping',
      app: 'APP',
      pcId: 'PcID',
      logLevel: 'Log level',
      logCode: 'Log code',
      csvDownload: 'Download CSV',
    },
    inspectionBoards: {
      title: 'Inspection board list',
      description: 'Displays a list of inspection boards.',
    },
    inspectionBoardDetail: {
      title: 'Inspection board detail',
      description: '',
      goBackToInspectionBoards: 'Back to the inspection board list',
    },
    thermalStorageUnits: {
      title: 'Thermal storage units list',
      description: 'Displays a list of thermal storage units.',
    },
    thermalStorageUnitDetail: {
      title: 'Thermal storage unit detail',
      description: '',
      goBackToInspectionBoards: 'Back to the thermal storage unit list',
    },
    temperatureLoggers: {
      title: 'Temperature logger list',
      description: 'Display a list of temperature loggers',
    },
    temperatureLoggerDetail: {
      title: 'Temperature logger detail',
      description: '',
      goBackToInspectionBoards: 'Back to the temperature logger list',
    },
    reusableOrders: {
      title: 'Shipping List',
      description: "Check the customer's order and ship the Boxes.",
    },
    reusableOrdersNew: {
      title: 'Order Registration',
      description: '',
    },
  },
  reusable,
}
