type Props = {
  serialNo: string
  model?: string
}
export const TableElementVixellSerialAndModel = ({
  serialNo,
  model = undefined,
}: Props) => (
  <div className="flex flex-col items-center">
    <div>{serialNo}</div>
    {model && <div>({model})</div>}
  </div>
)
