export const ExclamationSvg = () => (
  <svg
    width="37"
    height="37"
    viewBox="0 0 37 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.5 32.3752C20.2029 32.3752 21.5833 30.9947 21.5833 29.2918C21.5833 27.589 20.2029 26.2085 18.5 26.2085C16.7971 26.2085 15.4167 27.589 15.4167 29.2918C15.4167 30.9947 16.7971 32.3752 18.5 32.3752Z"
      fill="#F44336"
    />
    <path d="M15.4167 4.625H21.5833V23.125H15.4167V4.625Z" fill="#F44336" />
  </svg>
)
