import { Button } from '../molecules/button'
import { Lang } from '../../translations/lang'
import { PlusSvg } from '../svg/plus-svg'

type Props = {
  onClick?: () => void
}
export const NewButton = ({ onClick = () => undefined }: Props) => (
  <Button color="cyan" onClick={onClick}>
    <span className="flex justify-center items-center gap-[4px]">
      {Lang.new}
      <PlusSvg />
    </span>
  </Button>
)
