export const CheckWithoutCircleSvg = () => (
  <svg
    width="38"
    height="29"
    viewBox="0 0 38 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0909 22.9403L3.02273 13.8507L0 16.8806L12.0909 29L38 3.02985L34.9773 0L12.0909 22.9403Z"
      fill="#4CAF50"
    />
  </svg>
)
