import { Title } from '../../part/title'
import { InputWithLabel } from '../../molecules/input-with-label'
import { FormEvent, useState } from 'react'
import { Button } from '../../molecules/button'
import { Lang } from '../../../translations/lang'
import { webRequest } from '../../../types/handle-api'

type Props = {
  hide: () => void
}
export const CreateCustomer = ({ hide }: Props) => {
  const [customerIdentifier, setCustomerIdentifier] = useState<string>('')
  const [customerName, setCustomerName] = useState<string>('')
  const [siteIdentifier, setSiteIdentifier] = useState<string>('')
  const [siteName, setSiteName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')

  const [errorStatement, setErrorStatement] = useState<string>()

  async function submit(e: FormEvent) {
    e.preventDefault()
    setErrorStatement(undefined)
    if (password && password !== passwordConfirmation)
      return setErrorStatement(Lang.me.passwordAndConfirmationIsDifferent)

    const response = await webRequest('CustomersCreate', {
      customerIdentifier,
      name: customerName,
      representativeSiteIdentifier: siteIdentifier,
      representativeSiteName: siteName,
      representativeSitePassword: password,
    })
    if (response.status === 200) {
      window.location.reload()
    } else {
      console.log(response.data)
      if (response.data.params['representativeSiteIdentifier']) {
        setErrorStatement(Lang.admin.createCustomer.siteIdError)
      } else if (response.data.params['customerIdentifier']) {
        setErrorStatement(Lang.admin.createCustomer.customerIdError)
      }
    }
  }

  return (
    <div className="fixed top-0 left-0 flex justify-center items-center w-screen h-screen bg-modal-background">
      <form
        className="rounded-10 p-42 bg-white w-[800px] max-h-[90%] overflow-scroll"
        onSubmit={submit}
      >
        <Title title={Lang.admin.createCustomer.title} />
        <InputWithLabel
          required={true}
          className="mt-24"
          label={Lang.customerId}
          value={customerIdentifier}
          pattern="^[0-9a-zA-Z\-_]{4,32}$"
          title={Lang.restrictSiteIdPattern}
          onlyCapital={true}
          onChange={setCustomerIdentifier}
        />
        <InputWithLabel
          required={true}
          className="mt-24"
          label={Lang.customerName}
          value={customerName}
          onChange={setCustomerName}
        />
        <InputWithLabel
          required={true}
          className="mt-24"
          label={Lang.representativeSiteId}
          value={siteIdentifier}
          pattern="^[0-9a-zA-Z\-_]{4,32}$"
          title={Lang.restrictSiteIdPattern}
          onlyCapital={true}
          onChange={setSiteIdentifier}
        />
        <InputWithLabel
          required={true}
          className="mt-24"
          label={Lang.representativeSiteName}
          autoComplete="nope"
          value={siteName}
          onChange={setSiteName}
        />
        <InputWithLabel
          type="password"
          required={true}
          className="mt-24"
          label={Lang.admin.createCustomer.representativeSitePassword}
          autoComplete="nope"
          value={password}
          onChange={setPassword}
          pattern={'^[a-zA-Z\\d]{8,32}$'}
          title={Lang.passwordFormatTitle}
        />
        <InputWithLabel
          type="password"
          required={true}
          className="mt-24"
          label={
            Lang.admin.createCustomer.representativeSitePasswordConfirmation
          }
          value={passwordConfirmation}
          onChange={setPasswordConfirmation}
        />
        {errorStatement && (
          <p className="flex justify-center my-32 text-vixell-red text-sm">
            {errorStatement}
          </p>
        )}
        <div className="flex justify-center gap-[48px] mt-[60px]">
          <Button type="button" color="red" onClick={hide}>
            {Lang.cancel}
          </Button>
          <Button color="cyan">{Lang.create}</Button>
        </div>
      </form>
    </div>
  )
}
