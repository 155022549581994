import React from 'react'

type FlexType = 'center' | 'start' | 'end' | 'between' | 'around' | 'evenly'
export type CellProps = React.HTMLAttributes<HTMLDivElement> & {
  layout?: `${FlexType}_${FlexType}`
}
export const Cell = ({
  layout = 'start_center',
  className,
  children,
  ...props
}: CellProps) => {
  const [justify, items] = layout.split('_')
  return (
    <div
      className={`flex justify-${justify} items-${items} ${className}`}
      {...props}
    >
      {children}
    </div>
  )
}
