import { webRequest } from '../../types/handle-api'
import { useState } from 'react'
import { DefaultLayout } from '../../components/templates/detaulf-layout'

export const LostItem = () => {
  const [serialNo, setSerialNo] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [errorStatement, setErrorStatement] = useState<string>('')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  async function submit() {
    setErrorStatement('')
    setIsSubmitting(true)

    const response = await webRequest('ReusableOrderLostItem', {
      serialNo: serialNo,
    })

    if (response.status === 200) {
      setShowModal(true)
      setSerialNo('')
    } else {
      setErrorStatement('Serial Number is Not Found.')
    }

    setIsSubmitting(false)
  }

  const LostItemsModal = () => {
    return (
      <div className="h-full w-full flex justify-center items-center absolute">
        <div className="p-[24px] w-[280px] rounded-[3px] bg-white z-10 text-center">
          <p className="text-[14px]">Completed to register a lost item.</p>
          <button
            className="cursor-pointer w-[140px] h-[36px] rounded-[100px] bg-[#00BCD4] text-white font-bold text-[14px] mt-[16px] shadow-vixell"
            onClick={() => setShowModal(false)}
          >
            OK
          </button>
        </div>
      </div>
    )
  }

  return (
    <DefaultLayout>
      {showModal && <LostItemsModal />}
      {showModal && (
        <div
          className={
            showModal ? 'bg-black w-screen h-screen absolute opacity-50' : ''
          }
          onClick={() => setShowModal(false)}
        ></div>
      )}
      <div>
        <div className="p-[16px] bg-[#757575]">
          <p className="text-[18px] font-bold text-white">Lost Item</p>
        </div>
        <div className="p-[16px]">
          <p className="text-[14px] font-bold">Product Serial Number</p>
          <p className="text-[14px] mt-[8px]">Input Serial Number</p>
        </div>
        <div className="px-[16px] py-[8px]">
          <div className="flex border-[1px] border-[#757575] rounded-[3px]">
            <input
              placeholder="Serial Number"
              className="h-[38px] w-full"
              value={serialNo}
              onChange={(e) => setSerialNo(e.target.value)}
            />
          </div>
        </div>
        <span className="pl-[16px] text-[red]">{errorStatement}</span>
        <div className="p-[16px] flex justify-center">
          <button
            className={`${
              isSubmitting ? 'cursor-not-allowed' : 'cursor-pointer'
            } w-[140px] h-[36px] rounded-[100px] bg-[#00BCD4] text-white font-bold text-[14px] shadow-vixell`}
            onClick={submit}
          >
            Submit
          </button>
        </div>
      </div>
    </DefaultLayout>
  )
}
