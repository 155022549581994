import { useEffect, useState } from 'react'
import { DashboardForCustomer } from '../types/typescript-axios'
import { Title } from '../components/part/title'
import { OkIcon } from '../components/molecules/ok-icon'
import { NgIcon } from '../components/molecules/ng-icon'
import { injectComma } from '../utils/inject-comma'
import { VixellTable } from '../components/part/vixell-table'
import { Bar, BarChart, Tooltip, XAxis, YAxis } from 'recharts'
import moment from 'moment'
import { Lang } from '../translations/lang'
import { DefaultWithTitleLayout } from '../components/templates/default-with-title-layout'
import { webRequest } from '../types/handle-api'
import { useLogin } from '../contexts/login-provider'
import { LineColorProps } from '../components/part/vixell-table'

export const Dashboard = () => {
  const [totalOk, setTotalOk] = useState<number>(0)
  const [totalNg, setTotalNg] = useState<number>(0)
  const [dashboards, setDashboards] = useState<DashboardForCustomer[]>()
  const [lineColors, setLineColors] = useState<LineColorProps>()
  const mySite = useLogin().name

  useEffect(() => {
    async function fetchDashboard() {
      const response = await webRequest('SiteDashboard')
      if (response.status === 200) {
        const sum3months = (
          dashboard: DashboardForCustomer,
          okOrNg: 'ok' | 'ng'
        ) =>
          dashboard.onsite['12months']
            .slice(-3)
            .reduce((prev, cur) => prev + cur[okOrNg], 0)

        const responseDashboards = response.data.dashboards
        const oks = responseDashboards.reduce(
          (prev, cur) => prev + sum3months(cur, 'ok'),
          0
        )
        const ngs = responseDashboards.reduce(
          (prev, cur) => prev + sum3months(cur, 'ng'),
          0
        )

        setTotalOk(oks)
        setTotalNg(ngs)
        setDashboards(
          response.data.dashboards.sort((a, b) =>
            a.siteName > b.siteName ? 1 : -1
          )
        )
        
        const dashboard = response.data
        const colors: LineColorProps = {}
        dashboard.dashboards.map((dashboard, index) => {
          const color =
            dashboard.siteName === mySite ? 'yellow'
            : null

          if (color !== null) {
            colors[index] = color
          }
        }, [])
        setLineColors(colors)
      }
    }

    fetchDashboard()
  }, [])

  return (
    <DefaultWithTitleLayout title={Lang.header.dashboard}>
      {dashboards && (
        <>
          <div className="bg-white shadow-vixell w-full py-[42px] px-[60px] rounded-[10px]">
            <Title title={Lang.dashboard.title} />
            <div className="flex gap-[72px] pt-[32px]">
              <div className="flex items-center gap-[16px] text-3xl">
                <OkIcon />
                <span>{injectComma(totalOk)}</span>
                <span className="ml-4">{Lang.dashboard.countPostfix}</span>
              </div>
              <div className="flex items-center gap-[16px] text-3xl">
                <NgIcon />
                <span>{injectComma(totalNg)}</span>
                <span className="ml-4">{Lang.dashboard.countPostfix}</span>
              </div>
            </div>
          </div>
          <div className="mt-[46px]">
            <VixellTable
              classNames={['w-200 px-8', 'w-160', 'w-480', 'w-480']}
              headers={[
                '',
                Lang.dashboard.header.stockCount,
                Lang.dashboard.header.thirtyDays,
                Lang.dashboard.header.twelveMonths,
              ]}
              elements={dashboards.map((dashboard, index) => [
                dashboard.siteName,
                `${dashboard.stockedCount}`,
                <div className="py-[16px]" key={index}>
                  <BarChart
                    width={480}
                    height={180}
                    data={dashboard.onsite['30days'].map(
                      ({ ok, ng, date }) => ({
                        name: moment(date).format(
                          Lang.dashboard.graphMonthDateFormat
                        ),
                        value: ok + ng,
                      })
                    )}
                  >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="value" fill="#2196F3" />
                  </BarChart>
                </div>,
                <div className="py-[16px]" key={index}>
                  <BarChart
                    width={480}
                    height={180}
                    data={dashboard.onsite['12months'].map(
                      ({ ok, ng }, index) => ({
                        name: moment()
                          .subtract(11 - index, 'months')
                          .format(Lang.dashboard.graphMonthFormat),
                        value: ok + ng,
                      })
                    )}
                  >
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="value" fill="#2196F3" />
                  </BarChart>
                </div>,
              ])}
              options={{lineColors: lineColors}}
            />
          </div>
        </>
      )}
    </DefaultWithTitleLayout>
  )
}
