import { VacuumProcess } from '../../../types/typescript-axios'
import { Title } from '../../part/title'
import { ReactElement } from 'react'
import moment from 'moment'
import { Lang } from '../../../translations/lang'

export const VacuumProcessComponent = ({
  vacuumProcess,
}: {
  vacuumProcess: VacuumProcess | undefined
}) => (
  <div className="w-[100%] max-w-[1380px] mx-auto">
    <Title title={Lang.admin.vixellDetail.vacuumProcessResult} />
    <div className="flex mt-32 gap-[32px]">
      <VacuumProcessProcessResult
        title={Lang.admin.vixellDetail.acceptance}
        results={[
          [
            Lang.admin.vixellDetail.idmIssue,
            vacuumProcess?.acceptance?.idmIssue,
          ],
          [
            Lang.admin.vixellDetail.numberOfMoistAdsorbent,
            vacuumProcess?.acceptance?.numberOfMoistAdsorbent,
          ],
          [
            Lang.admin.vixellDetail.minimumWeightOfMoistAdsorbent,
            vacuumProcess?.acceptance?.minimumWeightOfMoistAdsorbent,
          ],
          [
            Lang.admin.vixellDetail.maximumWeightOfMoistAdsorbent,
            vacuumProcess?.acceptance?.maximumWeightOfMoistAdsorbent,
          ],
          [
            Lang.admin.vixellDetail.weightOfGasAdsorbent,
            vacuumProcess?.acceptance?.weightOfGasAdsorbent,
          ],
          [
            Lang.admin.vixellDetail.componentCheck,
            vacuumProcess?.acceptance?.componentCheck,
          ],
          [
            Lang.inspectionTime,
            <span className="text-sm" key={1}>
              {vacuumProcess?.acceptance?.processingDateTime &&
                moment(vacuumProcess.acceptance.processingDateTime).format(
                  Lang.dateTimeFormat
                )}
            </span>,
          ],
        ]}
      />
      <VacuumProcessProcessResult
        title={Lang.admin.vixellDetail.leakTest2}
        results={[
          [
            Lang.admin.vixellDetail.sensorVoltage,
            vacuumProcess?.leakTest2?.sensorVoltage,
          ],
          [
            Lang.admin.vixellDetail.sensorPressure,
            vacuumProcess?.leakTest2?.sensorPressure,
          ],
          [
            Lang.admin.vixellDetail.pressureDifferenceFromLeak1,
            vacuumProcess?.leakTest2?.pressureDifferenceFromLeak1,
          ],
          [
            Lang.admin.vixellDetail.inspectionResult,
            vacuumProcess?.leakTest2?.inspectionResult,
          ],
          [
            Lang.inspectionTime,
            <span className="text-sm" key={1}>
              {vacuumProcess?.leakTest2?.processingDateTime &&
                moment(vacuumProcess.leakTest2.processingDateTime).format(
                  Lang.dateTimeFormat
                )}
            </span>,
          ],
        ]}
      />
    </div>
    <div className="flex mt-32 gap-[32px]">
      <div>
        <VacuumProcessProcessResult
          title={Lang.admin.vixellDetail.vacuumEvacuation}
          results={[
            [
              Lang.admin.vixellDetail.urethaneLotNo,
              vacuumProcess?.vacuumEvacuation?.urethaneLotNo,
            ],
            [
              Lang.admin.vixellDetail.retryCount,
              vacuumProcess?.vacuumEvacuation?.retryCount,
            ],
            [
              Lang.admin.vixellDetail.evacuationPeriod,
              vacuumProcess?.vacuumEvacuation?.evacuationPeriod,
            ],
            [
              Lang.inspectionTime,
              <span className="text-sm" key={1}>
                {vacuumProcess?.vacuumEvacuation?.processingDateTime &&
                  moment(
                    vacuumProcess.vacuumEvacuation.processingDateTime
                  ).format(Lang.dateTimeFormat)}
              </span>,
            ],
          ]}
        />
        <div className="mt-32" />
        <VacuumProcessProcessResult
          title={Lang.admin.vixellDetail.leakTest1}
          results={[
            [
              Lang.admin.vixellDetail.sensorVoltage,
              vacuumProcess?.leakTest1?.sensorVoltage,
            ],
            [
              Lang.admin.vixellDetail.sensorPressure,
              vacuumProcess?.leakTest1?.sensorPressure,
            ],
            [
              Lang.admin.vixellDetail.inspectionResult,
              vacuumProcess?.leakTest1?.inspectionResult,
            ],
            [
              Lang.inspectionTime,
              <span className="text-sm" key={1}>
                {vacuumProcess?.leakTest1?.processingDateTime &&
                  moment(vacuumProcess.leakTest1.processingDateTime).format(
                    Lang.dateTimeFormat
                  )}
              </span>,
            ],
          ]}
        />
      </div>
      <VacuumProcessProcessResult
        title={Lang.admin.vixellDetail.vacuumCorrection}
        results={[
          [
            Lang.admin.vixellDetail.noncorrectedVoltage,
            vacuumProcess?.vacuumCorrection?.noncorrectedVoltage,
          ],
          [
            Lang.admin.vixellDetail.sensorPressure,
            vacuumProcess?.vacuumCorrection?.sensorPressure,
          ],
          [
            Lang.admin.vixellDetail.correctedVoltage,
            vacuumProcess?.vacuumCorrection?.correctedVoltage,
          ],
          [
            Lang.admin.vixellDetail.correctedPressure,
            vacuumProcess?.vacuumCorrection?.correctedPressure,
          ],
          [
            Lang.admin.vixellDetail.environmentalTemperature,
            vacuumProcess?.vacuumCorrection?.environmentalTemperature,
          ],
          [
            Lang.admin.vixellDetail.referenceVoltage,
            vacuumProcess?.vacuumCorrection?.referenceVoltage,
          ],
          [
            Lang.admin.vixellDetail.correctionValue,
            vacuumProcess?.vacuumCorrection?.correctionValue,
          ],
          [
            Lang.admin.vixellDetail.inspectionResult,
            vacuumProcess?.vacuumCorrection?.inspectionResult,
          ],
          [
            Lang.inspectionTime,
            <span className="text-sm" key={1}>
              {vacuumProcess?.vacuumCorrection?.processingDateTime &&
                moment(
                  vacuumProcess.vacuumCorrection.processingDateTime
                ).format(Lang.dateTimeFormat)}
            </span>,
          ],
        ]}
      />
    </div>
  </div>
)

type VacuumProcessProcessResultProps = {
  title: string
  results: [string, ReactElement | string | number | undefined][]
}
const VacuumProcessProcessResult = ({
  title,
  results,
}: VacuumProcessProcessResultProps) => (
  <div className="w-[640px]">
    <h3>{title}</h3>
    <div className="flex flex-wrap mt-12 gap-y-[12px]">
      {results.map((result, index) => (
        <VacuumProcessResultUnit key={index} result={result} />
      ))}
    </div>
  </div>
)

type VacuumProcessResultUnitProps = {
  result: [string, ReactElement | number | string | undefined]
}
const VacuumProcessResultUnit = ({
  result: [label, element],
}: VacuumProcessResultUnitProps) => (
  <div className="w-[152px] flex flex-col gap-[8px]">
    <label className="text-[10px] underline pr-4">{label}</label>
    <span className="inline-block break-all">{element}</span>
  </div>
)
