import { orderList } from './order-list/ja'
import { orderDetail } from './order-detail/ja'
import { stock } from './sp/stock/ja'
import { ship } from './sp/ship/ja'
import { menu } from './sp/menu/ja'
import { lend } from './sp/lend/ja'
import { returning } from './sp/return/ja'
import { defective } from './sp/defective/ja'
import { lostItem } from './sp/lost-item/ja'

export const reusable = {
  dashboard: {
    title: 'ダッシュボード',
    site: 'サイト',
    typeS: 'Type-S',
    typeL: 'Type-L',
    logger: 'Logger',
    box: 'BOX',
    r5: 'R5',
    c5: 'C5',
    s4: 'S4',
    f4: 'F4',
    d: 'D',
    gf: 'GF',
    ge: 'GE',
  },
  state: {
    CONFIRMED: '確定',
    NON_CONFIRMED: '未確定',
    CANCELLED: 'キャンセル',
  },
  loggingStartReservationType: {
    WHEN_SHIP_FROM_PANASONIC: 'パナソニック出荷時から計測開始',
    MANUAL: '手動(荷送人がSTARTを押した時開始)',
    SPECIFIC_TIME: '時刻指定開始(yyyy/mm/dd/ hh:mm:ss[TimeZone])',
  },
  reusableOrder: {
    orderNumber: '受注No',
    status: 'ステータス',
    customerCompanyName: '顧客会社名',
    customerCompanyDivision: '顧客担当部門名',
    customerPhone: '顧客連絡先TEL',
    notesForDistributionCenter: '受注連絡欄',
    consignorInfo: '荷送人(BOXお届け先)情報',
    deliveryDateToConsignor: 'お届け日',
    countryOfConsignor: 'お届け先国',
    stateOfConsignor: 'お届け先州',
    addressOfConsignor: 'お届け先住所',
    zipCodeOfConsignor: 'お届け先郵便番号',
    companyNameOfConsignor: 'お届け先会社名',
    divisionOfConsignor: 'お届け先担当部門名',
    phoneOfConsignor: 'お届け先TEL',
    notesForConsignor: '配達指定事項',
    consigneeInfo: '荷受人(BOX返却者)情報',
    deliveryDateToConsignee: '受渡し予定日',
    countryOfConsignee: '荷受人所在国',
    stateOfConsignee: '荷受人州',
    addressOfConsignee: '荷受人住所',
    zipCodeOfConsignee: '荷受人郵便番号',
    companyNameOfConsignee: '荷受人会社名',
    divisionOfConsignee: '荷受人担当部門名',
    phoneOfConsignee: '荷受人TEL',
    preCooling: '予冷有無',
    preCoolingNotes: '予冷連絡欄 保冷日数(days)orドライアイス量(kg)',
    hasLogger: '温度ロガー有無',
    loggerModel: 'ロガー機種',
    loggerSettingBy: '設定作業',
    loggerSettingByEnum: {
      PANASONIC: 'パナソニック',
      CUSTOMER: '顧客',
    },
    loggerAlertUpperTemperatureLimit: 'アラート設定 - 温度上限',
    loggerAlertLowerTemperatureLimit: 'アラート設定 - 温度下限',
    loggerAlertCapacity: 'アラート設定 - アラート許容',
    loggingStartReservationType: '計測開始予約',
    loggingStartReservationTime: '計測開始予約時間',
    notesForDelivery: '内容物メモ',
    loggerFilePath: '温度ロガーログ',
  },
  reusableOrderEdit: {
    title: '受注編集',
  },
  searchModal: {
    title: '受注検索',
    orderStatus: '受注ステータス',
    deliveryDateToConsignor: 'お届け日',
    deliveryDateToConsignee: '受渡予定日',
    box: 'ボックス',
    unit: '蓄熱U',
    preCooling: '予冷',
    logger: 'ロガー',
    shippingStatus: '出荷状態',
    freeText: 'フリーテキスト',
    typeS: 'Type-S',
    typeL: 'Type-L',
    confirmed: '確定',
    nonConfirmed: '未確定',
    cancelled: 'キャンセル',
    nonShipped: '未出荷',
    shipped: '出荷済',
    returned: '返却済',
    query: '文字列検索',
  },
  reusableOrderFormDetail: {
    productInfo: '商品情報',
    product: '商品',
    delete: '削除',
    boxSize: 'ボックスサイズ',
    thermalStorageUnitType: '蓄熱ユニット種類',
    preCooling: '予冷有無',
    preCoolingNotes: '予冷連絡欄  保冷日数(days) or ドライアイス量(kg)',
    temperatureLogger: '温度ロガー',
    temperatureLoggerSettingBy: '設定作業',
    temperatureLoggerSettingByPanasonic: 'パナソニック',
    temperatureLoggerSettingByCustomer: '顧客',
    temperatureLoggerModel: '温度ロガー機種',
    loggerAlertUpperTemperatureLimit: 'アラート設定 - 温度上限',
    loggerAlertLowerTemperatureLimit: 'アラート設定 - 温度下限',
    loggerAlertCapacity: 'アラート設定 - アラート許容  記録間隔×〇回は無視',
    loggingStartReservationType: '計測開始予約',
    loggingStartReservationTime: '計測開始予約時間',
    notesForDelivery:
      'メモ（VIXELLのポケットに表示される顧客用の自由入力メモ）',
  },
  reusableOrderHistory: {
    orderNo: '受注No',
    tradeType: '取引区分',
    processingDateTime: '取引日時',
    customerCompanyName: '顧客',
  },
  reusableAdmin: {
    title: '受注一覧',
    description: '受注の一覧を表示します。',
    headerTitle: [
      '受注No',
      '契約先',
      'お届け',
      '受け渡し',
      '商品1',
      '商品1設定',
      '明細数',
      '出荷数',
      '返却数',
      '印刷',
      '受注連絡欄',
    ],
  },
  reusableShipper: {
    title: '出荷一覧',
    description: '出荷の一覧を表示します。',
    headerTitle: [
      '出荷No',
      '契約先',
      'お届け',
      '受け渡し',
      '商品1',
      '商品1設定',
      '明細数',
      '出荷数',
      '返却数',
      '印刷',
      '受注連絡欄',
    ],
  },
  reusableReturn: {
    title: '返却予定一覧',
    description: '返却の一覧を表示します。',
    headerTitle: [
      '返却No',
      '契約先',
      'お届け',
      '受け渡し',
      '商品1',
      '商品1設定',
      '明細数',
      '出荷数',
      '返却数',
      '印刷',
      '受注連絡欄',
    ],
  },
  orderList,
  orderDetail,
  sp: {
    menu,
    stock,
    ship,
    lend,
    returning,
    defective,
    lostItem,
  },
}
