import { FC } from 'react'

type Props = {
  title: string
  description?: string
}
export const TitleWithDescription: FC<Props> = ({
  title,
  description,
  children,
}) => (
  <div>
    <h1>
      {title}
      <div className="vixell-gradient w-[560px] h-[1px]"></div>
    </h1>
    <div className="pt-[16px]">
      {description && <p className="text-xs">{description}</p>}
    </div>
    {children}
  </div>
)
