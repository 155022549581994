// 商品情報
import { objectKeys } from '../lib/object-keys'

export const VixellModels = [
  { label: 'Type-S:AE-V06GXR', value: 'AE-V06GXR', kind: 'Type-S' },
  { label: 'Type-L:AE-V12UXR', value: 'AE-V12UXR', kind: 'Type-L' },
] as const
export type VixellModel = typeof VixellModels[number]['value']
export const getVixellKind = (model: VixellModel) =>
  VixellModels.find((x) => x.value === model)?.kind

export const ThermalStorageUnitModels = {
  'AE-V06GXR': [
    {
      label: ' 2-8℃ :AE-V06C5R',
      value: 'AE-V06C5R',
      kind: ' 2-8℃',
    },
    {
      label: '15-25℃:AE-V06R5R',
      value: 'AE-V06R5R',
      kind: '15-25℃',
    },
    {
      label: ' -15℃ :AE-V06S4R',
      value: 'AE-V06S4R',
      kind: ' -15℃',
    },
    {
      label: ' -20℃ :AE-V06F4R',
      value: 'AE-V06F4R',
      kind: ' -20℃',
    },
    {
      label: 'Dry ice:AE-V06DXR',
      value: 'AE-V06DXR',
      kind: 'Dry ice',
    },
  ],
  'AE-V12UXR': [
    {
      label: ' 2-8℃ :AE-V12C5R',
      value: 'AE-V12C5R',
      kind: ' 2-8℃',
    },
    {
      label: '15-25℃:AE-V12R5R',
      value: 'AE-V12R5R',
      kind: '15-25℃',
    },
    {
      label: ' -15℃ :AE-V12S4R',
      value: 'AE-V12S4R',
      kind: ' -15℃',
    },
    {
      label: ' -20℃ :AE-V12F4R',
      value: 'AE-V12F4R',
      kind: ' -20℃',
    },
    {
      label: 'Dry ice:AE-V12DXR',
      value: 'AE-V12DXR',
      kind: 'Dry ice',
    },
  ],
} as const
export type ThermalStorageUnitModel =
  typeof ThermalStorageUnitModels[VixellModel][number]['value']
export const getThermalStorageUnitlKind = (model: ThermalStorageUnitModel) =>
  objectKeys(ThermalStorageUnitModels)
    .map((key) => ThermalStorageUnitModels[key])
    .flat()
    .find((x) => x.value === model)?.kind

export const TemperatureLoggerModels = [
  { label: 'LIBERO GF', value: 'LIBERO GF', kind: '-' },
  { label: 'LIBERO GE', value: 'LIBERO GE', kind: '-' },
] as const
export type TemperatureLoggerModel =
  typeof TemperatureLoggerModels[number]['value']
export const getTemperatureLoggerKind = (model: TemperatureLoggerModel) =>
  TemperatureLoggerModels.find((x) => x.value === model)?.kind
