export const inventories = {
  vixellInventories: {
    title: '棚卸状況（VIXELL)',
    description: 'すべてのVIXELLの棚卸状況を表示します。',
    inventoryState: '棚卸状況',
  },
  icePackInventories: {
    title: '棚卸状況（蓄熱剤)',
    description: 'すべての蓄熱剤の棚卸状況を表示します。',
    inventoryState: '棚卸状況',
  },
  thermalStorageUnitInventories: {
    title: '棚卸状況（蓄熱ユニット)',
    description: 'すべての蓄熱ユニットの棚卸状況を表示します。',
    inventoryState: '棚卸状況',
  },
  temperatureLoggerInventories: {
    title: '棚卸状況（温度ロガー)',
    description: 'すべての温度ロガーの棚卸状況を表示します。',
    inventoryState: '棚卸状況',
  },
}
