import { useEffect, useState } from 'react'
import {
  IcePackPart,
  IndexIcePackResponseComponent,
  ShippingStateValue,
  TemperatureTagCalibrationCalibrationResults,
} from '../../types/typescript-axios'

import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { VixellTable } from '../../components/part/vixell-table'
import { TableElementDatetime2Line } from '../../components/part/table-elements/table-element-datetime-2-line'
import { OkIcon } from '../../components/molecules/ok-icon'
import { NgIcon } from '../../components/molecules/ng-icon'
import { TableElementEpc } from '../../components/part/table-elements/table-element-epc'
import moment from 'moment'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { AdminIcePackSearchModal } from '../../components/templates/admin-ice-pack-detail/admin-ice-pack-search-modal'
import { SearchButton } from '../../components/part/search-button'
import { Lang } from '../../translations/lang'
import { webRequest } from '../../types/handle-api'
import { usePaging } from '../../contexts/use-paging'
import { downloadCsv } from '../../utils/download-csv'
import { toFormattedDatetime } from '../../utils/to-formatted-datetime'
import { toFormattedDate } from '../../utils/to-formatted-date'

export const AdminIcePacks = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()

  const [icePacks, setIcePacks] = useState<IndexIcePackResponseComponent[]>([])

  const [showSearchModal, setShowSearchModal] = useState<boolean>(false)

  const [loadNextPageAt, setLoadNextPageAt] = useState<Date>(new Date())
  const { currentPage, setPaging, stopPageLoad } = usePaging()
  const [searchParamsString, setSearchParamsString] = useState<string>()

  useEffect(() => {
    fetchDataAndSetStates()
  }, [searchParams])

  async function fetchDataAndSetStates(loadNextPage = false) {
    const response = await fetchIcePacks({ loadNextPage })
    if (response.status === 200) {
      if (searchParams.toString() === searchParamsString) {
        setIcePacks((i) => [...i, ...response.data.icePacks])
      } else {
        setIcePacks(response.data.icePacks)
      }
      setPaging(response.data)
      setSearchParamsString(searchParams.toString())
    }
  }

  async function fetchIcePacks({ all = false, loadNextPage = false }) {
    const temperatureSensorTagEpc = searchParams.get('serial')
    const contractNo = searchParams.get('contractNo')
    const siteName = searchParams.get('siteName')
    const shippingState = searchParams.get('shippingState')
    const shippingFrom = searchParams.get('shippingFrom')
    const shippingTill = searchParams.get('shippingTill')
    const calibrationFrom = searchParams.get('calibrationFrom')
    const calibrationTill = searchParams.get('calibrationTill')
    const onlyNg = searchParams.get('ng')
    const part = searchParams.get('part')
    const inspectFrom = searchParams.get('inspectFrom')
    const inspectTill = searchParams.get('inspectTill')

    return await webRequest('IcePacksIndex', {
      ...(temperatureSensorTagEpc && { temperatureSensorTagEpc }),
      ...(contractNo && { contractRepresentativeNo: contractNo }),
      ...(siteName && { latestSiteName: siteName }),
      ...(shippingState && {
        shippingStatus: shippingState as ShippingStateValue,
      }),
      ...(shippingFrom && { latestShippingStateDateTimeFrom: shippingFrom }),
      ...(shippingTill && { latestShippingStateDateTimeTill: shippingTill }),
      ...(calibrationFrom && { calibrationDateTimeFrom: calibrationFrom }),
      ...(calibrationTill && { calibrationDateTimeTill: calibrationTill }),
      ...(onlyNg && { onlyNg: true }),
      ...(part && { part: part as IcePackPart }),
      ...(inspectFrom && { latestOnsiteDateTimeFrom: inspectFrom }),
      ...(inspectTill && { latestOnsiteDateTimeTill: inspectTill }),
      ...(all && { all: true }),
      ...(!all && loadNextPage && { page: currentPage + 1 }),
    })
  }

  async function csvDownload() {
    const response = await fetchIcePacks({ all: true })
    if (response.status === 200) {
      const tIcePacks = Lang.admin.icePacks
      const tDetail = Lang.admin.icePackDetail
      const csvData = [
        [
          Lang.icePackSerialNo,
          Lang.icePackPart,
          Lang.siteName,
          Lang.contractRepresentativeNo,
          Lang.shippingState,
          Lang.shippingStateUpdatedDate,
          Lang.lastInspectionResult,
          Lang.lastInspectionTime,
          Lang.stockedDateTime,
          Lang.disposedDateTime,
          tDetail.calibrationStartDateTime,
          tDetail.referenceTemperatureSensorNo,
          tDetail.temperatureCorrectionSlope,
          tDetail.temperatureCorrectionIntercept,
          tDetail.rssiCorrectionSlope,
          tDetail.rssiCorrectionIntercept,
          ...([1, 2, 3, 4] as const)
            .map((time) => [
              `${tDetail.resultTime[time]} ${tDetail.referenceTemperature}`,
              `${tDetail.resultTime[time]} ${tDetail.tagTemperatureCode}`,
              `${tDetail.resultTime[time]} ${tDetail.rssi}`,
            ])
            .flat(),
        ],
        ...response.data.icePacks.map(
          ({
            temperatureSensorTagEpc,
            part,
            siteName,
            contractRepresentativeNo,
            shippingState,
            shippingStateProcessingDateTime,
            inspectionResult,
            onsiteIcePackResultProcessingDateTime,
            calibrationResult,
            stockedDateTime,
            disposedDateTime
          }) => [
            temperatureSensorTagEpc,
            part && Lang.icePack[part],
            siteName,
            contractRepresentativeNo,
            shippingState && Lang.shippingStateName[shippingState],
            toFormattedDatetime(shippingStateProcessingDateTime),
            inspectionResult,
            toFormattedDatetime(onsiteIcePackResultProcessingDateTime),
            toFormattedDatetime(stockedDateTime),
            toFormattedDatetime(disposedDateTime),
            toFormattedDate(calibrationResult?.calibrationStartDateTime),
            calibrationResult?.referenceTemperatureSensorNo,
            calibrationResult?.temperatureCorrectionSlope,
            calibrationResult?.temperatureCorrectionIntercept,
            calibrationResult?.rssiCorrectionSlope,
            calibrationResult?.rssiCorrectionIntercept,
            ...([1, 2, 3, 4] as const)
              .map((time) =>
                (function (
                  unit: TemperatureTagCalibrationCalibrationResults | undefined
                ) {
                  return [
                    unit?.referenceTemperature,
                    unit?.tagTemperatureCode,
                    unit?.rssi,
                  ]
                })(
                  calibrationResult?.calibrationResults &&
                    calibrationResult.calibrationResults[time - 1]
                )
              )
              .flat(),
          ]
        ),
      ]
      downloadCsv(csvData, `icePacks-${new Date().toISOString()}.csv`)
    }
  }

  function infiniteLoad() {
    if (stopPageLoad) return
    if (moment(loadNextPageAt).isAfter(moment().subtract(3, 'seconds'))) return

    setLoadNextPageAt(new Date())
    fetchDataAndSetStates(true)
  }

  const titleRightElement = (
    <div>
      <SearchButton onClick={() => setShowSearchModal(true)} />

      {[
        'serial',
        'contactNo',
        'siteName',
        'shippingState',
        'shippingFrom',
        'shippingTill',
        'calibrationFrom',
        'calibrationTill',
        'ng',
        'part',
        'inspectFrom',
        'inspectTill',
      ]
        .map((param) => searchParams.get(param))
        .filter((x) => x).length > 0 && (
        <p className="flex justify-end text-xs mt-8 font-bold">
          {Lang.searchCondition}
        </p>
      )}
      <div className="absolute flex flex-col content-end flex-wrap  gap-x-[8px] gap-y-[4px] h-[80px] text-xs mt-8">
        {searchParams.get('serial') && (
          <span>
            {Lang.admin.icePacks.serial}:{searchParams.get('serial')}
          </span>
        )}
        {searchParams.get('contractNo') && (
          <span>
            {Lang.admin.icePacks.contractNo}:{searchParams.get('contractNo')}
          </span>
        )}
        {searchParams.get('siteName') && (
          <span>
            {Lang.siteName}:{searchParams.get('siteName')}
          </span>
        )}
        {
          // searchParams.get('shippingState') && (
          //   // <span>{Lang.shippingState}:{Lang.shippingStateName[searchParams.get('shippingState') as ShippingStateValue]}</span>
          // )
        }
        {(searchParams.get('shippingFrom') ||
          searchParams.get('shippingTill')) && (
          <span>
            {Lang.shippingStateUpdatedDate}:
            {searchParams.get('shippingFrom') &&
              moment(searchParams.get('shippingFrom')).format(Lang.dateFormat)}
            -
            {searchParams.get('shippingTill') &&
              moment(searchParams.get('shippingTill')).format(Lang.dateFormat)}
          </span>
        )}
        {(searchParams.get('calibrationFrom') ||
          searchParams.get('calibrationTill')) && (
          <span>
            {Lang.admin.icePacks.calibrationDate}:
            {searchParams.get('calibrationFrom') &&
              moment(searchParams.get('calibrationFrom')).format(
                Lang.dateFormat
              )}
            -
            {searchParams.get('calibrationTill') &&
              moment(searchParams.get('calibrationTill')).format(
                Lang.dateFormat
              )}
          </span>
        )}
        {
          // searchParams.get('part') && (
          //   // <span>{Lang.icePackPart}:{Lang.icePack[searchParams.get('part') as IcePackPart]}</span>
          // )
        }
        {
          // searchParams.get('onlyNg') && (
          //   // <span>{Lang.admin.icePacks.onlyNg}:{Lang.icePack[searchParams.get('part') as IcePackPart]}</span>
          // )
        }

        {(searchParams.get('inspectFrom') ||
          searchParams.get('inspectTill')) && (
          <span>
            {Lang.inspectionTime}:
            {searchParams.get('inspectFrom') &&
              moment(searchParams.get('inspectFrom')).format(Lang.dateFormat)}
            -
            {searchParams.get('inspectTill') &&
              moment(searchParams.get('inspectTill')).format(Lang.dateFormat)}
          </span>
        )}
      </div>
    </div>
  )

  return (
    <DefaultWithTitleLayout
      title={Lang.admin.icePacks.title}
      description={Lang.admin.icePacks.description}
      titleRightElement={titleRightElement}
      infiniteLoad={infiniteLoad}
      csvDownload={csvDownload}
    >
      {icePacks && (
        <VixellTable
          rootClassName="my-64"
          classNames={[
            'flex-1',
            'w-140',
            'flex-1',
            'w-140',
            'w-120',
            'w-120',
            'flex-1',
            'flex-1',
          ]}
          headers={[
            Lang.icePackSerialNo,
            Lang.contractRepresentativeNo,
            Lang.siteName,
            <div className="flex flex-col items-center text-sm" key={1}>
              <div>{Lang.shippingState}</div>
              <div>{Lang.updatedDate}</div>
            </div>,
            Lang.admin.icePacks.calibrationDate,
            Lang.icePackPart,
            Lang.lastInspectionResult,
            Lang.inspectionTime,
          ]}
          elements={icePacks.map((icePack) => [
            <TableElementEpc
              epc={icePack.temperatureSensorTagEpc}
              key={icePack.temperatureSensorTagEpc}
            />,
            icePack?.contractRepresentativeNo,
            icePack?.siteName,
            icePack?.shippingState && (
              <div className="flex flex-col items-center">
                <div>{Lang.shippingStateName[icePack.shippingState]}</div>
                <div>
                  (
                  {moment(icePack.shippingStateProcessingDateTime).format(
                    Lang.dateFormat
                  )}
                  )
                </div>
              </div>
            ),
            icePack.calibrationProcessingDateTime &&
              moment(icePack.calibrationProcessingDateTime).format(
                Lang.dateFormat
              ),
            icePack.part && Lang.icePack[icePack.part],
            icePack.inspectionResult ? (
              icePack.inspectionResult === 'Pass' ? (
                <OkIcon />
              ) : (
                <NgIcon />
              )
            ) : (
              ''
            ),
            icePack?.onsiteIcePackResultProcessingDateTime && (
              <TableElementDatetime2Line
                dateTime={icePack.onsiteIcePackResultProcessingDateTime}
              />
            ),
          ])}
          onLineClick={(index) => navigate(`/ice-packs/${icePacks[index].id}`)}
        />
      )}
      {showSearchModal && (
        <AdminIcePackSearchModal hide={() => setShowSearchModal(false)} />
      )}
    </DefaultWithTitleLayout>
  )
}
