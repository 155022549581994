import { useFormContext } from 'react-hook-form'
import { ReusableOrdersNewFieldValues } from './ReusableOrderForm'
import { InputWithLabelAndRegister } from '../../molecules/input-with-label-and-register'
import { Button } from '../../molecules/button'
import { useEffect } from 'react'
import { Lang } from '../../../translations/lang'
import { RadioButtonsWithTitleAndLabels } from '../../part/forms/radio-buttons-with-title-and-labels'
import { SelectWithTitle } from '../../part/forms/select-with-title'
import { LabeledValue } from '../../molecules/select-control'
import {
  TemperatureLoggerModels,
  ThermalStorageUnitModel,
  ThermalStorageUnitModels,
  VixellModel,
  VixellModels,
} from '../../../constants/models'

const lang = Lang.reusable

const loggingStartReservationTimeZones = [
  { label: 'JST', value: '+0900' },
  { label: 'UTC', value: '+0000' },
] as const

const loggingStartReservationTypes = [
  {
    label: lang.loggingStartReservationType.WHEN_SHIP_FROM_PANASONIC,
    value: 'WHEN_SHIP_FROM_PANASONIC',
  },
  { label: lang.loggingStartReservationType.MANUAL, value: 'MANUAL' },
  {
    label: lang.loggingStartReservationType.SPECIFIC_TIME,
    value: 'SPECIFIC_TIME',
  },
] as const

const TimeZones = [{ label: 'Asia/Tokyo', value: 'Asia/Tokyo' }]
export type TimeZone = typeof TimeZones[number]['value']

export type LoggingStartReservation =
  typeof loggingStartReservationTypes[number]['value']
export const ReusableOrderFormDetail = ({
  index,
  orderDetailNumber,
  deleteMe,
}: {
  index: number
  orderDetailNumber: number
  deleteMe: () => void
}) => {
  const { register, watch, setValue, getValues, control } =
    useFormContext<ReusableOrdersNewFieldValues>()

  useEffect(() => {
    VixellModels[0].value
    registerKey('vixellModel')

    if (getValues(registerKey('vixellModel')) === undefined)
      setValue(registerKey('vixellModel'), VixellModels[0].value)
    if (
      getValues(registerKey('thermalStorageUnitModel')) === undefined ||
      getValues(registerKey('thermalStorageUnitModel')) === ''
    )
      setValue(
        registerKey('thermalStorageUnitModel'),
        ThermalStorageUnitModels['AE-V06GXR'][0].value
      )
    if (getValues(registerKey('preCooling')) === undefined)
      setValue(registerKey('preCooling'), false)
    if (getValues(registerKey('allowSupercoolingDelivery')) === undefined)
      setValue(registerKey('allowSupercoolingDelivery'), false)
    if (getValues(registerKey('hasLogger')) === undefined)
      setValue(registerKey('hasLogger'), false)
    if (getValues(registerKey('loggerModel')) === undefined)
      setValue(registerKey('loggerModel'), TemperatureLoggerModels[0].value)
    if (getValues(registerKey('loggerSettingBy')) === undefined)
      setValue(registerKey('loggerSettingBy'), 'CUSTOMER')
    if (getValues(registerKey('loggingStartReservationType')) === undefined)
      setValue(
        registerKey('loggingStartReservationType'),
        loggingStartReservationTypes[0].value
      )
    if (
      getValues(registerKey('loggerAlertUpperTemperatureLimit')) === undefined
    )
      setValue(registerKey('loggerAlertUpperTemperatureLimit'), 0)
    if (
      getValues(registerKey('loggerAlertLowerTemperatureLimit')) === undefined
    )
      setValue(registerKey('loggerAlertLowerTemperatureLimit'), 0)
  }, [])

  const registerKey = <
    T extends keyof ReusableOrdersNewFieldValues['reusableOrderDetails'][number]
  >(
    key: T
  ) =>
    `reusableOrderDetails.${index}.${key}` as `reusableOrderDetails.${number}.${T}`

  const vixellModel = watch(registerKey('vixellModel')) as VixellModel
  const thermalStorageUnitModel = watch(
    registerKey('thermalStorageUnitModel')
  ) as ThermalStorageUnitModel

  useEffect(() => {
    if (
      vixellModel === 'AE-V06GXR' &&
      !ThermalStorageUnitModels[vixellModel].some(
        (x) => x.value === thermalStorageUnitModel
      )
    )
      setValue(
        registerKey('thermalStorageUnitModel'),
        ThermalStorageUnitModels['AE-V06GXR'][0].value
      )
    if (
      vixellModel === 'AE-V12UXR' &&
      !ThermalStorageUnitModels[vixellModel].some(
        (x) => x.value === thermalStorageUnitModel
      )
    )
      setValue(
        registerKey('thermalStorageUnitModel'),
        ThermalStorageUnitModels['AE-V12UXR'][0].value
      )
  }, [vixellModel])

  const lang = Lang.reusable.reusableOrderFormDetail

  return (
    <div className="mt-32">
      <div className="text-lg font-bold">{lang.productInfo}</div>
      <div className="mt-24">
        <div className="flex items-center">
          <div className="text-sm font-bold">
            {lang.product}
            {orderDetailNumber}
          </div>
          <Button
            color="red"
            className="ml-32 w-60 h-[18px] text-xs"
            type="button"
            onClick={deleteMe}
          >
            {lang.delete}
          </Button>
        </div>
      </div>
      <SelectWithTitle
        className="mt-24 text-xs"
        title={lang.boxSize}
        required={true}
        name={registerKey('vixellModel')}
        control={control}
        values={VixellModels as any as LabeledValue<string>[]}
      />
      <SelectWithTitle
        className="mt-16 text-xs"
        title={lang.thermalStorageUnitType}
        required={true}
        name={registerKey('thermalStorageUnitModel')}
        control={control}
        values={
          (ThermalStorageUnitModels[vixellModel] ||
            []) as any as LabeledValue<string>[]
        }
      />
      <RadioButtonsWithTitleAndLabels
        className="mt-16 text-xs"
        title={lang.preCooling}
        required={true}
        control={control}
        name={registerKey('preCooling')}
        radios={[
          { label: Lang.with, value: true },
          { label: Lang.without, value: false },
        ]}
      />
      {watch(registerKey('preCooling')) && (
        <div className="mt-16 ml-16 text-xs">
          <InputWithLabelAndRegister
            label={lang.preCoolingNotes}
            alertLabel={`*${Lang.required}`}
            register={register(registerKey(`preCoolingNotes`))}
            className="mt-16"
          />
        </div>
      )}
      <RadioButtonsWithTitleAndLabels
        className="mt-16"
        title={Lang.temperatureLogger}
        required={true}
        control={control}
        name={registerKey('hasLogger')}
        radios={[
          { label: Lang.with, value: true },
          { label: Lang.without, value: false },
        ]}
      />
      {watch(registerKey('hasLogger')) && (
        <div className="mt-8 ml-16 text-xs">
          <SelectWithTitle
            title={lang.temperatureLoggerModel}
            required={true}
            name={registerKey('loggerModel')}
            control={control}
            values={TemperatureLoggerModels as any as LabeledValue<string>[]}
          />
          <RadioButtonsWithTitleAndLabels
            className="mt-16"
            title={lang.temperatureLoggerSettingBy}
            required={true}
            control={control}
            name={registerKey('loggerSettingBy')}
            radios={[
              {
                label: lang.temperatureLoggerSettingByPanasonic,
                value: 'PANASONIC',
              },
              {
                label: lang.temperatureLoggerSettingByCustomer,
                value: 'CUSTOMER',
              },
            ]}
          />
          {watch(registerKey('loggerSettingBy')) === 'PANASONIC' && (
            <>
              <div className="mt-16">
                <div>
                  <label>{lang.loggerAlertUpperTemperatureLimit}</label>
                  <label className="alert ml-8">*{Lang.required}</label>
                </div>
                <div className="flex items-end">
                  <input
                    className="mt-8 w-[100px]"
                    {...register(
                      registerKey('loggerAlertUpperTemperatureLimit'),
                      {
                        valueAsNumber: true,
                      }
                    )}
                  />
                  <span className="ml-8 font-bold">℃</span>
                </div>
              </div>
              <div className="mt-16">
                <div>
                  <label>{lang.loggerAlertLowerTemperatureLimit}</label>
                  <label className="alert ml-8">*{Lang.required}</label>
                </div>
                <div className="flex items-end">
                  <input
                    className="mt-8 w-[100px]"
                    {...register(
                      registerKey('loggerAlertLowerTemperatureLimit'),
                      {
                        valueAsNumber: true,
                      }
                    )}
                  />
                  <span className="ml-8 font-bold">℃</span>
                </div>
              </div>
              <InputWithLabelAndRegister
                label={lang.loggerAlertCapacity}
                alertLabel={`*${Lang.required}`}
                register={register(registerKey(`loggerAlertCapacity`))}
                className="mt-16"
              />
              <div className="mt-16">
                <div>
                  <label>{lang.loggingStartReservationType}</label>
                  <label className="alert ml-8">*{Lang.required}</label>
                </div>
                <select
                  className="mt-16"
                  {...register(registerKey('loggingStartReservationType'))}
                >
                  {loggingStartReservationTypes.map(
                    (loggingStartReservationType, index) => {
                      return (
                        <option
                          key={index}
                          value={loggingStartReservationType.value}
                        >
                          {loggingStartReservationType.label}
                        </option>
                      )
                    }
                  )}
                </select>
              </div>
              {watch(registerKey('loggingStartReservationType')) ===
                'SPECIFIC_TIME' && (
                <div className="mt-16">
                  <div>
                    <label>{lang.loggingStartReservationTime}</label>
                    <label className="alert ml-8">*{Lang.required}</label>
                  </div>
                  <div className="flex mt-8">
                    <input
                      type="datetime-local"
                      step={1}
                      className="w-[220px]"
                      {...register(registerKey('loggingStartReservationTime'))}
                    />
                    <select
                      className="ml-12 w-[112px]"
                      {...register(
                        registerKey('loggingStartReservationTimeZone')
                      )}
                    >
                      {loggingStartReservationTimeZones.map(
                        (loggingStartReservationTimeZone, index) => {
                          return (
                            <option
                              key={index}
                              value={loggingStartReservationTimeZone.value}
                            >
                              {loggingStartReservationTimeZone.label}
                            </option>
                          )
                        }
                      )}
                    </select>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
      <div className="mt-16">
        <div>
          <label>{lang.notesForDelivery}</label>
        </div>
        <textarea
          className="mt-8"
          rows={4}
          {...register(registerKey('notesForDelivery'))}
        />
      </div>
    </div>
  )
}
