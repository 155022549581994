import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { ProductType, ShippingStateLog } from '../../../types/typescript-axios'
import { usePaging } from '../../../contexts/use-paging'
import { webRequest } from '../../../types/handle-api'
import { VixellTable } from '../../part/vixell-table'
import { Lang } from '../../../translations/lang'
import { TableHeaderContractAndManagementNo } from '../../part/table-headers/table-header-contract-and-management-no'
import { TableElementDatetime2Line } from '../../part/table-elements/table-element-datetime-2-line'
import { InfiniteLoadComponent } from '../../molecules/infinite-load-component'
import { TableElementContractAndManagementNo } from '../../part/table-elements/table-element-contract-and-management-no'

type Props = {
  productType: ProductType
}
export const ShippingStateLogHistory = ({ productType }: Props) => {
  const { id } = useParams<{ id: string }>()
  const [histories, setHistories] = useState<ShippingStateLog[]>()
  const { currentPage, setPaging, stopPageLoad } = usePaging()

  useEffect(() => {
    fetchData()
  }, [id])

  async function fetchData() {
    const response = await webRequest('ShippingStateLogsIndex', {
      productType: productType,
      productId: Number(id),
      page: currentPage + 1,
    })
    if (response.status === 200) {
      setHistories([...(histories || []), ...response.data.shippingStateLogs])
      setPaging(response.data)
    }
  }

  function infiniteLoad() {
    if (stopPageLoad) return

    fetchData()
  }

  return (
    <div className="my-40 container">
      {histories && (
        <VixellTable
          classNames={['w-200', 'w-200', 'w-200']}
          headers={[
            Lang.tradeType,
            <TableHeaderContractAndManagementNo key={1} />,
            Lang.tradeDateTime,
          ]}
          elements={histories.map((history) => [
            Lang.shippingStateName[history.state],
            <TableElementContractAndManagementNo
              info={{
                contractRepresentativeNo:
                  history.trade?.contractRepresentativeNo,
                contractNo: history.contractNo,
                managementNo: history.managementNo,
              }}
            />,
            <TableElementDatetime2Line
              dateTime={history.processingDateTime}
              key={history.id}
            />,
          ])}
        />
      )}
      <InfiniteLoadComponent
        infiniteLoad={infiniteLoad}
        nextLoadDelayMilliSec={3000}
      />
    </div>
  )
}
