import { useEffect, useState } from 'react'
import { Site } from '../types/typescript-axios'
import { DefaultWithTitleLayout } from '../components/templates/default-with-title-layout'
import { VixellTable } from '../components/part/vixell-table'
import { SquareButton } from '../components/molecules/sqare-button'
import { NewButton } from '../components/part/new-button'
import { CreateSite } from '../components/templates/sites/create-site'
import { ConfirmModal } from '../components/part/ConfirmModal'
import { Lang } from '../translations/lang'
import { downloadCsv } from '../utils/download-csv'
import { EditSitePassword } from '../components/templates/sites/edit-site-password'
import { webRequest } from '../types/handle-api'

export const Sites = () => {
  const [sites, setSites] = useState<Site[]>()

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [editPasswordSite, setEditPasswordSite] = useState<Site>()
  const [stopSiteId, setStopSiteId] = useState<number>()

  useEffect(() => {
    async function fetchData() {
      const response = await fetchSites()
      if (response.status === 200) {
        setSites(response.data.sites)
      }
    }

    fetchData()
  }, [])

  async function fetchSites() {
    return await webRequest('SitesIndex')
  }

  async function csvDownload() {
    const response = await fetchSites()
    if (response.status === 200) {
      const csvData = [
        [Lang.siteId, Lang.siteName],
        ...response.data.sites.map(({ siteIdentifier, name }) => [
          siteIdentifier,
          name,
        ]),
      ]
      downloadCsv(csvData, `sites-${new Date().toISOString()}.csv`)
    }
  }

  async function stopSite() {
    if (!stopSiteId) return

    const response = await webRequest('SitesDestroy', {
      id: stopSiteId,
    })

    if (response.status === 200) {
      window.location.reload()
    }
  }

  const titleRightElement = (
    <div>
      <NewButton onClick={() => setShowCreateModal(true)} />
    </div>
  )

  return (
    <DefaultWithTitleLayout
      title={Lang.sites.title}
      description={Lang.sites.description}
      titleRightElement={titleRightElement}
      csvDownload={csvDownload}
    >
      {sites && (
        <VixellTable
          rootClassName="my-64"
          classNames={['w-200', 'w-200', 'flex-1 flex !justify-end px-[42px]']}
          headers={[Lang.siteId, Lang.siteName, '']}
          elements={sites.map((site) => [
            site.siteIdentifier,
            site.name,
            <SquareButton
              key={1}
              color="blue"
              onClick={() => setEditPasswordSite(site)}
            >
              {Lang.changePassword}
            </SquareButton>,
            <SquareButton
              key={1}
              color="red"
              onClick={() => setStopSiteId(site.id)}
            >
              {Lang.stopUsing}
            </SquareButton>,
          ])}
        />
      )}
      {showCreateModal && <CreateSite hide={() => setShowCreateModal(false)} />}
      {stopSiteId && (
        <ConfirmModal
          title={Lang.confirmStop}
          ok={stopSite}
          cancel={() => setStopSiteId(undefined)}
        />
      )}
      {editPasswordSite && (
        <EditSitePassword
          site={editPasswordSite}
          hide={() => setEditPasswordSite(undefined)}
        />
      )}
    </DefaultWithTitleLayout>
  )
}
