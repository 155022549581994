import { FC, ReactElement, useState } from 'react'

type TabElement = {
  tabName: string
  element: ReactElement
}

type Props = {
  tabs: TabElement[]
}

export const TabContainer: FC<Props> = ({ tabs }) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const selectedTabStyle = 'border-b-white'
  const nonselectedTabStyle = 'bg-[#F5F5F5] cursor-pointer hover:bg-gray-50'

  function changeTab(index: number) {
    if (index === selectedIndex) return

    setSelectedIndex(index)
  }

  return (
    <div className="flex flex-col flex-1">
      <div className="flex w-[80%] max-w-[1440px] mx-auto">
        {tabs.map(({ tabName }, index) => (
          <div
            key={index}
            onClick={() => changeTab(index)}
            className={[
              'relative flex justify-center items-center bg-white rounded-t-[10px] border border-[#B2EBF2]',
              'w-[146px] h-[42px] mb-[-1px] z-1',
              index === selectedIndex ? selectedTabStyle : nonselectedTabStyle,
              index > 0 ? 'ml-[-1px]' : '',
            ].join(' ')}
          >
            {tabName}
          </div>
        ))}
      </div>
      <div className="flex-1 border-t border-t-[#B2EBF2] bg-white">
        {tabs[selectedIndex].element}
      </div>
    </div>
  )
}
