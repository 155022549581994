import { FC, ReactNode } from 'react'

const LineColors = {
  gray: '#d3d3d3',
  yellow: '#fffacd',
} as const

type LineColorCode = keyof typeof LineColors
export type LineColorProps = {
  [key in number]: LineColorCode
}

type VixellTableProps = {
  classNames: string[]
  headers: (ReactNode | string)[]
  elements: (ReactNode | string | number | undefined)[][]
  rootClassName?: string
  options?: {
    lineColors?: LineColorProps
  }
  onLineClick?: (index: number) => void
}

export const VixellTable: FC<VixellTableProps> = ({
  classNames,
  headers,
  elements,
  rootClassName = '',
  options,
  onLineClick,
}) => {
  return (
    <div className={'shadow-vixell ' + rootClassName}>
      <div className="flex items-center rounded-t-[10px] bg-[#EEEEEE] min-h-[60px]">
        {headers.map((header, index) => (
          <div
            key={index}
            className={'flex justify-center items-center ' + classNames[index]}
          >
            {header}
          </div>
        ))}
      </div>
      {elements.map((line, rowIndex) => {
        const lineColorCode = options?.lineColors?.[rowIndex]
        // console.log('rowIndex', rowIndex)
        return (
          <div
            {...(onLineClick && { onClick: () => onLineClick(rowIndex) })}
            key={rowIndex}
            className={
              'flex items-center min-h-[80px] border-b border-b-[#E0E0E0] bg-white break-all ' +
              (onLineClick && ' cursor-pointer hover:bg-gray-50 ')
            }
            {...(lineColorCode && {
              style: { ...{ background: LineColors[lineColorCode] } },
            })}
          >
            {line.map((body, index) => (
              <div
                key={index}
                className={
                  'flex justify-center items-center ' + classNames[index]
                }
              >
                {body}
              </div>
            ))}
          </div>
        )
      })}
    </div>
  )
}
