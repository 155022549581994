import React, { useEffect, useRef } from 'react'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void
  className?: string
  children?: React.ReactNode
}

export const AutoFocusDiv = ({
  onBlur,
  className,
  children,
  ...props
}: Props) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current) ref.current.focus()
  }, [ref])

  return (
    <div
      className={className + ' outline-0 z-10'}
      ref={ref}
      tabIndex={100}
      onBlur={onBlur}
      {...props}
    >
      {children}
    </div>
  )
}
