import { Link, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  ReusableOrderStateLog,
  ReusableOrderStateLogProductTypeEnum,
  SiteType,
} from '../../../types/typescript-axios'
import { usePaging } from '../../../contexts/use-paging'
import { webRequest } from '../../../types/handle-api'
import { VixellTable } from '../../part/vixell-table'
import { Lang } from '../../../translations/lang'
import { TableElementDatetime2Line } from '../../part/table-elements/table-element-datetime-2-line'
import { InfiniteLoadComponent } from '../../molecules/infinite-load-component'
import { useLogin } from '../../../contexts/login-provider'

type Props = {
  productType: ReusableOrderStateLogProductTypeEnum
}
export const ReusableOrderHistory = ({ productType }: Props) => {
  const { id } = useParams<{ id: string }>()

  const [histories, setHistories] = useState<ReusableOrderStateLog[]>()
  const { currentPage, setPaging, stopPageLoad } = usePaging()
  const { siteType } = useLogin()
  const canSeeReusableOrderDetail = siteType !== SiteType.Admin

  useEffect(() => {
    fetchData()
  }, [id])

  async function fetchData() {
    const response = await webRequest('ReusableOrderStatesIndex', {
      productType,
      productId: Number(id),
      page: currentPage + 1,
    })
    if (response.status === 200) {
      setHistories([...(histories || []), ...response.data.reusableOrderStates])
      setPaging(response.data)
    }
  }

  function infiniteLoad() {
    if (stopPageLoad) return

    fetchData()
  }

  return (
    <div className="my-40 container">
      {histories && (
        <VixellTable
          classNames={['w-200', 'w-200', 'w-200']}
          headers={[
            Lang.reusable.reusableOrderHistory.orderNo,
            Lang.reusable.reusableOrderHistory.tradeType,
            Lang.reusable.reusableOrderHistory.processingDateTime,
            Lang.reusable.reusableOrderHistory.customerCompanyName,
          ]}
          elements={histories.map((history) => [
            canSeeReusableOrderDetail ? (
              <Link
                to={`/reusable-orders/${history.reusableOrderDetail?.reusableOrder?.id}`}
                className="text-blue-500 underline"
              >
                {`${history.reusableOrderDetail?.reusableOrder?.id}-${history.reusableOrderDetail?.orderDetailNumber}`}
              </Link>
            ) : (
              `${history.reusableOrderDetail?.reusableOrder?.id}-${history.reusableOrderDetail?.orderDetailNumber}`
            ),
            Lang.shippingStateName[history.state],
            <TableElementDatetime2Line
              dateTime={history.processingDateTime}
              key={history.id}
            />,
            history.reusableOrderDetail?.reusableOrder?.customerCompanyName,
          ])}
        />
      )}
      <InfiniteLoadComponent
        infiniteLoad={infiniteLoad}
        nextLoadDelayMilliSec={3000}
      />
    </div>
  )
}
