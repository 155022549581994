import {
  PrintCheckBoxChecked,
  PrintCheckBoxHandWrite,
  PrintCheckBoxUnchecked,
  PrintLayout,
} from '../../../components/templates/reusable-orders/print/print-layout'
import { PrintSection } from '../../../components/templates/reusable-orders/print/print-section'
import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { ReusableOrder } from '../../../types/typescript-axios'
import { webRequest } from '../../../types/handle-api'
import { Row } from '../../../components/atom/Row'
import { Column } from '../../../components/atom/Column'
import { QRCodeSVG } from 'qrcode.react'
import {
  OrderDetailContext,
  useOrderDetail,
} from '../../../contexts/use-order-detail'
import { toFormattedDate } from '../../../utils/to-formatted-date'
import { Cell } from '../../../components/atom/Cell'
import { ConsignorAddress } from '../../../components/molecules/ConsignorAddress'
import { Grid } from '../../../components/atom/Grid'
import {
  getThermalStorageUnitlKind,
  getVixellKind,
  ThermalStorageUnitModel,
  VixellModel,
} from '../../../constants/models'

export const ReusableDeliverySlip = () => {
  const { id } = useParams<{ id: string }>()
  const [reusableOrder, setReusableOrder] = useState<ReusableOrder>()

  useEffect(() => {
    async function fetchData() {
      const response = await webRequest('ReusableOrderShow', {
        id: Number(id),
      })
      if (response.status === 200) {
        setReusableOrder(response.data)
      }
    }

    fetchData()
  }, [])

  if (!reusableOrder) return <div />

  return (
    <div className="page-container">
      {reusableOrder.reusableOrderDetails.map((detail) => (
        <OrderDetailContext.Provider
          value={{ order: reusableOrder, orderDetail: detail }}
        >
          <Page />
        </OrderDetailContext.Provider>
      ))}
    </div>
  )
}

const Page = () => {
  const { order, orderDetail } = useOrderDetail()

  return (
    <PrintLayout title="VIXELL Goods Slip">
      <OrderInfo />
      <ItemInfo />
      {orderDetail.hasLogger && <LoggerSetting />}
      <PreCooling />
      <CustomerMemo />
    </PrintLayout>
  )
}

const OrderInfo = () => {
  const { order, orderDetail } = useOrderDetail()

  return (
    <PrintSection>
      <Row className="justify-between">
        <Column>
          <Row className="print-text-2xl font-bold">
            <Cell className="w-[180pt]">Order No</Cell>
            <Cell className="gap-x-[8pt]">
              <span>{`#${order.id}-${orderDetail.orderDetailNumber}`}</span>
              <span>{`(${orderDetail.orderDetailNumber}/${order.reusableOrderDetails.length})`}</span>
            </Cell>
          </Row>
          <Row className="print-text-2xl font-bold">
            <Cell className="w-[180pt]">Shipping Date</Cell>
            <Cell className="gap-x-[8pt]">
              <span>{toFormattedDate(order.deliveryDateToConsignor)}</span>
              <span>{order.deliveryDateMeridiemIndicatorToConsignor}</span>
            </Cell>
          </Row>
          <Row className="mt-[8pt] items-center">
            <Cell className="print-text-lg font-bold w-[100pt]">Address</Cell>
            <Cell className="print-text-md">
              <ConsignorAddress order={order} />
            </Cell>
          </Row>
        </Column>
        <Column>
          <QRCodeSVG
            size={90}
            key={orderDetail.id}
            value={`${order.id}-${orderDetail.orderDetailNumber}`}
          />
        </Column>
      </Row>
      <div className="print-h2"></div>
    </PrintSection>
  )
}
const ItemInfo = () => {
  const { order, orderDetail } = useOrderDetail()

  return (
    <PrintSection>
      <Row>
        <Cell className="print-h2">Item</Cell>
      </Row>
      <Grid className="grid-cols-[100pt_150pt_150pt_150pt]">
        <Grid className="grid-rows-[90pt_20pt_20pt_20pt_20pt] font-bold">
          <Cell layout="center_start" className="flex-col pl-[10pt]">
            <div>S/N</div>
            <div>QR</div>
          </Cell>
          <Cell layout="center_start" className="flex-col pl-[10pt]">
            Item
          </Cell>
          <Cell layout="center_start" className="flex-col pl-[10pt]">
            Type
          </Cell>
          <Cell layout="center_start" className="flex-col pl-[10pt]">
            Model
          </Cell>
          <Cell layout="center_start" className="flex-col pl-[10pt]">
            S/N
          </Cell>
        </Grid>
        {orderDetail.vixell && (
          <Grid className="grid-rows-[90pt_20pt_20pt_20pt_20pt] font-bold">
            <Cell>
              <QRCodeSVG
                size={90}
                value={`${orderDetail.vixellModel}_${orderDetail.vixell.serialNo}`}
              />
            </Cell>
            <Cell>BOX</Cell>
            <Cell>
              {getVixellKind(orderDetail.vixell.model as VixellModel)}
            </Cell>
            <Cell>{orderDetail.vixell.model}</Cell>
            <Cell className="font-normal">{orderDetail.vixell.serialNo}</Cell>
          </Grid>
        )}
        {orderDetail.thermalStorageUnit && (
          <Grid className="grid-rows-[90pt_20pt_20pt_20pt_20pt] font-bold">
            <Cell>
              <QRCodeSVG
                size={90}
                value={`${orderDetail.thermalStorageUnitModel}_${orderDetail.thermalStorageUnit.serialNo}`}
              />
            </Cell>
            <Cell>Unit</Cell>
            <Cell>
              {getThermalStorageUnitlKind(
                orderDetail.thermalStorageUnit.model as ThermalStorageUnitModel
              )}
            </Cell>
            <Cell>{orderDetail.thermalStorageUnit.model}</Cell>
            <Cell className="font-normal">
              {orderDetail.thermalStorageUnit.serialNo}
            </Cell>
          </Grid>
        )}
        {orderDetail.temperatureLogger && (
          <Grid className="grid-rows-[90pt_20pt_20pt_20pt_20pt] font-bold">
            <Cell>
              <QRCodeSVG
                size={90}
                value={`${orderDetail.loggerModel}_${orderDetail.temperatureLogger?.serialNo}`}
              />
            </Cell>
            <Cell>Logger</Cell>
            <Cell>
              {getThermalStorageUnitlKind(
                orderDetail.temperatureLogger.model as ThermalStorageUnitModel
              )}
            </Cell>
            <Cell>{orderDetail.temperatureLogger.model}</Cell>
            <Cell className="font-normal">
              {orderDetail.temperatureLogger.serialNo}
            </Cell>
          </Grid>
        )}
      </Grid>
    </PrintSection>
  )
}
const LoggerSetting = () => {
  const { orderDetail } = useOrderDetail()
  return (
    <Row className="p-[10pt] border-b border-b-black justify-between">
      <div>
        <Row className="gap-[16pt]">
          <div className="print-h2">Logger setting</div>
          <div className="flex items-center gap-[8pt]">
            {orderDetail.loggerSettingBy === 'PANASONIC' ? (
              <PrintCheckBoxUnchecked />
            ) : (
              <PrintCheckBoxChecked />
            )}
            <span className="print-text-lg font-bold">Necessary</span>
            {orderDetail.loggerSettingBy === 'PANASONIC' ? (
              <PrintCheckBoxChecked />
            ) : (
              <PrintCheckBoxUnchecked />
            )}
            <span className="print-text-lg font-bold">No need</span>
          </div>
        </Row>
        {orderDetail.loggerSettingBy === 'PANASONIC' && (
          <div className="mt-[12pt]">
            <div className="flex">
              <div className="w-[100pt] print-text-md font-bold">Starter</div>
              <div className="flex gap-[8pt] items-center w-[200pt] print-text-md font-bold">
                {orderDetail.loggingStartReservationType === 'MANUAL' ? (
                  <PrintCheckBoxUnchecked />
                ) : (
                  <PrintCheckBoxChecked />
                )}
                Before shipping
              </div>
              <div className="flex gap-[8pt] items-center w-[100pt] print-text-md font-bold">
                {orderDetail.loggingStartReservationType === 'MANUAL' ? (
                  <PrintCheckBoxChecked />
                ) : (
                  <PrintCheckBoxUnchecked />
                )}
                Customer
              </div>
            </div>
            <div className="flex">
              <div className="w-[100pt] print-text-md font-bold">Interval</div>
              <div className="flex gap-[8pt] items-center w-[200pt] print-text-md font-bold">
                <PrintCheckBoxChecked />
                15 min
              </div>
            </div>
            <div className="flex">
              <div className="w-[100pt] print-text-md font-bold">
                Alert/Delay
              </div>
              <div className="flex gap-[8pt] items-center w-[200pt] print-text-md font-bold">
                <PrintCheckBoxChecked />
                {orderDetail.loggerAlertLowerTemperatureLimit}℃~
                {orderDetail.loggerAlertUpperTemperatureLimit}℃
              </div>
              <div className="flex gap-[8pt] items-center w-[100pt] print-text-md font-bold">
                <PrintCheckBoxChecked />
                {orderDetail.loggerAlertCapacity}
              </div>
            </div>
          </div>
        )}
      </div>
      <PrintCheckBoxHandWrite />
    </Row>
  )
}
const PreCooling = () => {
  const { orderDetail } = useOrderDetail()
  return (
    <Row className="p-[10pt] border-b border-b-black justify-between">
      <div>
        <Row className="gap-[16pt]">
          <div className="print-h2">Pre-Cooling</div>
          <div className="flex items-center gap-[8pt]">
            {orderDetail.preCooling ? (
              <PrintCheckBoxChecked />
            ) : (
              <PrintCheckBoxUnchecked />
            )}
            <span className="print-text-lg font-bold">Done</span>
            {orderDetail.preCooling ? (
              <PrintCheckBoxUnchecked />
            ) : (
              <PrintCheckBoxChecked />
            )}
            <span className="print-text-lg font-bold">No</span>
          </div>
        </Row>
        {orderDetail.preCooling && (
          <>
            <div className="flex items-center gap-[8pt] mt-[8pt]">
              <div className="w-[100pt] print-text-md font-bold">
                Customer Request
              </div>
              <div className="print-text-md">
                {orderDetail.preCoolingNotes || 'Nothing special'}
              </div>
            </div>
            <div className="flex gap-[16pt]">
              <div className="flex items-center gap-[8pt] mt-[10pt]">
                <div className="w-[100pt] print-text-md font-bold">
                  Temp at preparation
                </div>
                <div className="flex justify-end items-center px-[12pt] print-text-md font-bold w-[100pt] h-[40pt] border border-black ">
                  ℃
                </div>
                e
              </div>
              <div className="flex items-center gap-[8pt] mt-[10pt]">
                <div className="w-[100pt] print-text-md font-bold">
                  Date and Time
                </div>
                <div className="flex justify-end items-center px-[12pt] print-text-md font-bold w-[130pt] h-[40pt] border border-black "></div>
              </div>
            </div>
          </>
        )}
      </div>
      <PrintCheckBoxHandWrite />
    </Row>
  )
}
const CustomerMemo = () => {
  const { orderDetail } = useOrderDetail()
  return (
    <PrintSection>
      <Row>
        <Cell
          className="flex-col w-[100pt] font-bold pb-[30pt]"
          layout="center_start"
        >
          <div>Customer</div>
          <div>Memo</div>
        </Cell>
        <Cell layout="start_start" className="flex-col">
          {[
            !orderDetail.preCooling && 'You MUST pre-cool before use.',
            orderDetail.loggerSettingBy === 'CUSTOMER' &&
              ' You MUST configure the logger before use.',
            orderDetail.loggingStartReservationType === 'MANUAL' &&
              'You MUST press the Logger START button before use.',
            orderDetail.notesForDelivery,
          ]
            .filter(Boolean)
            .map((txt) => (
              <div>{txt}</div>
            ))}
        </Cell>
      </Row>
    </PrintSection>
  )
}
