export const RightArrowSvg = () => {
  return (
    <svg
      width="33"
      height="25"
      viewBox="0 0 33 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.05 24.4L17.9 22.3L26.3 13.9H0V10.9H26.3L17.85 2.44998L20 0.349976L32.05 12.4L20.05 24.4Z"
        fill="#424242"
      />
    </svg>
  )
}
