import { memo, useMemo } from 'react'
import {
  Tuple,
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryScatter,
} from 'victory'
import moment from 'moment/moment'
import {
  PressureGraphData,
  PressureGraphXAxisType,
  SelectedVixell,
} from '../../../pages/admin/AdminPressureGraph'

const ONE_DAY_TIME_COUNT = 86400000

type GraphChartProps = {
  formattedData: PressureGraphData[]
  xAxisType: PressureGraphXAxisType
  selectedVixell: SelectedVixell | undefined
  setSelectedVixell: (selectedVixell: SelectedVixell) => void
  yAxisUpper?: number
  yAxisLower?: number
  xAxisUpper?: number
  xAxisLower?: number
  xAxisUpperDate?: string
  xAxisLowerDate?: string
  yRuledLineRange?: number
  xRuledLineRange?: number
}
export const PressureGraphChart = memo(
  ({
    xAxisType,
    selectedVixell,
    setSelectedVixell,
    formattedData,
    yAxisUpper,
    yAxisLower,
    xAxisUpper,
    xAxisLower,
    xAxisUpperDate,
    xAxisLowerDate,
    yRuledLineRange,
    xRuledLineRange,
  }: GraphChartProps) => {
    console.log('GraphChart')
    console.log(formattedData)

    const allLines = useMemo(
      () =>
        formattedData.map((dataset) => (
          <VictoryLine
            key={dataset.id}
            data={dataset.data}
            style={{
              data: {
                stroke: '#80DEEA',
                strokeWidth: 1,
              },
            }}
            // events={[
            //   {
            //     target: 'parent',
            //     eventHandlers: {
            //       onMouseOver: () => {
            //         setSelectedVixell({
            //           vixellId: dataset.vixellId,
            //           vixellSerialNo: dataset.id,
            //         })
            //       },
            //     },
            //   },
            // ]}
          />
        )),
      [formattedData]
    )

    const { minX, maxX, minY, maxY } = formattedData.reduce(
      (prev, cur) => {
        const { xMin, xMax, yMin, yMax } = cur.data.reduce(
          (p, c) => {
            if (p.xMin > c.x) p.xMin = Math.round(c.x)
            if (p.xMax < c.x) p.xMax = Math.round(c.x)
            if (p.yMin > c.y) p.yMin = Math.round(c.y)
            if (p.yMax < c.y) p.yMax = Math.round(c.y)
            return p
          },
          {
            xMin: Math.round(cur.data[0].x),
            xMax: Math.round(cur.data[0].x),
            yMin: Math.round(cur.data[0].y),
            yMax: Math.round(cur.data[0].y),
          }
        )

        if (prev.minX > xMin) prev.minX = xMin
        if (prev.maxX < xMax) prev.maxX = xMax
        if (prev.minY > yMin) prev.minY = yMin
        if (prev.maxY < yMax) prev.maxY = yMax

        return prev
      },
      {
        minX: Number.MAX_VALUE,
        maxX: Number.MIN_VALUE,
        minY: Number.MAX_VALUE,
        maxY: Number.MIN_VALUE,
      }
    )

    // yRuledLine はY軸と平行な罫線なので、xの値を参照する
    const yRuledLines = !yRuledLineRange
      ? undefined
      : xAxisType === 'processingDate'
      ? Array.from(
          { length: Math.round((maxX - minX) / ONE_DAY_TIME_COUNT) + 1 },
          (_, i) => Math.round(minX / ONE_DAY_TIME_COUNT) + i
        )
          .filter((i) => i % yRuledLineRange === 0)
          .map((x) => x * ONE_DAY_TIME_COUNT)
      : Array.from({ length: maxX - minX + 1 }, (_, i) => minX + i).filter(
          (i) => i % yRuledLineRange === 0
        )
    const xRuledLines = xRuledLineRange
      ? Array.from({ length: maxY - minY + 1 }, (_, i) => minY + i).filter(
          (i) => i % xRuledLineRange === 0
        )
      : undefined

    console.log('minX', minX, 'maxX', maxX, 'minY', minY, 'maxY', maxY)
    console.log('xRuledLines', xRuledLines)
    console.log('yRuledLines', yRuledLines)

    const allDots = useMemo(
      () =>
        formattedData.map((dataset) => (
          <VictoryScatter
            key={dataset.id}
            data={dataset.data}
            size={2}
            events={[
              {
                target: 'data',
                eventHandlers: {
                  onClick: () => {
                    console.log('onClick!', dataset.vixellId)
                    setSelectedVixell({
                      vixellId: dataset.vixellId,
                      vixellSerialNo: dataset.id,
                    })
                  },
                },
              },
            ]}
            style={{
              data: {
                fill: '#80DEEA',
              },
            }}
          />
        )),
      [formattedData]
    )

    // だいぶややこしい…。
    const yDomain =
      yAxisUpper && ([yAxisLower || 0, yAxisUpper] as Tuple<number>)
    const xDomain =
      xAxisType === 'elapsedDate' && xAxisUpper
        ? ([xAxisLower || 0, xAxisUpper] as Tuple<number>)
        : xAxisType === 'processingDate' && xAxisUpperDate
        ? ([
            moment(xAxisLowerDate).valueOf() || 0,
            moment(xAxisUpperDate).valueOf(),
          ] as Tuple<number>)
        : undefined
    const domain = (yDomain || xDomain) && {
      ...(yDomain && { y: yDomain }),
      ...(xDomain && { x: xDomain }),
    }

    return (
      <VictoryChart
        width={1200}
        height={600}
        padding={{ top: 24, bottom: 32, left: 40, right: 40 }}
        {...(domain && { domain })}
      >
        {xAxisType === 'processingDate' ? (
          <VictoryAxis
            {...(yRuledLines && { tickValues: yRuledLines })}
            tickFormat={(t) => moment(t).format('YYYY/MM/DD')}
            style={{
              tickLabels: { fontSize: 14 },
              grid: { stroke: '#616161' }, // Y軸の罫線の色を設定
            }}
          />
        ) : (
          <VictoryAxis
            {...(yRuledLines && { tickValues: yRuledLines })}
            style={{
              tickLabels: { fontSize: 14 },
              grid: { stroke: '#616161' }, // Y軸の罫線の色を設定
            }}
          />
        )}
        <VictoryAxis
          dependentAxis
          {...(xRuledLines && { tickValues: xRuledLines })}
          style={{
            tickLabels: { fontSize: 14 },
            grid: { stroke: '#616161' }, // X軸の罫線の色を設定
          }}
        />
        {allLines}
        {allDots}
        {formattedData
          .filter((d) => d.id === selectedVixell?.vixellSerialNo)
          .map((dataset) => (
            <VictoryLine
              key={dataset.id}
              data={dataset.data}
              style={{
                data: {
                  stroke: '#F44336',
                  strokeWidth: 1,
                },
              }}
            />
          ))}
        {formattedData
          .filter((d) => d.id === selectedVixell?.vixellSerialNo)
          .map((dataset) => (
            <VictoryScatter
              key={dataset.id}
              data={dataset.data}
              size={2}
              style={{
                data: {
                  fill: '#F44336',
                },
              }}
            />
          ))}
      </VictoryChart>
    )
  }
)
