import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { ReactNode } from 'react'

type Props<T extends FieldValues, X extends Path<T>> = {
  label: string | ReactNode
  control: Control<T>
  name: X
  value: T[X]
}
export const RadioButtonWithLabel = <T extends FieldValues, X extends Path<T>>({
  label,
  name,
  control,
  value,
}: Props<T, X>) => {
  return (
    <Controller
      render={({ field: { value: v, onChange } }) => (
        <div onClick={() => onChange(value)} className="flex items-center">
          <input
            type="radio"
            name={name}
            checked={v === value}
            className="radio"
            onChange={() => undefined}
          />
          {console.log(v)}
          <label className="pl-12 text-base">{label}</label>
        </div>
      )}
      name={name}
      control={control}
    />
  )
}
