import { useEffect, useState } from 'react'
import { Site, SiteType } from '../../types/typescript-axios'
import { webRequest } from '../../types/handle-api'

import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { VixellTable } from '../../components/part/vixell-table'
import { SquareButton } from '../../components/molecules/sqare-button'
import { CreateSite } from '../../components/templates/admin-sites/create-site'
import { ConfirmModal } from '../../components/part/ConfirmModal'
import { Button } from '../../components/molecules/button'
import { PlusSvg } from '../../components/svg/plus-svg'
import { Select } from '../../components/molecules/select'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SearchInput } from '../../components/molecules/search-input'
import moment from 'moment'
import { Lang } from '../../translations/lang'
import { downloadCsv } from '../../utils/download-csv'

const SiteTypes = [
  [Lang.all, ''] as const,
  [Lang.siteTypeName.ADMIN, SiteType.Admin] as const,
  [Lang.siteTypeName.PARENT, SiteType.Parent] as const,
  [Lang.siteTypeName.CHILD, SiteType.Child] as const,
  [Lang.siteTypeName.REUSABLE_ADMIN, SiteType.ReusableAdmin] as const,
  [Lang.siteTypeName.REUSABLE_CONTRACT, SiteType.ReusableContract] as const,
  [
    Lang.siteTypeName.REUSABLE_NON_RESIDENT,
    SiteType.ReusableNonResident,
  ] as const,
  [Lang.siteTypeName.REUSABLE_RETURN, SiteType.ReusableReturn] as const,
  [Lang.siteTypeName.REUSABLE_SHIPPER, SiteType.ReusableShipper] as const,
]

export const AdminSites = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [sites, setSites] = useState<Site[]>([])

  const [searchWord, setSearchWord] = useState<string>('')
  const [searchSiteType, setSearchSiteType] = useState<string>('')

  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [stopSiteId, setStopSiteId] = useState<number>()

  const [loadNextPageAt, setLoadNextPageAt] = useState<Date>(new Date())
  const [canNextPageLoad, setCanNextPageLoad] = useState<boolean>(false)
  const [nextPageNum, setNextPageNum] = useState<number>(1)
  const [searchParamsString, setSearchParamsString] = useState<string>()

  useEffect(() => {
    fetchDataAndSetStates()
  }, [searchParams])

  async function fetchDataAndSetStates() {
    const response = await fetchSites()
    if (response.status === 200) {
      if (searchParams.toString() === searchParamsString) {
        setSites((s) => [...s, ...response.data.sites])
      } else {
        setSites(response.data.sites)
      }
      setSearchParamsString(searchParams.toString())
      setNextPageNum(nextPageNum + 1)
      setCanNextPageLoad(response.data.sites.length > 0)
    }
  }

  async function fetchSites(all = false) {
    const query = searchParams.get('search')
    const siteType = searchParams.get('siteType')

    setSearchWord((w) => w || query || '')
    setSearchSiteType(siteType || '')

    return await webRequest('SitesIndex', {
      ...(query && { query }),
      ...(siteType && { siteType: siteType as SiteType }),
      ...(all && { all: true }),
      ...(!all && { page: nextPageNum }),
    })
  }

  function infiniteLoad() {
    if (!canNextPageLoad) return
    if (moment(loadNextPageAt).isAfter(moment().subtract(3, 'seconds'))) return

    setLoadNextPageAt(new Date())
    fetchDataAndSetStates()
  }

  async function stopSite() {
    if (!stopSiteId) return

    const response = await webRequest('SitesDestroy', {
      id: stopSiteId,
    })
    if (response.status === 200) {
      window.location.reload()
    }
  }

  function changeSearchParams(label: string, value: string) {
    if (value) searchParams.set(label, value)
    if (!value) searchParams.delete(label)
    setNextPageNum(1)
    navigate(`/sites?${searchParams.toString()}`)
  }

  async function csvDownload() {
    const response = await fetchSites(true)
    if (response.status === 200) {
      const csvData = [
        [
          Lang.siteType,
          Lang.siteId,
          Lang.siteName,
          Lang.customerId,
          Lang.customerName,
        ],
        ...response.data.sites.map((site) => [
          Lang.siteTypeName[site.siteType],
          site.siteIdentifier,
          site.name,
          site.customer?.customerIdentifier,
          site.customer?.name,
        ]),
      ]
      downloadCsv(csvData, `sites-${new Date().toISOString()}.csv`)
    }
  }

  const titleRightElement = (
    <div className="flex flex-col items-end">
      <Button color="cyan" onClick={() => setShowCreateModal(true)}>
        <span className="flex justify-center items-center gap-[4px]">
          {Lang.admin.sites.createAdmin}
          <PlusSvg />
        </span>
      </Button>
      <div className="flex items-end mt-16 gap-[48px]">
        <div className="flex flex-col">
          <label>{Lang.siteType}</label>
          <Select
            className="mt-8 !w-[140px]"
            value={searchSiteType}
            options={SiteTypes}
            onChange={(value) => changeSearchParams('siteType', value)}
          />
        </div>
        <SearchInput
          value={searchWord}
          onChange={setSearchWord}
          onClick={() => changeSearchParams('search', searchWord)}
        />
      </div>
    </div>
  )

  return (
    <DefaultWithTitleLayout
      title={Lang.admin.sites.title}
      description={Lang.admin.sites.description}
      titleRightElement={titleRightElement}
      infiniteLoad={infiniteLoad}
      csvDownload={csvDownload}
    >
      {sites && (
        <VixellTable
          rootClassName="my-64"
          classNames={[
            'w-200',
            'w-200',
            'w-200',
            'w-200',
            'w-200',
            'flex-1 flex !justify-end px-[42px]',
          ]}
          headers={[
            Lang.siteType,
            Lang.siteId,
            Lang.siteName,
            Lang.customerId,
            Lang.customerName,
            '',
          ]}
          elements={sites.map((site) => [
            Lang.siteTypeName[site.siteType],
            site.siteIdentifier,
            site.name,
            site.customer?.customerIdentifier,
            site.customer?.name,
            <SquareButton
              color="red"
              onClick={() => setStopSiteId(site.id)}
              key={site.id}
            >
              {Lang.stopUsing}
            </SquareButton>,
          ])}
        />
      )}
      {showCreateModal && <CreateSite hide={() => setShowCreateModal(false)} />}
      {stopSiteId && (
        <ConfirmModal
          title={Lang.confirmStop}
          ok={stopSite}
          cancel={() => setStopSiteId(undefined)}
        />
      )}
    </DefaultWithTitleLayout>
  )
}
