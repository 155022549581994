import { AdminOnsiteResult } from '../../../types/typescript-axios'
import { Modal } from '../../molecules/modal'
import { Title } from '../../part/title'
import { OkIcon } from '../../molecules/ok-icon'
import { NgIcon } from '../../molecules/ng-icon'
import { Button } from '../../molecules/button'
import { Lang } from '../../../translations/lang'

type Props = {
  result: AdminOnsiteResult
  hide: () => void
}
export const AdminOnsiteResultUnitModal = ({ result, hide }: Props) => {
  const onsiteVacuum = result.onsiteVacuumResult

  return (
    <Modal>
      <Title title={Lang.admin.onsiteResultDetail.title} />
      <div className="mt-32">
        <div className="flex rounded-t-10 bg-[#E0F7FA] border border-vixell-dark border-b-0">
          <div className="flex justify-center items-center w-[180px] h-[48px] font-bold">
            {Lang.admin.onsiteResultDetail.target}
          </div>
          <div className="flex justify-center items-center w-[100px] h-[48px] font-bold">
            {Lang.admin.onsiteResultDetail.result}
          </div>
        </div>
        <div className="flex h-[70px] border border-vixell-dark">
          <div className="flex flex-col justify-center items-center w-[180px] h-[70px] font-bold">
            <div>{Lang.admin.onsiteResultDetail.vacuum}</div>
            <div className="text-sm font-medium">{result.vixellSerialNo}</div>
          </div>
          <div className="flex justify-center items-center w-[100px] h-[70px] font-bold">
            {onsiteVacuum && (
              <div>
                {onsiteVacuum.inspectionResult === 'Pass' ? (
                  <OkIcon />
                ) : (
                  <NgIcon />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="w-full flex flex-wrap">
          {result.onsiteIcePackResult?.inspectionResults?.map((unit, index) => (
            <div
              key={index}
              className={
                'flex w-1/2 h-[70px] border border-vixell-dark border-t-0 ' +
                (index % 2 === 1 && ' border-l-0')
              }
            >
              <div className="flex flex-col justify-center items-center w-[180px] h-[70px] font-bold">
                {/*<div>{Lang.icePack[unit.part]}</div>*/}
                <div className="text-xs">
                  {unit.temperatureSensorTagEpc.slice(0, 14)}
                </div>
                <div className="text-xs">
                  {unit.temperatureSensorTagEpc.slice(14)}
                </div>
              </div>
              <div className="flex justify-center items-center w-[100px] h-[70px] font-bold">
                {unit && (
                  <div>
                    {unit.inspectionResult === 'Pass' ? <OkIcon /> : <NgIcon />}
                  </div>
                )}
              </div>
              <div className="flex-1 h-[70px] flex items-center font-bold text-sm p-8 break-all">
                {unit.errorDetail}
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-[72px]">
          <Button color="cyan" onClick={hide}>
            {Lang.ok}
          </Button>
        </div>
      </div>
    </Modal>
  )
}
