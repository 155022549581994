import { webRequest } from '../../../../types/handle-api'
import {
  getTemperatureLoggerKind,
  getThermalStorageUnitlKind,
  getVixellKind,
  TemperatureLoggerModel,
  ThermalStorageUnitModel,
  VixellModel,
} from '../../../../constants/models'
import { PrintLayout } from './print-layout'
import { Grid } from '../../../atom/Grid'
import { Column } from '../../../atom/Column'
import { ReactNode, useEffect, useState } from 'react'
import { Row } from '../../../atom/Row'
import { toFormattedDate } from '../../../../utils/to-formatted-date'
import { useParams } from 'react-router-dom'
import { ReusableOrder } from '../../../../types/typescript-axios'
import { Cell, CellProps } from '../../../atom/Cell'

export const PrintOrderDetailListPage = ({ title }: { title: string }) => {
  const { id } = useParams<{ id: string }>()
  const [reusableOrder, setReusableOrder] = useState<ReusableOrder>()

  useEffect(() => {
    async function fetchData() {
      const response = await webRequest('ReusableOrderShow', {
        id: Number(id),
      })
      if (response.status === 200) {
        setReusableOrder(response.data)
      }
    }

    fetchData()
  }, [])

  if (!reusableOrder) return <div />

  return (
    <div className="page-container">
      <PrintLayout title={title}>
        <OrderInfo order={reusableOrder} />
        <Table order={reusableOrder} />
      </PrintLayout>
    </div>
  )
}

const OrderInfo = ({ order }: { order: ReusableOrder }) => {
  return (
    <div>
      <Row className="print-text-2xl font-bold">
        <Column className="w-[180pt]">Order No</Column>
        <Column>{`#${order.id}`}</Column>
      </Row>
      <Row className="print-text-2xl font-bold">
        <Cell className="w-[180pt]">Shipping Date</Cell>
        <Cell className="gap-x-[8pt]">
          <span>{toFormattedDate(order.deliveryDateToConsignor)}</span>
          <span>{order.deliveryDateMeridiemIndicatorToConsignor}</span>
        </Cell>
      </Row>
      <Row className="mt-[8pt] print-text-2xl font-bold">
        <Column className="w-[180pt]">Shipping</Column>
        <Column className="w-[160pt]">{`${order.lenderSite?.name}`}</Column>
      </Row>
      <Row className="print-text-2xl font-bold">
        <Cell className="w-[180pt]">Return</Cell>
        <Cell className="w-[160pt]">{`${order.collectorSite?.name}`}</Cell>
      </Row>
    </div>
  )
}

const Table = ({ order }: { order: ReusableOrder }) => {
  return (
    <Grid className="grid-rows-[60pt] mt-[16pt]">
      <Grid className="grid-cols-[60pt_80pt_80pt_80pt_100pt_130pt]">
        <TableCell layout="center_center" className="flex-col bor">
          <div>Order</div>
          <div>No</div>
        </TableCell>
        <TableCell borderLeft={false}>Item</TableCell>
        <TableCell borderLeft={false}>Type</TableCell>
        <TableCell borderLeft={false}>Model</TableCell>
        <TableCell borderLeft={false}>S/N</TableCell>
        <TableCell borderLeft={false}>Option</TableCell>
      </Grid>
      {order.reusableOrderDetails.map((detail) => (
        <TableRow>
          <TableCell borderTop={false}>{detail.orderDetailNumber}</TableCell>
          {/* Item */}
          <TableCell borderLeft={false} borderTop={false} className="flex-col">
            <TableCellDetail>BOX</TableCellDetail>
            <TableCellDetail isBottom={!detail.hasLogger}>Unit</TableCellDetail>
            {detail.hasLogger && (
              <TableCellDetail isBottom={true}>Logger</TableCellDetail>
            )}
          </TableCell>
          {/* Type */}
          <TableCell borderLeft={false} borderTop={false} className="flex-col">
            <TableCellDetail>
              {getVixellKind(detail.vixellModel as VixellModel)}
            </TableCellDetail>
            <TableCellDetail isBottom={!detail.hasLogger}>
              {getThermalStorageUnitlKind(
                detail.thermalStorageUnitModel as ThermalStorageUnitModel
              )}
            </TableCellDetail>
            {detail.hasLogger && (
              <TableCellDetail isBottom={true}>
                {getTemperatureLoggerKind(
                  detail.loggerModel as TemperatureLoggerModel
                )}
              </TableCellDetail>
            )}
          </TableCell>
          {/* Model */}
          <TableCell borderLeft={false} borderTop={false} className="flex-col">
            <TableCellDetail>{detail.vixellModel}</TableCellDetail>
            <TableCellDetail isBottom={!detail.hasLogger}>
              {detail.thermalStorageUnitModel}
            </TableCellDetail>
            {detail.hasLogger && (
              <TableCellDetail isBottom={true}>
                {detail.loggerModel}
              </TableCellDetail>
            )}
          </TableCell>
          {/* S/N */}
          <TableCell borderLeft={false} borderTop={false} className="flex-col">
            <TableCellDetail>{detail.vixell?.serialNo}</TableCellDetail>
            <TableCellDetail isBottom={!detail.hasLogger}>
              {detail.thermalStorageUnit?.serialNo}
            </TableCellDetail>
            {detail.hasLogger && (
              <TableCellDetail isBottom={true}>
                {detail.temperatureLogger?.serialNo}
              </TableCellDetail>
            )}
          </TableCell>
          {/* Option-1 */}
          <TableCell borderLeft={false} borderTop={false} className="flex-col">
            <TableCellDetail>Pre-Cool</TableCellDetail>
            <TableCellDetail isBottom={!detail.hasLogger}>
              {detail.hasLogger && 'Logger'}
            </TableCellDetail>
            {detail.hasLogger && (
              <TableCellDetail isBottom={true}>Logging</TableCellDetail>
            )}
          </TableCell>
          {/* Option-2 */}
          <TableCell borderLeft={false} borderTop={false} className="flex-col">
            <TableCellDetail>
              {detail.preCooling ? 'Yes' : 'No'}
            </TableCellDetail>
            <TableCellDetail isBottom={!detail.hasLogger}>
              {!detail.hasLogger
                ? ''
                : detail.loggerSettingBy === 'PANASONIC'
                ? 'Configured'
                : 'NOT Set'}
            </TableCellDetail>
            {detail.hasLogger && (
              <TableCellDetail isBottom={true}>
                {detail.loggerSettingBy !== 'PANASONIC'
                  ? ''
                  : detail.loggingStartReservationType === 'MANUAL'
                  ? 'NOT Started'
                  : 'Started'}
              </TableCellDetail>
            )}
          </TableCell>
        </TableRow>
      ))}
    </Grid>
  )
}

const TableRow = ({ children }: { children: ReactNode }) => (
  <Grid className="grid-cols-[60pt_80pt_80pt_80pt_100pt_60pt_70pt]">
    {children}
  </Grid>
)

type TableCellProps = CellProps & {
  borderTop?: boolean
  borderLeft?: boolean
  borderRight?: boolean
  borderBottom?: boolean
}
const TableCell = ({
  borderTop = true,
  borderLeft = true,
  borderRight = true,
  borderBottom = true,
  children,
  className,
  ...props
}: TableCellProps) => {
  const borderClassName = []
  if (borderTop) borderClassName.push('border-t')
  if (borderLeft) borderClassName.push('border-l')
  if (borderRight) borderClassName.push('border-r')
  if (borderBottom) borderClassName.push('border-b')

  return (
    <Cell
      layout="center_center"
      className={`border-black ${borderClassName.join(' ')} ${className}`}
      {...props}
    >
      {children}
    </Cell>
  )
}

type TableCellDetailProps = CellProps & {
  isBottom?: boolean
}

const TableCellDetail = ({
  layout,
  className,
  isBottom = false,
  ...props
}: TableCellDetailProps) => {
  return (
    <Cell
      layout={layout ? layout : 'center_center'}
      className={`break-words border-black ${
        isBottom ? '' : 'border-b'
      } w-full min-h-[24pt] ${className}`}
      {...props}
    />
  )
}
