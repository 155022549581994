import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  ReusableOrder,
  ReusableOrderDetail,
} from '../../../types/typescript-axios'
import { webRequest } from '../../../types/handle-api'
import { QRCodeSVG } from 'qrcode.react'
import { toFormattedDate } from '../../../utils/to-formatted-date'
import { Row } from '../../../components/atom/Row'
import {
  PrintCheckBoxChecked,
  PrintCheckBoxHandWrite,
  PrintCheckBoxUnchecked,
} from '../../../components/templates/reusable-orders/print/print-layout'
import {
  OrderDetailContext,
  useOrderDetail,
} from '../../../contexts/use-order-detail'
import { ConsignorAddress } from '../../../components/molecules/ConsignorAddress'
import {
  getTemperatureLoggerKind,
  getThermalStorageUnitlKind,
  getVixellKind,
  TemperatureLoggerModel,
  ThermalStorageUnitModel,
  VixellModel,
} from '../../../constants/models'
import {
  ReturnVouchers,
  ReturnVoucherSiteName,
} from '../../../constants/return-vouchers'

export const ReusableShippingInstruction = () => {
  const { id } = useParams<{ id: string }>()
  const [reusableOrder, setReusableOrder] = useState<ReusableOrder>()

  useEffect(() => {
    async function fetchData() {
      const response = await webRequest('ReusableOrderShow', {
        id: Number(id),
      })
      if (response.status === 200) {
        setReusableOrder(response.data)
      }
    }

    fetchData()
  }, [])

  if (!reusableOrder) return <div />

  return (
    <div className="page-container">
      {reusableOrder.reusableOrderDetails.map((detail) => (
        <OrderDetailContext.Provider
          value={{ order: reusableOrder, orderDetail: detail }}
        >
          <ReusableOrderPage />
        </OrderDetailContext.Provider>
      ))}
    </div>
  )
}

const ReusableOrderPage = () => {
  const { order, orderDetail } = useOrderDetail()
  return (
    <div className="print-page h-[100vh] bg-red-500">
      <div className="flex justify-center items-center h-[36pt] text-[24pt] text-white font-bold border rounded bg-[#0f58ab]">
        VIXELL Order Sheet
      </div>
      <OrderInfo order={order} orderDetail={orderDetail} />
      <PickItems orderDetail={orderDetail} />
      <ReturnVoucher />
      <LoggerSetting orderDetail={orderDetail} />
      <PreCooling orderDetail={orderDetail} />
      <RegisterAndInspection />
      <Signature />
    </div>
  )
}

const OrderInfo = ({
  order,
  orderDetail,
}: {
  order: ReusableOrder
  orderDetail: ReusableOrderDetail
}) => {
  return (
    <div className="p-[10pt] border-b border-b-black">
      <div className="flex justify-between">
        <div className="flex gap-[16pt]">
          <div>
            <div className="print-text-2xl font-bold">Order No</div>
            <div className="print-text-2xl font-bold">Shipping Date</div>
          </div>
          <div>
            <div className="print-text-2xl font-bold">
              <span>{`#${order.id}-${orderDetail.orderDetailNumber}`}</span>
              <span className="ml-[16pt]">
                {`(${orderDetail.orderDetailNumber}/${order.reusableOrderDetails.length})`}
              </span>
            </div>
            <div className="print-text-2xl font-bold">
              {`${toFormattedDate(order.deliveryDateToConsignor)} ${
                order.deliveryDateMeridiemIndicatorToConsignor
              }`}
            </div>
          </div>
        </div>
        <div>
          <QRCodeSVG
            size={90}
            key={orderDetail.id}
            value={`${order.id}-${orderDetail.orderDetailNumber}`}
          />
        </div>
      </div>
      <div>
      <div className="flex border-b border-b-black">
          <div className="w-[120pt]">Address</div>
          <ConsignorAddress order={order} />
        </div>
        <div className="flex">
          <div className="w-[120pt]">Order Memo</div>
          <div>{order.notesForDistributionCenter || 'Nothing special'}</div>
        </div>
        <div className='flex'>
          <div className='w-[120pt]'>Delivery causion</div>
          <div>{order.notesForConsignor || 'Nothing special'}</div>
        </div>
        <div className="flex">
          <div className="w-[120pt]">Memo for Customer</div>
          <div>{orderDetail.notesForDelivery || 'Nothing special'}</div>
        </div>
      </div>
    </div>
  )
}

const PickItems = ({ orderDetail }: { orderDetail: ReusableOrderDetail }) => {
  return (
    <div className="p-[10pt] border-b border-b-black">
      <div className="print-h2">Pick Items</div>
      <div className="flex justify-between">
        <div>
          <div className="flex items-center">
            <div className="print-text-lg font-bold w-[100pt]">・BOX</div>
            <div className="print-text-lg font-bold w-[120pt]">
              {getVixellKind(orderDetail.vixellModel as VixellModel)}
            </div>
            <div className="print-text-lg font-bold w-[200pt]">
              {orderDetail.vixellModel}
            </div>
          </div>
          <div className="flex items-center">
            <div className="print-text-lg font-bold w-[100pt]">・Unit</div>
            <div className="print-text-lg font-bold w-[120pt]">
              {getThermalStorageUnitlKind(
                orderDetail.thermalStorageUnitModel as ThermalStorageUnitModel
              )}
            </div>
            <div className="print-text-lg font-bold w-[200pt]">
              {orderDetail.thermalStorageUnitModel}
            </div>
          </div>
          {orderDetail.hasLogger && (
            <div className="flex items-center">
              <div className="print-text-lg font-bold w-[100pt]">・Logger</div>
              <div className="print-text-lg font-bold w-[120pt]">
                {getTemperatureLoggerKind(
                  orderDetail.loggerModel as TemperatureLoggerModel
                )}
              </div>
              <div className="print-text-lg font-bold w-[120pt]">
                {orderDetail.loggerModel}
              </div>
            </div>
          )}
        </div>
        <PrintCheckBoxHandWrite />
      </div>
    </div>
  )
}

const ReturnVoucher = () => {
  const { order } = useOrderDetail()
  return (
    <Row className="justify-between items-center border-b border-b-black p-[10pt]">
      <Row className="flex items-center gap-[16pt]">
        <div className="print-h2">Return Voucher</div>
        <div className="flex items-center gap-[8pt]">
          <PrintCheckBoxChecked />
          <div className="print-text-lg font-bold w-[200pt]">
            {
              ReturnVouchers[
                order.collectorSite.siteIdentifier as ReturnVoucherSiteName
              ]
            }
          </div>
        </div>
      </Row>
      <PrintCheckBoxHandWrite />
    </Row>
  )
}
const LoggerSetting = ({
  orderDetail,
}: {
  orderDetail: ReusableOrderDetail
}) => {
  if (!orderDetail.hasLogger) return <></>

  return (
    <Row className="p-[10pt] border-b border-b-black justify-between">
      <div>
        <Row className="gap-[16pt]">
          <div className="print-h2">Logger setting</div>
          <div className="flex items-center gap-[8pt]">
            {orderDetail.loggerSettingBy === 'PANASONIC' ? (
              <PrintCheckBoxChecked />
            ) : (
              <PrintCheckBoxUnchecked />
            )}
            <span className="print-text-lg font-bold">Necessary</span>
            {orderDetail.loggerSettingBy === 'PANASONIC' ? (
              <PrintCheckBoxUnchecked />
            ) : (
              <PrintCheckBoxChecked />
            )}
            <span className="print-text-lg font-bold">No need</span>
          </div>
        </Row>
        {orderDetail.loggerSettingBy === 'PANASONIC' && (
          <div className="mt-[12pt]">
            <div className="flex">
              <div className="w-[100pt] print-text-md font-bold">Starter</div>
              <div className="flex gap-[8pt] items-center w-[200pt] print-text-md font-bold">
                {orderDetail.loggingStartReservationType === 'MANUAL' ? (
                  <PrintCheckBoxUnchecked />
                ) : (
                  <PrintCheckBoxChecked />
                )}
                Before shipping
              </div>
              <div className="flex gap-[8pt] items-center w-[100pt] print-text-md font-bold">
                {orderDetail.loggingStartReservationType === 'MANUAL' ? (
                  <PrintCheckBoxChecked />
                ) : (
                  <PrintCheckBoxUnchecked />
                )}
                Customer
              </div>
            </div>
            <div className="flex">
              <div className="w-[100pt] print-text-md font-bold">Interval</div>
              <div className="flex gap-[8pt] items-center w-[200pt] print-text-md font-bold">
                <PrintCheckBoxChecked />
                15 min
              </div>
            </div>
            <div className="flex">
              <div className="w-[100pt] print-text-md font-bold">
                Alert/Delay
              </div>
              <div className="flex gap-[8pt] items-center w-[200pt] print-text-md font-bold">
                <PrintCheckBoxChecked />
                {orderDetail.loggerAlertLowerTemperatureLimit}℃~
                {orderDetail.loggerAlertUpperTemperatureLimit}℃
              </div>
              <div className="flex gap-[8pt] items-center w-[100pt] print-text-md font-bold">
                <PrintCheckBoxChecked />
                {orderDetail.loggerAlertCapacity}
              </div>
            </div>
          </div>
        )}
      </div>
      <PrintCheckBoxHandWrite />
    </Row>
  )
}
const PreCooling = ({ orderDetail }: { orderDetail: ReusableOrderDetail }) => {
  return (
    <Row className="p-[10pt] border-b border-b-black justify-between">
      <div>
        <Row className="gap-[16pt]">
          <div className="print-h2">Pre-Cooling</div>
          <div className="flex items-center gap-[8pt]">
            {orderDetail.preCooling ? (
              <PrintCheckBoxChecked />
            ) : (
              <PrintCheckBoxUnchecked />
            )}
            <span className="print-text-lg font-bold">Necessary</span>
            {orderDetail.preCooling ? (
              <PrintCheckBoxUnchecked />
            ) : (
              <PrintCheckBoxChecked />
            )}
            <span className="print-text-lg font-bold">No need</span>
          </div>
        </Row>
        {orderDetail.preCooling && (
          <>
            <div className="flex items-center gap-[8pt] mt-[8pt]">
              <div className="w-[100pt] print-text-md font-bold">
                Customer Request
              </div>
              <div className="print-text-md">
                {orderDetail.preCoolingNotes || 'Nothing special'}
              </div>
            </div>
            <div className="flex gap-[16pt]">
              <div className="flex items-center gap-[8pt] mt-[10pt]">
                <div className="w-[100pt] print-text-md font-bold">
                  Temp at preparation
                </div>
                <div className="flex justify-end items-center px-[12pt] print-text-md font-bold w-[100pt] h-[40pt] border border-black ">
                  ℃
                </div>
              </div>
              <div className="flex items-center gap-[8pt] mt-[10pt]">
                <div className="w-[100pt] print-text-md font-bold">
                  Date and Time
                </div>
                <div className="flex justify-end items-center px-[12pt] print-text-md font-bold w-[140pt] h-[40pt] border border-black "></div>
              </div>
            </div>
          </>
        )}
      </div>
      <PrintCheckBoxHandWrite />
    </Row>
  )
}

const RegisterAndInspection = () => {
  return (
    <section className="p-[10pt] border-b border-b-black">
      <div className="print-h2">Register and Inspection</div>
      <Row className="justify-between mt-[12pt] items-center">
        <div className="flex items-center gap-[8pt]">
          <PrintCheckBoxChecked />
          <div className="print-text-md font-bold">
            Onsite Inspection and QR register
          </div>
        </div>
        <PrintCheckBoxHandWrite />
      </Row>
      <div className="flex justify-between mt-[6pt] items-center">
        <div className="flex items-center gap-[8pt]">
          <PrintCheckBoxChecked />
          <div className="print-text-md font-bold">
            Print and insert "Goods slip" and "Order note"
          </div>
        </div>
        <PrintCheckBoxHandWrite />
      </div>
      <div className="flex justify-between mt-[6pt] items-center">
        <div className="flex items-center gap-[8pt]">
          <PrintCheckBoxChecked />
          <div className="print-text-md font-bold">
            Transcription to "Goods slip" for temp at preparation
          </div>
        </div>
        <PrintCheckBoxHandWrite />
      </div>
    </section>
  )
}
const Signature = () => {
  return (
    <Row className="mt-[6pt] justify-end items-center gap-[8pt]">
      <span className="text-lg font-bold">Signature</span>
      <div className="border border-black w-[200pt] h-[28pt]" />
    </Row>
  )
}
