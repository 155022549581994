import { TemperatureTagCalibration } from '../../../types/typescript-axios'
import { Title } from '../../part/title'
import { ReactElement } from 'react'
import moment from 'moment'
import { Lang } from '../../../translations/lang'

export const TemperatureTagCalibrationComponent = ({
  calibration,
}: {
  calibration: TemperatureTagCalibration | undefined
}) => (
  <div>
    <Title title={Lang.admin.icePackDetail.calibrationResultTitle} />
    <div className="flex mt-32 gap-[32px]">
      <CalibrationResult
        title={Lang.admin.icePackDetail.lastInspection}
        results={[
          [
            Lang.admin.icePackDetail.referenceTemperatureSensorNo,
            calibration?.referenceTemperatureSensorNo,
          ],
          [
            Lang.admin.icePackDetail.temperatureCorrectionSlope,
            calibration?.temperatureCorrectionSlope,
          ],
          [
            Lang.admin.icePackDetail.temperatureCorrectionIntercept,
            calibration?.temperatureCorrectionIntercept,
          ],
          [
            Lang.admin.icePackDetail.rssiCorrectionSlope,
            calibration?.rssiCorrectionSlope,
          ],
          [
            Lang.admin.icePackDetail.rssiCorrectionIntercept,
            calibration?.rssiCorrectionIntercept,
          ],
          [
            Lang.inspectionTime,
            <span className="text-sm" key={1}>
              {calibration?.calibrationStartDateTime &&
                moment(calibration.calibrationStartDateTime).format(
                  Lang.dateTimeFormat
                )}
            </span>,
          ],
        ]}
      />
    </div>
    <div className="mt-32">
      <h3>{Lang.admin.icePackDetail.calibrationResults}</h3>
      {calibration?.calibrationResults
        ?.sort((x, y) => (x.id > y.id ? 1 : 0))
        ?.map((result, index) => (
          <div className="flex items-center mt-16" key={index}>
            <div className="text-lg font-bold w-64">No.{result.id}</div>
            <CalibrationResultUnit
              result={[
                Lang.admin.icePackDetail.referenceTemperature,
                result.referenceTemperature,
              ]}
            />
            <CalibrationResultUnit
              result={[
                Lang.admin.icePackDetail.tagTemperatureCode,
                result.tagTemperatureCode,
              ]}
            />
            <CalibrationResultUnit
              result={[Lang.admin.icePackDetail.rssi, result.rssi]}
            />
          </div>
        ))}
    </div>
  </div>
)

type CalibrationResultProps = {
  title: string
  results: [string, ReactElement | string | number | undefined][]
}
const CalibrationResult = ({ title, results }: CalibrationResultProps) => (
  <div>
    <h3>{title}</h3>
    <div className="flex flex-wrap mt-12 gap-y-[12px] gap-x-8">
      {results.map((result, index) => (
        <CalibrationResultUnit key={index} result={result} />
      ))}
    </div>
  </div>
)

type VacuumProcessResultUnitProps = {
  result: [string, ReactElement | number | string | undefined]
}
const CalibrationResultUnit = ({
  result: [label, element],
}: VacuumProcessResultUnitProps) => (
  <div className="w-[168px] flex flex-col gap-[8px] break-all">
    <label>{label}</label>
    <span>{element}</span>
  </div>
)
