import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { Lang } from '../../translations/lang'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { webRequest } from '../../types/handle-api'
import { ReusableOrder } from '../../types/typescript-axios'
import {
  ReusableOrderForm,
  ReusableOrdersNewFieldValues,
} from '../../components/templates/reusable-orders/ReusableOrderForm'

export const ReusableOrderEdit = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const [reusableOrder, setReusableOrder] = useState<ReusableOrder>()

  useEffect(() => {
    async function fetchData() {
      const response = await webRequest('ReusableOrderShow', {
        id: Number(id),
      })
      if (response.status === 200) {
        setReusableOrder(response.data)
      }
    }

    fetchData()
  }, [])

  async function submit(values: ReusableOrdersNewFieldValues) {
    if (!reusableOrder?.id) return false

    const response = await webRequest('ReusableOrderUpdate', {
      ...values,
      id: reusableOrder?.id,
    })
    if (response.status === 200) {
      navigate(`/reusable-orders/${reusableOrder.id}`)
      return true
    }
    return false
  }

  return (
    <DefaultWithTitleLayout title={Lang.reusable.reusableOrderEdit.title}>
      {reusableOrder && (
        <ReusableOrderForm submit={submit} defaultValues={reusableOrder} />
      )}
    </DefaultWithTitleLayout>
  )
}
