import { CheckSvg } from '../components/svg/check-svg'
import { FormEvent, useContext, useEffect, useState } from 'react'
import { setLoginToken } from '../local-storage/login-token'
import { LoginContext } from '../contexts/login-provider'
import { Lang } from '../translations/lang'
import { webRequest } from '../types/handle-api'

type SystemStatus = 'green' | 'yellow' | 'red'
type Notice = {
  date: string
  body: string
}

export const Login = () => {
  const [_, setLoginContext] = useContext(LoginContext)

  const [siteIdentifier, setSiteIdentifier] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [errorStatement, setErrorStatement] = useState<string>()

  const [systemStatus, setSystemStatus] = useState<SystemStatus>()
  const [notices, setNotices] = useState<Notice[]>([])

  useEffect(() => {
    async function fetchData() {
      const response = await fetch('/notices.json')
      const json = await response.json()

      setSystemStatus(json.status)
      setNotices(json.notices)
    }
    fetchData()
  }, [])

  async function login(e: FormEvent) {
    e.preventDefault()

    const response = await webRequest('Login', {
      siteIdentifier: siteIdentifier,
      password,
    })
    if (response.status === 200) {
      const { site, token } = response.data
      const loginToken = {
        version: 1,
        siteIdentifier: site.siteIdentifier,
        name: site.name,
        siteType: site.siteType,
        token,
      } as const
      setLoginToken(loginToken)
      // TODO: ここの処理が何をしようとしているか調べる
      setLoginContext(loginToken)
    } else {
      setErrorStatement(
        'アカウントID/サイトID、もしくはパスワードが間違っています'
      )
    }
  }

  return (
    <div>
      <svg
        className="absolute top-[519px] right-0  z-[-1]"
        width="1191"
        height="384"
        viewBox="0 0 1191 384"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 384L1191 0V384H0Z" fill="#80DEEA" fillOpacity="0.2" />
      </svg>
      <header className="flex justify-end px-[120px] pt-[60px]">
        <div className="flex flex-col justify-center items-center">
          {systemStatus === 'green' && (
            <>
              <div className="text-[#4CAF50] font-bold pb-[10px]">
                {Lang.login.green}
              </div>
              <CheckSvg />
            </>
          )}
        </div>
      </header>
      <form
        className="flex flex-col items-center justify-center mt-[20px]"
        onSubmit={login}
      >
        <img src="/images/logo.png" alt="logo" width={341} />
        <div className="mt-[50px]">
          <input
            type="text"
            placeholder={Lang.login.accountId}
            value={siteIdentifier}
            onChange={(e) => setSiteIdentifier(e.target.value.toUpperCase())}
            required={true}
          />
        </div>
        <div className="mt-[24px]">
          <input
            type="password"
            placeholder={Lang.login.password}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required={true}
          />
        </div>
        {errorStatement && <p className="error">{errorStatement}</p>}
        <div className="mt-[40px]">
          <button className="cursor-pointer w-[334px] h-[56px] rounded-[100px] bg-[#00BCD4] text-white font-bold text-[18px] shadow-vixell">
            {Lang.login.login}
          </button>
        </div>
      </form>
      <div className="mt-[64px] w-[600px] max-w-full mx-auto">
        <label>{Lang.login.news}</label>
        <div className="mt-[8px] border border-vixell-gray-light p-[24px] h-[200px] overflow-scroll">
          {notices.map((notice, index) => (
            <div className="flex" key={index}>
              <div className="text-[14px] leading-6">{notice.date}</div>
              <div className="flex-1 ml-[12px]">{notice.body}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
