import React, { useState } from 'react'
import './App.css'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import {
  deleteLoginToken,
  getLoginToken,
  LoginToken,
} from './local-storage/login-token'
import { LoginContext } from './contexts/login-provider'
import { AxiosResponse } from 'axios'
import { DefaultPage, PageItems } from './constants/pages'
import { Login } from './pages/Login'
import { ApiContext } from './contexts/api-provider'

function App() {
  const [loginToken, setLoginToken] = useState<LoginToken | null>(
    getLoginToken()
  )

  async function useApi<T>(
    result: Promise<AxiosResponse<T>>
  ): Promise<HandleErrorResponse<T>> {
    const response = await handleApi(result)
    if (response.status === 401) {
      deleteLoginToken()
      setLoginToken(null)
    }

    return response
  }

  return (
    <LoginContext.Provider value={[loginToken, setLoginToken, useApi]}>
      <ApiContext>
        <BrowserRouter>
          {loginToken ? (
            <Routes>
              {PageItems.filter(
                (page) =>
                  loginToken?.siteType &&
                  page.siteTypes.some((s) => s === loginToken.siteType)
              ).map((page, index) => (
                <Route key={index} path={page.url} element={page.component} />
              ))}
              <Route
                key="default-route"
                path="*"
                element={
                  <Navigate
                    to={DefaultPage[loginToken.siteType]}
                    replace={true}
                  />
                }
              />
            </Routes>
          ) : (
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Routes>
          )}
        </BrowserRouter>
      </ApiContext>
    </LoginContext.Provider>
  )
}

export default App

export type HandleErrorResponse<T> =
  | {
      status: 200
      data: T
    }
  | {
      status: 422 | 401
      data: {
        type: string
        code: string
        params: {
          [key: string]: string[]
        }
      }
    }

async function handleApi<T>(
  result: Promise<AxiosResponse<T>>
): Promise<HandleErrorResponse<T>> {
  try {
    const response = await result
    return {
      status: 200,
      data: response.data,
    }
  } catch (e: any) {
    return {
      status: e.response.status,
      data: e.response.data,
    }
  }
}
