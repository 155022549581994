import { useState } from 'react'

type Props = {
  toolTipText?: string
  children: React.ReactNode
}
export const TableElementWithTooltip = ({ toolTipText, children }: Props) => {
  const [showTooltip, setShowToolTip] = useState(false)
  // console.log('children', children)

  return (
    <div
      onMouseEnter={() => toolTipText && setShowToolTip(true) }
      onMouseLeave={() => toolTipText && setShowToolTip(false)}
      className={
        "max-w-full " 
        + (showTooltip && 'relative')
      }
    >
      <div>{children}</div>
      {showTooltip && (
        <div 
        className=
          "absolute top-[20px] left-0 bg-white w-max max-w-[300px] shadow-vixell border-[2px] border-[#d3d3d3] rounded-[10px] p-[10px] whitespace-pre-wrap"
        >
          {toolTipText}
        </div>
      )}
    </div>
  )
}
