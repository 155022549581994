import { Modal } from '../../molecules/modal'
import { Button } from '../../molecules/button'
import { Title } from '../../part/title'
import { Lang } from '../../../translations/lang'
import { Site } from '../../../types/typescript-axios'
import { InputWithLabel } from '../../molecules/input-with-label'
import { FormEvent, useState } from 'react'
import { webRequest } from '../../../types/handle-api'

type Props = {
  representativeSite: Site
  hide: () => void
}
export const EditRepresentativeSitePassword = ({
  representativeSite,
  hide,
}: Props) => {
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')

  const [errorStatement, setErrorStatement] = useState<string>()

  async function submit(e: FormEvent) {
    e.preventDefault()
    if (password !== passwordConfirmation)
      return setErrorStatement('パスワードが一致しません')
    const response = await webRequest('CustomersChangeSitePassword', {
      siteId: representativeSite.id,
      password: password,
    })
    console.log(JSON.stringify(response))
    if (response.status === 200) {
      window.location.reload()
    }
  }

  return (
    <Modal>
      <form onSubmit={submit}>
        <Title title={Lang.changePassword} />
        <div className="mt-16">
          <label>{Lang.representativeSiteId}</label>
          <div>{representativeSite.id}</div>
        </div>
        <div className="mt-16">
          <label>{Lang.representativeSiteName}</label>
          <div>{representativeSite.name}</div>
        </div>
        <InputWithLabel
          type="password"
          className="mt-16"
          label={Lang.password}
          value={password}
          required={true}
          pattern={'^[a-zA-Z\\d]{8,32}$'}
          title={Lang.passwordFormatTitle}
          onChange={setPassword}
        />
        <InputWithLabel
          type="password"
          className="mt-16"
          label={Lang.passwordConfirmation}
          value={passwordConfirmation}
          required={true}
          onChange={setPasswordConfirmation}
        />

        {errorStatement && (
          <p className="my-16 text-vixell-red text-center">{errorStatement}</p>
        )}

        <div className="mt-32 flex justify-center gap-8">
          <Button type="button" color="red" onClick={hide}>
            キャンセル
          </Button>
          <Button color="blue">変更</Button>
        </div>
      </form>
    </Modal>
  )
}
