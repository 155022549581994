import { FC } from 'react'

type Props = {
  color: 'cyan' | 'red' | 'blue'
  onClick?: () => void
  className?: string
}

export const SquareButton: FC<Props> = ({
  children,
  color,
  onClick = () => undefined,
  className,
}) => (
  <button
    className={[
      'inline-flex items-center justfy-center px-[18px] h-[40px] rounded-[3px] bg-white border hover:bg-gray-50 font-bold',
      color === 'cyan'
        ? 'text-vixell-cyan border-vixell-cyan'
        : color === 'red'
        ? 'text-vixell-red border-vixell-red'
        : 'text-vixell-blue border-vixell-blue',
      className,
    ].join(' ')}
    onClick={onClick}
  >
    {children}
  </button>
)
