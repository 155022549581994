import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { Lang } from '../../translations/lang'
import { webRequest } from '../../types/handle-api'
import { useNavigate } from 'react-router-dom'
import {
  ReusableOrderForm,
  ReusableOrdersNewFieldValues,
} from '../../components/templates/reusable-orders/ReusableOrderForm'

// 契約情報
export const ReusableOrdersNew = () => {
  const navigate = useNavigate()

  const submit = async (values: ReusableOrdersNewFieldValues) => {
    const response = await webRequest('ReusableOrderCreate', values)
    if (response.status === 200) {
      navigate('/reusable-orders')
      return true
    }
    return false
  }

  return (
    <DefaultWithTitleLayout
      title={Lang.admin.reusableOrdersNew.title}
      description={Lang.admin.reusableOrdersNew.description}
    >
      <ReusableOrderForm
        submit={submit}
        // defaultValues={{
        //   customerCompanyName: 'test',
        //   customerCompanyDivision: 'test',
        //   customerPhone: 'aaa',
        //   deliveryDateToConsignor: '2023-01-01T00:00:00Z',
        //   deliveryDateMeridiemIndicatorToConsignor: 'AM',
        //   countryOfConsignor: 'JP',
        //   companyNameOfConsignor: 'TestComp',
        //   stateOfConsignor: 'Tokyo',
        //   zipCodeOfConsignor: '102039',
        //   addressOfConsignor: '189289',
        //   divisionOfConsignor: '111',
        //   phoneOfConsignor: '111',
        //   countryOfConsignee: 'US',
        //   stateOfConsignee: '1828',
        // }}
      />
    </DefaultWithTitleLayout>
  )
}
