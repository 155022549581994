import { useEffect, useState } from 'react'
import { Trade } from '../../types/typescript-axios'

import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { VixellTable } from '../../components/part/vixell-table'
import { TableElementThermalStorageUnit } from '../../components/part/table-elements/table-element-thermal-storage-unit'
import { TableElementAccessory } from '../../components/part/table-elements/table-element-accessory'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SearchButton } from '../../components/part/search-button'
import { AdminTradesSearchModal } from '../../components/templates/admin-trades/admin-trades-search-modal'
import moment from 'moment'
import { Lang } from '../../translations/lang'
import { downloadCsv } from '../../utils/download-csv'
import { toFormattedDate } from '../../utils/to-formatted-date'
import { webRequest } from '../../types/handle-api'
import { usePaging } from '../../contexts/use-paging'
import { TableElementProductCountWithLink } from '../../components/part/table-elements/table-element-product-count-with-link'

export const AdminTrades = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [trades, setTrades] = useState<Trade[]>([])

  const [showSearchModal, setShowSearchModal] = useState<boolean>(false)

  const { currentPage, setPaging, stopPageLoad } = usePaging()
  const [loadNextPageAt, setLoadNextPageAt] = useState<Date>(new Date())
  const [searchParamsString, setSearchParamsString] = useState<string>()

  useEffect(() => {
    fetchDataAndSetStates()
  }, [searchParams])

  async function fetchDataAndSetStates(loadNextPage = false) {
    const response = await fetchTrades({ loadNextPage })
    if (response.status === 200) {
      if (searchParams.toString() === searchParamsString) {
        setTrades((t) => [...t, ...response.data.trades])
      } else {
        setTrades(response.data.trades)
      }
      setPaging(response.data)
      setSearchParamsString(searchParams.toString())
    }
  }

  async function fetchTrades({ all = false, loadNextPage = false }) {
    const contractRepresentativeNo = searchParams.get('contractNo')
    const tradeType = searchParams.get('tradeType')
    const customerName = searchParams.get('customerName')
    const destination = searchParams.get('destination')
    const returnEstimatedDateFrom = searchParams.get('estimateFrom')
    const returnEstimatedDateTill = searchParams.get('estimateTill')
    const returnCompletedDateFrom = searchParams.get('completeFrom')
    const returnCompletedDateTill = searchParams.get('completeTill')

    return await webRequest('TradesIndex', {
      ...(contractRepresentativeNo && { contractRepresentativeNo }),
      ...(tradeType && { tradeType }),
      ...(customerName && { customerName }),
      ...(destination && { destination }),
      ...(returnEstimatedDateFrom && { returnEstimatedDateFrom }),
      ...(returnEstimatedDateTill && { returnEstimatedDateTill }),
      ...(returnCompletedDateFrom && { returnCompletedDateFrom }),
      ...(returnCompletedDateTill && { returnCompletedDateTill }),
      ...(all && { all: true }),
      ...(!all && loadNextPage && { page: currentPage + 1 }),
    })
  }

  async function csvDownload() {
    const response = await fetchTrades({ all: true })
    if (response.status === 200) {
      const csvData = [
        [
          Lang.contractRepresentativeNo,
          Lang.tradeType,
          Lang.customerName,
          Lang.destination,
          Lang.returnEstimatedDate,
          Lang.returnCompletedDate,
        ],
        ...response.data.trades.map((trade) => [
          trade.contractRepresentativeNo,
          trade.tradeType,
          trade.customer?.name,
          trade.destination,
          toFormattedDate(trade.returnEstimatedDate),
          toFormattedDate(trade.returnCompletedDate),
        ]),
      ]
      downloadCsv(csvData, `trades-${new Date().toISOString()}.csv`)
    }
  }

  function infiniteLoad() {
    // TODO: 永遠に実行してしまうので、注意
    if (stopPageLoad) return
    if (moment(loadNextPageAt).isAfter(moment().subtract(3, 'seconds'))) return

    setLoadNextPageAt(new Date())
    fetchDataAndSetStates(true)
  }

  const titleRightElement = (
    <div>
      <SearchButton onClick={() => setShowSearchModal(true)} />

      {[
        'serial',
        'contactNo',
        'siteName',
        'shippingState',
        'shippingFrom',
        'shippingTill',
        'calibrationFrom',
        'calibrationTill',
        'ng',
        'part',
        'inspectFrom',
        'inspectTill',
      ]
        .map((param) => searchParams.get(param))
        .filter((x) => x).length > 0 && (
        <p className="flex justify-end text-xs mt-8 font-bold">
          {Lang.searchCondition}
        </p>
      )}
      <div className="absolute flex flex-col content-end flex-wrap  gap-x-[8px] gap-y-[4px] h-[80px] text-xs mt-8">
        {searchParams.get('contractNo') && (
          <span>
            {Lang.contractRepresentativeNo}:{searchParams.get('contractNo')}
          </span>
        )}
        {searchParams.get('tradeType') && (
          <span>
            {Lang.tradeType}:{searchParams.get('tradeType')}
          </span>
        )}
        {searchParams.get('customerName') && (
          <span>
            {Lang.customerName}:{searchParams.get('customerName')}
          </span>
        )}
        {searchParams.get('destination') && (
          <span>
            {Lang.destination}:{searchParams.get('destination')}
          </span>
        )}
        {(searchParams.get('estimateFrom') ||
          searchParams.get('estimateTill')) && (
          <span>
            {Lang.returnEstimatedDate}:
            {toFormattedDate(searchParams.get('estimateFrom'))}-
            {toFormattedDate(searchParams.get('estimateTill'))}
          </span>
        )}
        {(searchParams.get('completeFrom') ||
          searchParams.get('completeTill')) && (
          <span>
            {Lang.returnCompletedDate}:
            {toFormattedDate(searchParams.get('completeFrom'))}-
            {toFormattedDate(searchParams.get('completeTill'))}
          </span>
        )}
      </div>
    </div>
  )

  return (
    <DefaultWithTitleLayout
      title={Lang.admin.trades.title}
      description={Lang.admin.trades.description}
      titleRightElement={titleRightElement}
      infiniteLoad={infiniteLoad}
      csvDownload={csvDownload}
    >
      {trades && (
        <VixellTable
          rootClassName="my-64"
          classNames={[
            'flex-1',
            'min-w-140 flex-1',
            'flex-1',
            'min-w-140 flex-1',
            'min-w-120 flex-1',
            'min-w-120 flex-1',
            'flex-1',
            'flex-1',
            'flex-1',
          ]}
          headers={[
            <div className="flex flex-col items-center text-sm" key={1}>
              <div>{Lang.contractRepresentativeNo}</div>
              <div>{Lang.tradeType}</div>
            </div>,
            Lang.customerName,
            Lang.destination,
            <div className="flex flex-col items-center text-sm" key={1}>
              <div>{Lang.returnEstimatedDate}</div>
              <div>{Lang.returnCompletedDate}</div>
            </div>,
            Lang.vixell,
            Lang.icePackName,
            Lang.inspectionBoard,
            `${Lang.thermalStorageUnit}1`,
            `${Lang.accessory}1`,
          ]}
          elements={trades.map((trade) => [
            <div className="text-center" key={trade.id}>
              <div>{trade.contractRepresentativeNo}</div>
              <div>{trade.tradeType}</div>
            </div>,
            trade.customer?.name,
            trade.destination,
            <div className="text-center" key={trade.id}>
              <div>{toFormattedDate(trade.returnEstimatedDate)}</div>
              <div>{toFormattedDate(trade.returnCompletedDate)}</div>
            </div>,
            <TableElementProductCountWithLink
              type="vixells"
              products={trade.vixellModelCounts}
              contractRepresentativeNo={trade.contractRepresentativeNo}
            />,
            <TableElementProductCountWithLink
              type="ice-packs"
              count={trade.icePackCount}
              contractRepresentativeNo={trade.contractRepresentativeNo}
            />,
            <TableElementProductCountWithLink
              type="inspection-boards"
              products={trade.inspectionBoardModelCounts}
              contractRepresentativeNo={trade.contractRepresentativeNo}
            />,
            trade.thermalStorageUnits &&
            trade.thermalStorageUnits.length > 0 ? (
              <TableElementThermalStorageUnit
                unit={trade.thermalStorageUnits[0]}
              />
            ) : (
              ''
            ),
            trade.accessories && trade.accessories?.length > 0 ? (
              <TableElementAccessory accessory={trade.accessories[0]} />
            ) : (
              ''
            ),
          ])}
          onLineClick={(index) => navigate(`/trades/${trades[index].id}`)}
        />
      )}
      {showSearchModal && (
        <AdminTradesSearchModal hide={() => setShowSearchModal(false)} />
      )}
    </DefaultWithTitleLayout>
  )
}
