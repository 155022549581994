/* tslint:disable */
/* eslint-disable */
/**
 * Vixell API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from './configuration'
import globalAxios, {
  AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
} from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  toPathString,
} from './common'
// @ts-ignore
import {
  BASE_PATH,
  BaseAPI,
  COLLECTION_FORMATS,
  RequestArgs,
  RequiredError,
} from './base'

/**
 *
 * @export
 * @interface Acceptance
 */
export interface Acceptance {
  /**
   *
   * @type {string}
   * @memberof Acceptance
   */
  app: AcceptanceAppEnum
  /**
   *
   * @type {string}
   * @memberof Acceptance
   */
  logType: AcceptanceLogTypeEnum
  /**
   *
   * @type {CommonData & VacuumProcessCommon & object}
   * @memberof Acceptance
   */
  data: CommonData & VacuumProcessCommon & object
}

export const AcceptanceAppEnum = {
  VacuumProcess: 'vacuumProcess',
} as const

export type AcceptanceAppEnum =
  typeof AcceptanceAppEnum[keyof typeof AcceptanceAppEnum]
export const AcceptanceLogTypeEnum = {
  Acceptance: 'acceptance',
} as const

export type AcceptanceLogTypeEnum =
  typeof AcceptanceLogTypeEnum[keyof typeof AcceptanceLogTypeEnum]

/**
 *
 * @export
 * @interface AccessoryLast
 */
export interface AccessoryLast {
  /**
   *
   * @type {string}
   * @memberof AccessoryLast
   */
  productCode: string
  /**
   *
   * @type {number}
   * @memberof AccessoryLast
   */
  shippingCount: number
  /**
   *
   * @type {number}
   * @memberof AccessoryLast
   */
  returnCount: number
}
/**
 *
 * @export
 * @interface AccesssoryShipping
 */
export interface AccesssoryShipping {
  /**
   *
   * @type {string}
   * @memberof AccesssoryShipping
   */
  app: AccesssoryShippingAppEnum
  /**
   *
   * @type {string}
   * @memberof AccesssoryShipping
   */
  logType: AccesssoryShippingLogTypeEnum
  /**
   *
   * @type {CommonData & ShippingManagementCommonInfo & CommonThermalStorageUnitAndAccessory}
   * @memberof AccesssoryShipping
   */
  data: CommonData &
    ShippingManagementCommonInfo &
    CommonThermalStorageUnitAndAccessory
}

export const AccesssoryShippingAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type AccesssoryShippingAppEnum =
  typeof AccesssoryShippingAppEnum[keyof typeof AccesssoryShippingAppEnum]
export const AccesssoryShippingLogTypeEnum = {
  AccessoryShipping: 'accessoryShipping',
} as const

export type AccesssoryShippingLogTypeEnum =
  typeof AccesssoryShippingLogTypeEnum[keyof typeof AccesssoryShippingLogTypeEnum]

/**
 *
 * @export
 * @interface AdminDashboardRequest
 */
export interface AdminDashboardRequest {
  /**
   *
   * @type {string}
   * @memberof AdminDashboardRequest
   */
  type: AdminDashboardRequestTypeEnum
}

export const AdminDashboardRequestTypeEnum = {
  AdminDashboard: 'AdminDashboard',
} as const

export type AdminDashboardRequestTypeEnum =
  typeof AdminDashboardRequestTypeEnum[keyof typeof AdminDashboardRequestTypeEnum]

/**
 *
 * @export
 * @interface AdminDashboardResponse
 */
export interface AdminDashboardResponse {
  /**
   *
   * @type {string}
   * @memberof AdminDashboardResponse
   */
  type: AdminDashboardResponseTypeEnum
  /**
   *
   * @type {DashboardForAdmin}
   * @memberof AdminDashboardResponse
   */
  data: DashboardForAdmin
}

export const AdminDashboardResponseTypeEnum = {
  AdminDashboard: 'AdminDashboard',
} as const

export type AdminDashboardResponseTypeEnum =
  typeof AdminDashboardResponseTypeEnum[keyof typeof AdminDashboardResponseTypeEnum]

/**
 *
 * @export
 * @interface AdminOnsiteResult
 */
export interface AdminOnsiteResult {
  /**
   *
   * @type {string}
   * @memberof AdminOnsiteResult
   */
  vixellSerialNo: string
  /**
   *
   * @type {string}
   * @memberof AdminOnsiteResult
   */
  pcId: string
  /**
   *
   * @type {string}
   * @memberof AdminOnsiteResult
   */
  processingDateTime: string
  /**
   *
   * @type {string}
   * @memberof AdminOnsiteResult
   */
  siteName: string
  /**
   *
   * @type {string}
   * @memberof AdminOnsiteResult
   */
  fullContractNo?: string
  /**
   *
   * @type {string}
   * @memberof AdminOnsiteResult
   */
  managementNo?: string
  /**
   *
   * @type {OnsiteResultOnsiteVacuumResult}
   * @memberof AdminOnsiteResult
   */
  onsiteVacuumResult: OnsiteResultOnsiteVacuumResult
  /**
   *
   * @type {AdminOnsiteResultOnsiteIcePackResult}
   * @memberof AdminOnsiteResult
   */
  onsiteIcePackResult?: AdminOnsiteResultOnsiteIcePackResult
}
/**
 *
 * @export
 * @interface AdminOnsiteResultLast
 */
export interface AdminOnsiteResultLast {
  /**
   *
   * @type {string}
   * @memberof AdminOnsiteResultLast
   */
  vixellSerialNo: string
  /**
   *
   * @type {string}
   * @memberof AdminOnsiteResultLast
   */
  pcId: string
  /**
   *
   * @type {string}
   * @memberof AdminOnsiteResultLast
   */
  processingDateTime: string
  /**
   *
   * @type {string}
   * @memberof AdminOnsiteResultLast
   */
  siteName: string
  /**
   *
   * @type {ContractWithManagementNo}
   * @memberof AdminOnsiteResultLast
   */
  contractInfo: ContractWithManagementNo
  /**
   *
   * @type {OnsiteResultOnsiteVacuumResult}
   * @memberof AdminOnsiteResultLast
   */
  onsiteVacuumResult: OnsiteResultOnsiteVacuumResult
  /**
   *
   * @type {OnsiteResultOnsiteIcePackResult}
   * @memberof AdminOnsiteResultLast
   */
  onsiteIcePackResult?: OnsiteResultOnsiteIcePackResult
}
/**
 *
 * @export
 * @interface AdminOnsiteResultOnsiteIcePackResult
 */
export interface AdminOnsiteResultOnsiteIcePackResult {
  /**
   *
   * @type {string}
   * @memberof AdminOnsiteResultOnsiteIcePackResult
   */
  totalInspectionResult: string
  /**
   *
   * @type {string}
   * @memberof AdminOnsiteResultOnsiteIcePackResult
   */
  totalErrorDetail?: string
  /**
   *
   * @type {Array<AdminOnsiteResultOnsiteIcePackResultInspectionResults>}
   * @memberof AdminOnsiteResultOnsiteIcePackResult
   */
  inspectionResults?: Array<AdminOnsiteResultOnsiteIcePackResultInspectionResults>
}
/**
 *
 * @export
 * @interface AdminOnsiteResultOnsiteIcePackResultInspectionResults
 */
export interface AdminOnsiteResultOnsiteIcePackResultInspectionResults {
  /**
   *
   * @type {string}
   * @memberof AdminOnsiteResultOnsiteIcePackResultInspectionResults
   */
  temperatureSensorTagEpc: string
  /**
   *
   * @type {number}
   * @memberof AdminOnsiteResultOnsiteIcePackResultInspectionResults
   */
  icePackTemperature?: number
  /**
   *
   * @type {string}
   * @memberof AdminOnsiteResultOnsiteIcePackResultInspectionResults
   */
  inspectionResult?: string
  /**
   *
   * @type {string}
   * @memberof AdminOnsiteResultOnsiteIcePackResultInspectionResults
   */
  errorDetail?: string
}
/**
 *
 * @export
 * @interface AuthenticationError
 */
export interface AuthenticationError {
  /**
   *
   * @type {string}
   * @memberof AuthenticationError
   */
  type?: AuthenticationErrorTypeEnum
  /**
   *
   * @type {string}
   * @memberof AuthenticationError
   */
  code?: AuthenticationErrorCodeEnum
}

export const AuthenticationErrorTypeEnum = {
  AuthenticationError: 'authentication-error',
} as const

export type AuthenticationErrorTypeEnum =
  typeof AuthenticationErrorTypeEnum[keyof typeof AuthenticationErrorTypeEnum]
export const AuthenticationErrorCodeEnum = {
  HeaderRequired: 'header-required',
  InvalidAuthorizationHeader: 'invalid-authorization-header',
} as const

export type AuthenticationErrorCodeEnum =
  typeof AuthenticationErrorCodeEnum[keyof typeof AuthenticationErrorCodeEnum]

/**
 *
 * @export
 * @interface BelongingLog
 */
export interface BelongingLog {
  /**
   *
   * @type {number}
   * @memberof BelongingLog
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof BelongingLog
   */
  processingDateTime: string
  /**
   *
   * @type {Site}
   * @memberof BelongingLog
   */
  site: Site
  /**
   *
   * @type {BelongingLogTimingValue}
   * @memberof BelongingLog
   */
  timing: BelongingLogTimingValue
  /**
   *
   * @type {VixellProductData | IcePackProductData | ThermalStorageUnitProductData | TemperatureLoggerProductData}
   * @memberof BelongingLog
   */
  product?:
    | VixellProductData
    | IcePackProductData
    | ThermalStorageUnitProductData
    | TemperatureLoggerProductData
}
/**
 *
 * @export
 * @enum {string}
 */

export const BelongingLogTimingValue = {
  Stocked: 'STOCKED',
  Shipped: 'SHIPPED',
  Returned: 'RETURNED',
  Disposed: 'DISPOSED',
  Defective: 'DEFECTIVE',
  Onsite: 'ONSITE',
  Lost: 'LOST',
  ReusableStocked: 'REUSABLE_STOCKED',
  ReusableShipped: 'REUSABLE_SHIPPED',
  ReusableLend: 'REUSABLE_LEND',
  ReusableCollected: 'REUSABLE_COLLECTED',
} as const

export type BelongingLogTimingValue =
  typeof BelongingLogTimingValue[keyof typeof BelongingLogTimingValue]

/**
 *
 * @export
 * @interface BelongingLogsIndexRequest
 */
export interface BelongingLogsIndexRequest {
  /**
   *
   * @type {string}
   * @memberof BelongingLogsIndexRequest
   */
  type: BelongingLogsIndexRequestTypeEnum
  /**
   *
   * @type {BelongingLogsIndexRequestData}
   * @memberof BelongingLogsIndexRequest
   */
  data: BelongingLogsIndexRequestData
}

export const BelongingLogsIndexRequestTypeEnum = {
  BelongingLogsIndex: 'BelongingLogsIndex',
} as const

export type BelongingLogsIndexRequestTypeEnum =
  typeof BelongingLogsIndexRequestTypeEnum[keyof typeof BelongingLogsIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface BelongingLogsIndexRequestData
 */
export interface BelongingLogsIndexRequestData {
  /**
   *
   * @type {string}
   * @memberof BelongingLogsIndexRequestData
   */
  productType: BelongingLogsIndexRequestDataProductTypeEnum
  /**
   *
   * @type {number}
   * @memberof BelongingLogsIndexRequestData
   */
  productId: number
  /**
   *
   * @type {number}
   * @memberof BelongingLogsIndexRequestData
   */
  page?: number
}

export const BelongingLogsIndexRequestDataProductTypeEnum = {
  Vixell: 'VIXELL',
  TemperatureSensorTag: 'TEMPERATURE_SENSOR_TAG',
  ThermalStorageUnit: 'THERMAL_STORAGE_UNIT',
  TemperatureLogger: 'TEMPERATURE_LOGGER',
} as const

export type BelongingLogsIndexRequestDataProductTypeEnum =
  typeof BelongingLogsIndexRequestDataProductTypeEnum[keyof typeof BelongingLogsIndexRequestDataProductTypeEnum]

/**
 *
 * @export
 * @interface BelongingLogsIndexResponse
 */
export interface BelongingLogsIndexResponse {
  /**
   *
   * @type {string}
   * @memberof BelongingLogsIndexResponse
   */
  type: BelongingLogsIndexResponseTypeEnum
  /**
   *
   * @type {BelongingLogsIndexResponseData}
   * @memberof BelongingLogsIndexResponse
   */
  data: BelongingLogsIndexResponseData
}

export const BelongingLogsIndexResponseTypeEnum = {
  BelongingLogsIndex: 'BelongingLogsIndex',
} as const

export type BelongingLogsIndexResponseTypeEnum =
  typeof BelongingLogsIndexResponseTypeEnum[keyof typeof BelongingLogsIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface BelongingLogsIndexResponseData
 */
export interface BelongingLogsIndexResponseData {
  /**
   *
   * @type {Array<BelongingLog>}
   * @memberof BelongingLogsIndexResponseData
   */
  belongingLogs: Array<BelongingLog>
  /**
   *
   * @type {number}
   * @memberof BelongingLogsIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof BelongingLogsIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @enum {string}
 */

export const Box = {
  S: 'S',
  L: 'L',
} as const

export type Box = typeof Box[keyof typeof Box]

/**
 *
 * @export
 * @interface Calibration
 */
export interface Calibration {
  /**
   *
   * @type {string}
   * @memberof Calibration
   */
  app: CalibrationAppEnum
  /**
   *
   * @type {string}
   * @memberof Calibration
   */
  logType: CalibrationLogTypeEnum
  /**
   *
   * @type {CommonData & object}
   * @memberof Calibration
   */
  data: CommonData & object
}

export const CalibrationAppEnum = {
  TemperatureTagCalibration: 'temperatureTagCalibration',
} as const

export type CalibrationAppEnum =
  typeof CalibrationAppEnum[keyof typeof CalibrationAppEnum]
export const CalibrationLogTypeEnum = {
  Calibration: 'calibration',
} as const

export type CalibrationLogTypeEnum =
  typeof CalibrationLogTypeEnum[keyof typeof CalibrationLogTypeEnum]

/**
 *
 * @export
 * @interface ChangePassword
 */
export interface ChangePassword {
  /**
   *
   * @type {string}
   * @memberof ChangePassword
   */
  siteId: string
  /**
   *
   * @type {string}
   * @memberof ChangePassword
   */
  password: string
}
/**
 *
 * @export
 * @interface ChangePassword1
 */
export interface ChangePassword1 {
  /**
   *
   * @type {string}
   * @memberof ChangePassword1
   */
  representativeSiteId: string
  /**
   *
   * @type {string}
   * @memberof ChangePassword1
   */
  password: string
}
/**
 *
 * @export
 * @interface ClientAppLog
 */
export interface ClientAppLog {
  /**
   *
   * @type {string}
   * @memberof ClientAppLog
   */
  timestamp: string
  /**
   *
   * @type {string}
   * @memberof ClientAppLog
   */
  pcId: string
  /**
   *
   * @type {ClientAppType}
   * @memberof ClientAppLog
   */
  appType: ClientAppType
  /**
   *
   * @type {LogLevel}
   * @memberof ClientAppLog
   */
  logLevel: LogLevel
  /**
   *
   * @type {string}
   * @memberof ClientAppLog
   */
  logCode: string
  /**
   *
   * @type {string}
   * @memberof ClientAppLog
   */
  logDataUrl: string
  /**
   *
   * @type {Customer}
   * @memberof ClientAppLog
   */
  customer?: Customer
  /**
   *
   * @type {Site}
   * @memberof ClientAppLog
   */
  site?: Site
}
/**
 *
 * @export
 * @interface ClientAppOnsiteVacuum
 */
export interface ClientAppOnsiteVacuum {
  /**
   *
   * @type {string}
   * @memberof ClientAppOnsiteVacuum
   */
  app: ClientAppOnsiteVacuumAppEnum
  /**
   *
   * @type {string}
   * @memberof ClientAppOnsiteVacuum
   */
  logType: ClientAppOnsiteVacuumLogTypeEnum
  /**
   *
   * @type {CommonData & object}
   * @memberof ClientAppOnsiteVacuum
   */
  data: CommonData & object
}

export const ClientAppOnsiteVacuumAppEnum = {
  Onsite: 'onsite',
} as const

export type ClientAppOnsiteVacuumAppEnum =
  typeof ClientAppOnsiteVacuumAppEnum[keyof typeof ClientAppOnsiteVacuumAppEnum]
export const ClientAppOnsiteVacuumLogTypeEnum = {
  OnsiteVacuum: 'onsiteVacuum',
} as const

export type ClientAppOnsiteVacuumLogTypeEnum =
  typeof ClientAppOnsiteVacuumLogTypeEnum[keyof typeof ClientAppOnsiteVacuumLogTypeEnum]

/**
 *
 * @export
 * @enum {string}
 */

export const ClientAppType = {
  Onsite: 'onsite',
  ShippingManagement: 'shippingManagement',
  VacuumProcess: 'vacuumProcess',
  TemperatureTagCalibration: 'temperatureTagCalibration',
} as const

export type ClientAppType = typeof ClientAppType[keyof typeof ClientAppType]

/**
 *
 * @export
 * @interface ClientLog
 */
export interface ClientLog {
  /**
   *
   * @type {string}
   * @memberof ClientLog
   */
  timestamp: string
  /**
   *
   * @type {string}
   * @memberof ClientLog
   */
  pcId: string
  /**
   *
   * @type {ClientAppType}
   * @memberof ClientLog
   */
  appType: ClientAppType
  /**
   *
   * @type {LogLevel}
   * @memberof ClientLog
   */
  logLevel: LogLevel
  /**
   *
   * @type {string}
   * @memberof ClientLog
   */
  logCode: string
  /**
   *
   * @type {string}
   * @memberof ClientLog
   */
  logData: string
  /**
   *
   * @type {CustomerLast}
   * @memberof ClientLog
   */
  customer?: CustomerLast
  /**
   *
   * @type {SiteLast}
   * @memberof ClientLog
   */
  site?: SiteLast
}
/**
 *
 * @export
 * @interface ClientLog1
 */
export interface ClientLog1 {
  /**
   *
   * @type {string}
   * @memberof ClientLog1
   */
  app: ClientLog1AppEnum
  /**
   *
   * @type {string}
   * @memberof ClientLog1
   */
  logType: ClientLog1LogTypeEnum
  /**
   *
   * @type {CommonData & object}
   * @memberof ClientLog1
   */
  data: CommonData & object
}

export const ClientLog1AppEnum = {
  Onsite: 'onsite',
  VacuumProcess: 'vacuumProcess',
  TemperatureTagCalibration: 'temperatureTagCalibration',
  ShippingManagement: 'shippingManagement',
} as const

export type ClientLog1AppEnum =
  typeof ClientLog1AppEnum[keyof typeof ClientLog1AppEnum]
export const ClientLog1LogTypeEnum = {
  ClientLog: 'clientLog',
} as const

export type ClientLog1LogTypeEnum =
  typeof ClientLog1LogTypeEnum[keyof typeof ClientLog1LogTypeEnum]

/**
 *
 * @export
 * @interface ClientLogsIndexRequest
 */
export interface ClientLogsIndexRequest {
  /**
   *
   * @type {string}
   * @memberof ClientLogsIndexRequest
   */
  type: ClientLogsIndexRequestTypeEnum
  /**
   *
   * @type {ClientLogsIndexRequestData}
   * @memberof ClientLogsIndexRequest
   */
  data: ClientLogsIndexRequestData
}

export const ClientLogsIndexRequestTypeEnum = {
  ClientLogsIndex: 'ClientLogsIndex',
} as const

export type ClientLogsIndexRequestTypeEnum =
  typeof ClientLogsIndexRequestTypeEnum[keyof typeof ClientLogsIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface ClientLogsIndexRequestData
 */
export interface ClientLogsIndexRequestData {
  /**
   *
   * @type {string}
   * @memberof ClientLogsIndexRequestData
   */
  query?: string
  /**
   *
   * @type {ClientAppType}
   * @memberof ClientLogsIndexRequestData
   */
  app?: ClientAppType
  /**
   *
   * @type {LogLevel}
   * @memberof ClientLogsIndexRequestData
   */
  logLevel?: LogLevel
  /**
   *
   * @type {string}
   * @memberof ClientLogsIndexRequestData
   */
  from?: string
  /**
   *
   * @type {string}
   * @memberof ClientLogsIndexRequestData
   */
  till?: string
  /**
   *
   * @type {boolean}
   * @memberof ClientLogsIndexRequestData
   */
  all?: boolean
  /**
   *
   * @type {number}
   * @memberof ClientLogsIndexRequestData
   */
  page?: number
  /**
   *
   * @type {number}
   * @memberof ClientLogsIndexRequestData
   */
  per?: number
}
/**
 *
 * @export
 * @interface ClientLogsIndexResponse
 */
export interface ClientLogsIndexResponse {
  /**
   *
   * @type {string}
   * @memberof ClientLogsIndexResponse
   */
  type: ClientLogsIndexResponseTypeEnum
  /**
   *
   * @type {ClientLogsIndexResponseData}
   * @memberof ClientLogsIndexResponse
   */
  data: ClientLogsIndexResponseData
}

export const ClientLogsIndexResponseTypeEnum = {
  ClientLogsIndex: 'ClientLogsIndex',
} as const

export type ClientLogsIndexResponseTypeEnum =
  typeof ClientLogsIndexResponseTypeEnum[keyof typeof ClientLogsIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface ClientLogsIndexResponseData
 */
export interface ClientLogsIndexResponseData {
  /**
   *
   * @type {Array<ClientAppLog>}
   * @memberof ClientLogsIndexResponseData
   */
  logs: Array<ClientAppLog>
  /**
   *
   * @type {number}
   * @memberof ClientLogsIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof ClientLogsIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @interface CommonData
 */
export interface CommonData {
  /**
   * 処理日時
   * @type {string}
   * @memberof CommonData
   */
  processingDateTime: string
  /**
   * PC識別子(16進数かつ32桁)
   * @type {string}
   * @memberof CommonData
   */
  pcId: string
}
/**
 *
 * @export
 * @interface CommonInspectionBoard
 */
export interface CommonInspectionBoard {
  /**
   * 検査台-S/N(半角英数記号　255文字以内)
   * @type {string}
   * @memberof CommonInspectionBoard
   */
  inspectionBoardSerialNo: string
  /**
   * モデル
   * @type {string}
   * @memberof CommonInspectionBoard
   */
  model?: string
}
/**
 *
 * @export
 * @interface CommonTemperatureLogger
 */
export interface CommonTemperatureLogger {
  /**
   * シリアルナンバー(半角英数記号　255文字以内)
   * @type {string}
   * @memberof CommonTemperatureLogger
   */
  temperatureLoggerSerialNo: string
  /**
   * モデル
   * @type {string}
   * @memberof CommonTemperatureLogger
   */
  model?: string
}
/**
 *
 * @export
 * @interface CommonTemperatureTag
 */
export interface CommonTemperatureTag {
  /**
   *
   * @type {Array<CommonTemperatureTagTags>}
   * @memberof CommonTemperatureTag
   */
  tags: Array<CommonTemperatureTagTags>
}
/**
 *
 * @export
 * @interface CommonTemperatureTagTags
 */
export interface CommonTemperatureTagTags {
  /**
   * 温度センサータグS/N(EPC)
   * @type {string}
   * @memberof CommonTemperatureTagTags
   */
  temperatureSensorTagEpc: string
}
/**
 *
 * @export
 * @interface CommonThermalStorageUnit
 */
export interface CommonThermalStorageUnit {
  /**
   * シリアルナンバー(半角英数記号　255文字以内)
   * @type {string}
   * @memberof CommonThermalStorageUnit
   */
  thermalStorageUnitSerialNo: string
  /**
   * モデル
   * @type {string}
   * @memberof CommonThermalStorageUnit
   */
  model?: string
}
/**
 *
 * @export
 * @interface CommonThermalStorageUnitAndAccessory
 */
export interface CommonThermalStorageUnitAndAccessory {
  /**
   *
   * @type {Array<CommonThermalStorageUnitAndAccessoryProducts>}
   * @memberof CommonThermalStorageUnitAndAccessory
   */
  products: Array<CommonThermalStorageUnitAndAccessoryProducts>
}
/**
 *
 * @export
 * @interface CommonThermalStorageUnitAndAccessoryProducts
 */
export interface CommonThermalStorageUnitAndAccessoryProducts {
  /**
   * 品目コード
   * @type {string}
   * @memberof CommonThermalStorageUnitAndAccessoryProducts
   */
  productCode: string
  /**
   * 個数
   * @type {number}
   * @memberof CommonThermalStorageUnitAndAccessoryProducts
   */
  quantity: number
}
/**
 *
 * @export
 * @interface CommonVixell
 */
export interface CommonVixell {
  /**
   * VIXELL-S/N (16文字以下の英数字記号)
   * @type {string}
   * @memberof CommonVixell
   */
  vixellSerialNo: string
  /**
   * 機種名(自由入力（検査読取orキーボード）)
   * @type {string}
   * @memberof CommonVixell
   */
  model?: string
  /**
   * センサ電圧
   * @type {number}
   * @memberof CommonVixell
   */
  sensorVoltage?: number
  /**
   * センサ圧力
   * @type {number}
   * @memberof CommonVixell
   */
  sensorPressure?: number
  /**
   * 検査結果(Pass, Fail, Stop, Timeoutなど)
   * @type {string}
   * @memberof CommonVixell
   */
  inspectionResult?: string
}
/**
 *
 * @export
 * @interface ContractOnlyRepresentative
 */
export interface ContractOnlyRepresentative {
  /**
   *
   * @type {string}
   * @memberof ContractOnlyRepresentative
   */
  contractRepresentativeNo: string
}
/**
 *
 * @export
 * @interface ContractWithManagementNo
 */
export interface ContractWithManagementNo {
  /**
   *
   * @type {string}
   * @memberof ContractWithManagementNo
   */
  contractRepresentativeNo: string
  /**
   *
   * @type {string}
   * @memberof ContractWithManagementNo
   */
  contractNo?: string
  /**
   *
   * @type {string}
   * @memberof ContractWithManagementNo
   */
  managementNo?: string
  /**
   *
   * @type {string}
   * @memberof ContractWithManagementNo
   */
  tradeType?: string
}
/**
 *
 * @export
 * @interface CreateCustomer
 */
export interface CreateCustomer {
  /**
   *
   * @type {string}
   * @memberof CreateCustomer
   */
  customerId: string
  /**
   *
   * @type {string}
   * @memberof CreateCustomer
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreateCustomer
   */
  representativeSiteId: string
  /**
   *
   * @type {string}
   * @memberof CreateCustomer
   */
  representativeSiteName: string
  /**
   *
   * @type {string}
   * @memberof CreateCustomer
   */
  representativeSitePassword: string
}
/**
 *
 * @export
 * @interface CreateSite
 */
export interface CreateSite {
  /**
   *
   * @type {string}
   * @memberof CreateSite
   */
  siteId: string
  /**
   *
   * @type {string}
   * @memberof CreateSite
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof CreateSite
   */
  password: string
}
/**
 *
 * @export
 * @interface CreateSite1
 */
export interface CreateSite1 {
  /**
   *
   * @type {string}
   * @memberof CreateSite1
   */
  siteId: string
  /**
   *
   * @type {string}
   * @memberof CreateSite1
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreateSite1
   */
  password: string
}
/**
 *
 * @export
 * @interface Customer
 */
export interface Customer {
  /**
   *
   * @type {number}
   * @memberof Customer
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  customerIdentifier: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof Customer
   */
  representativeSiteId: string
  /**
   *
   * @type {Site}
   * @memberof Customer
   */
  representativeSite: Site
}
/**
 *
 * @export
 * @interface CustomerLast
 */
export interface CustomerLast {
  /**
   *
   * @type {string}
   * @memberof CustomerLast
   */
  customerId?: string
  /**
   *
   * @type {string}
   * @memberof CustomerLast
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CustomerLast
   */
  representativeSiteId: string
  /**
   *
   * @type {SiteLast}
   * @memberof CustomerLast
   */
  representativeSite: SiteLast
}
/**
 *
 * @export
 * @interface CustomersChangeSitePasswordRequest
 */
export interface CustomersChangeSitePasswordRequest {
  /**
   *
   * @type {string}
   * @memberof CustomersChangeSitePasswordRequest
   */
  type: CustomersChangeSitePasswordRequestTypeEnum
  /**
   *
   * @type {CustomersChangeSitePasswordRequestData}
   * @memberof CustomersChangeSitePasswordRequest
   */
  data: CustomersChangeSitePasswordRequestData
}

export const CustomersChangeSitePasswordRequestTypeEnum = {
  CustomersChangeSitePassword: 'CustomersChangeSitePassword',
} as const

export type CustomersChangeSitePasswordRequestTypeEnum =
  typeof CustomersChangeSitePasswordRequestTypeEnum[keyof typeof CustomersChangeSitePasswordRequestTypeEnum]

/**
 *
 * @export
 * @interface CustomersChangeSitePasswordRequestData
 */
export interface CustomersChangeSitePasswordRequestData {
  /**
   *
   * @type {number}
   * @memberof CustomersChangeSitePasswordRequestData
   */
  siteId: number
  /**
   *
   * @type {string}
   * @memberof CustomersChangeSitePasswordRequestData
   */
  password: string
}
/**
 *
 * @export
 * @interface CustomersChangeSitePasswordResponse
 */
export interface CustomersChangeSitePasswordResponse {
  /**
   *
   * @type {string}
   * @memberof CustomersChangeSitePasswordResponse
   */
  type: CustomersChangeSitePasswordResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof CustomersChangeSitePasswordResponse
   */
  data: SuccessResponseData
}

export const CustomersChangeSitePasswordResponseTypeEnum = {
  CustomersChangeSitePassword: 'CustomersChangeSitePassword',
} as const

export type CustomersChangeSitePasswordResponseTypeEnum =
  typeof CustomersChangeSitePasswordResponseTypeEnum[keyof typeof CustomersChangeSitePasswordResponseTypeEnum]

/**
 *
 * @export
 * @interface CustomersCreateRequest
 */
export interface CustomersCreateRequest {
  /**
   *
   * @type {string}
   * @memberof CustomersCreateRequest
   */
  type: CustomersCreateRequestTypeEnum
  /**
   *
   * @type {CustomersCreateRequestData}
   * @memberof CustomersCreateRequest
   */
  data: CustomersCreateRequestData
}

export const CustomersCreateRequestTypeEnum = {
  CustomersCreate: 'CustomersCreate',
} as const

export type CustomersCreateRequestTypeEnum =
  typeof CustomersCreateRequestTypeEnum[keyof typeof CustomersCreateRequestTypeEnum]

/**
 *
 * @export
 * @interface CustomersCreateRequestData
 */
export interface CustomersCreateRequestData {
  /**
   *
   * @type {string}
   * @memberof CustomersCreateRequestData
   */
  customerIdentifier: string
  /**
   *
   * @type {string}
   * @memberof CustomersCreateRequestData
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CustomersCreateRequestData
   */
  representativeSiteIdentifier: string
  /**
   *
   * @type {string}
   * @memberof CustomersCreateRequestData
   */
  representativeSiteName: string
  /**
   *
   * @type {string}
   * @memberof CustomersCreateRequestData
   */
  representativeSitePassword: string
}
/**
 *
 * @export
 * @interface CustomersCreateResponse
 */
export interface CustomersCreateResponse {
  /**
   *
   * @type {string}
   * @memberof CustomersCreateResponse
   */
  type: CustomersCreateResponseTypeEnum
  /**
   *
   * @type {CustomersCreateResponseData}
   * @memberof CustomersCreateResponse
   */
  data: CustomersCreateResponseData
}

export const CustomersCreateResponseTypeEnum = {
  CustomersCreate: 'CustomersCreate',
} as const

export type CustomersCreateResponseTypeEnum =
  typeof CustomersCreateResponseTypeEnum[keyof typeof CustomersCreateResponseTypeEnum]

/**
 *
 * @export
 * @interface CustomersCreateResponseData
 */
export interface CustomersCreateResponseData {
  /**
   *
   * @type {string}
   * @memberof CustomersCreateResponseData
   */
  type: CustomersCreateResponseDataTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof CustomersCreateResponseData
   */
  data: SuccessResponseData
}

export const CustomersCreateResponseDataTypeEnum = {
  SitesCreate: 'SitesCreate',
} as const

export type CustomersCreateResponseDataTypeEnum =
  typeof CustomersCreateResponseDataTypeEnum[keyof typeof CustomersCreateResponseDataTypeEnum]

/**
 *
 * @export
 * @interface CustomersDestroyRequest
 */
export interface CustomersDestroyRequest {
  /**
   *
   * @type {string}
   * @memberof CustomersDestroyRequest
   */
  type: CustomersDestroyRequestTypeEnum
  /**
   *
   * @type {ReusableOrderShowRequestData}
   * @memberof CustomersDestroyRequest
   */
  data: ReusableOrderShowRequestData
}

export const CustomersDestroyRequestTypeEnum = {
  CustomersDestroy: 'CustomersDestroy',
} as const

export type CustomersDestroyRequestTypeEnum =
  typeof CustomersDestroyRequestTypeEnum[keyof typeof CustomersDestroyRequestTypeEnum]

/**
 *
 * @export
 * @interface CustomersDestroyResponse
 */
export interface CustomersDestroyResponse {
  /**
   *
   * @type {string}
   * @memberof CustomersDestroyResponse
   */
  type: CustomersDestroyResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof CustomersDestroyResponse
   */
  data: SuccessResponseData
}

export const CustomersDestroyResponseTypeEnum = {
  CustomersDestroy: 'CustomersDestroy',
} as const

export type CustomersDestroyResponseTypeEnum =
  typeof CustomersDestroyResponseTypeEnum[keyof typeof CustomersDestroyResponseTypeEnum]

/**
 *
 * @export
 * @interface CustomersIndexRequest
 */
export interface CustomersIndexRequest {
  /**
   *
   * @type {string}
   * @memberof CustomersIndexRequest
   */
  type: CustomersIndexRequestTypeEnum
}

export const CustomersIndexRequestTypeEnum = {
  CustomersIndex: 'CustomersIndex',
} as const

export type CustomersIndexRequestTypeEnum =
  typeof CustomersIndexRequestTypeEnum[keyof typeof CustomersIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface CustomersIndexResponse
 */
export interface CustomersIndexResponse {
  /**
   *
   * @type {string}
   * @memberof CustomersIndexResponse
   */
  type: CustomersIndexResponseTypeEnum
  /**
   *
   * @type {CustomersIndexResponseData}
   * @memberof CustomersIndexResponse
   */
  data: CustomersIndexResponseData
}

export const CustomersIndexResponseTypeEnum = {
  CustomersIndex: 'CustomersIndex',
} as const

export type CustomersIndexResponseTypeEnum =
  typeof CustomersIndexResponseTypeEnum[keyof typeof CustomersIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface CustomersIndexResponseData
 */
export interface CustomersIndexResponseData {
  /**
   *
   * @type {Array<Customer>}
   * @memberof CustomersIndexResponseData
   */
  customers: Array<Customer>
  /**
   *
   * @type {boolean}
   * @memberof CustomersIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @interface DashboardForAdmin
 */
export interface DashboardForAdmin {
  /**
   *
   * @type {Array<DashboardForAdminDashboards>}
   * @memberof DashboardForAdmin
   */
  dashboards: Array<DashboardForAdminDashboards>
  /**
   *
   * @type {DashboardForAdminLastVixells}
   * @memberof DashboardForAdmin
   */
  vixells: DashboardForAdminLastVixells
}
/**
 *
 * @export
 * @interface DashboardForAdminAdditionalProperty
 */
export interface DashboardForAdminAdditionalProperty {
  /**
   *
   * @type {string}
   * @memberof DashboardForAdminAdditionalProperty
   */
  customerId: string
  /**
   *
   * @type {string}
   * @memberof DashboardForAdminAdditionalProperty
   */
  customerName: string
  /**
   *
   * @type {DashboardForAdminAdditionalPropertyClientLogs}
   * @memberof DashboardForAdminAdditionalProperty
   */
  clientLogs: DashboardForAdminAdditionalPropertyClientLogs
}
/**
 *
 * @export
 * @interface DashboardForAdminAdditionalPropertyClientLogs
 */
export interface DashboardForAdminAdditionalPropertyClientLogs {
  /**
   *
   * @type {number}
   * @memberof DashboardForAdminAdditionalPropertyClientLogs
   */
  '30days': number
  /**
   *
   * @type {number}
   * @memberof DashboardForAdminAdditionalPropertyClientLogs
   */
  '12months': number
}
/**
 *
 * @export
 * @interface DashboardForAdminDashboards
 */
export interface DashboardForAdminDashboards {
  /**
   *
   * @type {number}
   * @memberof DashboardForAdminDashboards
   */
  siteId?: number
  /**
   *
   * @type {string}
   * @memberof DashboardForAdminDashboards
   */
  siteName: string
  /**
   *
   * @type {number}
   * @memberof DashboardForAdminDashboards
   */
  customerId?: number
  /**
   *
   * @type {string}
   * @memberof DashboardForAdminDashboards
   */
  customerName: string
  /**
   *
   * @type {number}
   * @memberof DashboardForAdminDashboards
   */
  stockedCount: number
  /**
   *
   * @type {DashboardForAdminOnsite}
   * @memberof DashboardForAdminDashboards
   */
  onsite: DashboardForAdminOnsite
  /**
   *
   * @type {DashboardForAdminAdditionalPropertyClientLogs}
   * @memberof DashboardForAdminDashboards
   */
  clientAppLog: DashboardForAdminAdditionalPropertyClientLogs
}
/**
 *
 * @export
 * @interface DashboardForAdminLast
 */
export interface DashboardForAdminLast {
  /**
   *
   * @type {Array<DashboardForAdminAdditionalProperty & DashboardForCustomerLast>}
   * @memberof DashboardForAdminLast
   */
  dashboards: Array<
    DashboardForAdminAdditionalProperty & DashboardForCustomerLast
  >
  /**
   *
   * @type {DashboardForAdminLastVixells}
   * @memberof DashboardForAdminLast
   */
  vixells: DashboardForAdminLastVixells
  /**
   *
   * @type {string}
   * @memberof DashboardForAdminLast
   */
  processingDateTime?: string
}
/**
 *
 * @export
 * @interface DashboardForAdminLastVixells
 */
export interface DashboardForAdminLastVixells {
  /**
   *
   * @type {number}
   * @memberof DashboardForAdminLastVixells
   */
  active: number
  /**
   *
   * @type {number}
   * @memberof DashboardForAdminLastVixells
   */
  disposed: number
}
/**
 *
 * @export
 * @interface DashboardForAdminOnsite
 */
export interface DashboardForAdminOnsite {
  /**
   *
   * @type {Onsite}
   * @memberof DashboardForAdminOnsite
   */
  '30days': Onsite
  /**
   *
   * @type {Onsite}
   * @memberof DashboardForAdminOnsite
   */
  '12months': Onsite
}
/**
 *
 * @export
 * @interface DashboardForCustomer
 */
export interface DashboardForCustomer {
  /**
   *
   * @type {string}
   * @memberof DashboardForCustomer
   */
  siteName: string
  /**
   *
   * @type {number}
   * @memberof DashboardForCustomer
   */
  stockedCount: number
  /**
   *
   * @type {DashboardForCustomerLastOnsite}
   * @memberof DashboardForCustomer
   */
  onsite: DashboardForCustomerLastOnsite
}
/**
 *
 * @export
 * @interface DashboardForCustomerLast
 */
export interface DashboardForCustomerLast {
  /**
   *
   * @type {string}
   * @memberof DashboardForCustomerLast
   */
  siteId: string
  /**
   *
   * @type {string}
   * @memberof DashboardForCustomerLast
   */
  siteName: string
  /**
   *
   * @type {number}
   * @memberof DashboardForCustomerLast
   */
  stockedCount: number
  /**
   *
   * @type {DashboardForCustomerLastOnsite}
   * @memberof DashboardForCustomerLast
   */
  onsite: DashboardForCustomerLastOnsite
}
/**
 *
 * @export
 * @interface DashboardForCustomerLastOnsite
 */
export interface DashboardForCustomerLastOnsite {
  /**
   *
   * @type {Array<Onsite>}
   * @memberof DashboardForCustomerLastOnsite
   */
  '30days': Array<Onsite>
  /**
   *
   * @type {Array<Onsite>}
   * @memberof DashboardForCustomerLastOnsite
   */
  '12months': Array<Onsite>
}
/**
 *
 * @export
 * @interface DestroyCustomer
 */
export interface DestroyCustomer {
  /**
   *
   * @type {string}
   * @memberof DestroyCustomer
   */
  customerId: string
}
/**
 *
 * @export
 * @interface IcePack
 */
export interface IcePack {
  /**
   *
   * @type {number}
   * @memberof IcePack
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof IcePack
   */
  temperatureSensorTagEpc: string
  /**
   *
   * @type {string}
   * @memberof IcePack
   */
  model?: string
  /**
   *
   * @type {IcePackPart}
   * @memberof IcePack
   */
  part?: IcePackPart
  /**
   *
   * @type {string}
   * @memberof IcePack
   */
  stockedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof IcePack
   */
  disposedDateTime?: string
}
/**
 *
 * @export
 * @interface IcePackInventory
 */
export interface IcePackInventory {
  /**
   *
   * @type {string}
   * @memberof IcePackInventory
   */
  temperatureSensorTagEpc: string
  /**
   *
   * @type {boolean}
   * @memberof IcePackInventory
   */
  settled: boolean
  /**
   *
   * @type {string}
   * @memberof IcePackInventory
   */
  contractRepresentativeNo?: string
  /**
   *
   * @type {string}
   * @memberof IcePackInventory
   */
  processingDateTime?: string
  /**
   *
   * @type {SiteLast}
   * @memberof IcePackInventory
   */
  site?: SiteLast
  /**
   *
   * @type {IcePackPartLast}
   * @memberof IcePackInventory
   */
  part: IcePackPartLast
}
/**
 *
 * @export
 * @interface IcePackLast
 */
export interface IcePackLast {
  /**
   *
   * @type {string}
   * @memberof IcePackLast
   */
  temperatureSensorTagEpc: string
  /**
   *
   * @type {string}
   * @memberof IcePackLast
   */
  model?: string
  /**
   *
   * @type {IcePackPartLast}
   * @memberof IcePackLast
   */
  part?: IcePackPartLast
  /**
   *
   * @type {string}
   * @memberof IcePackLast
   */
  stockedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof IcePackLast
   */
  disposedDateTime?: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const IcePackPart = {
  Left: 'LEFT',
  Right: 'RIGHT',
  Bottom: 'BOTTOM',
  Top: 'TOP',
  Front: 'FRONT',
  Back: 'BACK',
} as const

export type IcePackPart = typeof IcePackPart[keyof typeof IcePackPart]

/**
 *
 * @export
 * @enum {string}
 */

export const IcePackPartLast = {
  Left: 'LEFT',
  Right: 'RIGHT',
  Bottom: 'BOTTOM',
  Top: 'TOP',
  Front: 'FRONT',
  Back: 'BACK',
} as const

export type IcePackPartLast =
  typeof IcePackPartLast[keyof typeof IcePackPartLast]

/**
 *
 * @export
 * @interface IcePackProductData
 */
export interface IcePackProductData {
  /**
   *
   * @type {string}
   * @memberof IcePackProductData
   */
  type: IcePackProductDataTypeEnum
  /**
   *
   * @type {IcePack}
   * @memberof IcePackProductData
   */
  product: IcePack
}

export const IcePackProductDataTypeEnum = {
  TemperatureSensorTag: 'TEMPERATURE_SENSOR_TAG',
} as const

export type IcePackProductDataTypeEnum =
  typeof IcePackProductDataTypeEnum[keyof typeof IcePackProductDataTypeEnum]

/**
 *
 * @export
 * @interface IcePacksIndexRequest
 */
export interface IcePacksIndexRequest {
  /**
   *
   * @type {string}
   * @memberof IcePacksIndexRequest
   */
  type: IcePacksIndexRequestTypeEnum
  /**
   *
   * @type {IcePacksIndexRequestComponent}
   * @memberof IcePacksIndexRequest
   */
  data: IcePacksIndexRequestComponent
}

export const IcePacksIndexRequestTypeEnum = {
  IcePacksIndex: 'IcePacksIndex',
} as const

export type IcePacksIndexRequestTypeEnum =
  typeof IcePacksIndexRequestTypeEnum[keyof typeof IcePacksIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface IcePacksIndexRequestComponent
 */
export interface IcePacksIndexRequestComponent {
  /**
   *
   * @type {string}
   * @memberof IcePacksIndexRequestComponent
   */
  temperatureSensorTagEpc?: string
  /**
   *
   * @type {string}
   * @memberof IcePacksIndexRequestComponent
   */
  latestSiteName?: string
  /**
   *
   * @type {string}
   * @memberof IcePacksIndexRequestComponent
   */
  contractRepresentativeNo?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof IcePacksIndexRequestComponent
   */
  shippingStatus?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof IcePacksIndexRequestComponent
   */
  latestShippingStateDateTimeFrom?: string
  /**
   *
   * @type {string}
   * @memberof IcePacksIndexRequestComponent
   */
  latestShippingStateDateTimeTill?: string
  /**
   *
   * @type {string}
   * @memberof IcePacksIndexRequestComponent
   */
  calibrationDateTimeFrom?: string
  /**
   *
   * @type {string}
   * @memberof IcePacksIndexRequestComponent
   */
  calibrationDateTimeTill?: string
  /**
   *
   * @type {IcePackPart}
   * @memberof IcePacksIndexRequestComponent
   */
  part?: IcePackPart
  /**
   *
   * @type {boolean}
   * @memberof IcePacksIndexRequestComponent
   */
  onlyNg?: boolean
  /**
   *
   * @type {number}
   * @memberof IcePacksIndexRequestComponent
   */
  sensorPressureMinimum?: number
  /**
   *
   * @type {number}
   * @memberof IcePacksIndexRequestComponent
   */
  sensorPressureMaximum?: number
  /**
   *
   * @type {string}
   * @memberof IcePacksIndexRequestComponent
   */
  latestOnsiteDateTimeFrom?: string
  /**
   *
   * @type {string}
   * @memberof IcePacksIndexRequestComponent
   */
  latestOnsiteDateTimeTill?: string
  /**
   *
   * @type {boolean}
   * @memberof IcePacksIndexRequestComponent
   */
  all?: boolean
  /**
   *
   * @type {string}
   * @memberof IcePacksIndexRequestComponent
   */
  exclusiveStartKey?: string
}
/**
 *
 * @export
 * @interface IcePacksIndexResponse
 */
export interface IcePacksIndexResponse {
  /**
   *
   * @type {string}
   * @memberof IcePacksIndexResponse
   */
  type: IcePacksIndexResponseTypeEnum
  /**
   *
   * @type {IcePacksIndexResponseData}
   * @memberof IcePacksIndexResponse
   */
  data: IcePacksIndexResponseData
}

export const IcePacksIndexResponseTypeEnum = {
  IcePacksIndex: 'IcePacksIndex',
} as const

export type IcePacksIndexResponseTypeEnum =
  typeof IcePacksIndexResponseTypeEnum[keyof typeof IcePacksIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface IcePacksIndexResponseData
 */
export interface IcePacksIndexResponseData {
  /**
   *
   * @type {Array<IndexIcePackResponseComponent>}
   * @memberof IcePacksIndexResponseData
   */
  icePacks: Array<IndexIcePackResponseComponent>
  /**
   *
   * @type {number}
   * @memberof IcePacksIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof IcePacksIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @interface IcePacksShowRequest
 */
export interface IcePacksShowRequest {
  /**
   *
   * @type {string}
   * @memberof IcePacksShowRequest
   */
  type: IcePacksShowRequestTypeEnum
  /**
   *
   * @type {ReusableOrderShowRequestData}
   * @memberof IcePacksShowRequest
   */
  data: ReusableOrderShowRequestData
}

export const IcePacksShowRequestTypeEnum = {
  IcePacksShow: 'IcePacksShow',
} as const

export type IcePacksShowRequestTypeEnum =
  typeof IcePacksShowRequestTypeEnum[keyof typeof IcePacksShowRequestTypeEnum]

/**
 *
 * @export
 * @interface IcePacksShowResponse
 */
export interface IcePacksShowResponse {
  /**
   *
   * @type {string}
   * @memberof IcePacksShowResponse
   */
  type: IcePacksShowResponseTypeEnum
  /**
   *
   * @type {IcePacksShowResponseData}
   * @memberof IcePacksShowResponse
   */
  data: IcePacksShowResponseData
}

export const IcePacksShowResponseTypeEnum = {
  IcePacksShow: 'IcePacksShow',
} as const

export type IcePacksShowResponseTypeEnum =
  typeof IcePacksShowResponseTypeEnum[keyof typeof IcePacksShowResponseTypeEnum]

/**
 *
 * @export
 * @interface IcePacksShowResponseData
 */
export interface IcePacksShowResponseData {
  /**
   *
   * @type {IcePack}
   * @memberof IcePacksShowResponseData
   */
  icePack?: IcePack
  /**
   *
   * @type {OnsiteIcePack1}
   * @memberof IcePacksShowResponseData
   */
  onsiteResult?: OnsiteIcePack1
  /**
   *
   * @type {TemperatureTagCalibration}
   * @memberof IcePacksShowResponseData
   */
  calibrationResult?: TemperatureTagCalibration
}
/**
 *
 * @export
 * @interface IndexClientLogs
 */
export interface IndexClientLogs {
  /**
   *
   * @type {string}
   * @memberof IndexClientLogs
   */
  query?: string
  /**
   *
   * @type {ClientAppType}
   * @memberof IndexClientLogs
   */
  app?: ClientAppType
  /**
   *
   * @type {LogLevel}
   * @memberof IndexClientLogs
   */
  logLevel?: LogLevel
  /**
   *
   * @type {string}
   * @memberof IndexClientLogs
   */
  from?: string
  /**
   *
   * @type {string}
   * @memberof IndexClientLogs
   */
  till?: string
  /**
   *
   * @type {boolean}
   * @memberof IndexClientLogs
   */
  all?: boolean
  /**
   *
   * @type {string}
   * @memberof IndexClientLogs
   */
  exclusiveStartKey?: string
}
/**
 *
 * @export
 * @interface IndexIcePackResponse
 */
export interface IndexIcePackResponse {
  /**
   *
   * @type {IcePackLast}
   * @memberof IndexIcePackResponse
   */
  icePack: IcePackLast
  /**
   *
   * @type {SiteLast}
   * @memberof IndexIcePackResponse
   */
  site?: SiteLast
  /**
   *
   * @type {TemperatureTagCalibrationLast}
   * @memberof IndexIcePackResponse
   */
  calibrationResult?: TemperatureTagCalibrationLast
  /**
   *
   * @type {ContractOnlyRepresentative}
   * @memberof IndexIcePackResponse
   */
  contractInfo?: ContractOnlyRepresentative
  /**
   *
   * @type {ShippingStateLast}
   * @memberof IndexIcePackResponse
   */
  shippingState?: ShippingStateLast
  /**
   *
   * @type {OnsiteIcePackUnitLast}
   * @memberof IndexIcePackResponse
   */
  onsiteResult?: OnsiteIcePackUnitLast
}
/**
 *
 * @export
 * @interface IndexIcePackResponseComponent
 */
export interface IndexIcePackResponseComponent {
  /**
   *
   * @type {number}
   * @memberof IndexIcePackResponseComponent
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponent
   */
  temperatureSensorTagEpc: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponent
   */
  model?: string
  /**
   *
   * @type {IcePackPart}
   * @memberof IndexIcePackResponseComponent
   */
  part?: IcePackPart
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponent
   */
  stockedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponent
   */
  disposedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponent
   */
  siteName?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponent
   */
  contractRepresentativeNo?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponent
   */
  calibrationProcessingDateTime?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof IndexIcePackResponseComponent
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponent
   */
  shippingStateProcessingDateTime?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponent
   */
  onsiteIcePackResultProcessingDateTime?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponent
   */
  inspectionResult?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponent
   */
  errorDetail?: string
  /**
   *
   * @type {TemperatureTagCalibration}
   * @memberof IndexIcePackResponseComponent
   */
  calibrationResult?: TemperatureTagCalibration
}
/**
 *
 * @export
 * @interface IndexIcePackResponseComponentAllOf
 */
export interface IndexIcePackResponseComponentAllOf {
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponentAllOf
   */
  siteName?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponentAllOf
   */
  contractRepresentativeNo?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponentAllOf
   */
  calibrationProcessingDateTime?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof IndexIcePackResponseComponentAllOf
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponentAllOf
   */
  shippingStateProcessingDateTime?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponentAllOf
   */
  onsiteIcePackResultProcessingDateTime?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponentAllOf
   */
  inspectionResult?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePackResponseComponentAllOf
   */
  errorDetail?: string
  /**
   *
   * @type {TemperatureTagCalibration}
   * @memberof IndexIcePackResponseComponentAllOf
   */
  calibrationResult?: TemperatureTagCalibration
}
/**
 *
 * @export
 * @interface IndexIcePacks
 */
export interface IndexIcePacks {
  /**
   *
   * @type {string}
   * @memberof IndexIcePacks
   */
  temperatureSensorTagEpc?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePacks
   */
  latestSiteName?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePacks
   */
  contractNo?: string
  /**
   *
   * @type {ShippingStateValueLast}
   * @memberof IndexIcePacks
   */
  shippingStatus?: ShippingStateValueLast
  /**
   *
   * @type {string}
   * @memberof IndexIcePacks
   */
  latestShippingStateDateTimeFrom?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePacks
   */
  latestShippingStateDateTimeTill?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePacks
   */
  calibrationDateTimeFrom?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePacks
   */
  calibrationDateTimeTill?: string
  /**
   *
   * @type {IcePackPartLast}
   * @memberof IndexIcePacks
   */
  part?: IcePackPartLast
  /**
   *
   * @type {boolean}
   * @memberof IndexIcePacks
   */
  onlyNg?: boolean
  /**
   *
   * @type {number}
   * @memberof IndexIcePacks
   */
  sensorPressureMinimum?: number
  /**
   *
   * @type {number}
   * @memberof IndexIcePacks
   */
  sensorPressureMaximum?: number
  /**
   *
   * @type {string}
   * @memberof IndexIcePacks
   */
  latestOnsiteDateTimeFrom?: string
  /**
   *
   * @type {string}
   * @memberof IndexIcePacks
   */
  latestOnsiteDateTimeTill?: string
  /**
   *
   * @type {boolean}
   * @memberof IndexIcePacks
   */
  all?: boolean
  /**
   *
   * @type {string}
   * @memberof IndexIcePacks
   */
  exclusiveStartKey?: string
}
/**
 *
 * @export
 * @interface IndexInspectionBoards
 */
export interface IndexInspectionBoards {
  /**
   *
   * @type {string}
   * @memberof IndexInspectionBoards
   */
  query?: string
  /**
   *
   * @type {ShippingStateValueLast}
   * @memberof IndexInspectionBoards
   */
  shippingState?: ShippingStateValueLast
  /**
   *
   * @type {string}
   * @memberof IndexInspectionBoards
   */
  latestShippingStateDateTimeFrom?: string
  /**
   *
   * @type {string}
   * @memberof IndexInspectionBoards
   */
  latestShippingStateDateTimeTill?: string
  /**
   *
   * @type {boolean}
   * @memberof IndexInspectionBoards
   */
  all?: boolean
  /**
   *
   * @type {string}
   * @memberof IndexInspectionBoards
   */
  exclusiveStartKey?: string
}
/**
 *
 * @export
 * @interface IndexInventoriesIcePack
 */
export interface IndexInventoriesIcePack {
  /**
   *
   * @type {string}
   * @memberof IndexInventoriesIcePack
   */
  query?: string
  /**
   *
   * @type {string}
   * @memberof IndexInventoriesIcePack
   */
  status?: IndexInventoriesIcePackStatusEnum
  /**
   *
   * @type {IcePackPartLast}
   * @memberof IndexInventoriesIcePack
   */
  part?: IcePackPartLast
  /**
   *
   * @type {string}
   * @memberof IndexInventoriesIcePack
   */
  from?: string
  /**
   *
   * @type {string}
   * @memberof IndexInventoriesIcePack
   */
  till?: string
  /**
   *
   * @type {boolean}
   * @memberof IndexInventoriesIcePack
   */
  all?: boolean
  /**
   *
   * @type {string}
   * @memberof IndexInventoriesIcePack
   */
  exclusiveStartKey?: string
}

export const IndexInventoriesIcePackStatusEnum = {
  NotYet: 'not-yet',
} as const

export type IndexInventoriesIcePackStatusEnum =
  typeof IndexInventoriesIcePackStatusEnum[keyof typeof IndexInventoriesIcePackStatusEnum]

/**
 *
 * @export
 * @interface IndexInventoriesVixell
 */
export interface IndexInventoriesVixell {
  /**
   *
   * @type {string}
   * @memberof IndexInventoriesVixell
   */
  query?: string
  /**
   *
   * @type {string}
   * @memberof IndexInventoriesVixell
   */
  status?: IndexInventoriesVixellStatusEnum
  /**
   *
   * @type {string}
   * @memberof IndexInventoriesVixell
   */
  from?: string
  /**
   *
   * @type {string}
   * @memberof IndexInventoriesVixell
   */
  till?: string
  /**
   *
   * @type {boolean}
   * @memberof IndexInventoriesVixell
   */
  all?: boolean
  /**
   *
   * @type {string}
   * @memberof IndexInventoriesVixell
   */
  exclusiveStartKey?: string
}

export const IndexInventoriesVixellStatusEnum = {
  NotYet: 'not-yet',
} as const

export type IndexInventoriesVixellStatusEnum =
  typeof IndexInventoriesVixellStatusEnum[keyof typeof IndexInventoriesVixellStatusEnum]

/**
 *
 * @export
 * @interface IndexOnsiteResult
 */
export interface IndexOnsiteResult {
  /**
   *
   * @type {string}
   * @memberof IndexOnsiteResult
   */
  query?: string
  /**
   *
   * @type {boolean}
   * @memberof IndexOnsiteResult
   */
  ngOnly?: boolean
  /**
   *
   * @type {boolean}
   * @memberof IndexOnsiteResult
   */
  latestOnly?: boolean
  /**
   *
   * @type {string}
   * @memberof IndexOnsiteResult
   */
  from?: string
  /**
   *
   * @type {string}
   * @memberof IndexOnsiteResult
   */
  till?: string
  /**
   *
   * @type {boolean}
   * @memberof IndexOnsiteResult
   */
  all?: boolean
  /**
   *
   * @type {string}
   * @memberof IndexOnsiteResult
   */
  exclusiveStartKey?: string
}
/**
 *
 * @export
 * @interface IndexSites
 */
export interface IndexSites {
  /**
   *
   * @type {string}
   * @memberof IndexSites
   */
  query?: string
  /**
   *
   * @type {SiteTypeLowerCaseLast}
   * @memberof IndexSites
   */
  siteType?: SiteTypeLowerCaseLast
  /**
   *
   * @type {boolean}
   * @memberof IndexSites
   */
  all?: boolean
  /**
   *
   * @type {string}
   * @memberof IndexSites
   */
  exclusiveStartKey?: string
}
/**
 *
 * @export
 * @interface IndexTrades
 */
export interface IndexTrades {
  /**
   *
   * @type {string}
   * @memberof IndexTrades
   */
  contractRepresentativeNo?: string
  /**
   *
   * @type {string}
   * @memberof IndexTrades
   */
  tradeType?: string
  /**
   *
   * @type {string}
   * @memberof IndexTrades
   */
  customerName?: string
  /**
   *
   * @type {string}
   * @memberof IndexTrades
   */
  destination?: string
  /**
   *
   * @type {string}
   * @memberof IndexTrades
   */
  returnEstimatedDateFrom?: string
  /**
   *
   * @type {string}
   * @memberof IndexTrades
   */
  returnEstimatedDateTill?: string
  /**
   *
   * @type {string}
   * @memberof IndexTrades
   */
  returnCompletedDateFrom?: string
  /**
   *
   * @type {string}
   * @memberof IndexTrades
   */
  returnCompletedDateTill?: string
  /**
   *
   * @type {boolean}
   * @memberof IndexTrades
   */
  all?: boolean
  /**
   *
   * @type {string}
   * @memberof IndexTrades
   */
  exclusiveStartKey?: string
}
/**
 *
 * @export
 * @interface IndexVixellRequest
 */
export interface IndexVixellRequest {
  /**
   *
   * @type {string}
   * @memberof IndexVixellRequest
   */
  vixellSerialNo?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellRequest
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellRequest
   */
  latestSiteName?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellRequest
   */
  contractNo?: string
  /**
   *
   * @type {ShippingStateValueLast}
   * @memberof IndexVixellRequest
   */
  shippingStatus?: ShippingStateValueLast
  /**
   *
   * @type {string}
   * @memberof IndexVixellRequest
   */
  latestShippingStateDateTimeFrom?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellRequest
   */
  latestShippingStateDateTimeTill?: string
  /**
   *
   * @type {boolean}
   * @memberof IndexVixellRequest
   */
  onlyNg?: boolean
  /**
   *
   * @type {number}
   * @memberof IndexVixellRequest
   */
  sensorPressureMinimum?: number
  /**
   *
   * @type {number}
   * @memberof IndexVixellRequest
   */
  sensorPressureMaximum?: number
  /**
   *
   * @type {string}
   * @memberof IndexVixellRequest
   */
  latestOnsiteDateTimeFrom?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellRequest
   */
  latestOnsiteDateTimeTill?: string
  /**
   *
   * @type {boolean}
   * @memberof IndexVixellRequest
   */
  all?: boolean
  /**
   *
   * @type {string}
   * @memberof IndexVixellRequest
   */
  exclusiveStartKey?: string
}
/**
 *
 * @export
 * @interface IndexVixellResponse
 */
export interface IndexVixellResponse {
  /**
   *
   * @type {VixellLast}
   * @memberof IndexVixellResponse
   */
  vixell: VixellLast
  /**
   *
   * @type {SiteLast}
   * @memberof IndexVixellResponse
   */
  site?: SiteLast
  /**
   *
   * @type {ContractWithManagementNo}
   * @memberof IndexVixellResponse
   */
  contractInfo?: ContractWithManagementNo
  /**
   *
   * @type {ShippingStateLast}
   * @memberof IndexVixellResponse
   */
  shippingState?: ShippingStateLast
  /**
   *
   * @type {OnsiteVacuumLast}
   * @memberof IndexVixellResponse
   */
  onsiteResult?: OnsiteVacuumLast
  /**
   *
   * @type {VacuumProcessLast}
   * @memberof IndexVixellResponse
   */
  vacuumProcess?: VacuumProcessLast
}
/**
 *
 * @export
 * @interface IndexVixellsResponseDataComponent
 */
export interface IndexVixellsResponseDataComponent {
  /**
   *
   * @type {number}
   * @memberof IndexVixellsResponseDataComponent
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponent
   */
  serialNo: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponent
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponent
   */
  IDm?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponent
   */
  contractNo?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponent
   */
  managementNo?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponent
   */
  siteIdentifier?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponent
   */
  siteName?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponent
   */
  fullContractNo?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponent
   */
  tradeType?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponent
   */
  stockedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponent
   */
  disposedDateTime?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof IndexVixellsResponseDataComponent
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponent
   */
  shippingStateProcessingDateTime?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponent
   */
  inspectionResult?: string
  /**
   *
   * @type {number}
   * @memberof IndexVixellsResponseDataComponent
   */
  sensorPressure?: number
  /**
   *
   * @type {number}
   * @memberof IndexVixellsResponseDataComponent
   */
  sensorVoltage?: number
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponent
   */
  belongingLogProcessingDateTime?: string
  /**
   *
   * @type {VacuumProcess}
   * @memberof IndexVixellsResponseDataComponent
   */
  vacuumProcess?: VacuumProcess
}
/**
 *
 * @export
 * @interface IndexVixellsResponseDataComponentAllOf
 */
export interface IndexVixellsResponseDataComponentAllOf {
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponentAllOf
   */
  siteIdentifier?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponentAllOf
   */
  siteName?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponentAllOf
   */
  fullContractNo?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponentAllOf
   */
  managementNo?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponentAllOf
   */
  tradeType?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponentAllOf
   */
  stockedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponentAllOf
   */
  disposedDateTime?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof IndexVixellsResponseDataComponentAllOf
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponentAllOf
   */
  shippingStateProcessingDateTime?: string
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponentAllOf
   */
  inspectionResult?: string
  /**
   *
   * @type {number}
   * @memberof IndexVixellsResponseDataComponentAllOf
   */
  sensorPressure?: number
  /**
   *
   * @type {number}
   * @memberof IndexVixellsResponseDataComponentAllOf
   */
  sensorVoltage?: number
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataComponentAllOf
   */
  belongingLogProcessingDateTime?: string
  /**
   *
   * @type {VacuumProcess}
   * @memberof IndexVixellsResponseDataComponentAllOf
   */
  vacuumProcess?: VacuumProcess
}
/**
 *
 * @export
 * @interface IndexVixellsResponseDataRootComponent
 */
export interface IndexVixellsResponseDataRootComponent {
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseDataRootComponent
   */
  type: IndexVixellsResponseDataRootComponentTypeEnum
  /**
   *
   * @type {Array<IndexVixellsResponseDataComponent>}
   * @memberof IndexVixellsResponseDataRootComponent
   */
  vixells: Array<IndexVixellsResponseDataComponent>
  /**
   *
   * @type {number}
   * @memberof IndexVixellsResponseDataRootComponent
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof IndexVixellsResponseDataRootComponent
   */
  stopPageLoad: boolean
}

export const IndexVixellsResponseDataRootComponentTypeEnum = {
  Data: 'data',
} as const

export type IndexVixellsResponseDataRootComponentTypeEnum =
  typeof IndexVixellsResponseDataRootComponentTypeEnum[keyof typeof IndexVixellsResponseDataRootComponentTypeEnum]

/**
 *
 * @export
 * @interface IndexVixellsResponseUrlComponent
 */
export interface IndexVixellsResponseUrlComponent {
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseUrlComponent
   */
  type: IndexVixellsResponseUrlComponentTypeEnum
  /**
   *
   * @type {string}
   * @memberof IndexVixellsResponseUrlComponent
   */
  url: string
}

export const IndexVixellsResponseUrlComponentTypeEnum = {
  Url: 'url',
} as const

export type IndexVixellsResponseUrlComponentTypeEnum =
  typeof IndexVixellsResponseUrlComponentTypeEnum[keyof typeof IndexVixellsResponseUrlComponentTypeEnum]

/**
 *
 * @export
 * @interface InlineObject
 */
export interface InlineObject {
  /**
   *
   * @type {string}
   * @memberof InlineObject
   */
  inspectionBoardSerialNo: string
  /**
   *
   * @type {string}
   * @memberof InlineObject
   */
  exclusiveStartKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse200
   */
  message: InlineResponse200MessageEnum
}

export const InlineResponse200MessageEnum = {
  Success: 'success',
} as const

export type InlineResponse200MessageEnum =
  typeof InlineResponse200MessageEnum[keyof typeof InlineResponse200MessageEnum]

/**
 *
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse2001
   */
  message: string
}
/**
 *
 * @export
 * @interface InlineResponse20010
 */
export interface InlineResponse20010 {
  /**
   *
   * @type {Array<CustomerLast>}
   * @memberof InlineResponse20010
   */
  customers?: Array<CustomerLast>
}
/**
 *
 * @export
 * @interface InlineResponse20011
 */
export interface InlineResponse20011 {
  /**
   *
   * @type {Array<SiteLast>}
   * @memberof InlineResponse20011
   */
  sites: Array<SiteLast>
  /**
   *
   * @type {string}
   * @memberof InlineResponse20011
   */
  lastEvaluatedKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse20012
 */
export interface InlineResponse20012 {
  /**
   *
   * @type {Array<IndexVixellResponse>}
   * @memberof InlineResponse20012
   */
  vixells: Array<IndexVixellResponse>
  /**
   *
   * @type {string}
   * @memberof InlineResponse20012
   */
  lastEvaluatedKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse20013
 */
export interface InlineResponse20013 {
  /**
   *
   * @type {Array<ShippingStateLast>}
   * @memberof InlineResponse20013
   */
  shippingStates: Array<ShippingStateLast>
  /**
   *
   * @type {string}
   * @memberof InlineResponse20013
   */
  lastEvaluatedKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse20014
 */
export interface InlineResponse20014 {
  /**
   *
   * @type {Array<OnsiteVacuumLast>}
   * @memberof InlineResponse20014
   */
  results: Array<OnsiteVacuumLast>
  /**
   *
   * @type {string}
   * @memberof InlineResponse20014
   */
  lastEvaluatedKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse20015
 */
export interface InlineResponse20015 {
  /**
   *
   * @type {Array<Transition & object>}
   * @memberof InlineResponse20015
   */
  transitions: Array<Transition & object>
  /**
   *
   * @type {string}
   * @memberof InlineResponse20015
   */
  lastEvaluatedKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse20016
 */
export interface InlineResponse20016 {
  /**
   *
   * @type {Array<IndexIcePackResponse>}
   * @memberof InlineResponse20016
   */
  icePacks: Array<IndexIcePackResponse>
  /**
   *
   * @type {string}
   * @memberof InlineResponse20016
   */
  lastEvaluatedKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse20017
 */
export interface InlineResponse20017 {
  /**
   *
   * @type {Array<OnsiteIcePackUnitLast>}
   * @memberof InlineResponse20017
   */
  results: Array<OnsiteIcePackUnitLast>
  /**
   *
   * @type {string}
   * @memberof InlineResponse20017
   */
  lastEvaluatedKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse20018
 */
export interface InlineResponse20018 {
  /**
   *
   * @type {Array<Transition & object>}
   * @memberof InlineResponse20018
   */
  transitions: Array<Transition & object>
  /**
   *
   * @type {string}
   * @memberof InlineResponse20018
   */
  lastEvaluatedKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse20019
 */
export interface InlineResponse20019 {
  /**
   *
   * @type {Array<InspectionBoardLast>}
   * @memberof InlineResponse20019
   */
  inspectionBoards: Array<InspectionBoardLast>
  /**
   *
   * @type {string}
   * @memberof InlineResponse20019
   */
  lastEvaluatedKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse2002
 */
export interface InlineResponse2002 {
  /**
   *
   * @type {SiteLast}
   * @memberof InlineResponse2002
   */
  site: SiteLast
}
/**
 *
 * @export
 * @interface InlineResponse20020
 */
export interface InlineResponse20020 {
  /**
   *
   * @type {InspectionBoardLast}
   * @memberof InlineResponse20020
   */
  inspectionBoard?: InspectionBoardLast
}
/**
 *
 * @export
 * @interface InlineResponse20021
 */
export interface InlineResponse20021 {
  /**
   *
   * @type {Array<AdminOnsiteResultLast>}
   * @memberof InlineResponse20021
   */
  results: Array<AdminOnsiteResultLast>
  /**
   *
   * @type {string}
   * @memberof InlineResponse20021
   */
  lastEvaluatedKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse20022
 */
export interface InlineResponse20022 {
  /**
   *
   * @type {Array<ClientLog>}
   * @memberof InlineResponse20022
   */
  logs: Array<ClientLog>
  /**
   *
   * @type {string}
   * @memberof InlineResponse20022
   */
  lastEvaluatedKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse20023
 */
export interface InlineResponse20023 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse20023
   */
  lastEvaluatedKey?: string
  /**
   *
   * @type {Array<TradeLast & object>}
   * @memberof InlineResponse20023
   */
  trades: Array<TradeLast & object>
}
/**
 *
 * @export
 * @interface InlineResponse20024
 */
export interface InlineResponse20024 {
  /**
   *
   * @type {TradeLast & object}
   * @memberof InlineResponse20024
   */
  trade?: TradeLast & object
}
/**
 *
 * @export
 * @interface InlineResponse2003
 */
export interface InlineResponse2003 {
  /**
   *
   * @type {Array<DashboardForCustomerLast>}
   * @memberof InlineResponse2003
   */
  dashboards: Array<DashboardForCustomerLast>
}
/**
 *
 * @export
 * @interface InlineResponse2004
 */
export interface InlineResponse2004 {
  /**
   *
   * @type {Array<VixellInventoryLast>}
   * @memberof InlineResponse2004
   */
  vixells: Array<VixellInventoryLast>
  /**
   *
   * @type {string}
   * @memberof InlineResponse2004
   */
  lastEvaluatedKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse2005
 */
export interface InlineResponse2005 {
  /**
   *
   * @type {Array<IcePackInventory>}
   * @memberof InlineResponse2005
   */
  icePacks: Array<IcePackInventory>
  /**
   *
   * @type {string}
   * @memberof InlineResponse2005
   */
  lastEvaluatedKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse2006
 */
export interface InlineResponse2006 {
  /**
   *
   * @type {Array<OnsiteResult>}
   * @memberof InlineResponse2006
   */
  results: Array<OnsiteResult>
  /**
   *
   * @type {string}
   * @memberof InlineResponse2006
   */
  lastEvaluatedKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse2007
 */
export interface InlineResponse2007 {
  /**
   *
   * @type {OnsiteVacuumLast}
   * @memberof InlineResponse2007
   */
  onsiteVacuum?: OnsiteVacuumLast
  /**
   *
   * @type {Array<OnsiteIcePackUnitLast>}
   * @memberof InlineResponse2007
   */
  onsiteIcePackResultUnits?: Array<OnsiteIcePackUnitLast>
}
/**
 *
 * @export
 * @interface InlineResponse2008
 */
export interface InlineResponse2008 {
  /**
   *
   * @type {Array<SiteLast>}
   * @memberof InlineResponse2008
   */
  sites: Array<SiteLast>
}
/**
 *
 * @export
 * @interface InlineResponse2009
 */
export interface InlineResponse2009 {
  /**
   *
   * @type {Array<CustomerLast>}
   * @memberof InlineResponse2009
   */
  customers: Array<CustomerLast>
  /**
   *
   * @type {string}
   * @memberof InlineResponse2009
   */
  lastEvaluatedKey?: string
}
/**
 *
 * @export
 * @interface InlineResponse422
 */
export interface InlineResponse422 {
  /**
   *
   * @type {string}
   * @memberof InlineResponse422
   */
  type?: InlineResponse422TypeEnum
  /**
   *
   * @type {string}
   * @memberof InlineResponse422
   */
  code?: InlineResponse422CodeEnum
}

export const InlineResponse422TypeEnum = {
  InvalidRequestError: 'invalid-request-error',
} as const

export type InlineResponse422TypeEnum =
  typeof InlineResponse422TypeEnum[keyof typeof InlineResponse422TypeEnum]
export const InlineResponse422CodeEnum = {
  LogTypeRequired: 'log-type-required',
  DataRequired: 'data-required',
  InvalidLogType: 'invalid-log-type',
  InvalidData: 'invalid-data',
} as const

export type InlineResponse422CodeEnum =
  typeof InlineResponse422CodeEnum[keyof typeof InlineResponse422CodeEnum]

/**
 *
 * @export
 * @interface InspectionBoard
 */
export interface InspectionBoard {
  /**
   *
   * @type {number}
   * @memberof InspectionBoard
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof InspectionBoard
   */
  serialNo: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoard
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoard
   */
  stockedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoard
   */
  disposedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoard
   */
  contractNo?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoard
   */
  managementNo?: string
}
/**
 *
 * @export
 * @interface InspectionBoardDispose
 */
export interface InspectionBoardDispose {
  /**
   *
   * @type {string}
   * @memberof InspectionBoardDispose
   */
  app: InspectionBoardDisposeAppEnum
  /**
   *
   * @type {string}
   * @memberof InspectionBoardDispose
   */
  logType: InspectionBoardDisposeLogTypeEnum
  /**
   *
   * @type {CommonData & CommonInspectionBoard}
   * @memberof InspectionBoardDispose
   */
  data: CommonData & CommonInspectionBoard
}

export const InspectionBoardDisposeAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type InspectionBoardDisposeAppEnum =
  typeof InspectionBoardDisposeAppEnum[keyof typeof InspectionBoardDisposeAppEnum]
export const InspectionBoardDisposeLogTypeEnum = {
  InspectionBoardDispose: 'inspectionBoardDispose',
} as const

export type InspectionBoardDisposeLogTypeEnum =
  typeof InspectionBoardDisposeLogTypeEnum[keyof typeof InspectionBoardDisposeLogTypeEnum]

/**
 *
 * @export
 * @interface InspectionBoardLast
 */
export interface InspectionBoardLast {
  /**
   *
   * @type {string}
   * @memberof InspectionBoardLast
   */
  inspectionBoardSerialNo: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoardLast
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoardLast
   */
  stockedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoardLast
   */
  disposedDateTime?: string
  /**
   *
   * @type {ShippingStateValueLast}
   * @memberof InspectionBoardLast
   */
  shippingState?: ShippingStateValueLast
  /**
   *
   * @type {string}
   * @memberof InspectionBoardLast
   */
  shippingStateUpdatedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoardLast
   */
  contractRepresentativeNo?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoardLast
   */
  contractNo?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoardLast
   */
  managementNo?: string
}
/**
 *
 * @export
 * @interface InspectionBoardProductData
 */
export interface InspectionBoardProductData {
  /**
   *
   * @type {string}
   * @memberof InspectionBoardProductData
   */
  type: InspectionBoardProductDataTypeEnum
  /**
   *
   * @type {InspectionBoard}
   * @memberof InspectionBoardProductData
   */
  product: InspectionBoard
}

export const InspectionBoardProductDataTypeEnum = {
  InspectionBoard: 'INSPECTION_BOARD',
} as const

export type InspectionBoardProductDataTypeEnum =
  typeof InspectionBoardProductDataTypeEnum[keyof typeof InspectionBoardProductDataTypeEnum]

/**
 *
 * @export
 * @interface InspectionBoardReturn
 */
export interface InspectionBoardReturn {
  /**
   *
   * @type {string}
   * @memberof InspectionBoardReturn
   */
  app: InspectionBoardReturnAppEnum
  /**
   *
   * @type {string}
   * @memberof InspectionBoardReturn
   */
  logType: InspectionBoardReturnLogTypeEnum
  /**
   *
   * @type {CommonData & CommonInspectionBoard}
   * @memberof InspectionBoardReturn
   */
  data: CommonData & CommonInspectionBoard
}

export const InspectionBoardReturnAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type InspectionBoardReturnAppEnum =
  typeof InspectionBoardReturnAppEnum[keyof typeof InspectionBoardReturnAppEnum]
export const InspectionBoardReturnLogTypeEnum = {
  InspectionBoardReturn: 'inspectionBoardReturn',
} as const

export type InspectionBoardReturnLogTypeEnum =
  typeof InspectionBoardReturnLogTypeEnum[keyof typeof InspectionBoardReturnLogTypeEnum]

/**
 *
 * @export
 * @interface InspectionBoardShipping
 */
export interface InspectionBoardShipping {
  /**
   *
   * @type {string}
   * @memberof InspectionBoardShipping
   */
  app: InspectionBoardShippingAppEnum
  /**
   *
   * @type {string}
   * @memberof InspectionBoardShipping
   */
  logType: InspectionBoardShippingLogTypeEnum
  /**
   *
   * @type {CommonData & ShippingManagementCommonInfo & VixellInspectionBoardContractInfo & CommonInspectionBoard}
   * @memberof InspectionBoardShipping
   */
  data: CommonData &
    ShippingManagementCommonInfo &
    VixellInspectionBoardContractInfo &
    CommonInspectionBoard
}

export const InspectionBoardShippingAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type InspectionBoardShippingAppEnum =
  typeof InspectionBoardShippingAppEnum[keyof typeof InspectionBoardShippingAppEnum]
export const InspectionBoardShippingLogTypeEnum = {
  InspectionBoardShipping: 'inspectionBoardShipping',
} as const

export type InspectionBoardShippingLogTypeEnum =
  typeof InspectionBoardShippingLogTypeEnum[keyof typeof InspectionBoardShippingLogTypeEnum]

/**
 *
 * @export
 * @interface InspectionBoardsIndexRequest
 */
export interface InspectionBoardsIndexRequest {
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsIndexRequest
   */
  type: InspectionBoardsIndexRequestTypeEnum
  /**
   *
   * @type {InspectionBoardsIndexRequestData}
   * @memberof InspectionBoardsIndexRequest
   */
  data: InspectionBoardsIndexRequestData
}

export const InspectionBoardsIndexRequestTypeEnum = {
  InspectionBoardsIndex: 'InspectionBoardsIndex',
} as const

export type InspectionBoardsIndexRequestTypeEnum =
  typeof InspectionBoardsIndexRequestTypeEnum[keyof typeof InspectionBoardsIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface InspectionBoardsIndexRequestData
 */
export interface InspectionBoardsIndexRequestData {
  /**
   * シリアルNo, モデル, {契約代表No}-{契約No} の3つを部分一致で検索できる
   * @type {string}
   * @memberof InspectionBoardsIndexRequestData
   */
  query?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof InspectionBoardsIndexRequestData
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsIndexRequestData
   */
  latestShippingStateDateTimeFrom?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsIndexRequestData
   */
  latestShippingStateDateTimeTill?: string
  /**
   *
   * @type {boolean}
   * @memberof InspectionBoardsIndexRequestData
   */
  all?: boolean
  /**
   *
   * @type {number}
   * @memberof InspectionBoardsIndexRequestData
   */
  page?: number
  /**
   *
   * @type {number}
   * @memberof InspectionBoardsIndexRequestData
   */
  per?: number
}
/**
 *
 * @export
 * @interface InspectionBoardsIndexResponse
 */
export interface InspectionBoardsIndexResponse {
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsIndexResponse
   */
  type: InspectionBoardsIndexResponseTypeEnum
  /**
   *
   * @type {InspectionBoardsIndexResponseData}
   * @memberof InspectionBoardsIndexResponse
   */
  data: InspectionBoardsIndexResponseData
}

export const InspectionBoardsIndexResponseTypeEnum = {
  InspectionBoardsIndex: 'InspectionBoardsIndex',
} as const

export type InspectionBoardsIndexResponseTypeEnum =
  typeof InspectionBoardsIndexResponseTypeEnum[keyof typeof InspectionBoardsIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface InspectionBoardsIndexResponseComponent
 */
export interface InspectionBoardsIndexResponseComponent {
  /**
   *
   * @type {number}
   * @memberof InspectionBoardsIndexResponseComponent
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsIndexResponseComponent
   */
  serialNo: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsIndexResponseComponent
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsIndexResponseComponent
   */
  stockedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsIndexResponseComponent
   */
  disposedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsIndexResponseComponent
   */
  contractNo?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsIndexResponseComponent
   */
  managementNo?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof InspectionBoardsIndexResponseComponent
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsIndexResponseComponent
   */
  shippingStateUpdatedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsIndexResponseComponent
   */
  fullContractNo?: string
}
/**
 *
 * @export
 * @interface InspectionBoardsIndexResponseComponentAllOf
 */
export interface InspectionBoardsIndexResponseComponentAllOf {
  /**
   *
   * @type {ShippingStateValue}
   * @memberof InspectionBoardsIndexResponseComponentAllOf
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsIndexResponseComponentAllOf
   */
  shippingStateUpdatedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsIndexResponseComponentAllOf
   */
  fullContractNo?: string
}
/**
 *
 * @export
 * @interface InspectionBoardsIndexResponseData
 */
export interface InspectionBoardsIndexResponseData {
  /**
   *
   * @type {Array<InspectionBoardsIndexResponseComponent>}
   * @memberof InspectionBoardsIndexResponseData
   */
  inspectionBoards: Array<InspectionBoardsIndexResponseComponent>
  /**
   *
   * @type {number}
   * @memberof InspectionBoardsIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof InspectionBoardsIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @interface InspectionBoardsShowRequest
 */
export interface InspectionBoardsShowRequest {
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsShowRequest
   */
  type: InspectionBoardsShowRequestTypeEnum
  /**
   *
   * @type {ReusableOrderShowRequestData}
   * @memberof InspectionBoardsShowRequest
   */
  data: ReusableOrderShowRequestData
}

export const InspectionBoardsShowRequestTypeEnum = {
  InspectionBoardsShow: 'InspectionBoardsShow',
} as const

export type InspectionBoardsShowRequestTypeEnum =
  typeof InspectionBoardsShowRequestTypeEnum[keyof typeof InspectionBoardsShowRequestTypeEnum]

/**
 *
 * @export
 * @interface InspectionBoardsShowResponse
 */
export interface InspectionBoardsShowResponse {
  /**
   *
   * @type {string}
   * @memberof InspectionBoardsShowResponse
   */
  type: InspectionBoardsShowResponseTypeEnum
  /**
   *
   * @type {InspectionBoardsShowResponseData}
   * @memberof InspectionBoardsShowResponse
   */
  data: InspectionBoardsShowResponseData
}

export const InspectionBoardsShowResponseTypeEnum = {
  InspectionBoardsShow: 'InspectionBoardsShow',
} as const

export type InspectionBoardsShowResponseTypeEnum =
  typeof InspectionBoardsShowResponseTypeEnum[keyof typeof InspectionBoardsShowResponseTypeEnum]

/**
 *
 * @export
 * @interface InspectionBoardsShowResponseData
 */
export interface InspectionBoardsShowResponseData {
  /**
   *
   * @type {InspectionBoard}
   * @memberof InspectionBoardsShowResponseData
   */
  inspectionBoard: InspectionBoard
}
/**
 *
 * @export
 * @interface InventoriesTemperatureLoggersIndexRequest
 */
export interface InventoriesTemperatureLoggersIndexRequest {
  /**
   *
   * @type {string}
   * @memberof InventoriesTemperatureLoggersIndexRequest
   */
  type: InventoriesTemperatureLoggersIndexRequestTypeEnum
  /**
   *
   * @type {InventoriesVixellsIndexRequestData}
   * @memberof InventoriesTemperatureLoggersIndexRequest
   */
  data: InventoriesVixellsIndexRequestData
}

export const InventoriesTemperatureLoggersIndexRequestTypeEnum = {
  InventoriesTemperatureLoggersIndex: 'InventoriesTemperatureLoggersIndex',
} as const

export type InventoriesTemperatureLoggersIndexRequestTypeEnum =
  typeof InventoriesTemperatureLoggersIndexRequestTypeEnum[keyof typeof InventoriesTemperatureLoggersIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface InventoriesTemperatureLoggersIndexResponse
 */
export interface InventoriesTemperatureLoggersIndexResponse {
  /**
   *
   * @type {string}
   * @memberof InventoriesTemperatureLoggersIndexResponse
   */
  type: InventoriesTemperatureLoggersIndexResponseTypeEnum
  /**
   *
   * @type {InventoriesTemperatureLoggersIndexResponseData}
   * @memberof InventoriesTemperatureLoggersIndexResponse
   */
  data: InventoriesTemperatureLoggersIndexResponseData
}

export const InventoriesTemperatureLoggersIndexResponseTypeEnum = {
  InventoriesTemperatureLoggersIndex: 'InventoriesTemperatureLoggersIndex',
} as const

export type InventoriesTemperatureLoggersIndexResponseTypeEnum =
  typeof InventoriesTemperatureLoggersIndexResponseTypeEnum[keyof typeof InventoriesTemperatureLoggersIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface InventoriesTemperatureLoggersIndexResponseData
 */
export interface InventoriesTemperatureLoggersIndexResponseData {
  /**
   *
   * @type {Array<TemperatureLoggerInventory>}
   * @memberof InventoriesTemperatureLoggersIndexResponseData
   */
  temperatureLoggers: Array<TemperatureLoggerInventory>
  /**
   *
   * @type {number}
   * @memberof InventoriesTemperatureLoggersIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof InventoriesTemperatureLoggersIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @interface InventoriesTemperatureSensorTagsIndexRequest
 */
export interface InventoriesTemperatureSensorTagsIndexRequest {
  /**
   *
   * @type {string}
   * @memberof InventoriesTemperatureSensorTagsIndexRequest
   */
  type: InventoriesTemperatureSensorTagsIndexRequestTypeEnum
  /**
   *
   * @type {InventoriesTemperatureSensorTagsIndexRequestData}
   * @memberof InventoriesTemperatureSensorTagsIndexRequest
   */
  data: InventoriesTemperatureSensorTagsIndexRequestData
}

export const InventoriesTemperatureSensorTagsIndexRequestTypeEnum = {
  InventoriesTemperatureSensorTagsIndex:
    'InventoriesTemperatureSensorTagsIndex',
} as const

export type InventoriesTemperatureSensorTagsIndexRequestTypeEnum =
  typeof InventoriesTemperatureSensorTagsIndexRequestTypeEnum[keyof typeof InventoriesTemperatureSensorTagsIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface InventoriesTemperatureSensorTagsIndexRequestData
 */
export interface InventoriesTemperatureSensorTagsIndexRequestData {
  /**
   *
   * @type {string}
   * @memberof InventoriesTemperatureSensorTagsIndexRequestData
   */
  query?: string
  /**
   *
   * @type {string}
   * @memberof InventoriesTemperatureSensorTagsIndexRequestData
   */
  status?: InventoriesTemperatureSensorTagsIndexRequestDataStatusEnum
  /**
   *
   * @type {IcePackPart}
   * @memberof InventoriesTemperatureSensorTagsIndexRequestData
   */
  part?: IcePackPart
  /**
   *
   * @type {string}
   * @memberof InventoriesTemperatureSensorTagsIndexRequestData
   */
  from?: string
  /**
   *
   * @type {string}
   * @memberof InventoriesTemperatureSensorTagsIndexRequestData
   */
  till?: string
  /**
   *
   * @type {boolean}
   * @memberof InventoriesTemperatureSensorTagsIndexRequestData
   */
  all?: boolean
  /**
   *
   * @type {number}
   * @memberof InventoriesTemperatureSensorTagsIndexRequestData
   */
  page?: number
  /**
   *
   * @type {number}
   * @memberof InventoriesTemperatureSensorTagsIndexRequestData
   */
  per?: number
}

export const InventoriesTemperatureSensorTagsIndexRequestDataStatusEnum = {
  NotYet: 'not-yet',
} as const

export type InventoriesTemperatureSensorTagsIndexRequestDataStatusEnum =
  typeof InventoriesTemperatureSensorTagsIndexRequestDataStatusEnum[keyof typeof InventoriesTemperatureSensorTagsIndexRequestDataStatusEnum]

/**
 *
 * @export
 * @interface InventoriesTemperatureSensorTagsIndexResponse
 */
export interface InventoriesTemperatureSensorTagsIndexResponse {
  /**
   *
   * @type {string}
   * @memberof InventoriesTemperatureSensorTagsIndexResponse
   */
  type: InventoriesTemperatureSensorTagsIndexResponseTypeEnum
  /**
   *
   * @type {InventoriesTemperatureSensorTagsIndexResponseData}
   * @memberof InventoriesTemperatureSensorTagsIndexResponse
   */
  data: InventoriesTemperatureSensorTagsIndexResponseData
}

export const InventoriesTemperatureSensorTagsIndexResponseTypeEnum = {
  InventoriesTemperatureSensorTagsIndex:
    'InventoriesTemperatureSensorTagsIndex',
} as const

export type InventoriesTemperatureSensorTagsIndexResponseTypeEnum =
  typeof InventoriesTemperatureSensorTagsIndexResponseTypeEnum[keyof typeof InventoriesTemperatureSensorTagsIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface InventoriesTemperatureSensorTagsIndexResponseData
 */
export interface InventoriesTemperatureSensorTagsIndexResponseData {
  /**
   *
   * @type {Array<InventoriesTemperatureSensorTagsIndexResponseDataTemperatureSensorTags>}
   * @memberof InventoriesTemperatureSensorTagsIndexResponseData
   */
  temperatureSensorTags: Array<InventoriesTemperatureSensorTagsIndexResponseDataTemperatureSensorTags>
  /**
   *
   * @type {number}
   * @memberof InventoriesTemperatureSensorTagsIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof InventoriesTemperatureSensorTagsIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @interface InventoriesTemperatureSensorTagsIndexResponseDataTemperatureSensorTags
 */
export interface InventoriesTemperatureSensorTagsIndexResponseDataTemperatureSensorTags {
  /**
   *
   * @type {string}
   * @memberof InventoriesTemperatureSensorTagsIndexResponseDataTemperatureSensorTags
   */
  temperatureSensorTagEpc: string
  /**
   *
   * @type {boolean}
   * @memberof InventoriesTemperatureSensorTagsIndexResponseDataTemperatureSensorTags
   */
  settled: boolean
  /**
   *
   * @type {string}
   * @memberof InventoriesTemperatureSensorTagsIndexResponseDataTemperatureSensorTags
   */
  contractRepresentativeNo?: string
  /**
   *
   * @type {string}
   * @memberof InventoriesTemperatureSensorTagsIndexResponseDataTemperatureSensorTags
   */
  processingDateTime?: string
  /**
   *
   * @type {string}
   * @memberof InventoriesTemperatureSensorTagsIndexResponseDataTemperatureSensorTags
   */
  siteName?: string
  /**
   *
   * @type {IcePackPart}
   * @memberof InventoriesTemperatureSensorTagsIndexResponseDataTemperatureSensorTags
   */
  part: IcePackPart
}
/**
 *
 * @export
 * @interface InventoriesThermalStorageUnitsIndexRequest
 */
export interface InventoriesThermalStorageUnitsIndexRequest {
  /**
   *
   * @type {string}
   * @memberof InventoriesThermalStorageUnitsIndexRequest
   */
  type: InventoriesThermalStorageUnitsIndexRequestTypeEnum
  /**
   *
   * @type {InventoriesVixellsIndexRequestData}
   * @memberof InventoriesThermalStorageUnitsIndexRequest
   */
  data: InventoriesVixellsIndexRequestData
}

export const InventoriesThermalStorageUnitsIndexRequestTypeEnum = {
  InventoriesThermalStorageUnitsIndex: 'InventoriesThermalStorageUnitsIndex',
} as const

export type InventoriesThermalStorageUnitsIndexRequestTypeEnum =
  typeof InventoriesThermalStorageUnitsIndexRequestTypeEnum[keyof typeof InventoriesThermalStorageUnitsIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface InventoriesThermalStorageUnitsIndexResponse
 */
export interface InventoriesThermalStorageUnitsIndexResponse {
  /**
   *
   * @type {string}
   * @memberof InventoriesThermalStorageUnitsIndexResponse
   */
  type: InventoriesThermalStorageUnitsIndexResponseTypeEnum
  /**
   *
   * @type {InventoriesThermalStorageUnitsIndexResponseData}
   * @memberof InventoriesThermalStorageUnitsIndexResponse
   */
  data: InventoriesThermalStorageUnitsIndexResponseData
}

export const InventoriesThermalStorageUnitsIndexResponseTypeEnum = {
  InventoriesThermalStorageUnitsIndex: 'InventoriesThermalStorageUnitsIndex',
} as const

export type InventoriesThermalStorageUnitsIndexResponseTypeEnum =
  typeof InventoriesThermalStorageUnitsIndexResponseTypeEnum[keyof typeof InventoriesThermalStorageUnitsIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface InventoriesThermalStorageUnitsIndexResponseData
 */
export interface InventoriesThermalStorageUnitsIndexResponseData {
  /**
   *
   * @type {Array<ThermalStorageUnitInventory>}
   * @memberof InventoriesThermalStorageUnitsIndexResponseData
   */
  thermalStorageUnits: Array<ThermalStorageUnitInventory>
  /**
   *
   * @type {number}
   * @memberof InventoriesThermalStorageUnitsIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof InventoriesThermalStorageUnitsIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @interface InventoriesVixellsIndexRequest
 */
export interface InventoriesVixellsIndexRequest {
  /**
   *
   * @type {string}
   * @memberof InventoriesVixellsIndexRequest
   */
  type: InventoriesVixellsIndexRequestTypeEnum
  /**
   *
   * @type {InventoriesVixellsIndexRequestData}
   * @memberof InventoriesVixellsIndexRequest
   */
  data: InventoriesVixellsIndexRequestData
}

export const InventoriesVixellsIndexRequestTypeEnum = {
  InventoriesVixellsIndex: 'InventoriesVixellsIndex',
} as const

export type InventoriesVixellsIndexRequestTypeEnum =
  typeof InventoriesVixellsIndexRequestTypeEnum[keyof typeof InventoriesVixellsIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface InventoriesVixellsIndexRequestData
 */
export interface InventoriesVixellsIndexRequestData {
  /**
   *
   * @type {string}
   * @memberof InventoriesVixellsIndexRequestData
   */
  query?: string
  /**
   *
   * @type {string}
   * @memberof InventoriesVixellsIndexRequestData
   */
  status?: InventoriesVixellsIndexRequestDataStatusEnum
  /**
   *
   * @type {string}
   * @memberof InventoriesVixellsIndexRequestData
   */
  from?: string
  /**
   *
   * @type {string}
   * @memberof InventoriesVixellsIndexRequestData
   */
  till?: string
  /**
   *
   * @type {boolean}
   * @memberof InventoriesVixellsIndexRequestData
   */
  all?: boolean
  /**
   *
   * @type {number}
   * @memberof InventoriesVixellsIndexRequestData
   */
  page?: number
  /**
   *
   * @type {number}
   * @memberof InventoriesVixellsIndexRequestData
   */
  per?: number
}

export const InventoriesVixellsIndexRequestDataStatusEnum = {
  NotYet: 'not-yet',
} as const

export type InventoriesVixellsIndexRequestDataStatusEnum =
  typeof InventoriesVixellsIndexRequestDataStatusEnum[keyof typeof InventoriesVixellsIndexRequestDataStatusEnum]

/**
 *
 * @export
 * @interface InventoriesVixellsIndexResponse
 */
export interface InventoriesVixellsIndexResponse {
  /**
   *
   * @type {string}
   * @memberof InventoriesVixellsIndexResponse
   */
  type: InventoriesVixellsIndexResponseTypeEnum
  /**
   *
   * @type {InventoriesVixellsIndexResponseData}
   * @memberof InventoriesVixellsIndexResponse
   */
  data: InventoriesVixellsIndexResponseData
}

export const InventoriesVixellsIndexResponseTypeEnum = {
  InventoriesVixellsIndex: 'InventoriesVixellsIndex',
} as const

export type InventoriesVixellsIndexResponseTypeEnum =
  typeof InventoriesVixellsIndexResponseTypeEnum[keyof typeof InventoriesVixellsIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface InventoriesVixellsIndexResponseData
 */
export interface InventoriesVixellsIndexResponseData {
  /**
   *
   * @type {Array<VixellInventory>}
   * @memberof InventoriesVixellsIndexResponseData
   */
  vixells: Array<VixellInventory>
  /**
   *
   * @type {number}
   * @memberof InventoriesVixellsIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof InventoriesVixellsIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @interface LeakTest1
 */
export interface LeakTest1 {
  /**
   *
   * @type {string}
   * @memberof LeakTest1
   */
  app: LeakTest1AppEnum
  /**
   *
   * @type {string}
   * @memberof LeakTest1
   */
  logType: LeakTest1LogTypeEnum
  /**
   *
   * @type {CommonData & VacuumProcessCommon & object}
   * @memberof LeakTest1
   */
  data: CommonData & VacuumProcessCommon & object
}

export const LeakTest1AppEnum = {
  VacuumProcess: 'vacuumProcess',
} as const

export type LeakTest1AppEnum =
  typeof LeakTest1AppEnum[keyof typeof LeakTest1AppEnum]
export const LeakTest1LogTypeEnum = {
  LeakTest1: 'leakTest1',
} as const

export type LeakTest1LogTypeEnum =
  typeof LeakTest1LogTypeEnum[keyof typeof LeakTest1LogTypeEnum]

/**
 *
 * @export
 * @interface LeakTest2
 */
export interface LeakTest2 {
  /**
   *
   * @type {string}
   * @memberof LeakTest2
   */
  app: LeakTest2AppEnum
  /**
   *
   * @type {string}
   * @memberof LeakTest2
   */
  logType: LeakTest2LogTypeEnum
  /**
   *
   * @type {CommonData & VacuumProcessCommon & object}
   * @memberof LeakTest2
   */
  data: CommonData & VacuumProcessCommon & object
}

export const LeakTest2AppEnum = {
  VacuumProcess: 'vacuumProcess',
} as const

export type LeakTest2AppEnum =
  typeof LeakTest2AppEnum[keyof typeof LeakTest2AppEnum]
export const LeakTest2LogTypeEnum = {
  LeakTest2: 'leakTest2',
} as const

export type LeakTest2LogTypeEnum =
  typeof LeakTest2LogTypeEnum[keyof typeof LeakTest2LogTypeEnum]

/**
 *
 * @export
 * @enum {string}
 */

export const LogLevel = {
  Info: 'INFO',
  Warn: 'WARN',
  Error: 'ERROR',
} as const

export type LogLevel = typeof LogLevel[keyof typeof LogLevel]

/**
 *
 * @export
 * @interface LogRequest
 */
export interface LogRequest {
  /**
   *
   * @type {string}
   * @memberof LogRequest
   */
  app: LogRequestAppEnum
  /**
   *
   * @type {string}
   * @memberof LogRequest
   */
  logType: LogRequestLogTypeEnum
  /**
   *
   * @type {CommonData & ShippingManagementCommonInfo & CommonThermalStorageUnitAndAccessory}
   * @memberof LogRequest
   */
  data: CommonData &
    ShippingManagementCommonInfo &
    CommonThermalStorageUnitAndAccessory
}

export const LogRequestAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type LogRequestAppEnum =
  typeof LogRequestAppEnum[keyof typeof LogRequestAppEnum]
export const LogRequestLogTypeEnum = {
  AccessoryShipping: 'accessoryShipping',
} as const

export type LogRequestLogTypeEnum =
  typeof LogRequestLogTypeEnum[keyof typeof LogRequestLogTypeEnum]

/**
 *
 * @export
 * @enum {string}
 */

export const LoggingSettingBy = {
  Panasonic: 'PANASONIC',
  Customer: 'CUSTOMER',
} as const

export type LoggingSettingBy =
  typeof LoggingSettingBy[keyof typeof LoggingSettingBy]

/**
 *
 * @export
 * @enum {string}
 */

export const LoggingStartReservationType = {
  WhenShipFromPanasonic: 'WHEN_SHIP_FROM_PANASONIC',
  Manual: 'MANUAL',
  SpecificTime: 'SPECIFIC_TIME',
} as const

export type LoggingStartReservationType =
  typeof LoggingStartReservationType[keyof typeof LoggingStartReservationType]

/**
 *
 * @export
 * @interface Login
 */
export interface Login {
  /**
   * サイトID
   * @type {string}
   * @memberof Login
   */
  siteId: string
  /**
   * パスワード
   * @type {string}
   * @memberof Login
   */
  password: string
}
/**
 *
 * @export
 * @interface LoginFailureResponse
 */
export interface LoginFailureResponse {
  /**
   *
   * @type {string}
   * @memberof LoginFailureResponse
   */
  type: LoginFailureResponseTypeEnum
  /**
   *
   * @type {string}
   * @memberof LoginFailureResponse
   */
  code: LoginFailureResponseCodeEnum
}

export const LoginFailureResponseTypeEnum = {
  InvalidRequestError: 'invalid-request-error',
} as const

export type LoginFailureResponseTypeEnum =
  typeof LoginFailureResponseTypeEnum[keyof typeof LoginFailureResponseTypeEnum]
export const LoginFailureResponseCodeEnum = {
  EmailRequired: 'email-required',
  PasswordRequired: 'password-required',
  InvalidEmailOrPassword: 'invalid-email-or-password',
} as const

export type LoginFailureResponseCodeEnum =
  typeof LoginFailureResponseCodeEnum[keyof typeof LoginFailureResponseCodeEnum]

/**
 *
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
  /**
   *
   * @type {string}
   * @memberof LoginRequest
   */
  type: LoginRequestTypeEnum
  /**
   *
   * @type {LoginRequestComponent}
   * @memberof LoginRequest
   */
  data: LoginRequestComponent
}

export const LoginRequestTypeEnum = {
  Login: 'Login',
} as const

export type LoginRequestTypeEnum =
  typeof LoginRequestTypeEnum[keyof typeof LoginRequestTypeEnum]

/**
 *
 * @export
 * @interface LoginRequestComponent
 */
export interface LoginRequestComponent {
  /**
   *
   * @type {string}
   * @memberof LoginRequestComponent
   */
  siteIdentifier: string
  /**
   *
   * @type {string}
   * @memberof LoginRequestComponent
   */
  password: string
}
/**
 *
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
  /**
   * アクセストークン(有効期限は、最終アクセスから3ヶ月)
   * @type {string}
   * @memberof LoginResponse
   */
  token: string
}
/**
 *
 * @export
 * @interface LoginResponse1
 */
export interface LoginResponse1 {
  /**
   *
   * @type {string}
   * @memberof LoginResponse1
   */
  type: LoginResponse1TypeEnum
  /**
   *
   * @type {LoginResponseComponent}
   * @memberof LoginResponse1
   */
  data: LoginResponseComponent
}

export const LoginResponse1TypeEnum = {
  Login: 'Login',
} as const

export type LoginResponse1TypeEnum =
  typeof LoginResponse1TypeEnum[keyof typeof LoginResponse1TypeEnum]

/**
 *
 * @export
 * @interface LoginResponseComponent
 */
export interface LoginResponseComponent {
  /**
   *
   * @type {string}
   * @memberof LoginResponseComponent
   */
  token: string
  /**
   *
   * @type {Site}
   * @memberof LoginResponseComponent
   */
  site: Site
}
/**
 *
 * @export
 * @interface MeShowRequest
 */
export interface MeShowRequest {
  /**
   *
   * @type {string}
   * @memberof MeShowRequest
   */
  type: MeShowRequestTypeEnum
}

export const MeShowRequestTypeEnum = {
  MeShow: 'MeShow',
} as const

export type MeShowRequestTypeEnum =
  typeof MeShowRequestTypeEnum[keyof typeof MeShowRequestTypeEnum]

/**
 *
 * @export
 * @interface MeShowResponse
 */
export interface MeShowResponse {
  /**
   *
   * @type {string}
   * @memberof MeShowResponse
   */
  type: MeShowResponseTypeEnum
  /**
   *
   * @type {MeShowResponseData}
   * @memberof MeShowResponse
   */
  data: MeShowResponseData
}

export const MeShowResponseTypeEnum = {
  MeShow: 'MeShow',
} as const

export type MeShowResponseTypeEnum =
  typeof MeShowResponseTypeEnum[keyof typeof MeShowResponseTypeEnum]

/**
 *
 * @export
 * @interface MeShowResponseData
 */
export interface MeShowResponseData {
  /**
   *
   * @type {Site}
   * @memberof MeShowResponseData
   */
  site: Site
}
/**
 *
 * @export
 * @interface MeUpdateRequest
 */
export interface MeUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof MeUpdateRequest
   */
  type: MeUpdateRequestTypeEnum
  /**
   *
   * @type {MeUpdateRequestData}
   * @memberof MeUpdateRequest
   */
  data: MeUpdateRequestData
}

export const MeUpdateRequestTypeEnum = {
  MeUpdate: 'MeUpdate',
} as const

export type MeUpdateRequestTypeEnum =
  typeof MeUpdateRequestTypeEnum[keyof typeof MeUpdateRequestTypeEnum]

/**
 *
 * @export
 * @interface MeUpdateRequestData
 */
export interface MeUpdateRequestData {
  /**
   *
   * @type {string}
   * @memberof MeUpdateRequestData
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof MeUpdateRequestData
   */
  password?: string
}
/**
 *
 * @export
 * @interface MeUpdateResponse
 */
export interface MeUpdateResponse {
  /**
   *
   * @type {string}
   * @memberof MeUpdateResponse
   */
  type: MeUpdateResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof MeUpdateResponse
   */
  data: SuccessResponseData
}

export const MeUpdateResponseTypeEnum = {
  MeUpdate: 'MeUpdate',
} as const

export type MeUpdateResponseTypeEnum =
  typeof MeUpdateResponseTypeEnum[keyof typeof MeUpdateResponseTypeEnum]

/**
 *
 * @export
 * @enum {string}
 */

export const MeridiemIndicator = {
  Am: 'AM',
  Pm: 'PM',
} as const

export type MeridiemIndicator =
  typeof MeridiemIndicator[keyof typeof MeridiemIndicator]

/**
 *
 * @export
 * @interface Onsite
 */
export interface Onsite {
  /**
   *
   * @type {number}
   * @memberof Onsite
   */
  ok: number
  /**
   *
   * @type {number}
   * @memberof Onsite
   */
  ng: number
  /**
   * YYYY-MM-DD
   * @type {string}
   * @memberof Onsite
   */
  date: string
}
/**
 *
 * @export
 * @interface OnsiteIcePack
 */
export interface OnsiteIcePack {
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePack
   */
  app: OnsiteIcePackAppEnum
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePack
   */
  logType: OnsiteIcePackLogTypeEnum
  /**
   *
   * @type {CommonData & object}
   * @memberof OnsiteIcePack
   */
  data: CommonData & object
}

export const OnsiteIcePackAppEnum = {
  Onsite: 'onsite',
} as const

export type OnsiteIcePackAppEnum =
  typeof OnsiteIcePackAppEnum[keyof typeof OnsiteIcePackAppEnum]
export const OnsiteIcePackLogTypeEnum = {
  OnsiteIcePack: 'onsiteIcePack',
} as const

export type OnsiteIcePackLogTypeEnum =
  typeof OnsiteIcePackLogTypeEnum[keyof typeof OnsiteIcePackLogTypeEnum]

/**
 *
 * @export
 * @interface OnsiteIcePack1
 */
export interface OnsiteIcePack1 {
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePack1
   */
  totalInspectionResult?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePack1
   */
  totalErrorDetail?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePack1
   */
  processingDateTime?: string
  /**
   *
   * @type {Array<OnsiteIcePackLastInspectionResults>}
   * @memberof OnsiteIcePack1
   */
  inspectionResults?: Array<OnsiteIcePackLastInspectionResults>
}
/**
 *
 * @export
 * @interface OnsiteIcePackLast
 */
export interface OnsiteIcePackLast {
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackLast
   */
  totalInspectionResult?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackLast
   */
  totalErrorDetail?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackLast
   */
  processingDateTime?: string
  /**
   *
   * @type {Array<OnsiteIcePackLastInspectionResults>}
   * @memberof OnsiteIcePackLast
   */
  inspectionResults?: Array<OnsiteIcePackLastInspectionResults>
}
/**
 *
 * @export
 * @interface OnsiteIcePackLastInspectionResults
 */
export interface OnsiteIcePackLastInspectionResults {
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackLastInspectionResults
   */
  temperatureSensorTagEpc: string
  /**
   *
   * @type {number}
   * @memberof OnsiteIcePackLastInspectionResults
   */
  icePackTemperature: number
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackLastInspectionResults
   */
  inspectionResult: string
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackLastInspectionResults
   */
  errorDetail: string
}
/**
 *
 * @export
 * @interface OnsiteIcePackResultUnitsIndexRequest
 */
export interface OnsiteIcePackResultUnitsIndexRequest {
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackResultUnitsIndexRequest
   */
  type: OnsiteIcePackResultUnitsIndexRequestTypeEnum
  /**
   *
   * @type {OnsiteIcePackResultUnitsIndexRequestData}
   * @memberof OnsiteIcePackResultUnitsIndexRequest
   */
  data: OnsiteIcePackResultUnitsIndexRequestData
}

export const OnsiteIcePackResultUnitsIndexRequestTypeEnum = {
  OnsiteIcePackResultUnitsIndex: 'OnsiteIcePackResultUnitsIndex',
} as const

export type OnsiteIcePackResultUnitsIndexRequestTypeEnum =
  typeof OnsiteIcePackResultUnitsIndexRequestTypeEnum[keyof typeof OnsiteIcePackResultUnitsIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface OnsiteIcePackResultUnitsIndexRequestData
 */
export interface OnsiteIcePackResultUnitsIndexRequestData {
  /**
   *
   * @type {number}
   * @memberof OnsiteIcePackResultUnitsIndexRequestData
   */
  icePackId: number
  /**
   *
   * @type {number}
   * @memberof OnsiteIcePackResultUnitsIndexRequestData
   */
  page?: number
}
/**
 *
 * @export
 * @interface OnsiteIcePackResultUnitsIndexResponse
 */
export interface OnsiteIcePackResultUnitsIndexResponse {
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackResultUnitsIndexResponse
   */
  type: OnsiteIcePackResultUnitsIndexResponseTypeEnum
  /**
   *
   * @type {OnsiteIcePackResultUnitsIndexResponseData}
   * @memberof OnsiteIcePackResultUnitsIndexResponse
   */
  data: OnsiteIcePackResultUnitsIndexResponseData
}

export const OnsiteIcePackResultUnitsIndexResponseTypeEnum = {
  OnsiteIcePackResultUnitsIndex: 'OnsiteIcePackResultUnitsIndex',
} as const

export type OnsiteIcePackResultUnitsIndexResponseTypeEnum =
  typeof OnsiteIcePackResultUnitsIndexResponseTypeEnum[keyof typeof OnsiteIcePackResultUnitsIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface OnsiteIcePackResultUnitsIndexResponseData
 */
export interface OnsiteIcePackResultUnitsIndexResponseData {
  /**
   *
   * @type {Array<OnsiteIcePackUnit>}
   * @memberof OnsiteIcePackResultUnitsIndexResponseData
   */
  onsiteIcePackResultUnits: Array<OnsiteIcePackUnit>
  /**
   *
   * @type {number}
   * @memberof OnsiteIcePackResultUnitsIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof OnsiteIcePackResultUnitsIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @interface OnsiteIcePackUnit
 */
export interface OnsiteIcePackUnit {
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackUnit
   */
  temperatureSensorTagEpc: string
  /**
   *
   * @type {number}
   * @memberof OnsiteIcePackUnit
   */
  icePackTemperature?: number
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackUnit
   */
  inspectionResult: string
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackUnit
   */
  errorDetail?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackUnit
   */
  processingDateTime: string
  /**
   *
   * @type {IcePackPart}
   * @memberof OnsiteIcePackUnit
   */
  part: IcePackPart
}
/**
 *
 * @export
 * @interface OnsiteIcePackUnitLast
 */
export interface OnsiteIcePackUnitLast {
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackUnitLast
   */
  temperatureSensorTagEpc: string
  /**
   *
   * @type {number}
   * @memberof OnsiteIcePackUnitLast
   */
  icePackTemperature?: number
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackUnitLast
   */
  inspectionResult: string
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackUnitLast
   */
  errorDetail?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteIcePackUnitLast
   */
  processingDateTime: string
  /**
   *
   * @type {IcePackPartLast}
   * @memberof OnsiteIcePackUnitLast
   */
  part: IcePackPartLast
}
/**
 *
 * @export
 * @interface OnsiteResult
 */
export interface OnsiteResult {
  /**
   *
   * @type {string}
   * @memberof OnsiteResult
   */
  vixellSerialNo: string
  /**
   *
   * @type {string}
   * @memberof OnsiteResult
   */
  pcId: string
  /**
   *
   * @type {string}
   * @memberof OnsiteResult
   */
  processingDateTime: string
  /**
   *
   * @type {string}
   * @memberof OnsiteResult
   */
  siteName: string
  /**
   *
   * @type {ContractWithManagementNo}
   * @memberof OnsiteResult
   */
  contractInfo: ContractWithManagementNo
  /**
   *
   * @type {string}
   * @memberof OnsiteResult
   */
  lastEvaluatedKey?: string
  /**
   *
   * @type {OnsiteResultOnsiteVacuumResult}
   * @memberof OnsiteResult
   */
  onsiteVacuumResult: OnsiteResultOnsiteVacuumResult
  /**
   *
   * @type {OnsiteResultOnsiteIcePackResult}
   * @memberof OnsiteResult
   */
  onsiteIcePackResult: OnsiteResultOnsiteIcePackResult
}
/**
 *
 * @export
 * @interface OnsiteResultOnsiteIcePackResult
 */
export interface OnsiteResultOnsiteIcePackResult {
  /**
   *
   * @type {string}
   * @memberof OnsiteResultOnsiteIcePackResult
   */
  totalInspectionResult: string
  /**
   *
   * @type {string}
   * @memberof OnsiteResultOnsiteIcePackResult
   */
  totalErrorDetail?: string
  /**
   *
   * @type {OnsiteResultOnsiteIcePackResultInspectionResults}
   * @memberof OnsiteResultOnsiteIcePackResult
   */
  inspectionResults?: OnsiteResultOnsiteIcePackResultInspectionResults
}
/**
 *
 * @export
 * @interface OnsiteResultOnsiteIcePackResultInspectionResults
 */
export interface OnsiteResultOnsiteIcePackResultInspectionResults {
  /**
   *
   * @type {string}
   * @memberof OnsiteResultOnsiteIcePackResultInspectionResults
   */
  '　temperatureSensorTagEpc'?: string
  /**
   *
   * @type {number}
   * @memberof OnsiteResultOnsiteIcePackResultInspectionResults
   */
  '　icePackTemperature'?: number
  /**
   *
   * @type {string}
   * @memberof OnsiteResultOnsiteIcePackResultInspectionResults
   */
  '　inspectionResult'?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteResultOnsiteIcePackResultInspectionResults
   */
  '　errorDetail'?: string
}
/**
 *
 * @export
 * @interface OnsiteResultOnsiteVacuumResult
 */
export interface OnsiteResultOnsiteVacuumResult {
  /**
   *
   * @type {string}
   * @memberof OnsiteResultOnsiteVacuumResult
   */
  inspectionResult: string
}
/**
 *
 * @export
 * @interface OnsiteResultsIndexRequest
 */
export interface OnsiteResultsIndexRequest {
  /**
   *
   * @type {string}
   * @memberof OnsiteResultsIndexRequest
   */
  type: OnsiteResultsIndexRequestTypeEnum
  /**
   *
   * @type {OnsiteResultsIndexRequestData}
   * @memberof OnsiteResultsIndexRequest
   */
  data: OnsiteResultsIndexRequestData
}

export const OnsiteResultsIndexRequestTypeEnum = {
  OnsiteResultsIndex: 'OnsiteResultsIndex',
} as const

export type OnsiteResultsIndexRequestTypeEnum =
  typeof OnsiteResultsIndexRequestTypeEnum[keyof typeof OnsiteResultsIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface OnsiteResultsIndexRequestData
 */
export interface OnsiteResultsIndexRequestData {
  /**
   *
   * @type {string}
   * @memberof OnsiteResultsIndexRequestData
   */
  query?: string
  /**
   *
   * @type {boolean}
   * @memberof OnsiteResultsIndexRequestData
   */
  ngOnly?: boolean
  /**
   *
   * @type {boolean}
   * @memberof OnsiteResultsIndexRequestData
   */
  latestOnly?: boolean
  /**
   *
   * @type {string}
   * @memberof OnsiteResultsIndexRequestData
   */
  from?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteResultsIndexRequestData
   */
  till?: string
  /**
   *
   * @type {boolean}
   * @memberof OnsiteResultsIndexRequestData
   */
  all?: boolean
  /**
   *
   * @type {number}
   * @memberof OnsiteResultsIndexRequestData
   */
  page?: number
  /**
   *
   * @type {number}
   * @memberof OnsiteResultsIndexRequestData
   */
  per?: number
}
/**
 *
 * @export
 * @interface OnsiteResultsIndexResponse
 */
export interface OnsiteResultsIndexResponse {
  /**
   *
   * @type {string}
   * @memberof OnsiteResultsIndexResponse
   */
  type: OnsiteResultsIndexResponseTypeEnum
  /**
   *
   * @type {OnsiteResultsIndexResponseData}
   * @memberof OnsiteResultsIndexResponse
   */
  data: OnsiteResultsIndexResponseData
}

export const OnsiteResultsIndexResponseTypeEnum = {
  OnsiteResultsIndex: 'OnsiteResultsIndex',
} as const

export type OnsiteResultsIndexResponseTypeEnum =
  typeof OnsiteResultsIndexResponseTypeEnum[keyof typeof OnsiteResultsIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface OnsiteResultsIndexResponseData
 */
export interface OnsiteResultsIndexResponseData {
  /**
   *
   * @type {Array<AdminOnsiteResult>}
   * @memberof OnsiteResultsIndexResponseData
   */
  results: Array<AdminOnsiteResult>
  /**
   *
   * @type {number}
   * @memberof OnsiteResultsIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof OnsiteResultsIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @interface OnsiteResultsPressureGraphRequest
 */
export interface OnsiteResultsPressureGraphRequest {
  /**
   *
   * @type {string}
   * @memberof OnsiteResultsPressureGraphRequest
   */
  type: OnsiteResultsPressureGraphRequestTypeEnum
  /**
   *
   * @type {OnsiteResultsPressureGraphRequestData}
   * @memberof OnsiteResultsPressureGraphRequest
   */
  data: OnsiteResultsPressureGraphRequestData
}

export const OnsiteResultsPressureGraphRequestTypeEnum = {
  OnsiteResultsPressureGraph: 'OnsiteResultsPressureGraph',
} as const

export type OnsiteResultsPressureGraphRequestTypeEnum =
  typeof OnsiteResultsPressureGraphRequestTypeEnum[keyof typeof OnsiteResultsPressureGraphRequestTypeEnum]

/**
 *
 * @export
 * @interface OnsiteResultsPressureGraphRequestData
 */
export interface OnsiteResultsPressureGraphRequestData {
  /**
   *
   * @type {string}
   * @memberof OnsiteResultsPressureGraphRequestData
   */
  vixellSerialNo?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteResultsPressureGraphRequestData
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteResultsPressureGraphRequestData
   */
  contractNo?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteResultsPressureGraphRequestData
   */
  stockedFrom?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteResultsPressureGraphRequestData
   */
  stockedTill?: string
}
/**
 *
 * @export
 * @interface OnsiteResultsPressureGraphResponse
 */
export interface OnsiteResultsPressureGraphResponse {
  /**
   *
   * @type {string}
   * @memberof OnsiteResultsPressureGraphResponse
   */
  type: OnsiteResultsPressureGraphResponseTypeEnum
  /**
   *
   * @type {OnsiteResultsPressureGraphResponseData}
   * @memberof OnsiteResultsPressureGraphResponse
   */
  data: OnsiteResultsPressureGraphResponseData
}

export const OnsiteResultsPressureGraphResponseTypeEnum = {
  OnsiteResultsPressureGraph: 'OnsiteResultsPressureGraph',
} as const

export type OnsiteResultsPressureGraphResponseTypeEnum =
  typeof OnsiteResultsPressureGraphResponseTypeEnum[keyof typeof OnsiteResultsPressureGraphResponseTypeEnum]

/**
 *
 * @export
 * @interface OnsiteResultsPressureGraphResponseData
 */
export interface OnsiteResultsPressureGraphResponseData {
  /**
   *
   * @type {{ [key: string]: Array<PressureGraphEntity>; }}
   * @memberof OnsiteResultsPressureGraphResponseData
   */
  results: { [key: string]: Array<PressureGraphEntity> }
}
/**
 *
 * @export
 * @interface OnsiteVacuum
 */
export interface OnsiteVacuum {
  /**
   *
   * @type {string}
   * @memberof OnsiteVacuum
   */
  vixellSerialNo?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteVacuum
   */
  processingDateTime: string
  /**
   *
   * @type {number}
   * @memberof OnsiteVacuum
   */
  sensorVoltage: number
  /**
   *
   * @type {number}
   * @memberof OnsiteVacuum
   */
  sensorPressure: number
  /**
   *
   * @type {string}
   * @memberof OnsiteVacuum
   */
  contractRepresentativeNo?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteVacuum
   */
  contractNo?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteVacuum
   */
  managementNo?: string
  /**
   *
   * @type {OnsiteVacuumTiming1}
   * @memberof OnsiteVacuum
   */
  timing: OnsiteVacuumTiming1
  /**
   *
   * @type {string}
   * @memberof OnsiteVacuum
   */
  inspectionResult: string
}
/**
 *
 * @export
 * @interface OnsiteVacuumLast
 */
export interface OnsiteVacuumLast {
  /**
   *
   * @type {string}
   * @memberof OnsiteVacuumLast
   */
  vixellSerialNo?: string
  /**
   *
   * @type {string}
   * @memberof OnsiteVacuumLast
   */
  processingDateTime: string
  /**
   *
   * @type {number}
   * @memberof OnsiteVacuumLast
   */
  sensorVoltage: number
  /**
   *
   * @type {number}
   * @memberof OnsiteVacuumLast
   */
  sensorPressure: number
  /**
   *
   * @type {OnsiteVacuumTiming}
   * @memberof OnsiteVacuumLast
   */
  timing: OnsiteVacuumTiming
  /**
   *
   * @type {string}
   * @memberof OnsiteVacuumLast
   */
  inspectionResult: string
}
/**
 *
 * @export
 * @interface OnsiteVacuumResultsIndexRequest
 */
export interface OnsiteVacuumResultsIndexRequest {
  /**
   *
   * @type {string}
   * @memberof OnsiteVacuumResultsIndexRequest
   */
  type: OnsiteVacuumResultsIndexRequestTypeEnum
  /**
   *
   * @type {OnsiteVacuumResultsIndexRequestData}
   * @memberof OnsiteVacuumResultsIndexRequest
   */
  data: OnsiteVacuumResultsIndexRequestData
}

export const OnsiteVacuumResultsIndexRequestTypeEnum = {
  OnsiteVacuumResultsIndex: 'OnsiteVacuumResultsIndex',
} as const

export type OnsiteVacuumResultsIndexRequestTypeEnum =
  typeof OnsiteVacuumResultsIndexRequestTypeEnum[keyof typeof OnsiteVacuumResultsIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface OnsiteVacuumResultsIndexRequestData
 */
export interface OnsiteVacuumResultsIndexRequestData {
  /**
   *
   * @type {number}
   * @memberof OnsiteVacuumResultsIndexRequestData
   */
  vixellId: number
  /**
   *
   * @type {number}
   * @memberof OnsiteVacuumResultsIndexRequestData
   */
  page?: number
}
/**
 *
 * @export
 * @interface OnsiteVacuumResultsIndexResponse
 */
export interface OnsiteVacuumResultsIndexResponse {
  /**
   *
   * @type {string}
   * @memberof OnsiteVacuumResultsIndexResponse
   */
  type: OnsiteVacuumResultsIndexResponseTypeEnum
  /**
   *
   * @type {OnsiteVacuumResultsIndexResponseData}
   * @memberof OnsiteVacuumResultsIndexResponse
   */
  data: OnsiteVacuumResultsIndexResponseData
}

export const OnsiteVacuumResultsIndexResponseTypeEnum = {
  OnsiteVacuumResultsIndex: 'OnsiteVacuumResultsIndex',
} as const

export type OnsiteVacuumResultsIndexResponseTypeEnum =
  typeof OnsiteVacuumResultsIndexResponseTypeEnum[keyof typeof OnsiteVacuumResultsIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface OnsiteVacuumResultsIndexResponseData
 */
export interface OnsiteVacuumResultsIndexResponseData {
  /**
   *
   * @type {Array<OnsiteVacuum>}
   * @memberof OnsiteVacuumResultsIndexResponseData
   */
  onsiteVacuumResults: Array<OnsiteVacuum>
  /**
   *
   * @type {number}
   * @memberof OnsiteVacuumResultsIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof OnsiteVacuumResultsIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @enum {string}
 */

export const OnsiteVacuumTiming = {
  Stocked: 'stocked',
  Shipped: 'shipped',
  Returned: 'returned',
  Disposed: 'disposed',
  Onsite: 'onsite',
} as const

export type OnsiteVacuumTiming =
  typeof OnsiteVacuumTiming[keyof typeof OnsiteVacuumTiming]

/**
 *
 * @export
 * @enum {string}
 */

export const OnsiteVacuumTiming1 = {
  Stocked: 'STOCKED',
  Shipped: 'SHIPPED',
  Returned: 'RETURNED',
  Disposed: 'DISPOSED',
  Onsite: 'ONSITE',
} as const

export type OnsiteVacuumTiming1 =
  typeof OnsiteVacuumTiming1[keyof typeof OnsiteVacuumTiming1]

/**
 *
 * @export
 * @interface PagingRequest
 */
export interface PagingRequest {
  /**
   *
   * @type {string}
   * @memberof PagingRequest
   */
  vixellSerialNo: string
  /**
   *
   * @type {string}
   * @memberof PagingRequest
   */
  exclusiveStartKey?: string
}
/**
 *
 * @export
 * @interface PagingRequest1
 */
export interface PagingRequest1 {
  /**
   *
   * @type {string}
   * @memberof PagingRequest1
   */
  temperatureSensorTagEpc: string
  /**
   *
   * @type {string}
   * @memberof PagingRequest1
   */
  exclusiveStartKey?: string
}
/**
 *
 * @export
 * @interface PressureGraphEntity
 */
export interface PressureGraphEntity {
  /**
   *
   * @type {number}
   * @memberof PressureGraphEntity
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof PressureGraphEntity
   */
  pressure: number
  /**
   *
   * @type {string}
   * @memberof PressureGraphEntity
   */
  serialNo: string
  /**
   *
   * @type {string}
   * @memberof PressureGraphEntity
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof PressureGraphEntity
   */
  processingAt: string
  /**
   *
   * @type {string}
   * @memberof PressureGraphEntity
   */
  stockedAt: string
}
/**
 *
 * @export
 * @interface ProductNote
 */
export interface ProductNote {
  /**
   *
   * @type {number}
   * @memberof ProductNote
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof ProductNote
   */
  text: string
  /**
   *
   * @type {string}
   * @memberof ProductNote
   */
  processingDateTime: string
  /**
   *
   * @type {VixellProductData | ThermalStorageUnitProductData | TemperatureLoggerProductData}
   * @memberof ProductNote
   */
  product?:
    | VixellProductData
    | ThermalStorageUnitProductData
    | TemperatureLoggerProductData
}
/**
 *
 * @export
 * @interface ProductNotesCreateRequest
 */
export interface ProductNotesCreateRequest {
  /**
   *
   * @type {string}
   * @memberof ProductNotesCreateRequest
   */
  type: ProductNotesCreateRequestTypeEnum
  /**
   *
   * @type {ProductNotesCreateRequestData}
   * @memberof ProductNotesCreateRequest
   */
  data: ProductNotesCreateRequestData
}

export const ProductNotesCreateRequestTypeEnum = {
  ProductNotesCreate: 'ProductNotesCreate',
} as const

export type ProductNotesCreateRequestTypeEnum =
  typeof ProductNotesCreateRequestTypeEnum[keyof typeof ProductNotesCreateRequestTypeEnum]

/**
 *
 * @export
 * @interface ProductNotesCreateRequestData
 */
export interface ProductNotesCreateRequestData {
  /**
   *
   * @type {string}
   * @memberof ProductNotesCreateRequestData
   */
  productType: ProductNotesCreateRequestDataProductTypeEnum
  /**
   *
   * @type {number}
   * @memberof ProductNotesCreateRequestData
   */
  productId: number
  /**
   *
   * @type {string}
   * @memberof ProductNotesCreateRequestData
   */
  text: string
}

export const ProductNotesCreateRequestDataProductTypeEnum = {
  Vixell: 'VIXELL',
  ThermalStorageUnit: 'THERMAL_STORAGE_UNIT',
  TemperatureLogger: 'TEMPERATURE_LOGGER',
} as const

export type ProductNotesCreateRequestDataProductTypeEnum =
  typeof ProductNotesCreateRequestDataProductTypeEnum[keyof typeof ProductNotesCreateRequestDataProductTypeEnum]

/**
 *
 * @export
 * @interface ProductNotesCreateResponse
 */
export interface ProductNotesCreateResponse {
  /**
   *
   * @type {string}
   * @memberof ProductNotesCreateResponse
   */
  type: ProductNotesCreateResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof ProductNotesCreateResponse
   */
  data: SuccessResponseData
}

export const ProductNotesCreateResponseTypeEnum = {
  ProductNotesCreate: 'ProductNotesCreate',
} as const

export type ProductNotesCreateResponseTypeEnum =
  typeof ProductNotesCreateResponseTypeEnum[keyof typeof ProductNotesCreateResponseTypeEnum]

/**
 *
 * @export
 * @interface ProductNotesIndexRequest
 */
export interface ProductNotesIndexRequest {
  /**
   *
   * @type {string}
   * @memberof ProductNotesIndexRequest
   */
  type: ProductNotesIndexRequestTypeEnum
  /**
   *
   * @type {ProductNotesIndexRequestData}
   * @memberof ProductNotesIndexRequest
   */
  data: ProductNotesIndexRequestData
}

export const ProductNotesIndexRequestTypeEnum = {
  ProductNotesIndex: 'ProductNotesIndex',
} as const

export type ProductNotesIndexRequestTypeEnum =
  typeof ProductNotesIndexRequestTypeEnum[keyof typeof ProductNotesIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface ProductNotesIndexRequestData
 */
export interface ProductNotesIndexRequestData {
  /**
   *
   * @type {string}
   * @memberof ProductNotesIndexRequestData
   */
  productType: ProductNotesIndexRequestDataProductTypeEnum
  /**
   *
   * @type {number}
   * @memberof ProductNotesIndexRequestData
   */
  productId: number
  /**
   *
   * @type {number}
   * @memberof ProductNotesIndexRequestData
   */
  page?: number
}

export const ProductNotesIndexRequestDataProductTypeEnum = {
  Vixell: 'VIXELL',
  ThermalStorageUnit: 'THERMAL_STORAGE_UNIT',
  TemperatureLogger: 'TEMPERATURE_LOGGER',
} as const

export type ProductNotesIndexRequestDataProductTypeEnum =
  typeof ProductNotesIndexRequestDataProductTypeEnum[keyof typeof ProductNotesIndexRequestDataProductTypeEnum]

/**
 *
 * @export
 * @interface ProductNotesIndexResponse
 */
export interface ProductNotesIndexResponse {
  /**
   *
   * @type {string}
   * @memberof ProductNotesIndexResponse
   */
  type: ProductNotesIndexResponseTypeEnum
  /**
   *
   * @type {ProductNotesIndexResponseData}
   * @memberof ProductNotesIndexResponse
   */
  data: ProductNotesIndexResponseData
}

export const ProductNotesIndexResponseTypeEnum = {
  ProductNotesIndex: 'ProductNotesIndex',
} as const

export type ProductNotesIndexResponseTypeEnum =
  typeof ProductNotesIndexResponseTypeEnum[keyof typeof ProductNotesIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface ProductNotesIndexResponseData
 */
export interface ProductNotesIndexResponseData {
  /**
   *
   * @type {Array<ProductNote>}
   * @memberof ProductNotesIndexResponseData
   */
  productNotes: Array<ProductNote>
  /**
   *
   * @type {number}
   * @memberof ProductNotesIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof ProductNotesIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @enum {string}
 */

export const ProductType = {
  Vixell: 'VIXELL',
  TemperatureSensorTag: 'TEMPERATURE_SENSOR_TAG',
  ThermalStorageUnit: 'THERMAL_STORAGE_UNIT',
  InspectionBoard: 'INSPECTION_BOARD',
  TemperatureLogger: 'TEMPERATURE_LOGGER',
} as const

export type ProductType = typeof ProductType[keyof typeof ProductType]

/**
 * 出荷時に共通する項目(リユーザブルで追加された蓄熱Uと温度ロガー）
 * @export
 * @interface ReusableContractInfo
 */
export interface ReusableContractInfo {
  /**
   * 契約代表No
   * @type {string}
   * @memberof ReusableContractInfo
   */
  contractRepresentativeNo: string
  /**
   * 契約No
   * @type {string}
   * @memberof ReusableContractInfo
   */
  contractNo?: string | null
  /**
   * 管理No(半角英数記号　255文字以内)
   * @type {string}
   * @memberof ReusableContractInfo
   */
  managementNo?: string | null
}
/**
 *
 * @export
 * @interface ReusableOrder
 */
export interface ReusableOrder {
  /**
   *
   * @type {number}
   * @memberof ReusableOrder
   */
  id: number
  /**
   *
   * @type {ReusableOrderState}
   * @memberof ReusableOrder
   */
  state: ReusableOrderState
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  customerCompanyName: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  customerCompanyDivision: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  customerPhone: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  notesForDistributionCenter?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  deliveryDateToConsignor: string
  /**
   *
   * @type {MeridiemIndicator}
   * @memberof ReusableOrder
   */
  deliveryDateMeridiemIndicatorToConsignor: MeridiemIndicator
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  countryOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  stateOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  addressOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  zipCodeOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  companyNameOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  divisionOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  phoneOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  notesForConsignor?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  deliveryDateToConsignee?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  countryOfConsignee: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  stateOfConsignee?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  addressOfConsignee?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  zipCodeOfConsignee: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  companyNameOfConsignee?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  divisionOfConsignee?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrder
   */
  phoneOfConsignee?: string
  /**
   *
   * @type {number}
   * @memberof ReusableOrder
   */
  lenderSiteId: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrder
   */
  collectorSiteId: number
  /**
   *
   * @type {Site}
   * @memberof ReusableOrder
   */
  lenderSite: Site
  /**
   *
   * @type {Site}
   * @memberof ReusableOrder
   */
  collectorSite: Site
  /**
   *
   * @type {Array<ReusableOrderDetail>}
   * @memberof ReusableOrder
   */
  reusableOrderDetails: Array<ReusableOrderDetail>
  /**
   *
   * @type {number}
   * @memberof ReusableOrder
   */
  detailCount?: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrder
   */
  lendCount?: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrder
   */
  collectedCount?: number
}
/**
 *
 * @export
 * @interface ReusableOrderCreateRequest
 */
export interface ReusableOrderCreateRequest {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderCreateRequest
   */
  type: ReusableOrderCreateRequestTypeEnum
  /**
   *
   * @type {ReusableOrderFormComponent}
   * @memberof ReusableOrderCreateRequest
   */
  data: ReusableOrderFormComponent
}

export const ReusableOrderCreateRequestTypeEnum = {
  ReusableOrderCreate: 'ReusableOrderCreate',
} as const

export type ReusableOrderCreateRequestTypeEnum =
  typeof ReusableOrderCreateRequestTypeEnum[keyof typeof ReusableOrderCreateRequestTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderCreateResponse
 */
export interface ReusableOrderCreateResponse {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderCreateResponse
   */
  type: ReusableOrderCreateResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof ReusableOrderCreateResponse
   */
  data: SuccessResponseData
}

export const ReusableOrderCreateResponseTypeEnum = {
  ReusableOrderCreate: 'ReusableOrderCreate',
} as const

export type ReusableOrderCreateResponseTypeEnum =
  typeof ReusableOrderCreateResponseTypeEnum[keyof typeof ReusableOrderCreateResponseTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderDashboardRequest
 */
export interface ReusableOrderDashboardRequest {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDashboardRequest
   */
  type: ReusableOrderDashboardRequestTypeEnum
}

export const ReusableOrderDashboardRequestTypeEnum = {
  ReusableOrderDashboard: 'ReusableOrderDashboard',
} as const

export type ReusableOrderDashboardRequestTypeEnum =
  typeof ReusableOrderDashboardRequestTypeEnum[keyof typeof ReusableOrderDashboardRequestTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderDashboardResponse
 */
export interface ReusableOrderDashboardResponse {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDashboardResponse
   */
  type: ReusableOrderDashboardResponseTypeEnum
  /**
   *
   * @type {ReusableOrderDashboardResponseComponent}
   * @memberof ReusableOrderDashboardResponse
   */
  data: ReusableOrderDashboardResponseComponent
}

export const ReusableOrderDashboardResponseTypeEnum = {
  ReusableOrderDashboard: 'ReusableOrderDashboard',
} as const

export type ReusableOrderDashboardResponseTypeEnum =
  typeof ReusableOrderDashboardResponseTypeEnum[keyof typeof ReusableOrderDashboardResponseTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderDashboardResponseComponent
 */
export interface ReusableOrderDashboardResponseComponent {
  /**
   *
   * @type {Array<ReusableOrderDashboardResponseComponentSites>}
   * @memberof ReusableOrderDashboardResponseComponent
   */
  sites: Array<ReusableOrderDashboardResponseComponentSites>
}
/**
 *
 * @export
 * @interface ReusableOrderDashboardResponseComponentSites
 */
export interface ReusableOrderDashboardResponseComponentSites {
  /**
   *
   * @type {Site}
   * @memberof ReusableOrderDashboardResponseComponentSites
   */
  site: Site
  /**
   *
   * @type {ReusableOrderDashboardResponseSiteComponent}
   * @memberof ReusableOrderDashboardResponseComponentSites
   */
  analytics: ReusableOrderDashboardResponseSiteComponent
}
/**
 *
 * @export
 * @interface ReusableOrderDashboardResponseSiteComponent
 */
export interface ReusableOrderDashboardResponseSiteComponent {
  /**
   *
   * @type {ReusableOrderDashboardResponseVixellAnalyticsComponent}
   * @memberof ReusableOrderDashboardResponseSiteComponent
   */
  typeS?: ReusableOrderDashboardResponseVixellAnalyticsComponent
  /**
   *
   * @type {ReusableOrderDashboardResponseVixellAnalyticsComponent}
   * @memberof ReusableOrderDashboardResponseSiteComponent
   */
  typeL?: ReusableOrderDashboardResponseVixellAnalyticsComponent
  /**
   *
   * @type {ReusableOrderDashboardResponseSiteComponentLogger}
   * @memberof ReusableOrderDashboardResponseSiteComponent
   */
  logger?: ReusableOrderDashboardResponseSiteComponentLogger
}
/**
 *
 * @export
 * @interface ReusableOrderDashboardResponseSiteComponentLogger
 */
export interface ReusableOrderDashboardResponseSiteComponentLogger {
  /**
   *
   * @type {number}
   * @memberof ReusableOrderDashboardResponseSiteComponentLogger
   */
  gf: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderDashboardResponseSiteComponentLogger
   */
  ge: number
}
/**
 *
 * @export
 * @interface ReusableOrderDashboardResponseVixellAnalyticsComponent
 */
export interface ReusableOrderDashboardResponseVixellAnalyticsComponent {
  /**
   *
   * @type {number}
   * @memberof ReusableOrderDashboardResponseVixellAnalyticsComponent
   */
  box: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderDashboardResponseVixellAnalyticsComponent
   */
  r5: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderDashboardResponseVixellAnalyticsComponent
   */
  c5: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderDashboardResponseVixellAnalyticsComponent
   */
  s4: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderDashboardResponseVixellAnalyticsComponent
   */
  f4: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderDashboardResponseVixellAnalyticsComponent
   */
  d: number
}
/**
 * NG品
 * @export
 * @interface ReusableOrderDefectiveRequest
 */
export interface ReusableOrderDefectiveRequest {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDefectiveRequest
   */
  type: ReusableOrderDefectiveRequestTypeEnum
  /**
   *
   * @type {ReusableOrderStockRequestData}
   * @memberof ReusableOrderDefectiveRequest
   */
  data: ReusableOrderStockRequestData
}

export const ReusableOrderDefectiveRequestTypeEnum = {
  ReusableOrderDefective: 'ReusableOrderDefective',
} as const

export type ReusableOrderDefectiveRequestTypeEnum =
  typeof ReusableOrderDefectiveRequestTypeEnum[keyof typeof ReusableOrderDefectiveRequestTypeEnum]

/**
 * NG品
 * @export
 * @interface ReusableOrderDefectiveResponse
 */
export interface ReusableOrderDefectiveResponse {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDefectiveResponse
   */
  type: ReusableOrderDefectiveResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof ReusableOrderDefectiveResponse
   */
  data: SuccessResponseData
}

export const ReusableOrderDefectiveResponseTypeEnum = {
  ReusableOrderDefective: 'ReusableOrderDefective',
} as const

export type ReusableOrderDefectiveResponseTypeEnum =
  typeof ReusableOrderDefectiveResponseTypeEnum[keyof typeof ReusableOrderDefectiveResponseTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderDetail
 */
export interface ReusableOrderDetail {
  /**
   *
   * @type {number}
   * @memberof ReusableOrderDetail
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderDetail
   */
  orderDetailNumber: number
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDetail
   */
  vixellModel: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDetail
   */
  thermalStorageUnitModel: string
  /**
   *
   * @type {boolean}
   * @memberof ReusableOrderDetail
   */
  preCooling: boolean
  /**
   *
   * @type {boolean}
   * @memberof ReusableOrderDetail
   */
  allowSupercoolingDelivery: boolean
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDetail
   */
  preCoolingNotes?: string
  /**
   *
   * @type {boolean}
   * @memberof ReusableOrderDetail
   */
  hasLogger: boolean
  /**
   *
   * @type {LoggingSettingBy}
   * @memberof ReusableOrderDetail
   */
  loggerSettingBy: LoggingSettingBy
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDetail
   */
  loggerModel?: string
  /**
   *
   * @type {number}
   * @memberof ReusableOrderDetail
   */
  loggerAlertUpperTemperatureLimit?: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderDetail
   */
  loggerAlertLowerTemperatureLimit?: number
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDetail
   */
  loggerAlertCapacity?: string
  /**
   *
   * @type {LoggingStartReservationType}
   * @memberof ReusableOrderDetail
   */
  loggingStartReservationType?: LoggingStartReservationType
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDetail
   */
  loggingStartReservationTime?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDetail
   */
  loggingStartReservationTimeZone?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDetail
   */
  notesForDelivery?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDetail
   */
  loggerFilePath?: string
  /**
   *
   * @type {number}
   * @memberof ReusableOrderDetail
   */
  lenderSiteId?: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderDetail
   */
  collectorSiteId?: number
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDetail
   */
  vixellLendingDate?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDetail
   */
  vixellCollectedDate?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDetail
   */
  thermalStorageUnitLendDate?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDetail
   */
  thermalStorageUnitCollectedDate?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDetail
   */
  temperatureLoggerLendDate?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderDetail
   */
  temperatureLoggerCollectedDate?: string
  /**
   *
   * @type {Vixell}
   * @memberof ReusableOrderDetail
   */
  vixell?: Vixell
  /**
   *
   * @type {ThermalStorageUnit}
   * @memberof ReusableOrderDetail
   */
  thermalStorageUnit?: ThermalStorageUnit
  /**
   *
   * @type {TemperatureLogger}
   * @memberof ReusableOrderDetail
   */
  temperatureLogger?: TemperatureLogger
  /**
   *
   * @type {ReusableOrder}
   * @memberof ReusableOrderDetail
   */
  reusableOrder?: ReusableOrder
}
/**
 * 明細情報取得
 * @export
 * @interface ReusableOrderFetchOrderDetailRequest
 */
export interface ReusableOrderFetchOrderDetailRequest {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFetchOrderDetailRequest
   */
  type: ReusableOrderFetchOrderDetailRequestTypeEnum
  /**
   *
   * @type {ReusableOrderFetchOrderDetailRequestData}
   * @memberof ReusableOrderFetchOrderDetailRequest
   */
  data: ReusableOrderFetchOrderDetailRequestData
}

export const ReusableOrderFetchOrderDetailRequestTypeEnum = {
  ReusableOrderFetchOrderDetail: 'ReusableOrderFetchOrderDetail',
} as const

export type ReusableOrderFetchOrderDetailRequestTypeEnum =
  typeof ReusableOrderFetchOrderDetailRequestTypeEnum[keyof typeof ReusableOrderFetchOrderDetailRequestTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderFetchOrderDetailRequestData
 */
export interface ReusableOrderFetchOrderDetailRequestData {
  /**
   * <order-no>-<order-detail-no> という文字列
   * @type {string}
   * @memberof ReusableOrderFetchOrderDetailRequestData
   */
  orderAndOrderDetailNo?: string
}
/**
 * 明細情報取得
 * @export
 * @interface ReusableOrderFetchOrderDetailResponse
 */
export interface ReusableOrderFetchOrderDetailResponse {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFetchOrderDetailResponse
   */
  type: ReusableOrderFetchOrderDetailResponseTypeEnum
  /**
   *
   * @type {ReusableOrderFetchOrderDetailResponseData}
   * @memberof ReusableOrderFetchOrderDetailResponse
   */
  data: ReusableOrderFetchOrderDetailResponseData
}

export const ReusableOrderFetchOrderDetailResponseTypeEnum = {
  ReusableOrderFetchOrderDetail: 'ReusableOrderFetchOrderDetail',
} as const

export type ReusableOrderFetchOrderDetailResponseTypeEnum =
  typeof ReusableOrderFetchOrderDetailResponseTypeEnum[keyof typeof ReusableOrderFetchOrderDetailResponseTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderFetchOrderDetailResponseData
 */
export interface ReusableOrderFetchOrderDetailResponseData {
  /**
   *
   * @type {ReusableOrderDetail}
   * @memberof ReusableOrderFetchOrderDetailResponseData
   */
  orderDetail?: ReusableOrderDetail
}
/**
 *
 * @export
 * @interface ReusableOrderFormComponent
 */
export interface ReusableOrderFormComponent {
  /**
   *
   * @type {ReusableOrderState}
   * @memberof ReusableOrderFormComponent
   */
  state: ReusableOrderState
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  customerCompanyName: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  customerCompanyDivision: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  customerPhone: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  notesForDistributionCenter?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  deliveryDateToConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  deliveryDateMeridiemIndicatorToConsignor: ReusableOrderFormComponentDeliveryDateMeridiemIndicatorToConsignorEnum
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  countryOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  stateOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  addressOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  zipCodeOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  companyNameOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  divisionOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  phoneOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  deliveryDateToConsignee?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  countryOfConsignee: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  stateOfConsignee?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  addressOfConsignee?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  zipCodeOfConsignee: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  companyNameOfConsignee?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  divisionOfConsignee?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponent
   */
  phoneOfConsignee?: string
  /**
   *
   * @type {number}
   * @memberof ReusableOrderFormComponent
   */
  lenderSiteId: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderFormComponent
   */
  collectorSiteId: number
  /**
   *
   * @type {Array<ReusableOrderFormComponentReusableOrderDetails>}
   * @memberof ReusableOrderFormComponent
   */
  reusableOrderDetails: Array<ReusableOrderFormComponentReusableOrderDetails>
}

export const ReusableOrderFormComponentDeliveryDateMeridiemIndicatorToConsignorEnum =
  {
    Am: 'AM',
    Pm: 'PM',
  } as const

export type ReusableOrderFormComponentDeliveryDateMeridiemIndicatorToConsignorEnum =
  typeof ReusableOrderFormComponentDeliveryDateMeridiemIndicatorToConsignorEnum[keyof typeof ReusableOrderFormComponentDeliveryDateMeridiemIndicatorToConsignorEnum]

/**
 *
 * @export
 * @interface ReusableOrderFormComponentReusableOrderDetails
 */
export interface ReusableOrderFormComponentReusableOrderDetails {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  vixellModel: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  thermalStorageUnitModel: string
  /**
   *
   * @type {boolean}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  preCooling: boolean
  /**
   *
   * @type {boolean}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  allowSupercoolingDelivery?: boolean
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  preCoolingNotes?: string
  /**
   *
   * @type {boolean}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  hasLogger: boolean
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  loggerModel?: string
  /**
   *
   * @type {number}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  loggingInterval?: ReusableOrderFormComponentReusableOrderDetailsLoggingIntervalEnum
  /**
   *
   * @type {number}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  loggerAlertUpperTemperatureLimit?: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  loggerAlertLowerTemperatureLimit?: number
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  loggerAlertCapacity?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  loggingStartReservationTime?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  notesForDelivery?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  loggerFilePath?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  vixellLendingDate?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  vixellCollectedDate?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  thermalStorageUnitLendingDate?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  thermalStorageUnitCollectedDate?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  temperatureLoggerLendingDate?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderFormComponentReusableOrderDetails
   */
  temperatureLoggerCollectedDate?: string
}

export const ReusableOrderFormComponentReusableOrderDetailsLoggingIntervalEnum =
  {
    NUMBER_15: 15,
    NUMBER_30: 30,
    NUMBER_45: 45,
    NUMBER_60: 60,
  } as const

export type ReusableOrderFormComponentReusableOrderDetailsLoggingIntervalEnum =
  typeof ReusableOrderFormComponentReusableOrderDetailsLoggingIntervalEnum[keyof typeof ReusableOrderFormComponentReusableOrderDetailsLoggingIntervalEnum]

/**
 *
 * @export
 * @interface ReusableOrderIndexRequest
 */
export interface ReusableOrderIndexRequest {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexRequest
   */
  type: ReusableOrderIndexRequestTypeEnum
  /**
   *
   * @type {ReusableOrderIndexRequestComponent}
   * @memberof ReusableOrderIndexRequest
   */
  data: ReusableOrderIndexRequestComponent
}

export const ReusableOrderIndexRequestTypeEnum = {
  ReusableOrderIndex: 'ReusableOrderIndex',
} as const

export type ReusableOrderIndexRequestTypeEnum =
  typeof ReusableOrderIndexRequestTypeEnum[keyof typeof ReusableOrderIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderIndexRequestComponent
 */
export interface ReusableOrderIndexRequestComponent {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexRequestComponent
   */
  orderBy?: ReusableOrderIndexRequestComponentOrderByEnum
  /**
   *
   * @type {Array<ReusableOrderSearchOrderStatus>}
   * @memberof ReusableOrderIndexRequestComponent
   */
  orderStatus?: Array<ReusableOrderSearchOrderStatus>
  /**
   *
   * @type {Array<ReusableOrderSearchShippingStatus>}
   * @memberof ReusableOrderIndexRequestComponent
   */
  shippingStatus?: Array<ReusableOrderSearchShippingStatus>
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexRequestComponent
   */
  deliveryDateToConsignorFrom?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexRequestComponent
   */
  deliveryDateToConsignorTill?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexRequestComponent
   */
  deliveryDateToConsigneeFrom?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexRequestComponent
   */
  deliveryDateToConsigneeTill?: string
  /**
   *
   * @type {Array<Box>}
   * @memberof ReusableOrderIndexRequestComponent
   */
  box?: Array<Box>
  /**
   *
   * @type {Array<Unit>}
   * @memberof ReusableOrderIndexRequestComponent
   */
  unit?: Array<Unit>
  /**
   *
   * @type {Array<boolean>}
   * @memberof ReusableOrderIndexRequestComponent
   */
  preCooling?: Array<boolean>
  /**
   *
   * @type {Array<boolean>}
   * @memberof ReusableOrderIndexRequestComponent
   */
  hasLogger?: Array<boolean>
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexRequestComponent
   */
  query?: string
  /**
   *
   * @type {number}
   * @memberof ReusableOrderIndexRequestComponent
   */
  page?: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderIndexRequestComponent
   */
  per?: number
}

export const ReusableOrderIndexRequestComponentOrderByEnum = {
  OrderNumber: 'orderNumber',
  DeliveryDateToConsignor: 'deliveryDateToConsignor',
  DeliveryDateToConsignee: 'deliveryDateToConsignee',
} as const

export type ReusableOrderIndexRequestComponentOrderByEnum =
  typeof ReusableOrderIndexRequestComponentOrderByEnum[keyof typeof ReusableOrderIndexRequestComponentOrderByEnum]

/**
 *
 * @export
 * @interface ReusableOrderIndexResponse
 */
export interface ReusableOrderIndexResponse {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponse
   */
  type: ReusableOrderIndexResponseTypeEnum
  /**
   *
   * @type {ReusableOrderIndexResponseData}
   * @memberof ReusableOrderIndexResponse
   */
  data: ReusableOrderIndexResponseData
}

export const ReusableOrderIndexResponseTypeEnum = {
  ReusableOrderIndex: 'ReusableOrderIndex',
} as const

export type ReusableOrderIndexResponseTypeEnum =
  typeof ReusableOrderIndexResponseTypeEnum[keyof typeof ReusableOrderIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderIndexResponseComponent
 */
export interface ReusableOrderIndexResponseComponent {
  /**
   *
   * @type {number}
   * @memberof ReusableOrderIndexResponseComponent
   */
  id: number
  /**
   *
   * @type {ReusableOrderState}
   * @memberof ReusableOrderIndexResponseComponent
   */
  state: ReusableOrderState
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  customerCompanyName: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  customerCompanyDivision: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  customerPhone: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  notesForDistributionCenter?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  deliveryDateToConsignor: string
  /**
   *
   * @type {MeridiemIndicator}
   * @memberof ReusableOrderIndexResponseComponent
   */
  deliveryDateMeridiemIndicatorToConsignor: MeridiemIndicator
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  countryOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  stateOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  addressOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  zipCodeOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  companyNameOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  divisionOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  phoneOfConsignor: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  notesForConsignor?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  deliveryDateToConsignee?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  countryOfConsignee: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  stateOfConsignee?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  addressOfConsignee?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  zipCodeOfConsignee: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  companyNameOfConsignee?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  divisionOfConsignee?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  phoneOfConsignee?: string
  /**
   *
   * @type {number}
   * @memberof ReusableOrderIndexResponseComponent
   */
  lenderSiteId: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderIndexResponseComponent
   */
  collectorSiteId: number
  /**
   *
   * @type {Site}
   * @memberof ReusableOrderIndexResponseComponent
   */
  lenderSite: Site
  /**
   *
   * @type {Site}
   * @memberof ReusableOrderIndexResponseComponent
   */
  collectorSite: Site
  /**
   *
   * @type {Array<ReusableOrderDetail>}
   * @memberof ReusableOrderIndexResponseComponent
   */
  reusableOrderDetails: Array<ReusableOrderDetail>
  /**
   *
   * @type {number}
   * @memberof ReusableOrderIndexResponseComponent
   */
  detailCount: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderIndexResponseComponent
   */
  lendCount: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderIndexResponseComponent
   */
  collectedCount: number
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  vixellModel: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  thermalStorageUnitModel: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponent
   */
  temperatureLoggerModel?: string
  /**
   *
   * @type {boolean}
   * @memberof ReusableOrderIndexResponseComponent
   */
  hasLogger: boolean
  /**
   *
   * @type {boolean}
   * @memberof ReusableOrderIndexResponseComponent
   */
  preCooling?: boolean
}
/**
 *
 * @export
 * @interface ReusableOrderIndexResponseComponentAllOf
 */
export interface ReusableOrderIndexResponseComponentAllOf {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponentAllOf
   */
  vixellModel: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponentAllOf
   */
  thermalStorageUnitModel: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderIndexResponseComponentAllOf
   */
  temperatureLoggerModel?: string
  /**
   *
   * @type {boolean}
   * @memberof ReusableOrderIndexResponseComponentAllOf
   */
  hasLogger: boolean
  /**
   *
   * @type {boolean}
   * @memberof ReusableOrderIndexResponseComponentAllOf
   */
  preCooling?: boolean
  /**
   *
   * @type {number}
   * @memberof ReusableOrderIndexResponseComponentAllOf
   */
  detailCount: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderIndexResponseComponentAllOf
   */
  lendCount: number
  /**
   *
   * @type {number}
   * @memberof ReusableOrderIndexResponseComponentAllOf
   */
  collectedCount: number
}
/**
 *
 * @export
 * @interface ReusableOrderIndexResponseData
 */
export interface ReusableOrderIndexResponseData {
  /**
   *
   * @type {Array<ReusableOrderIndexResponseComponent>}
   * @memberof ReusableOrderIndexResponseData
   */
  reusableOrders: Array<ReusableOrderIndexResponseComponent>
  /**
   *
   * @type {number}
   * @memberof ReusableOrderIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof ReusableOrderIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 * 貸出
 * @export
 * @interface ReusableOrderLendingRequest
 */
export interface ReusableOrderLendingRequest {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderLendingRequest
   */
  type: ReusableOrderLendingRequestTypeEnum
  /**
   *
   * @type {ReusableOrderLendingRequestData}
   * @memberof ReusableOrderLendingRequest
   */
  data: ReusableOrderLendingRequestData
}

export const ReusableOrderLendingRequestTypeEnum = {
  ReusableOrderLending: 'ReusableOrderLending',
} as const

export type ReusableOrderLendingRequestTypeEnum =
  typeof ReusableOrderLendingRequestTypeEnum[keyof typeof ReusableOrderLendingRequestTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderLendingRequestData
 */
export interface ReusableOrderLendingRequestData {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderLendingRequestData
   */
  orderAndOrderDetailNo?: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderLendingRequestData
   */
  vixellSerialNo: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderLendingRequestData
   */
  thermalStorageUnitSerialNo: string
  /**
   *
   * @type {string}
   * @memberof ReusableOrderLendingRequestData
   */
  temperatureLoggerSerialNo?: string
}
/**
 * 貸出
 * @export
 * @interface ReusableOrderLendingResponse
 */
export interface ReusableOrderLendingResponse {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderLendingResponse
   */
  type: ReusableOrderLendingResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof ReusableOrderLendingResponse
   */
  data: SuccessResponseData
}

export const ReusableOrderLendingResponseTypeEnum = {
  ReusableOrderLending: 'ReusableOrderLending',
} as const

export type ReusableOrderLendingResponseTypeEnum =
  typeof ReusableOrderLendingResponseTypeEnum[keyof typeof ReusableOrderLendingResponseTypeEnum]

/**
 * 紛失
 * @export
 * @interface ReusableOrderLostItemRequest
 */
export interface ReusableOrderLostItemRequest {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderLostItemRequest
   */
  type: ReusableOrderLostItemRequestTypeEnum
  /**
   *
   * @type {ReusableOrderLostItemRequestData}
   * @memberof ReusableOrderLostItemRequest
   */
  data: ReusableOrderLostItemRequestData
}

export const ReusableOrderLostItemRequestTypeEnum = {
  ReusableOrderLostItem: 'ReusableOrderLostItem',
} as const

export type ReusableOrderLostItemRequestTypeEnum =
  typeof ReusableOrderLostItemRequestTypeEnum[keyof typeof ReusableOrderLostItemRequestTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderLostItemRequestData
 */
export interface ReusableOrderLostItemRequestData {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderLostItemRequestData
   */
  serialNo?: string
}
/**
 * 紛失
 * @export
 * @interface ReusableOrderLostItemResponse
 */
export interface ReusableOrderLostItemResponse {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderLostItemResponse
   */
  type: ReusableOrderLostItemResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof ReusableOrderLostItemResponse
   */
  data: SuccessResponseData
}

export const ReusableOrderLostItemResponseTypeEnum = {
  ReusableOrderLostItem: 'ReusableOrderLostItem',
} as const

export type ReusableOrderLostItemResponseTypeEnum =
  typeof ReusableOrderLostItemResponseTypeEnum[keyof typeof ReusableOrderLostItemResponseTypeEnum]

/**
 * 明細情報削除
 * @export
 * @interface ReusableOrderResetOrderDetailRequest
 */
export interface ReusableOrderResetOrderDetailRequest {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderResetOrderDetailRequest
   */
  type: ReusableOrderResetOrderDetailRequestTypeEnum
  /**
   *
   * @type {ReusableOrderFetchOrderDetailRequestData}
   * @memberof ReusableOrderResetOrderDetailRequest
   */
  data: ReusableOrderFetchOrderDetailRequestData
}

export const ReusableOrderResetOrderDetailRequestTypeEnum = {
  ReusableOrderResetOrderDetail: 'ReusableOrderResetOrderDetail',
} as const

export type ReusableOrderResetOrderDetailRequestTypeEnum =
  typeof ReusableOrderResetOrderDetailRequestTypeEnum[keyof typeof ReusableOrderResetOrderDetailRequestTypeEnum]

/**
 * 明細情報削除
 * @export
 * @interface ReusableOrderResetOrderDetailResponse
 */
export interface ReusableOrderResetOrderDetailResponse {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderResetOrderDetailResponse
   */
  type: ReusableOrderResetOrderDetailResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof ReusableOrderResetOrderDetailResponse
   */
  data: SuccessResponseData
}

export const ReusableOrderResetOrderDetailResponseTypeEnum = {
  ReusableOrderResetOrderDetail: 'ReusableOrderResetOrderDetail',
} as const

export type ReusableOrderResetOrderDetailResponseTypeEnum =
  typeof ReusableOrderResetOrderDetailResponseTypeEnum[keyof typeof ReusableOrderResetOrderDetailResponseTypeEnum]

/**
 * 返却
 * @export
 * @interface ReusableOrderReturnRequest
 */
export interface ReusableOrderReturnRequest {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderReturnRequest
   */
  type: ReusableOrderReturnRequestTypeEnum
  /**
   *
   * @type {ReusableOrderStockRequestData}
   * @memberof ReusableOrderReturnRequest
   */
  data: ReusableOrderStockRequestData
}

export const ReusableOrderReturnRequestTypeEnum = {
  ReusableOrderReturn: 'ReusableOrderReturn',
} as const

export type ReusableOrderReturnRequestTypeEnum =
  typeof ReusableOrderReturnRequestTypeEnum[keyof typeof ReusableOrderReturnRequestTypeEnum]

/**
 * 返却
 * @export
 * @interface ReusableOrderReturnResponse
 */
export interface ReusableOrderReturnResponse {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderReturnResponse
   */
  type: ReusableOrderReturnResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof ReusableOrderReturnResponse
   */
  data: SuccessResponseData
}

export const ReusableOrderReturnResponseTypeEnum = {
  ReusableOrderReturn: 'ReusableOrderReturn',
} as const

export type ReusableOrderReturnResponseTypeEnum =
  typeof ReusableOrderReturnResponseTypeEnum[keyof typeof ReusableOrderReturnResponseTypeEnum]

/**
 *
 * @export
 * @enum {string}
 */

export const ReusableOrderSearchOrderStatus = {
  Cancelled: 'CANCELLED',
  Confirmed: 'CONFIRMED',
  NonConfirmed: 'NON_CONFIRMED',
} as const

export type ReusableOrderSearchOrderStatus =
  typeof ReusableOrderSearchOrderStatus[keyof typeof ReusableOrderSearchOrderStatus]

/**
 *
 * @export
 * @enum {string}
 */

export const ReusableOrderSearchShippingStatus = {
  NonShipped: 'NON_SHIPPED',
  Shipped: 'SHIPPED',
  Returned: 'RETURNED',
} as const

export type ReusableOrderSearchShippingStatus =
  typeof ReusableOrderSearchShippingStatus[keyof typeof ReusableOrderSearchShippingStatus]

/**
 * 出庫
 * @export
 * @interface ReusableOrderShipRequest
 */
export interface ReusableOrderShipRequest {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderShipRequest
   */
  type: ReusableOrderShipRequestTypeEnum
  /**
   *
   * @type {ReusableOrderStockRequestData}
   * @memberof ReusableOrderShipRequest
   */
  data: ReusableOrderStockRequestData
}

export const ReusableOrderShipRequestTypeEnum = {
  ReusableOrderShip: 'ReusableOrderShip',
} as const

export type ReusableOrderShipRequestTypeEnum =
  typeof ReusableOrderShipRequestTypeEnum[keyof typeof ReusableOrderShipRequestTypeEnum]

/**
 * 出庫
 * @export
 * @interface ReusableOrderShipResponse
 */
export interface ReusableOrderShipResponse {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderShipResponse
   */
  type: ReusableOrderShipResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof ReusableOrderShipResponse
   */
  data: SuccessResponseData
}

export const ReusableOrderShipResponseTypeEnum = {
  ReusableOrderShip: 'ReusableOrderShip',
} as const

export type ReusableOrderShipResponseTypeEnum =
  typeof ReusableOrderShipResponseTypeEnum[keyof typeof ReusableOrderShipResponseTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderShowRequest
 */
export interface ReusableOrderShowRequest {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderShowRequest
   */
  type: ReusableOrderShowRequestTypeEnum
  /**
   *
   * @type {ReusableOrderShowRequestData}
   * @memberof ReusableOrderShowRequest
   */
  data: ReusableOrderShowRequestData
}

export const ReusableOrderShowRequestTypeEnum = {
  ReusableOrderShow: 'ReusableOrderShow',
} as const

export type ReusableOrderShowRequestTypeEnum =
  typeof ReusableOrderShowRequestTypeEnum[keyof typeof ReusableOrderShowRequestTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderShowRequestData
 */
export interface ReusableOrderShowRequestData {
  /**
   *
   * @type {number}
   * @memberof ReusableOrderShowRequestData
   */
  id: number
}
/**
 *
 * @export
 * @interface ReusableOrderShowResponse
 */
export interface ReusableOrderShowResponse {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderShowResponse
   */
  type: ReusableOrderShowResponseTypeEnum
  /**
   *
   * @type {ReusableOrder}
   * @memberof ReusableOrderShowResponse
   */
  data: ReusableOrder
}

export const ReusableOrderShowResponseTypeEnum = {
  ReusableOrderShow: 'ReusableOrderShow',
} as const

export type ReusableOrderShowResponseTypeEnum =
  typeof ReusableOrderShowResponseTypeEnum[keyof typeof ReusableOrderShowResponseTypeEnum]

/**
 *
 * @export
 * @enum {string}
 */

export const ReusableOrderState = {
  Cancelled: 'CANCELLED',
  Confirmed: 'CONFIRMED',
  NonConfirmed: 'NON_CONFIRMED',
} as const

export type ReusableOrderState =
  typeof ReusableOrderState[keyof typeof ReusableOrderState]

/**
 *
 * @export
 * @interface ReusableOrderStateLog
 */
export interface ReusableOrderStateLog {
  /**
   *
   * @type {number}
   * @memberof ReusableOrderStateLog
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof ReusableOrderStateLog
   */
  processingDateTime: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof ReusableOrderStateLog
   */
  state: ShippingStateValue
  /**
   *
   * @type {ReusableOrder}
   * @memberof ReusableOrderStateLog
   */
  reusableOrder?: ReusableOrder
  /**
   *
   * @type {ReusableOrderDetail}
   * @memberof ReusableOrderStateLog
   */
  reusableOrderDetail?: ReusableOrderDetail
  /**
   *
   * @type {string}
   * @memberof ReusableOrderStateLog
   */
  productType?: ReusableOrderStateLogProductTypeEnum
  /**
   *
   * @type {VixellProductData | ThermalStorageUnitProductData | TemperatureLoggerProductData}
   * @memberof ReusableOrderStateLog
   */
  product?:
    | VixellProductData
    | ThermalStorageUnitProductData
    | TemperatureLoggerProductData
}

export const ReusableOrderStateLogProductTypeEnum = {
  Vixell: 'VIXELL',
  ThermalStorageUnit: 'THERMAL_STORAGE_UNIT',
  TemperatureLogger: 'TEMPERATURE_LOGGER',
} as const

export type ReusableOrderStateLogProductTypeEnum =
  typeof ReusableOrderStateLogProductTypeEnum[keyof typeof ReusableOrderStateLogProductTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderStatesIndexRequest
 */
export interface ReusableOrderStatesIndexRequest {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderStatesIndexRequest
   */
  type: ReusableOrderStatesIndexRequestTypeEnum
  /**
   *
   * @type {ProductNotesIndexRequestData}
   * @memberof ReusableOrderStatesIndexRequest
   */
  data: ProductNotesIndexRequestData
}

export const ReusableOrderStatesIndexRequestTypeEnum = {
  ReusableOrderStatesIndex: 'ReusableOrderStatesIndex',
} as const

export type ReusableOrderStatesIndexRequestTypeEnum =
  typeof ReusableOrderStatesIndexRequestTypeEnum[keyof typeof ReusableOrderStatesIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderStatesIndexResponse
 */
export interface ReusableOrderStatesIndexResponse {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderStatesIndexResponse
   */
  type: ReusableOrderStatesIndexResponseTypeEnum
  /**
   *
   * @type {ReusableOrderStatesIndexResponseData}
   * @memberof ReusableOrderStatesIndexResponse
   */
  data: ReusableOrderStatesIndexResponseData
}

export const ReusableOrderStatesIndexResponseTypeEnum = {
  ReusableOrderStatesIndex: 'ReusableOrderStatesIndex',
} as const

export type ReusableOrderStatesIndexResponseTypeEnum =
  typeof ReusableOrderStatesIndexResponseTypeEnum[keyof typeof ReusableOrderStatesIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderStatesIndexResponseData
 */
export interface ReusableOrderStatesIndexResponseData {
  /**
   *
   * @type {Array<ReusableOrderStateLog>}
   * @memberof ReusableOrderStatesIndexResponseData
   */
  reusableOrderStates: Array<ReusableOrderStateLog>
  /**
   *
   * @type {number}
   * @memberof ReusableOrderStatesIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof ReusableOrderStatesIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 * 入庫
 * @export
 * @interface ReusableOrderStockRequest
 */
export interface ReusableOrderStockRequest {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderStockRequest
   */
  type: ReusableOrderStockRequestTypeEnum
  /**
   *
   * @type {ReusableOrderStockRequestData}
   * @memberof ReusableOrderStockRequest
   */
  data: ReusableOrderStockRequestData
}

export const ReusableOrderStockRequestTypeEnum = {
  ReusableOrderStock: 'ReusableOrderStock',
} as const

export type ReusableOrderStockRequestTypeEnum =
  typeof ReusableOrderStockRequestTypeEnum[keyof typeof ReusableOrderStockRequestTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderStockRequestData
 */
export interface ReusableOrderStockRequestData {
  /**
   *
   * @type {Array<string>}
   * @memberof ReusableOrderStockRequestData
   */
  serialNos?: Array<string>
}
/**
 * 入庫
 * @export
 * @interface ReusableOrderStockResponse
 */
export interface ReusableOrderStockResponse {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderStockResponse
   */
  type: ReusableOrderStockResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof ReusableOrderStockResponse
   */
  data: SuccessResponseData
}

export const ReusableOrderStockResponseTypeEnum = {
  ReusableOrderStock: 'ReusableOrderStock',
} as const

export type ReusableOrderStockResponseTypeEnum =
  typeof ReusableOrderStockResponseTypeEnum[keyof typeof ReusableOrderStockResponseTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderUpdateRequest
 */
export interface ReusableOrderUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderUpdateRequest
   */
  type: ReusableOrderUpdateRequestTypeEnum
  /**
   *
   * @type {ReusableOrderFormComponent & ReusableOrderUpdateRequestComponent}
   * @memberof ReusableOrderUpdateRequest
   */
  data: ReusableOrderFormComponent & ReusableOrderUpdateRequestComponent
}

export const ReusableOrderUpdateRequestTypeEnum = {
  ReusableOrderUpdate: 'ReusableOrderUpdate',
} as const

export type ReusableOrderUpdateRequestTypeEnum =
  typeof ReusableOrderUpdateRequestTypeEnum[keyof typeof ReusableOrderUpdateRequestTypeEnum]

/**
 *
 * @export
 * @interface ReusableOrderUpdateRequestComponent
 */
export interface ReusableOrderUpdateRequestComponent {
  /**
   *
   * @type {number}
   * @memberof ReusableOrderUpdateRequestComponent
   */
  id: number
}
/**
 *
 * @export
 * @interface ReusableOrderUpdateResponse
 */
export interface ReusableOrderUpdateResponse {
  /**
   *
   * @type {string}
   * @memberof ReusableOrderUpdateResponse
   */
  type: ReusableOrderUpdateResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof ReusableOrderUpdateResponse
   */
  data: SuccessResponseData
}

export const ReusableOrderUpdateResponseTypeEnum = {
  ReusableOrderUpdate: 'ReusableOrderUpdate',
} as const

export type ReusableOrderUpdateResponseTypeEnum =
  typeof ReusableOrderUpdateResponseTypeEnum[keyof typeof ReusableOrderUpdateResponseTypeEnum]

/**
 *
 * @export
 * @interface ReusableThermalStorageUnitDispose
 */
export interface ReusableThermalStorageUnitDispose {
  /**
   *
   * @type {string}
   * @memberof ReusableThermalStorageUnitDispose
   */
  app: ReusableThermalStorageUnitDisposeAppEnum
  /**
   *
   * @type {string}
   * @memberof ReusableThermalStorageUnitDispose
   */
  logType: ReusableThermalStorageUnitDisposeLogTypeEnum
  /**
   *
   * @type {CommonData & CommonThermalStorageUnit}
   * @memberof ReusableThermalStorageUnitDispose
   */
  data: CommonData & CommonThermalStorageUnit
}

export const ReusableThermalStorageUnitDisposeAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type ReusableThermalStorageUnitDisposeAppEnum =
  typeof ReusableThermalStorageUnitDisposeAppEnum[keyof typeof ReusableThermalStorageUnitDisposeAppEnum]
export const ReusableThermalStorageUnitDisposeLogTypeEnum = {
  ReusableThermalStorageUnitDispose: 'reusableThermalStorageUnitDispose',
} as const

export type ReusableThermalStorageUnitDisposeLogTypeEnum =
  typeof ReusableThermalStorageUnitDisposeLogTypeEnum[keyof typeof ReusableThermalStorageUnitDisposeLogTypeEnum]

/**
 *
 * @export
 * @interface ReusableThermalStorageUnitReturn
 */
export interface ReusableThermalStorageUnitReturn {
  /**
   *
   * @type {string}
   * @memberof ReusableThermalStorageUnitReturn
   */
  app: ReusableThermalStorageUnitReturnAppEnum
  /**
   *
   * @type {string}
   * @memberof ReusableThermalStorageUnitReturn
   */
  logType: ReusableThermalStorageUnitReturnLogTypeEnum
  /**
   *
   * @type {CommonData & CommonThermalStorageUnit}
   * @memberof ReusableThermalStorageUnitReturn
   */
  data: CommonData & CommonThermalStorageUnit
}

export const ReusableThermalStorageUnitReturnAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type ReusableThermalStorageUnitReturnAppEnum =
  typeof ReusableThermalStorageUnitReturnAppEnum[keyof typeof ReusableThermalStorageUnitReturnAppEnum]
export const ReusableThermalStorageUnitReturnLogTypeEnum = {
  ReusableThermalStorageUnitReturn: 'reusableThermalStorageUnitReturn',
} as const

export type ReusableThermalStorageUnitReturnLogTypeEnum =
  typeof ReusableThermalStorageUnitReturnLogTypeEnum[keyof typeof ReusableThermalStorageUnitReturnLogTypeEnum]

/**
 *
 * @export
 * @interface ReusableThermalStorageUnitShipping
 */
export interface ReusableThermalStorageUnitShipping {
  /**
   *
   * @type {string}
   * @memberof ReusableThermalStorageUnitShipping
   */
  app: ReusableThermalStorageUnitShippingAppEnum
  /**
   *
   * @type {string}
   * @memberof ReusableThermalStorageUnitShipping
   */
  logType: ReusableThermalStorageUnitShippingLogTypeEnum
  /**
   *
   * @type {CommonData & ShippingManagementCommonInfo & ReusableContractInfo & CommonThermalStorageUnit}
   * @memberof ReusableThermalStorageUnitShipping
   */
  data: CommonData &
    ShippingManagementCommonInfo &
    ReusableContractInfo &
    CommonThermalStorageUnit
}

export const ReusableThermalStorageUnitShippingAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type ReusableThermalStorageUnitShippingAppEnum =
  typeof ReusableThermalStorageUnitShippingAppEnum[keyof typeof ReusableThermalStorageUnitShippingAppEnum]
export const ReusableThermalStorageUnitShippingLogTypeEnum = {
  ReusableThermalStorageUnitShipping: 'reusableThermalStorageUnitShipping',
} as const

export type ReusableThermalStorageUnitShippingLogTypeEnum =
  typeof ReusableThermalStorageUnitShippingLogTypeEnum[keyof typeof ReusableThermalStorageUnitShippingLogTypeEnum]

/**
 * シリアルナンバーからVixellなどを探す
 * @export
 * @interface SearchWithSerialNoRequest
 */
export interface SearchWithSerialNoRequest {
  /**
   *
   * @type {string}
   * @memberof SearchWithSerialNoRequest
   */
  type: SearchWithSerialNoRequestTypeEnum
  /**
   *
   * @type {SearchWithSerialNoRequestData}
   * @memberof SearchWithSerialNoRequest
   */
  data: SearchWithSerialNoRequestData
}

export const SearchWithSerialNoRequestTypeEnum = {
  SearchWithSerialNo: 'SearchWithSerialNo',
} as const

export type SearchWithSerialNoRequestTypeEnum =
  typeof SearchWithSerialNoRequestTypeEnum[keyof typeof SearchWithSerialNoRequestTypeEnum]

/**
 *
 * @export
 * @interface SearchWithSerialNoRequestData
 */
export interface SearchWithSerialNoRequestData {
  /**
   *
   * @type {string}
   * @memberof SearchWithSerialNoRequestData
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof SearchWithSerialNoRequestData
   */
  serialNo: string
}
/**
 * シリアルナンバーからVixellなどを探す
 * @export
 * @interface SearchWithSerialNoResponse
 */
export interface SearchWithSerialNoResponse {
  /**
   *
   * @type {string}
   * @memberof SearchWithSerialNoResponse
   */
  type: SearchWithSerialNoResponseTypeEnum
  /**
   *
   * @type {SearchWithSerialNoResponseComponent}
   * @memberof SearchWithSerialNoResponse
   */
  data: SearchWithSerialNoResponseComponent
}

export const SearchWithSerialNoResponseTypeEnum = {
  SearchWithSerialNo: 'SearchWithSerialNo',
} as const

export type SearchWithSerialNoResponseTypeEnum =
  typeof SearchWithSerialNoResponseTypeEnum[keyof typeof SearchWithSerialNoResponseTypeEnum]

/**
 * @type SearchWithSerialNoResponseComponent
 * @export
 */
export type SearchWithSerialNoResponseComponent =
  | SerialNoResponseTemperatureLogger
  | SerialNoResponseThermalStorageUnit
  | SerialNoResponseVixell

/**
 *
 * @export
 * @interface SerialNoResponseTemperatureLogger
 */
export interface SerialNoResponseTemperatureLogger {
  /**
   *
   * @type {string}
   * @memberof SerialNoResponseTemperatureLogger
   */
  type: SerialNoResponseTemperatureLoggerTypeEnum
  /**
   *
   * @type {TemperatureLogger}
   * @memberof SerialNoResponseTemperatureLogger
   */
  data: TemperatureLogger
}

export const SerialNoResponseTemperatureLoggerTypeEnum = {
  TemperatureLogger: 'TEMPERATURE_LOGGER',
} as const

export type SerialNoResponseTemperatureLoggerTypeEnum =
  typeof SerialNoResponseTemperatureLoggerTypeEnum[keyof typeof SerialNoResponseTemperatureLoggerTypeEnum]

/**
 *
 * @export
 * @interface SerialNoResponseThermalStorageUnit
 */
export interface SerialNoResponseThermalStorageUnit {
  /**
   *
   * @type {string}
   * @memberof SerialNoResponseThermalStorageUnit
   */
  type: SerialNoResponseThermalStorageUnitTypeEnum
  /**
   *
   * @type {ThermalStorageUnit}
   * @memberof SerialNoResponseThermalStorageUnit
   */
  data: ThermalStorageUnit
}

export const SerialNoResponseThermalStorageUnitTypeEnum = {
  ThermalStorageUnit: 'THERMAL_STORAGE_UNIT',
} as const

export type SerialNoResponseThermalStorageUnitTypeEnum =
  typeof SerialNoResponseThermalStorageUnitTypeEnum[keyof typeof SerialNoResponseThermalStorageUnitTypeEnum]

/**
 *
 * @export
 * @interface SerialNoResponseVixell
 */
export interface SerialNoResponseVixell {
  /**
   *
   * @type {string}
   * @memberof SerialNoResponseVixell
   */
  type: SerialNoResponseVixellTypeEnum
  /**
   *
   * @type {Vixell}
   * @memberof SerialNoResponseVixell
   */
  data: Vixell
}

export const SerialNoResponseVixellTypeEnum = {
  Vixell: 'VIXELL',
} as const

export type SerialNoResponseVixellTypeEnum =
  typeof SerialNoResponseVixellTypeEnum[keyof typeof SerialNoResponseVixellTypeEnum]

/**
 *
 * @export
 * @interface SetSiteId
 */
export interface SetSiteId {
  /**
   *
   * @type {string}
   * @memberof SetSiteId
   */
  siteId: string
}
/**
 * 出荷返却アプリで共通する項目
 * @export
 * @interface ShippingManagementCommonInfo
 */
export interface ShippingManagementCommonInfo {
  /**
   * 契約代表No
   * @type {string}
   * @memberof ShippingManagementCommonInfo
   */
  contractRepresentativeNo: string
  /**
   * 取引区分 半角英数字記号で以下を意味する区分が入る。 物販/リース(他社資産)/リース(BDC資産)/サンプル(BDC資産)/寄贈
   * @type {string}
   * @memberof ShippingManagementCommonInfo
   */
  tradeType: string
  /**
   * 顧客ID(自由入力（バーコードorキーボード）)
   * @type {string}
   * @memberof ShippingManagementCommonInfo
   */
  customerId: string
  /**
   * 仕向地(自由入力（バーコードorキーボード）)
   * @type {string}
   * @memberof ShippingManagementCommonInfo
   */
  destination: string
}
/**
 *
 * @export
 * @interface ShippingStateLast
 */
export interface ShippingStateLast {
  /**
   *
   * @type {string}
   * @memberof ShippingStateLast
   */
  processingDateTime: string
  /**
   *
   * @type {ShippingStateValueLast}
   * @memberof ShippingStateLast
   */
  state: ShippingStateValueLast
  /**
   *
   * @type {ContractWithManagementNo | ContractOnlyRepresentative}
   * @memberof ShippingStateLast
   */
  contractInfo?: ContractWithManagementNo | ContractOnlyRepresentative
}
/**
 *
 * @export
 * @interface ShippingStateLog
 */
export interface ShippingStateLog {
  /**
   *
   * @type {number}
   * @memberof ShippingStateLog
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof ShippingStateLog
   */
  processingDateTime: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof ShippingStateLog
   */
  state: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof ShippingStateLog
   */
  contractNo?: string
  /**
   *
   * @type {string}
   * @memberof ShippingStateLog
   */
  managementNo?: string
  /**
   *
   * @type {Trade}
   * @memberof ShippingStateLog
   */
  trade?: Trade
  /**
   *
   * @type {VixellProductData | IcePackProductData | InspectionBoardProductData | ThermalStorageUnitProductData | TemperatureLoggerProductData}
   * @memberof ShippingStateLog
   */
  product?:
    | VixellProductData
    | IcePackProductData
    | InspectionBoardProductData
    | ThermalStorageUnitProductData
    | TemperatureLoggerProductData
}
/**
 *
 * @export
 * @interface ShippingStateLogsIndexRequest
 */
export interface ShippingStateLogsIndexRequest {
  /**
   *
   * @type {string}
   * @memberof ShippingStateLogsIndexRequest
   */
  type: ShippingStateLogsIndexRequestTypeEnum
  /**
   *
   * @type {ShippingStateLogsIndexRequestData}
   * @memberof ShippingStateLogsIndexRequest
   */
  data: ShippingStateLogsIndexRequestData
}

export const ShippingStateLogsIndexRequestTypeEnum = {
  ShippingStateLogsIndex: 'ShippingStateLogsIndex',
} as const

export type ShippingStateLogsIndexRequestTypeEnum =
  typeof ShippingStateLogsIndexRequestTypeEnum[keyof typeof ShippingStateLogsIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface ShippingStateLogsIndexRequestData
 */
export interface ShippingStateLogsIndexRequestData {
  /**
   *
   * @type {ProductType}
   * @memberof ShippingStateLogsIndexRequestData
   */
  productType: ProductType
  /**
   *
   * @type {number}
   * @memberof ShippingStateLogsIndexRequestData
   */
  productId: number
  /**
   *
   * @type {number}
   * @memberof ShippingStateLogsIndexRequestData
   */
  page?: number
}
/**
 *
 * @export
 * @interface ShippingStateLogsIndexResponse
 */
export interface ShippingStateLogsIndexResponse {
  /**
   *
   * @type {string}
   * @memberof ShippingStateLogsIndexResponse
   */
  type: ShippingStateLogsIndexResponseTypeEnum
  /**
   *
   * @type {ShippingStateLogsIndexResponseData}
   * @memberof ShippingStateLogsIndexResponse
   */
  data: ShippingStateLogsIndexResponseData
}

export const ShippingStateLogsIndexResponseTypeEnum = {
  ShippingStateLogsIndex: 'ShippingStateLogsIndex',
} as const

export type ShippingStateLogsIndexResponseTypeEnum =
  typeof ShippingStateLogsIndexResponseTypeEnum[keyof typeof ShippingStateLogsIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface ShippingStateLogsIndexResponseData
 */
export interface ShippingStateLogsIndexResponseData {
  /**
   *
   * @type {Array<ShippingStateLog>}
   * @memberof ShippingStateLogsIndexResponseData
   */
  shippingStateLogs: Array<ShippingStateLog>
  /**
   *
   * @type {number}
   * @memberof ShippingStateLogsIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof ShippingStateLogsIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @enum {string}
 */

export const ShippingStateValue = {
  Stocked: 'STOCKED',
  Shipped: 'SHIPPED',
  Returned: 'RETURNED',
  Disposed: 'DISPOSED',
  Defective: 'DEFECTIVE',
  Lost: 'LOST',
  ReusableStocked: 'REUSABLE_STOCKED',
  ReusableShipped: 'REUSABLE_SHIPPED',
  ReusableLend: 'REUSABLE_LEND',
  ReusableCollected: 'REUSABLE_COLLECTED',
} as const

export type ShippingStateValue =
  typeof ShippingStateValue[keyof typeof ShippingStateValue]

/**
 *
 * @export
 * @enum {string}
 */

export const ShippingStateValueLast = {
  Stocked: 'STOCKED',
  Shipped: 'SHIPPED',
  Returned: 'RETURNED',
  Disposed: 'DISPOSED',
} as const

export type ShippingStateValueLast =
  typeof ShippingStateValueLast[keyof typeof ShippingStateValueLast]

/**
 *
 * @export
 * @interface ShowIcePack
 */
export interface ShowIcePack {
  /**
   *
   * @type {string}
   * @memberof ShowIcePack
   */
  temperatureSensorTagEpc: string
}
/**
 *
 * @export
 * @interface ShowIcePackResponse
 */
export interface ShowIcePackResponse {
  /**
   *
   * @type {IcePackLast}
   * @memberof ShowIcePackResponse
   */
  icePack?: IcePackLast
  /**
   *
   * @type {OnsiteIcePackLast}
   * @memberof ShowIcePackResponse
   */
  onsiteResult?: OnsiteIcePackLast
  /**
   *
   * @type {TemperatureTagCalibrationLast}
   * @memberof ShowIcePackResponse
   */
  calibrationResult?: TemperatureTagCalibrationLast
}
/**
 *
 * @export
 * @interface ShowInspectionBoard
 */
export interface ShowInspectionBoard {
  /**
   *
   * @type {string}
   * @memberof ShowInspectionBoard
   */
  inspectionBoardSerialNo: string
}
/**
 *
 * @export
 * @interface ShowOnsiteResult
 */
export interface ShowOnsiteResult {
  /**
   *
   * @type {string}
   * @memberof ShowOnsiteResult
   */
  pcId?: string
  /**
   *
   * @type {string}
   * @memberof ShowOnsiteResult
   */
  processingDateTime?: string
}
/**
 *
 * @export
 * @interface ShowTrade
 */
export interface ShowTrade {
  /**
   *
   * @type {string}
   * @memberof ShowTrade
   */
  contractRepresentativeNo: string
}
/**
 *
 * @export
 * @interface ShowVixellRequest
 */
export interface ShowVixellRequest {
  /**
   *
   * @type {string}
   * @memberof ShowVixellRequest
   */
  vixellSerialNo: string
}
/**
 *
 * @export
 * @interface ShowVixellResponse
 */
export interface ShowVixellResponse {
  /**
   *
   * @type {VixellLast}
   * @memberof ShowVixellResponse
   */
  vixell?: VixellLast
  /**
   *
   * @type {OnsiteVacuumLast}
   * @memberof ShowVixellResponse
   */
  onsiteResult?: OnsiteVacuumLast
  /**
   *
   * @type {VacuumProcessLast}
   * @memberof ShowVixellResponse
   */
  vacuumProcess?: VacuumProcessLast
}
/**
 *
 * @export
 * @interface Site
 */
export interface Site {
  /**
   *
   * @type {number}
   * @memberof Site
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof Site
   */
  siteIdentifier: string
  /**
   *
   * @type {string}
   * @memberof Site
   */
  name: string
  /**
   *
   * @type {SiteType}
   * @memberof Site
   */
  siteType: SiteType
  /**
   *
   * @type {Customer}
   * @memberof Site
   */
  customer?: Customer
}
/**
 *
 * @export
 * @interface SiteDashboardRequest
 */
export interface SiteDashboardRequest {
  /**
   *
   * @type {string}
   * @memberof SiteDashboardRequest
   */
  type: SiteDashboardRequestTypeEnum
}

export const SiteDashboardRequestTypeEnum = {
  SiteDashboard: 'SiteDashboard',
} as const

export type SiteDashboardRequestTypeEnum =
  typeof SiteDashboardRequestTypeEnum[keyof typeof SiteDashboardRequestTypeEnum]

/**
 *
 * @export
 * @interface SiteDashboardResponse
 */
export interface SiteDashboardResponse {
  /**
   *
   * @type {string}
   * @memberof SiteDashboardResponse
   */
  type: SiteDashboardResponseTypeEnum
  /**
   *
   * @type {SiteDashboardResponseData}
   * @memberof SiteDashboardResponse
   */
  data: SiteDashboardResponseData
}

export const SiteDashboardResponseTypeEnum = {
  SiteDashboard: 'SiteDashboard',
} as const

export type SiteDashboardResponseTypeEnum =
  typeof SiteDashboardResponseTypeEnum[keyof typeof SiteDashboardResponseTypeEnum]

/**
 *
 * @export
 * @interface SiteDashboardResponseData
 */
export interface SiteDashboardResponseData {
  /**
   *
   * @type {Array<DashboardForCustomer>}
   * @memberof SiteDashboardResponseData
   */
  dashboards: Array<DashboardForCustomer>
}
/**
 *
 * @export
 * @interface SiteLast
 */
export interface SiteLast {
  /**
   *
   * @type {string}
   * @memberof SiteLast
   */
  siteId: string
  /**
   *
   * @type {string}
   * @memberof SiteLast
   */
  name: string
  /**
   *
   * @type {SiteTypeLowerCaseLast}
   * @memberof SiteLast
   */
  siteType: SiteTypeLowerCaseLast
  /**
   *
   * @type {CustomerLast}
   * @memberof SiteLast
   */
  customer?: CustomerLast
}
/**
 *
 * @export
 * @enum {string}
 */

export const SiteType = {
  Admin: 'ADMIN',
  Parent: 'PARENT',
  Child: 'CHILD',
  ReusableAdmin: 'REUSABLE_ADMIN',
  ReusableContract: 'REUSABLE_CONTRACT',
  ReusableNonResident: 'REUSABLE_NON_RESIDENT',
  ReusableShipper: 'REUSABLE_SHIPPER',
  ReusableReturn: 'REUSABLE_RETURN',
} as const

export type SiteType = typeof SiteType[keyof typeof SiteType]

/**
 *
 * @export
 * @enum {string}
 */

export const SiteTypeLowerCaseLast = {
  Admin: 'ADMIN',
  Parent: 'PARENT',
  Child: 'CHILD',
} as const

export type SiteTypeLowerCaseLast =
  typeof SiteTypeLowerCaseLast[keyof typeof SiteTypeLowerCaseLast]

/**
 *
 * @export
 * @interface SitesChangePasswordRequest
 */
export interface SitesChangePasswordRequest {
  /**
   *
   * @type {string}
   * @memberof SitesChangePasswordRequest
   */
  type: SitesChangePasswordRequestTypeEnum
  /**
   *
   * @type {SitesChangePasswordRequestData}
   * @memberof SitesChangePasswordRequest
   */
  data: SitesChangePasswordRequestData
}

export const SitesChangePasswordRequestTypeEnum = {
  SitesChangePassword: 'SitesChangePassword',
} as const

export type SitesChangePasswordRequestTypeEnum =
  typeof SitesChangePasswordRequestTypeEnum[keyof typeof SitesChangePasswordRequestTypeEnum]

/**
 *
 * @export
 * @interface SitesChangePasswordRequestData
 */
export interface SitesChangePasswordRequestData {
  /**
   *
   * @type {number}
   * @memberof SitesChangePasswordRequestData
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof SitesChangePasswordRequestData
   */
  password: string
}
/**
 *
 * @export
 * @interface SitesChangePasswordResponse
 */
export interface SitesChangePasswordResponse {
  /**
   *
   * @type {string}
   * @memberof SitesChangePasswordResponse
   */
  type: SitesChangePasswordResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof SitesChangePasswordResponse
   */
  data: SuccessResponseData
}

export const SitesChangePasswordResponseTypeEnum = {
  SitesChangePassword: 'SitesChangePassword',
} as const

export type SitesChangePasswordResponseTypeEnum =
  typeof SitesChangePasswordResponseTypeEnum[keyof typeof SitesChangePasswordResponseTypeEnum]

/**
 *
 * @export
 * @interface SitesCreateAdminRequest
 */
export interface SitesCreateAdminRequest {
  /**
   *
   * @type {string}
   * @memberof SitesCreateAdminRequest
   */
  type: SitesCreateAdminRequestTypeEnum
  /**
   *
   * @type {SitesCreateAdminRequestData}
   * @memberof SitesCreateAdminRequest
   */
  data: SitesCreateAdminRequestData
}

export const SitesCreateAdminRequestTypeEnum = {
  SitesCreateAdmin: 'SitesCreateAdmin',
} as const

export type SitesCreateAdminRequestTypeEnum =
  typeof SitesCreateAdminRequestTypeEnum[keyof typeof SitesCreateAdminRequestTypeEnum]

/**
 *
 * @export
 * @interface SitesCreateAdminRequestData
 */
export interface SitesCreateAdminRequestData {
  /**
   *
   * @type {string}
   * @memberof SitesCreateAdminRequestData
   */
  siteIdentifier: string
  /**
   *
   * @type {string}
   * @memberof SitesCreateAdminRequestData
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof SitesCreateAdminRequestData
   */
  password: string
}
/**
 *
 * @export
 * @interface SitesCreateAdminResponse
 */
export interface SitesCreateAdminResponse {
  /**
   *
   * @type {string}
   * @memberof SitesCreateAdminResponse
   */
  type: SitesCreateAdminResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof SitesCreateAdminResponse
   */
  data: SuccessResponseData
}

export const SitesCreateAdminResponseTypeEnum = {
  SitesCreateAdmin: 'SitesCreateAdmin',
} as const

export type SitesCreateAdminResponseTypeEnum =
  typeof SitesCreateAdminResponseTypeEnum[keyof typeof SitesCreateAdminResponseTypeEnum]

/**
 *
 * @export
 * @interface SitesCreateRequest
 */
export interface SitesCreateRequest {
  /**
   *
   * @type {string}
   * @memberof SitesCreateRequest
   */
  type: SitesCreateRequestTypeEnum
  /**
   *
   * @type {SitesCreateAdminRequestData}
   * @memberof SitesCreateRequest
   */
  data: SitesCreateAdminRequestData
}

export const SitesCreateRequestTypeEnum = {
  SitesCreate: 'SitesCreate',
} as const

export type SitesCreateRequestTypeEnum =
  typeof SitesCreateRequestTypeEnum[keyof typeof SitesCreateRequestTypeEnum]

/**
 *
 * @export
 * @interface SitesCreateResponse
 */
export interface SitesCreateResponse {
  /**
   *
   * @type {string}
   * @memberof SitesCreateResponse
   */
  type: SitesCreateResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof SitesCreateResponse
   */
  data: SuccessResponseData
}

export const SitesCreateResponseTypeEnum = {
  SitesCreate: 'SitesCreate',
} as const

export type SitesCreateResponseTypeEnum =
  typeof SitesCreateResponseTypeEnum[keyof typeof SitesCreateResponseTypeEnum]

/**
 *
 * @export
 * @interface SitesDestroyRequest
 */
export interface SitesDestroyRequest {
  /**
   *
   * @type {string}
   * @memberof SitesDestroyRequest
   */
  type: SitesDestroyRequestTypeEnum
  /**
   *
   * @type {ReusableOrderShowRequestData}
   * @memberof SitesDestroyRequest
   */
  data: ReusableOrderShowRequestData
}

export const SitesDestroyRequestTypeEnum = {
  SitesDestroy: 'SitesDestroy',
} as const

export type SitesDestroyRequestTypeEnum =
  typeof SitesDestroyRequestTypeEnum[keyof typeof SitesDestroyRequestTypeEnum]

/**
 *
 * @export
 * @interface SitesDestroyResponse
 */
export interface SitesDestroyResponse {
  /**
   *
   * @type {string}
   * @memberof SitesDestroyResponse
   */
  type: SitesDestroyResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof SitesDestroyResponse
   */
  data: SuccessResponseData
}

export const SitesDestroyResponseTypeEnum = {
  SitesDestroy: 'SitesDestroy',
} as const

export type SitesDestroyResponseTypeEnum =
  typeof SitesDestroyResponseTypeEnum[keyof typeof SitesDestroyResponseTypeEnum]

/**
 *
 * @export
 * @interface SitesIndexRequest
 */
export interface SitesIndexRequest {
  /**
   *
   * @type {string}
   * @memberof SitesIndexRequest
   */
  type: SitesIndexRequestTypeEnum
  /**
   *
   * @type {SitesIndexRequestData}
   * @memberof SitesIndexRequest
   */
  data: SitesIndexRequestData
}

export const SitesIndexRequestTypeEnum = {
  SitesIndex: 'SitesIndex',
} as const

export type SitesIndexRequestTypeEnum =
  typeof SitesIndexRequestTypeEnum[keyof typeof SitesIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface SitesIndexRequestData
 */
export interface SitesIndexRequestData {
  /**
   *
   * @type {string}
   * @memberof SitesIndexRequestData
   */
  query?: string
  /**
   *
   * @type {number}
   * @memberof SitesIndexRequestData
   */
  page?: number
  /**
   *
   * @type {number}
   * @memberof SitesIndexRequestData
   */
  per?: number
  /**
   *
   * @type {SiteType}
   * @memberof SitesIndexRequestData
   */
  siteType?: SiteType
  /**
   *
   * @type {boolean}
   * @memberof SitesIndexRequestData
   */
  all?: boolean
}
/**
 *
 * @export
 * @interface SitesIndexResponse
 */
export interface SitesIndexResponse {
  /**
   *
   * @type {string}
   * @memberof SitesIndexResponse
   */
  type: SitesIndexResponseTypeEnum
  /**
   *
   * @type {SitesIndexResponseData}
   * @memberof SitesIndexResponse
   */
  data: SitesIndexResponseData
}

export const SitesIndexResponseTypeEnum = {
  SitesIndex: 'SitesIndex',
} as const

export type SitesIndexResponseTypeEnum =
  typeof SitesIndexResponseTypeEnum[keyof typeof SitesIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface SitesIndexResponseData
 */
export interface SitesIndexResponseData {
  /**
   *
   * @type {Array<Site>}
   * @memberof SitesIndexResponseData
   */
  sites: Array<Site>
  /**
   *
   * @type {boolean}
   * @memberof SitesIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @interface SuccessResponseData
 */
export interface SuccessResponseData {
  /**
   *
   * @type {string}
   * @memberof SuccessResponseData
   */
  message: SuccessResponseDataMessageEnum
}

export const SuccessResponseDataMessageEnum = {
  Success: 'success',
} as const

export type SuccessResponseDataMessageEnum =
  typeof SuccessResponseDataMessageEnum[keyof typeof SuccessResponseDataMessageEnum]

/**
 *
 * @export
 * @interface TemperatureLogger
 */
export interface TemperatureLogger {
  /**
   *
   * @type {number}
   * @memberof TemperatureLogger
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof TemperatureLogger
   */
  serialNo: string
  /**
   *
   * @type {string}
   * @memberof TemperatureLogger
   */
  model?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof TemperatureLogger
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof TemperatureLogger
   */
  shippingStateUpdatedDateTime?: string
}
/**
 *
 * @export
 * @interface TemperatureLoggerDispose
 */
export interface TemperatureLoggerDispose {
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerDispose
   */
  app: TemperatureLoggerDisposeAppEnum
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerDispose
   */
  logType: TemperatureLoggerDisposeLogTypeEnum
  /**
   *
   * @type {CommonData & CommonTemperatureLogger}
   * @memberof TemperatureLoggerDispose
   */
  data: CommonData & CommonTemperatureLogger
}

export const TemperatureLoggerDisposeAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type TemperatureLoggerDisposeAppEnum =
  typeof TemperatureLoggerDisposeAppEnum[keyof typeof TemperatureLoggerDisposeAppEnum]
export const TemperatureLoggerDisposeLogTypeEnum = {
  TemperatureLoggerDispose: 'temperatureLoggerDispose',
} as const

export type TemperatureLoggerDisposeLogTypeEnum =
  typeof TemperatureLoggerDisposeLogTypeEnum[keyof typeof TemperatureLoggerDisposeLogTypeEnum]

/**
 *
 * @export
 * @interface TemperatureLoggerInventory
 */
export interface TemperatureLoggerInventory {
  /**
   *
   * @type {number}
   * @memberof TemperatureLoggerInventory
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerInventory
   */
  serialNo: string
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerInventory
   */
  model?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof TemperatureLoggerInventory
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerInventory
   */
  shippingStateUpdatedDateTime?: string
  /**
   *
   * @type {boolean}
   * @memberof TemperatureLoggerInventory
   */
  settled: boolean
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerInventory
   */
  processingDateTime?: string
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerInventory
   */
  siteName?: string
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerInventory
   */
  fullContractNo?: string
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerInventory
   */
  managementNo?: string
}
/**
 *
 * @export
 * @interface TemperatureLoggerInventoryAllOf
 */
export interface TemperatureLoggerInventoryAllOf {
  /**
   *
   * @type {boolean}
   * @memberof TemperatureLoggerInventoryAllOf
   */
  settled: boolean
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerInventoryAllOf
   */
  processingDateTime?: string
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerInventoryAllOf
   */
  siteName?: string
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerInventoryAllOf
   */
  fullContractNo?: string
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerInventoryAllOf
   */
  managementNo?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof TemperatureLoggerInventoryAllOf
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerInventoryAllOf
   */
  shippingStateUpdatedDateTime?: string
}
/**
 *
 * @export
 * @interface TemperatureLoggerProductData
 */
export interface TemperatureLoggerProductData {
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerProductData
   */
  type: TemperatureLoggerProductDataTypeEnum
  /**
   *
   * @type {TemperatureLogger}
   * @memberof TemperatureLoggerProductData
   */
  product: TemperatureLogger
}

export const TemperatureLoggerProductDataTypeEnum = {
  TemperatureLogger: 'TEMPERATURE_LOGGER',
} as const

export type TemperatureLoggerProductDataTypeEnum =
  typeof TemperatureLoggerProductDataTypeEnum[keyof typeof TemperatureLoggerProductDataTypeEnum]

/**
 *
 * @export
 * @interface TemperatureLoggerReturn
 */
export interface TemperatureLoggerReturn {
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerReturn
   */
  app: TemperatureLoggerReturnAppEnum
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerReturn
   */
  logType: TemperatureLoggerReturnLogTypeEnum
  /**
   *
   * @type {CommonData & CommonTemperatureLogger}
   * @memberof TemperatureLoggerReturn
   */
  data: CommonData & CommonTemperatureLogger
}

export const TemperatureLoggerReturnAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type TemperatureLoggerReturnAppEnum =
  typeof TemperatureLoggerReturnAppEnum[keyof typeof TemperatureLoggerReturnAppEnum]
export const TemperatureLoggerReturnLogTypeEnum = {
  TemperatureLoggerReturn: 'temperatureLoggerReturn',
} as const

export type TemperatureLoggerReturnLogTypeEnum =
  typeof TemperatureLoggerReturnLogTypeEnum[keyof typeof TemperatureLoggerReturnLogTypeEnum]

/**
 *
 * @export
 * @interface TemperatureLoggerShipping
 */
export interface TemperatureLoggerShipping {
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerShipping
   */
  app: TemperatureLoggerShippingAppEnum
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggerShipping
   */
  logType: TemperatureLoggerShippingLogTypeEnum
  /**
   *
   * @type {CommonData & ShippingManagementCommonInfo & ReusableContractInfo & CommonTemperatureLogger}
   * @memberof TemperatureLoggerShipping
   */
  data: CommonData &
    ShippingManagementCommonInfo &
    ReusableContractInfo &
    CommonTemperatureLogger
}

export const TemperatureLoggerShippingAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type TemperatureLoggerShippingAppEnum =
  typeof TemperatureLoggerShippingAppEnum[keyof typeof TemperatureLoggerShippingAppEnum]
export const TemperatureLoggerShippingLogTypeEnum = {
  TemperatureLoggerShipping: 'temperatureLoggerShipping',
} as const

export type TemperatureLoggerShippingLogTypeEnum =
  typeof TemperatureLoggerShippingLogTypeEnum[keyof typeof TemperatureLoggerShippingLogTypeEnum]

/**
 *
 * @export
 * @interface TemperatureLoggersIndexRequest
 */
export interface TemperatureLoggersIndexRequest {
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersIndexRequest
   */
  type: TemperatureLoggersIndexRequestTypeEnum
  /**
   *
   * @type {TemperatureLoggersIndexRequestData}
   * @memberof TemperatureLoggersIndexRequest
   */
  data: TemperatureLoggersIndexRequestData
}

export const TemperatureLoggersIndexRequestTypeEnum = {
  TemperatureLoggersIndex: 'TemperatureLoggersIndex',
} as const

export type TemperatureLoggersIndexRequestTypeEnum =
  typeof TemperatureLoggersIndexRequestTypeEnum[keyof typeof TemperatureLoggersIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface TemperatureLoggersIndexRequestData
 */
export interface TemperatureLoggersIndexRequestData {
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersIndexRequestData
   */
  query?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof TemperatureLoggersIndexRequestData
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersIndexRequestData
   */
  latestShippingStateDateTimeFrom?: string
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersIndexRequestData
   */
  latestShippingStateDateTimeTill?: string
  /**
   *
   * @type {boolean}
   * @memberof TemperatureLoggersIndexRequestData
   */
  all?: boolean
  /**
   *
   * @type {number}
   * @memberof TemperatureLoggersIndexRequestData
   */
  page?: number
  /**
   *
   * @type {number}
   * @memberof TemperatureLoggersIndexRequestData
   */
  per?: number
}
/**
 *
 * @export
 * @interface TemperatureLoggersIndexResponse
 */
export interface TemperatureLoggersIndexResponse {
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersIndexResponse
   */
  type: TemperatureLoggersIndexResponseTypeEnum
  /**
   *
   * @type {TemperatureLoggersIndexResponseData}
   * @memberof TemperatureLoggersIndexResponse
   */
  data: TemperatureLoggersIndexResponseData
}

export const TemperatureLoggersIndexResponseTypeEnum = {
  TemperatureLoggersIndex: 'TemperatureLoggersIndex',
} as const

export type TemperatureLoggersIndexResponseTypeEnum =
  typeof TemperatureLoggersIndexResponseTypeEnum[keyof typeof TemperatureLoggersIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface TemperatureLoggersIndexResponseComponent
 */
export interface TemperatureLoggersIndexResponseComponent {
  /**
   *
   * @type {number}
   * @memberof TemperatureLoggersIndexResponseComponent
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersIndexResponseComponent
   */
  serialNo: string
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersIndexResponseComponent
   */
  model?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof TemperatureLoggersIndexResponseComponent
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersIndexResponseComponent
   */
  shippingStateUpdatedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersIndexResponseComponent
   */
  fullContractNo?: string
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersIndexResponseComponent
   */
  managementNo?: string
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersIndexResponseComponent
   */
  siteName?: string
}
/**
 *
 * @export
 * @interface TemperatureLoggersIndexResponseComponentAllOf
 */
export interface TemperatureLoggersIndexResponseComponentAllOf {
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersIndexResponseComponentAllOf
   */
  fullContractNo?: string
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersIndexResponseComponentAllOf
   */
  managementNo?: string
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersIndexResponseComponentAllOf
   */
  siteName?: string
}
/**
 *
 * @export
 * @interface TemperatureLoggersIndexResponseData
 */
export interface TemperatureLoggersIndexResponseData {
  /**
   *
   * @type {Array<TemperatureLoggersIndexResponseComponent>}
   * @memberof TemperatureLoggersIndexResponseData
   */
  temperatureLoggers: Array<TemperatureLoggersIndexResponseComponent>
  /**
   *
   * @type {number}
   * @memberof TemperatureLoggersIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof TemperatureLoggersIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @interface TemperatureLoggersShowRequest
 */
export interface TemperatureLoggersShowRequest {
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersShowRequest
   */
  type: TemperatureLoggersShowRequestTypeEnum
  /**
   *
   * @type {ReusableOrderShowRequestData}
   * @memberof TemperatureLoggersShowRequest
   */
  data: ReusableOrderShowRequestData
}

export const TemperatureLoggersShowRequestTypeEnum = {
  TemperatureLoggersShow: 'TemperatureLoggersShow',
} as const

export type TemperatureLoggersShowRequestTypeEnum =
  typeof TemperatureLoggersShowRequestTypeEnum[keyof typeof TemperatureLoggersShowRequestTypeEnum]

/**
 *
 * @export
 * @interface TemperatureLoggersShowResponse
 */
export interface TemperatureLoggersShowResponse {
  /**
   *
   * @type {string}
   * @memberof TemperatureLoggersShowResponse
   */
  type: TemperatureLoggersShowResponseTypeEnum
  /**
   *
   * @type {TemperatureLoggersShowResponseData}
   * @memberof TemperatureLoggersShowResponse
   */
  data: TemperatureLoggersShowResponseData
}

export const TemperatureLoggersShowResponseTypeEnum = {
  TemperatureLoggersShow: 'TemperatureLoggersShow',
} as const

export type TemperatureLoggersShowResponseTypeEnum =
  typeof TemperatureLoggersShowResponseTypeEnum[keyof typeof TemperatureLoggersShowResponseTypeEnum]

/**
 *
 * @export
 * @interface TemperatureLoggersShowResponseData
 */
export interface TemperatureLoggersShowResponseData {
  /**
   *
   * @type {TemperatureLogger}
   * @memberof TemperatureLoggersShowResponseData
   */
  temperatureLogger: TemperatureLogger
}
/**
 *
 * @export
 * @interface TemperatureTagCalibration
 */
export interface TemperatureTagCalibration {
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibration
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof TemperatureTagCalibration
   */
  calibrationStartDateTime: string
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibration
   */
  referenceTemperatureSensorNo: number
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibration
   */
  temperatureCorrectionSlope: number
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibration
   */
  temperatureCorrectionIntercept: number
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibration
   */
  rssiCorrectionSlope: number
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibration
   */
  rssiCorrectionIntercept: number
  /**
   *
   * @type {Array<TemperatureTagCalibrationCalibrationResults>}
   * @memberof TemperatureTagCalibration
   */
  calibrationResults: Array<TemperatureTagCalibrationCalibrationResults>
}
/**
 *
 * @export
 * @interface TemperatureTagCalibrationCalibrationResults
 */
export interface TemperatureTagCalibrationCalibrationResults {
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibrationCalibrationResults
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibrationCalibrationResults
   */
  referenceTemperature: number
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibrationCalibrationResults
   */
  tagTemperatureCode: number
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibrationCalibrationResults
   */
  rssi: number
}
/**
 *
 * @export
 * @interface TemperatureTagCalibrationLast
 */
export interface TemperatureTagCalibrationLast {
  /**
   *
   * @type {string}
   * @memberof TemperatureTagCalibrationLast
   */
  calibrationStartDateTime: string
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibrationLast
   */
  referenceTemperatureSensorNo: number
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibrationLast
   */
  temperatureCorrectionSlope: number
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibrationLast
   */
  temperatureCorrectionIntercept: number
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibrationLast
   */
  rssiCorrectionSlope: number
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibrationLast
   */
  rssiCorrectionIntercept: number
  /**
   *
   * @type {Array<TemperatureTagCalibrationLastCalibrationResults>}
   * @memberof TemperatureTagCalibrationLast
   */
  calibrationResults: Array<TemperatureTagCalibrationLastCalibrationResults>
}
/**
 *
 * @export
 * @interface TemperatureTagCalibrationLastCalibrationResults
 */
export interface TemperatureTagCalibrationLastCalibrationResults {
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibrationLastCalibrationResults
   */
  id: number
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibrationLastCalibrationResults
   */
  referenceTemperature?: number
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibrationLastCalibrationResults
   */
  tagTemperatureCode: number
  /**
   *
   * @type {number}
   * @memberof TemperatureTagCalibrationLastCalibrationResults
   */
  rssi: number
}
/**
 *
 * @export
 * @interface TemperatureTagDispose
 */
export interface TemperatureTagDispose {
  /**
   *
   * @type {string}
   * @memberof TemperatureTagDispose
   */
  app: TemperatureTagDisposeAppEnum
  /**
   *
   * @type {string}
   * @memberof TemperatureTagDispose
   */
  logType: TemperatureTagDisposeLogTypeEnum
  /**
   *
   * @type {CommonData & CommonTemperatureTag}
   * @memberof TemperatureTagDispose
   */
  data: CommonData & CommonTemperatureTag
}

export const TemperatureTagDisposeAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type TemperatureTagDisposeAppEnum =
  typeof TemperatureTagDisposeAppEnum[keyof typeof TemperatureTagDisposeAppEnum]
export const TemperatureTagDisposeLogTypeEnum = {
  TemperatureTagDispose: 'temperatureTagDispose',
} as const

export type TemperatureTagDisposeLogTypeEnum =
  typeof TemperatureTagDisposeLogTypeEnum[keyof typeof TemperatureTagDisposeLogTypeEnum]

/**
 *
 * @export
 * @interface TemperatureTagReturn
 */
export interface TemperatureTagReturn {
  /**
   *
   * @type {string}
   * @memberof TemperatureTagReturn
   */
  app: TemperatureTagReturnAppEnum
  /**
   *
   * @type {string}
   * @memberof TemperatureTagReturn
   */
  logType: TemperatureTagReturnLogTypeEnum
  /**
   *
   * @type {CommonData & CommonTemperatureTag}
   * @memberof TemperatureTagReturn
   */
  data: CommonData & CommonTemperatureTag
}

export const TemperatureTagReturnAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type TemperatureTagReturnAppEnum =
  typeof TemperatureTagReturnAppEnum[keyof typeof TemperatureTagReturnAppEnum]
export const TemperatureTagReturnLogTypeEnum = {
  TemperatureTagReturn: 'temperatureTagReturn',
} as const

export type TemperatureTagReturnLogTypeEnum =
  typeof TemperatureTagReturnLogTypeEnum[keyof typeof TemperatureTagReturnLogTypeEnum]

/**
 *
 * @export
 * @interface TemperatureTagShipping
 */
export interface TemperatureTagShipping {
  /**
   *
   * @type {string}
   * @memberof TemperatureTagShipping
   */
  app: TemperatureTagShippingAppEnum
  /**
   *
   * @type {string}
   * @memberof TemperatureTagShipping
   */
  logType: TemperatureTagShippingLogTypeEnum
  /**
   *
   * @type {CommonData & ShippingManagementCommonInfo & CommonTemperatureTag}
   * @memberof TemperatureTagShipping
   */
  data: CommonData & ShippingManagementCommonInfo & CommonTemperatureTag
}

export const TemperatureTagShippingAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type TemperatureTagShippingAppEnum =
  typeof TemperatureTagShippingAppEnum[keyof typeof TemperatureTagShippingAppEnum]
export const TemperatureTagShippingLogTypeEnum = {
  TemperatureTagShipping: 'temperatureTagShipping',
} as const

export type TemperatureTagShippingLogTypeEnum =
  typeof TemperatureTagShippingLogTypeEnum[keyof typeof TemperatureTagShippingLogTypeEnum]

/**
 *
 * @export
 * @interface ThermalStorageUnit
 */
export interface ThermalStorageUnit {
  /**
   *
   * @type {number}
   * @memberof ThermalStorageUnit
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnit
   */
  serialNo: string
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnit
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnit
   */
  contractNo?: string
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnit
   */
  managementNo?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof ThermalStorageUnit
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnit
   */
  shippingStateUpdatedDateTime?: string
}
/**
 *
 * @export
 * @interface ThermalStorageUnitInventory
 */
export interface ThermalStorageUnitInventory {
  /**
   *
   * @type {number}
   * @memberof ThermalStorageUnitInventory
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitInventory
   */
  serialNo: string
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitInventory
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitInventory
   */
  contractNo?: string
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitInventory
   */
  managementNo?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof ThermalStorageUnitInventory
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitInventory
   */
  shippingStateUpdatedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitInventory
   */
  siteName?: string
  /**
   *
   * @type {boolean}
   * @memberof ThermalStorageUnitInventory
   */
  settled: boolean
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitInventory
   */
  fullContractNo?: string
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitInventory
   */
  processingDateTime?: string
}
/**
 *
 * @export
 * @interface ThermalStorageUnitInventoryAllOf
 */
export interface ThermalStorageUnitInventoryAllOf {
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitInventoryAllOf
   */
  siteName?: string
  /**
   *
   * @type {boolean}
   * @memberof ThermalStorageUnitInventoryAllOf
   */
  settled: boolean
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitInventoryAllOf
   */
  fullContractNo?: string
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitInventoryAllOf
   */
  managementNo?: string
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitInventoryAllOf
   */
  processingDateTime?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof ThermalStorageUnitInventoryAllOf
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitInventoryAllOf
   */
  shippingStateUpdatedDateTime?: string
}
/**
 *
 * @export
 * @interface ThermalStorageUnitLast
 */
export interface ThermalStorageUnitLast {
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitLast
   */
  productCode: string
  /**
   *
   * @type {number}
   * @memberof ThermalStorageUnitLast
   */
  shippingCount: number
  /**
   *
   * @type {number}
   * @memberof ThermalStorageUnitLast
   */
  returnCount: number
}
/**
 *
 * @export
 * @interface ThermalStorageUnitProductData
 */
export interface ThermalStorageUnitProductData {
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitProductData
   */
  type: ThermalStorageUnitProductDataTypeEnum
  /**
   *
   * @type {ThermalStorageUnit}
   * @memberof ThermalStorageUnitProductData
   */
  product: ThermalStorageUnit
}

export const ThermalStorageUnitProductDataTypeEnum = {
  ThermalStorageUnit: 'THERMAL_STORAGE_UNIT',
} as const

export type ThermalStorageUnitProductDataTypeEnum =
  typeof ThermalStorageUnitProductDataTypeEnum[keyof typeof ThermalStorageUnitProductDataTypeEnum]

/**
 *
 * @export
 * @interface ThermalStorageUnitShipping
 */
export interface ThermalStorageUnitShipping {
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitShipping
   */
  app: ThermalStorageUnitShippingAppEnum
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitShipping
   */
  logType: ThermalStorageUnitShippingLogTypeEnum
  /**
   *
   * @type {CommonData & ShippingManagementCommonInfo & CommonThermalStorageUnitAndAccessory}
   * @memberof ThermalStorageUnitShipping
   */
  data: CommonData &
    ShippingManagementCommonInfo &
    CommonThermalStorageUnitAndAccessory
}

export const ThermalStorageUnitShippingAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type ThermalStorageUnitShippingAppEnum =
  typeof ThermalStorageUnitShippingAppEnum[keyof typeof ThermalStorageUnitShippingAppEnum]
export const ThermalStorageUnitShippingLogTypeEnum = {
  ThermalStorageUnitShipping: 'thermalStorageUnitShipping',
} as const

export type ThermalStorageUnitShippingLogTypeEnum =
  typeof ThermalStorageUnitShippingLogTypeEnum[keyof typeof ThermalStorageUnitShippingLogTypeEnum]

/**
 *
 * @export
 * @interface ThermalStorageUnitsIndexRequest
 */
export interface ThermalStorageUnitsIndexRequest {
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitsIndexRequest
   */
  type: ThermalStorageUnitsIndexRequestTypeEnum
  /**
   *
   * @type {TemperatureLoggersIndexRequestData}
   * @memberof ThermalStorageUnitsIndexRequest
   */
  data: TemperatureLoggersIndexRequestData
}

export const ThermalStorageUnitsIndexRequestTypeEnum = {
  ThermalStorageUnitsIndex: 'ThermalStorageUnitsIndex',
} as const

export type ThermalStorageUnitsIndexRequestTypeEnum =
  typeof ThermalStorageUnitsIndexRequestTypeEnum[keyof typeof ThermalStorageUnitsIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface ThermalStorageUnitsIndexResponse
 */
export interface ThermalStorageUnitsIndexResponse {
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitsIndexResponse
   */
  type: ThermalStorageUnitsIndexResponseTypeEnum
  /**
   *
   * @type {ThermalStorageUnitsIndexResponseData}
   * @memberof ThermalStorageUnitsIndexResponse
   */
  data: ThermalStorageUnitsIndexResponseData
}

export const ThermalStorageUnitsIndexResponseTypeEnum = {
  ThermalStorageUnitsIndex: 'ThermalStorageUnitsIndex',
} as const

export type ThermalStorageUnitsIndexResponseTypeEnum =
  typeof ThermalStorageUnitsIndexResponseTypeEnum[keyof typeof ThermalStorageUnitsIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface ThermalStorageUnitsIndexResponseComponent
 */
export interface ThermalStorageUnitsIndexResponseComponent {
  /**
   *
   * @type {number}
   * @memberof ThermalStorageUnitsIndexResponseComponent
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitsIndexResponseComponent
   */
  serialNo: string
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitsIndexResponseComponent
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitsIndexResponseComponent
   */
  contractNo?: string
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitsIndexResponseComponent
   */
  managementNo?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof ThermalStorageUnitsIndexResponseComponent
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitsIndexResponseComponent
   */
  shippingStateUpdatedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitsIndexResponseComponent
   */
  fullContractNo?: string
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitsIndexResponseComponent
   */
  siteName?: string
}
/**
 *
 * @export
 * @interface ThermalStorageUnitsIndexResponseData
 */
export interface ThermalStorageUnitsIndexResponseData {
  /**
   *
   * @type {Array<ThermalStorageUnitsIndexResponseComponent>}
   * @memberof ThermalStorageUnitsIndexResponseData
   */
  thermalStorageUnits: Array<ThermalStorageUnitsIndexResponseComponent>
  /**
   *
   * @type {number}
   * @memberof ThermalStorageUnitsIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof ThermalStorageUnitsIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @interface ThermalStorageUnitsShowRequest
 */
export interface ThermalStorageUnitsShowRequest {
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitsShowRequest
   */
  type: ThermalStorageUnitsShowRequestTypeEnum
  /**
   *
   * @type {ReusableOrderShowRequestData}
   * @memberof ThermalStorageUnitsShowRequest
   */
  data: ReusableOrderShowRequestData
}

export const ThermalStorageUnitsShowRequestTypeEnum = {
  ThermalStorageUnitsShow: 'ThermalStorageUnitsShow',
} as const

export type ThermalStorageUnitsShowRequestTypeEnum =
  typeof ThermalStorageUnitsShowRequestTypeEnum[keyof typeof ThermalStorageUnitsShowRequestTypeEnum]

/**
 *
 * @export
 * @interface ThermalStorageUnitsShowResponse
 */
export interface ThermalStorageUnitsShowResponse {
  /**
   *
   * @type {string}
   * @memberof ThermalStorageUnitsShowResponse
   */
  type: ThermalStorageUnitsShowResponseTypeEnum
  /**
   *
   * @type {ThermalStorageUnitsShowResponseData}
   * @memberof ThermalStorageUnitsShowResponse
   */
  data: ThermalStorageUnitsShowResponseData
}

export const ThermalStorageUnitsShowResponseTypeEnum = {
  ThermalStorageUnitsShow: 'ThermalStorageUnitsShow',
} as const

export type ThermalStorageUnitsShowResponseTypeEnum =
  typeof ThermalStorageUnitsShowResponseTypeEnum[keyof typeof ThermalStorageUnitsShowResponseTypeEnum]

/**
 *
 * @export
 * @interface ThermalStorageUnitsShowResponseData
 */
export interface ThermalStorageUnitsShowResponseData {
  /**
   *
   * @type {ThermalStorageUnit}
   * @memberof ThermalStorageUnitsShowResponseData
   */
  thermalStorageUnit: ThermalStorageUnit
}
/**
 *
 * @export
 * @interface Trade
 */
export interface Trade {
  /**
   *
   * @type {number}
   * @memberof Trade
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof Trade
   */
  contractRepresentativeNo: string
  /**
   *
   * @type {string}
   * @memberof Trade
   */
  tradeType?: string
  /**
   *
   * @type {string}
   * @memberof Trade
   */
  destination?: string
  /**
   *
   * @type {string}
   * @memberof Trade
   */
  customerId: string
  /**
   *
   * @type {string}
   * @memberof Trade
   */
  returnEstimatedDate?: string
  /**
   *
   * @type {string}
   * @memberof Trade
   */
  returnCompletedDate?: string
  /**
   * 存在しないときは空の配列
   * @type {Array<TradeVixellModelCounts>}
   * @memberof Trade
   */
  vixellModelCounts: Array<TradeVixellModelCounts>
  /**
   * 存在しないときは0
   * @type {number}
   * @memberof Trade
   */
  icePackCount: number
  /**
   * 存在しないときは空の配列
   * @type {Array<TradeVixellModelCounts>}
   * @memberof Trade
   */
  inspectionBoardModelCounts: Array<TradeVixellModelCounts>
  /**
   *
   * @type {Array<TradeThermalStorageUnit>}
   * @memberof Trade
   */
  thermalStorageUnits?: Array<TradeThermalStorageUnit>
  /**
   *
   * @type {Array<TradeAccessory>}
   * @memberof Trade
   */
  accessories?: Array<TradeAccessory>
  /**
   *
   * @type {Customer}
   * @memberof Trade
   */
  customer?: Customer
}
/**
 *
 * @export
 * @interface TradeAccessory
 */
export interface TradeAccessory {
  /**
   *
   * @type {string}
   * @memberof TradeAccessory
   */
  productCode: string
  /**
   *
   * @type {number}
   * @memberof TradeAccessory
   */
  shippingCount: number
  /**
   *
   * @type {number}
   * @memberof TradeAccessory
   */
  returnCount: number
}
/**
 *
 * @export
 * @interface TradeLast
 */
export interface TradeLast {
  /**
   *
   * @type {string}
   * @memberof TradeLast
   */
  contractRepresentativeNo: string
  /**
   *
   * @type {string}
   * @memberof TradeLast
   */
  tradeType?: string
  /**
   *
   * @type {string}
   * @memberof TradeLast
   */
  destination?: string
  /**
   *
   * @type {string}
   * @memberof TradeLast
   */
  customerId?: string
  /**
   *
   * @type {string}
   * @memberof TradeLast
   */
  returnEstimatedDate?: string
  /**
   *
   * @type {string}
   * @memberof TradeLast
   */
  returnCompletedDate?: string
  /**
   *
   * @type {Array<ThermalStorageUnitLast>}
   * @memberof TradeLast
   */
  thermalStorageUnits?: Array<ThermalStorageUnitLast>
  /**
   *
   * @type {Array<AccessoryLast>}
   * @memberof TradeLast
   */
  accessories?: Array<AccessoryLast>
  /**
   *
   * @type {CustomerLast}
   * @memberof TradeLast
   */
  customer?: CustomerLast
}
/**
 *
 * @export
 * @interface TradeThermalStorageUnit
 */
export interface TradeThermalStorageUnit {
  /**
   *
   * @type {string}
   * @memberof TradeThermalStorageUnit
   */
  productCode: string
  /**
   *
   * @type {number}
   * @memberof TradeThermalStorageUnit
   */
  shippingCount: number
  /**
   *
   * @type {number}
   * @memberof TradeThermalStorageUnit
   */
  returnCount: number
}
/**
 *
 * @export
 * @interface TradeVixellModelCounts
 */
export interface TradeVixellModelCounts {
  /**
   *
   * @type {string}
   * @memberof TradeVixellModelCounts
   */
  model: string
  /**
   *
   * @type {number}
   * @memberof TradeVixellModelCounts
   */
  count: number
}
/**
 *
 * @export
 * @interface TradesIndexRequest
 */
export interface TradesIndexRequest {
  /**
   *
   * @type {string}
   * @memberof TradesIndexRequest
   */
  type: TradesIndexRequestTypeEnum
  /**
   *
   * @type {TradesIndexRequestData}
   * @memberof TradesIndexRequest
   */
  data: TradesIndexRequestData
}

export const TradesIndexRequestTypeEnum = {
  TradesIndex: 'TradesIndex',
} as const

export type TradesIndexRequestTypeEnum =
  typeof TradesIndexRequestTypeEnum[keyof typeof TradesIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface TradesIndexRequestData
 */
export interface TradesIndexRequestData {
  /**
   *
   * @type {string}
   * @memberof TradesIndexRequestData
   */
  contractRepresentativeNo?: string
  /**
   *
   * @type {string}
   * @memberof TradesIndexRequestData
   */
  tradeType?: string
  /**
   *
   * @type {string}
   * @memberof TradesIndexRequestData
   */
  customerName?: string
  /**
   *
   * @type {string}
   * @memberof TradesIndexRequestData
   */
  destination?: string
  /**
   *
   * @type {string}
   * @memberof TradesIndexRequestData
   */
  returnEstimatedDateFrom?: string
  /**
   *
   * @type {string}
   * @memberof TradesIndexRequestData
   */
  returnEstimatedDateTill?: string
  /**
   *
   * @type {string}
   * @memberof TradesIndexRequestData
   */
  returnCompletedDateFrom?: string
  /**
   *
   * @type {string}
   * @memberof TradesIndexRequestData
   */
  returnCompletedDateTill?: string
  /**
   *
   * @type {boolean}
   * @memberof TradesIndexRequestData
   */
  all?: boolean
}
/**
 *
 * @export
 * @interface TradesIndexResponse
 */
export interface TradesIndexResponse {
  /**
   *
   * @type {string}
   * @memberof TradesIndexResponse
   */
  type: TradesIndexResponseTypeEnum
  /**
   *
   * @type {TradesIndexResponseData}
   * @memberof TradesIndexResponse
   */
  data: TradesIndexResponseData
}

export const TradesIndexResponseTypeEnum = {
  TradesIndex: 'TradesIndex',
} as const

export type TradesIndexResponseTypeEnum =
  typeof TradesIndexResponseTypeEnum[keyof typeof TradesIndexResponseTypeEnum]

/**
 *
 * @export
 * @interface TradesIndexResponseData
 */
export interface TradesIndexResponseData {
  /**
   *
   * @type {Array<Trade & object>}
   * @memberof TradesIndexResponseData
   */
  trades: Array<Trade & object>
  /**
   *
   * @type {number}
   * @memberof TradesIndexResponseData
   */
  currentPage: number
  /**
   *
   * @type {boolean}
   * @memberof TradesIndexResponseData
   */
  stopPageLoad: boolean
}
/**
 *
 * @export
 * @interface TradesShowRequest
 */
export interface TradesShowRequest {
  /**
   *
   * @type {string}
   * @memberof TradesShowRequest
   */
  type: TradesShowRequestTypeEnum
  /**
   *
   * @type {ReusableOrderShowRequestData}
   * @memberof TradesShowRequest
   */
  data: ReusableOrderShowRequestData
}

export const TradesShowRequestTypeEnum = {
  TradesShow: 'TradesShow',
} as const

export type TradesShowRequestTypeEnum =
  typeof TradesShowRequestTypeEnum[keyof typeof TradesShowRequestTypeEnum]

/**
 *
 * @export
 * @interface TradesShowResponse
 */
export interface TradesShowResponse {
  /**
   *
   * @type {string}
   * @memberof TradesShowResponse
   */
  type: TradesShowResponseTypeEnum
  /**
   *
   * @type {TradesShowResponseData}
   * @memberof TradesShowResponse
   */
  data: TradesShowResponseData
}

export const TradesShowResponseTypeEnum = {
  TradesShow: 'TradesShow',
} as const

export type TradesShowResponseTypeEnum =
  typeof TradesShowResponseTypeEnum[keyof typeof TradesShowResponseTypeEnum]

/**
 *
 * @export
 * @interface TradesShowResponseComponent
 */
export interface TradesShowResponseComponent {
  /**
   *
   * @type {number}
   * @memberof TradesShowResponseComponent
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof TradesShowResponseComponent
   */
  contractRepresentativeNo: string
  /**
   *
   * @type {string}
   * @memberof TradesShowResponseComponent
   */
  tradeType?: string
  /**
   *
   * @type {string}
   * @memberof TradesShowResponseComponent
   */
  destination?: string
  /**
   *
   * @type {string}
   * @memberof TradesShowResponseComponent
   */
  customerId: string
  /**
   *
   * @type {string}
   * @memberof TradesShowResponseComponent
   */
  returnEstimatedDate?: string
  /**
   *
   * @type {string}
   * @memberof TradesShowResponseComponent
   */
  returnCompletedDate?: string
  /**
   * 存在しないときは空の配列
   * @type {Array<TradeVixellModelCounts>}
   * @memberof TradesShowResponseComponent
   */
  vixellModelCounts: Array<TradeVixellModelCounts>
  /**
   * 存在しないときは0
   * @type {number}
   * @memberof TradesShowResponseComponent
   */
  icePackCount: number
  /**
   * 存在しないときは空の配列
   * @type {Array<TradeVixellModelCounts>}
   * @memberof TradesShowResponseComponent
   */
  inspectionBoardModelCounts: Array<TradeVixellModelCounts>
  /**
   *
   * @type {Array<TradeThermalStorageUnit>}
   * @memberof TradesShowResponseComponent
   */
  thermalStorageUnits?: Array<TradeThermalStorageUnit>
  /**
   *
   * @type {Array<TradeAccessory>}
   * @memberof TradesShowResponseComponent
   */
  accessories?: Array<TradeAccessory>
  /**
   *
   * @type {Customer}
   * @memberof TradesShowResponseComponent
   */
  customer?: Customer
}
/**
 *
 * @export
 * @interface TradesShowResponseComponentAllOf
 */
export interface TradesShowResponseComponentAllOf {
  /**
   *
   * @type {Customer}
   * @memberof TradesShowResponseComponentAllOf
   */
  customer?: Customer
}
/**
 *
 * @export
 * @interface TradesShowResponseData
 */
export interface TradesShowResponseData {
  /**
   *
   * @type {TradesShowResponseComponent}
   * @memberof TradesShowResponseData
   */
  trade?: TradesShowResponseComponent
}
/**
 *
 * @export
 * @interface TradesUpdateRequest
 */
export interface TradesUpdateRequest {
  /**
   *
   * @type {string}
   * @memberof TradesUpdateRequest
   */
  type: TradesUpdateRequestTypeEnum
  /**
   *
   * @type {TradesUpdateRequestData}
   * @memberof TradesUpdateRequest
   */
  data: TradesUpdateRequestData
}

export const TradesUpdateRequestTypeEnum = {
  TradesUpdate: 'TradesUpdate',
} as const

export type TradesUpdateRequestTypeEnum =
  typeof TradesUpdateRequestTypeEnum[keyof typeof TradesUpdateRequestTypeEnum]

/**
 *
 * @export
 * @interface TradesUpdateRequestData
 */
export interface TradesUpdateRequestData {
  /**
   *
   * @type {number}
   * @memberof TradesUpdateRequestData
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof TradesUpdateRequestData
   */
  tradeType?: string
  /**
   *
   * @type {string}
   * @memberof TradesUpdateRequestData
   */
  destination?: string
  /**
   *
   * @type {string}
   * @memberof TradesUpdateRequestData
   */
  returnEstimatedDate?: string
  /**
   *
   * @type {string}
   * @memberof TradesUpdateRequestData
   */
  returnCompletedDate?: string
  /**
   *
   * @type {Array<UpdateTradeThermalStorageUnits>}
   * @memberof TradesUpdateRequestData
   */
  thermalStorageUnits?: Array<UpdateTradeThermalStorageUnits>
  /**
   *
   * @type {Array<UpdateTradeThermalStorageUnits>}
   * @memberof TradesUpdateRequestData
   */
  accessories?: Array<UpdateTradeThermalStorageUnits>
}
/**
 *
 * @export
 * @interface TradesUpdateResponse
 */
export interface TradesUpdateResponse {
  /**
   *
   * @type {string}
   * @memberof TradesUpdateResponse
   */
  type: TradesUpdateResponseTypeEnum
  /**
   *
   * @type {SuccessResponseData}
   * @memberof TradesUpdateResponse
   */
  data: SuccessResponseData
}

export const TradesUpdateResponseTypeEnum = {
  TradesUpdate: 'TradesUpdate',
} as const

export type TradesUpdateResponseTypeEnum =
  typeof TradesUpdateResponseTypeEnum[keyof typeof TradesUpdateResponseTypeEnum]

/**
 *
 * @export
 * @interface Transition
 */
export interface Transition {
  /**
   *
   * @type {string}
   * @memberof Transition
   */
  siteId: string
  /**
   *
   * @type {string}
   * @memberof Transition
   */
  processingDateTime: string
  /**
   *
   * @type {SiteLast}
   * @memberof Transition
   */
  site: SiteLast
}
/**
 *
 * @export
 * @enum {string}
 */

export const Unit = {
  R: 'R',
  C: 'C',
  F: 'F',
  S: 'S',
  D: 'D',
} as const

export type Unit = typeof Unit[keyof typeof Unit]

/**
 *
 * @export
 * @interface UpdateMe
 */
export interface UpdateMe {
  /**
   *
   * @type {string}
   * @memberof UpdateMe
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof UpdateMe
   */
  password?: string
}
/**
 *
 * @export
 * @interface UpdateTrade
 */
export interface UpdateTrade {
  /**
   *
   * @type {string}
   * @memberof UpdateTrade
   */
  contractRepresentativeNo: string
  /**
   *
   * @type {string}
   * @memberof UpdateTrade
   */
  tradeType?: string
  /**
   *
   * @type {string}
   * @memberof UpdateTrade
   */
  destination?: string
  /**
   *
   * @type {string}
   * @memberof UpdateTrade
   */
  returnEstimatedDate?: string
  /**
   *
   * @type {string}
   * @memberof UpdateTrade
   */
  returnCompletedDate?: string
  /**
   *
   * @type {Array<UpdateTradeThermalStorageUnits>}
   * @memberof UpdateTrade
   */
  thermalStorageUnits?: Array<UpdateTradeThermalStorageUnits>
  /**
   *
   * @type {Array<UpdateTradeThermalStorageUnits>}
   * @memberof UpdateTrade
   */
  accessories?: Array<UpdateTradeThermalStorageUnits>
}
/**
 *
 * @export
 * @interface UpdateTradeThermalStorageUnits
 */
export interface UpdateTradeThermalStorageUnits {
  /**
   *
   * @type {string}
   * @memberof UpdateTradeThermalStorageUnits
   */
  productCode: string
  /**
   *
   * @type {number}
   * @memberof UpdateTradeThermalStorageUnits
   */
  shippingCount: number
  /**
   *
   * @type {number}
   * @memberof UpdateTradeThermalStorageUnits
   */
  returnCount: number
}
/**
 *
 * @export
 * @interface VacuumCorrection
 */
export interface VacuumCorrection {
  /**
   *
   * @type {string}
   * @memberof VacuumCorrection
   */
  app: VacuumCorrectionAppEnum
  /**
   *
   * @type {string}
   * @memberof VacuumCorrection
   */
  logType: VacuumCorrectionLogTypeEnum
  /**
   *
   * @type {CommonData & VacuumProcessCommon & object}
   * @memberof VacuumCorrection
   */
  data: CommonData & VacuumProcessCommon & object
}

export const VacuumCorrectionAppEnum = {
  VacuumProcess: 'vacuumProcess',
} as const

export type VacuumCorrectionAppEnum =
  typeof VacuumCorrectionAppEnum[keyof typeof VacuumCorrectionAppEnum]
export const VacuumCorrectionLogTypeEnum = {
  VacuumCorrection: 'vacuumCorrection',
} as const

export type VacuumCorrectionLogTypeEnum =
  typeof VacuumCorrectionLogTypeEnum[keyof typeof VacuumCorrectionLogTypeEnum]

/**
 *
 * @export
 * @interface VacuumEvacuation
 */
export interface VacuumEvacuation {
  /**
   *
   * @type {string}
   * @memberof VacuumEvacuation
   */
  app: VacuumEvacuationAppEnum
  /**
   *
   * @type {string}
   * @memberof VacuumEvacuation
   */
  logType: VacuumEvacuationLogTypeEnum
  /**
   *
   * @type {CommonData & VacuumProcessCommon & object}
   * @memberof VacuumEvacuation
   */
  data: CommonData & VacuumProcessCommon & object
}

export const VacuumEvacuationAppEnum = {
  VacuumProcess: 'vacuumProcess',
} as const

export type VacuumEvacuationAppEnum =
  typeof VacuumEvacuationAppEnum[keyof typeof VacuumEvacuationAppEnum]
export const VacuumEvacuationLogTypeEnum = {
  VacuumEvacuation: 'vacuumEvacuation',
} as const

export type VacuumEvacuationLogTypeEnum =
  typeof VacuumEvacuationLogTypeEnum[keyof typeof VacuumEvacuationLogTypeEnum]

/**
 *
 * @export
 * @interface VacuumProcess
 */
export interface VacuumProcess {
  /**
   *
   * @type {VacuumProcessLastAcceptance}
   * @memberof VacuumProcess
   */
  acceptance?: VacuumProcessLastAcceptance
  /**
   *
   * @type {VacuumProcessLastVacuumEvacuation}
   * @memberof VacuumProcess
   */
  vacuumEvacuation?: VacuumProcessLastVacuumEvacuation
  /**
   *
   * @type {VacuumProcessLastLeakTest1}
   * @memberof VacuumProcess
   */
  leakTest1?: VacuumProcessLastLeakTest1
  /**
   *
   * @type {VacuumProcessLastLeakTest2}
   * @memberof VacuumProcess
   */
  leakTest2?: VacuumProcessLastLeakTest2
  /**
   *
   * @type {VacuumProcessLastVacuumCorrection}
   * @memberof VacuumProcess
   */
  vacuumCorrection?: VacuumProcessLastVacuumCorrection
}
/**
 *
 * @export
 * @interface VacuumProcessCommon
 */
export interface VacuumProcessCommon {
  /**
   * VIXELL-S/N(16文字以下の英数字記号)
   * @type {string}
   * @memberof VacuumProcessCommon
   */
  vixellSerialNo: string
}
/**
 *
 * @export
 * @interface VacuumProcessLast
 */
export interface VacuumProcessLast {
  /**
   *
   * @type {VacuumProcessLastAcceptance}
   * @memberof VacuumProcessLast
   */
  acceptance?: VacuumProcessLastAcceptance
  /**
   *
   * @type {VacuumProcessLastVacuumEvacuation}
   * @memberof VacuumProcessLast
   */
  vacuumEvacuation?: VacuumProcessLastVacuumEvacuation
  /**
   *
   * @type {VacuumProcessLastLeakTest1}
   * @memberof VacuumProcessLast
   */
  leakTest1?: VacuumProcessLastLeakTest1
  /**
   *
   * @type {VacuumProcessLastLeakTest2}
   * @memberof VacuumProcessLast
   */
  leakTest2?: VacuumProcessLastLeakTest2
  /**
   *
   * @type {VacuumProcessLastVacuumCorrection}
   * @memberof VacuumProcessLast
   */
  vacuumCorrection?: VacuumProcessLastVacuumCorrection
}
/**
 *
 * @export
 * @interface VacuumProcessLastAcceptance
 */
export interface VacuumProcessLastAcceptance {
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastAcceptance
   */
  model: string
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastAcceptance
   */
  IDm: string
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastAcceptance
   */
  idmIssue: string
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastAcceptance
   */
  numberOfMoistAdsorbent: number
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastAcceptance
   */
  minimumWeightOfMoistAdsorbent: number
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastAcceptance
   */
  maximumWeightOfMoistAdsorbent: number
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastAcceptance
   */
  weightOfGasAdsorbent?: number
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastAcceptance
   */
  componentCheck: string
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastAcceptance
   */
  processingDateTime: string
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastAcceptance
   */
  pcId: string
}
/**
 *
 * @export
 * @interface VacuumProcessLastLeakTest1
 */
export interface VacuumProcessLastLeakTest1 {
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastLeakTest1
   */
  sensorVoltage: number
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastLeakTest1
   */
  sensorPressure: number
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastLeakTest1
   */
  inspectionResult: string
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastLeakTest1
   */
  processingDateTime: string
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastLeakTest1
   */
  pcId: string
}
/**
 *
 * @export
 * @interface VacuumProcessLastLeakTest2
 */
export interface VacuumProcessLastLeakTest2 {
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastLeakTest2
   */
  sensorVoltage: number
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastLeakTest2
   */
  sensorPressure: number
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastLeakTest2
   */
  pressureDifferenceFromLeak1: number
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastLeakTest2
   */
  inspectionResult?: string
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastLeakTest2
   */
  processingDateTime: string
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastLeakTest2
   */
  pcId: string
}
/**
 *
 * @export
 * @interface VacuumProcessLastVacuumCorrection
 */
export interface VacuumProcessLastVacuumCorrection {
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastVacuumCorrection
   */
  noncorrectedVoltage: number
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastVacuumCorrection
   */
  sensorPressure: number
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastVacuumCorrection
   */
  correctedVoltage: number
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastVacuumCorrection
   */
  correctedPressure: number
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastVacuumCorrection
   */
  environmentalTemperature: number
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastVacuumCorrection
   */
  referenceVoltage: number
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastVacuumCorrection
   */
  correctionValue: number
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastVacuumCorrection
   */
  inspectionResult: string
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastVacuumCorrection
   */
  processingDateTime?: string
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastVacuumCorrection
   */
  pcId?: string
}
/**
 *
 * @export
 * @interface VacuumProcessLastVacuumEvacuation
 */
export interface VacuumProcessLastVacuumEvacuation {
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastVacuumEvacuation
   */
  urethaneLotNo: string
  /**
   * 設備番号（もともとはリトライ回数だったが、設備番号になった）
   * @type {number}
   * @memberof VacuumProcessLastVacuumEvacuation
   */
  retryCount: number
  /**
   *
   * @type {number}
   * @memberof VacuumProcessLastVacuumEvacuation
   */
  evacuationPeriod: number
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastVacuumEvacuation
   */
  processingDateTime: string
  /**
   *
   * @type {string}
   * @memberof VacuumProcessLastVacuumEvacuation
   */
  pcId: string
}
/**
 *
 * @export
 * @interface Vixell
 */
export interface Vixell {
  /**
   *
   * @type {number}
   * @memberof Vixell
   */
  id: number
  /**
   *
   * @type {string}
   * @memberof Vixell
   */
  serialNo: string
  /**
   *
   * @type {string}
   * @memberof Vixell
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof Vixell
   */
  IDm?: string
  /**
   *
   * @type {string}
   * @memberof Vixell
   */
  contractNo?: string
  /**
   *
   * @type {string}
   * @memberof Vixell
   */
  managementNo?: string
}
/**
 *
 * @export
 * @interface VixellDispose
 */
export interface VixellDispose {
  /**
   *
   * @type {string}
   * @memberof VixellDispose
   */
  app: VixellDisposeAppEnum
  /**
   *
   * @type {string}
   * @memberof VixellDispose
   */
  logType: VixellDisposeLogTypeEnum
  /**
   *
   * @type {CommonData & CommonVixell}
   * @memberof VixellDispose
   */
  data: CommonData & CommonVixell
}

export const VixellDisposeAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type VixellDisposeAppEnum =
  typeof VixellDisposeAppEnum[keyof typeof VixellDisposeAppEnum]
export const VixellDisposeLogTypeEnum = {
  VixellDispose: 'vixellDispose',
} as const

export type VixellDisposeLogTypeEnum =
  typeof VixellDisposeLogTypeEnum[keyof typeof VixellDisposeLogTypeEnum]

/**
 *
 * @export
 * @interface VixellIndexRequestComponent
 */
export interface VixellIndexRequestComponent {
  /**
   *
   * @type {string}
   * @memberof VixellIndexRequestComponent
   */
  serialNo?: string
  /**
   *
   * @type {string}
   * @memberof VixellIndexRequestComponent
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof VixellIndexRequestComponent
   */
  latestSiteName?: string
  /**
   * {契約代表No}-{契約No}, 管理No の2つを部分一致で検索できる
   * @type {string}
   * @memberof VixellIndexRequestComponent
   */
  contractNo?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof VixellIndexRequestComponent
   */
  shippingStatus?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof VixellIndexRequestComponent
   */
  latestShippingStateDateTimeFrom?: string
  /**
   *
   * @type {string}
   * @memberof VixellIndexRequestComponent
   */
  latestShippingStateDateTimeTill?: string
  /**
   *
   * @type {boolean}
   * @memberof VixellIndexRequestComponent
   */
  onlyNg?: boolean
  /**
   *
   * @type {number}
   * @memberof VixellIndexRequestComponent
   */
  sensorPressureMinimum?: number
  /**
   *
   * @type {number}
   * @memberof VixellIndexRequestComponent
   */
  sensorPressureMaximum?: number
  /**
   *
   * @type {string}
   * @memberof VixellIndexRequestComponent
   */
  latestBelongingLogDateTimeFrom?: string
  /**
   *
   * @type {string}
   * @memberof VixellIndexRequestComponent
   */
  latestBelongingLogDateTimeTill?: string
  /**
   *
   * @type {string}
   * @memberof VixellIndexRequestComponent
   */
  timezone?: string
  /**
   *
   * @type {boolean}
   * @memberof VixellIndexRequestComponent
   */
  csv?: boolean
  /**
   *
   * @type {boolean}
   * @memberof VixellIndexRequestComponent
   */
  all?: boolean
  /**
   *
   * @type {string}
   * @memberof VixellIndexRequestComponent
   */
  exclusiveStartKey?: string
}
/**
 * 出荷時に共通する項目
 * @export
 * @interface VixellInspectionBoardContractInfo
 */
export interface VixellInspectionBoardContractInfo {
  /**
   * 契約代表No
   * @type {string}
   * @memberof VixellInspectionBoardContractInfo
   */
  contractRepresentativeNo: string
  /**
   * 契約No
   * @type {string}
   * @memberof VixellInspectionBoardContractInfo
   */
  contractNo: string
  /**
   * 管理No(半角英数記号　255文字以内)
   * @type {string}
   * @memberof VixellInspectionBoardContractInfo
   */
  managementNo: string
}
/**
 *
 * @export
 * @interface VixellInventory
 */
export interface VixellInventory {
  /**
   *
   * @type {string}
   * @memberof VixellInventory
   */
  serialNo: string
  /**
   *
   * @type {string}
   * @memberof VixellInventory
   */
  model: string
  /**
   *
   * @type {boolean}
   * @memberof VixellInventory
   */
  settled: boolean
  /**
   *
   * @type {string}
   * @memberof VixellInventory
   */
  fullContractNo?: string
  /**
   *
   * @type {string}
   * @memberof VixellInventory
   */
  managementNo?: string
  /**
   *
   * @type {string}
   * @memberof VixellInventory
   */
  processingDateTime: string
  /**
   *
   * @type {string}
   * @memberof VixellInventory
   */
  siteName?: string
  /**
   *
   * @type {ShippingStateValue}
   * @memberof VixellInventory
   */
  shippingState?: ShippingStateValue
  /**
   *
   * @type {string}
   * @memberof VixellInventory
   */
  shippingStateProcessingDateTime?: string
}
/**
 *
 * @export
 * @interface VixellInventoryLast
 */
export interface VixellInventoryLast {
  /**
   *
   * @type {string}
   * @memberof VixellInventoryLast
   */
  vixellSerialNo: string
  /**
   *
   * @type {boolean}
   * @memberof VixellInventoryLast
   */
  settled: boolean
  /**
   *
   * @type {ContractWithManagementNo}
   * @memberof VixellInventoryLast
   */
  contractInfo?: ContractWithManagementNo
  /**
   *
   * @type {string}
   * @memberof VixellInventoryLast
   */
  processingDateTime: string
  /**
   *
   * @type {SiteLast}
   * @memberof VixellInventoryLast
   */
  site?: SiteLast
}
/**
 *
 * @export
 * @interface VixellLast
 */
export interface VixellLast {
  /**
   *
   * @type {string}
   * @memberof VixellLast
   */
  vixellSerialNo: string
  /**
   *
   * @type {string}
   * @memberof VixellLast
   */
  model?: string
  /**
   *
   * @type {string}
   * @memberof VixellLast
   */
  IDm?: string
  /**
   *
   * @type {string}
   * @memberof VixellLast
   */
  stockedDateTime?: string
  /**
   *
   * @type {string}
   * @memberof VixellLast
   */
  disposedDateTime?: string
}
/**
 *
 * @export
 * @interface VixellProductData
 */
export interface VixellProductData {
  /**
   *
   * @type {string}
   * @memberof VixellProductData
   */
  type: VixellProductDataTypeEnum
  /**
   *
   * @type {Vixell}
   * @memberof VixellProductData
   */
  product: Vixell
}

export const VixellProductDataTypeEnum = {
  Vixell: 'VIXELL',
} as const

export type VixellProductDataTypeEnum =
  typeof VixellProductDataTypeEnum[keyof typeof VixellProductDataTypeEnum]

/**
 *
 * @export
 * @interface VixellReturn
 */
export interface VixellReturn {
  /**
   *
   * @type {string}
   * @memberof VixellReturn
   */
  app: VixellReturnAppEnum
  /**
   *
   * @type {string}
   * @memberof VixellReturn
   */
  logType: VixellReturnLogTypeEnum
  /**
   *
   * @type {CommonData & CommonVixell}
   * @memberof VixellReturn
   */
  data: CommonData & CommonVixell
}

export const VixellReturnAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type VixellReturnAppEnum =
  typeof VixellReturnAppEnum[keyof typeof VixellReturnAppEnum]
export const VixellReturnLogTypeEnum = {
  VixellReturn: 'vixellReturn',
} as const

export type VixellReturnLogTypeEnum =
  typeof VixellReturnLogTypeEnum[keyof typeof VixellReturnLogTypeEnum]

/**
 *
 * @export
 * @interface VixellShipping
 */
export interface VixellShipping {
  /**
   *
   * @type {string}
   * @memberof VixellShipping
   */
  app: VixellShippingAppEnum
  /**
   *
   * @type {string}
   * @memberof VixellShipping
   */
  logType: VixellShippingLogTypeEnum
  /**
   *
   * @type {CommonData & ShippingManagementCommonInfo & VixellInspectionBoardContractInfo & CommonVixell}
   * @memberof VixellShipping
   */
  data: CommonData &
    ShippingManagementCommonInfo &
    VixellInspectionBoardContractInfo &
    CommonVixell
}

export const VixellShippingAppEnum = {
  ShippingManagement: 'shippingManagement',
} as const

export type VixellShippingAppEnum =
  typeof VixellShippingAppEnum[keyof typeof VixellShippingAppEnum]
export const VixellShippingLogTypeEnum = {
  VixellShipping: 'vixellShipping',
} as const

export type VixellShippingLogTypeEnum =
  typeof VixellShippingLogTypeEnum[keyof typeof VixellShippingLogTypeEnum]

/**
 *
 * @export
 * @interface VixellsIndexRequest
 */
export interface VixellsIndexRequest {
  /**
   *
   * @type {string}
   * @memberof VixellsIndexRequest
   */
  type: VixellsIndexRequestTypeEnum
  /**
   *
   * @type {VixellIndexRequestComponent}
   * @memberof VixellsIndexRequest
   */
  data: VixellIndexRequestComponent
}

export const VixellsIndexRequestTypeEnum = {
  VixellsIndex: 'VixellsIndex',
} as const

export type VixellsIndexRequestTypeEnum =
  typeof VixellsIndexRequestTypeEnum[keyof typeof VixellsIndexRequestTypeEnum]

/**
 *
 * @export
 * @interface VixellsIndexResponse
 */
export interface VixellsIndexResponse {
  /**
   *
   * @type {string}
   * @memberof VixellsIndexResponse
   */
  type: VixellsIndexResponseTypeEnum
  /**
   *
   * @type {VixellsIndexResponseComponent}
   * @memberof VixellsIndexResponse
   */
  data: VixellsIndexResponseComponent
}

export const VixellsIndexResponseTypeEnum = {
  VixellsIndex: 'VixellsIndex',
} as const

export type VixellsIndexResponseTypeEnum =
  typeof VixellsIndexResponseTypeEnum[keyof typeof VixellsIndexResponseTypeEnum]

/**
 * @type VixellsIndexResponseComponent
 * @export
 */
export type VixellsIndexResponseComponent =
  | IndexVixellsResponseDataRootComponent
  | IndexVixellsResponseUrlComponent

/**
 *
 * @export
 * @interface VixellsShowRequest
 */
export interface VixellsShowRequest {
  /**
   *
   * @type {string}
   * @memberof VixellsShowRequest
   */
  type: VixellsShowRequestTypeEnum
  /**
   *
   * @type {ReusableOrderShowRequestData}
   * @memberof VixellsShowRequest
   */
  data: ReusableOrderShowRequestData
}

export const VixellsShowRequestTypeEnum = {
  VixellsShow: 'VixellsShow',
} as const

export type VixellsShowRequestTypeEnum =
  typeof VixellsShowRequestTypeEnum[keyof typeof VixellsShowRequestTypeEnum]

/**
 *
 * @export
 * @interface VixellsShowResponse
 */
export interface VixellsShowResponse {
  /**
   *
   * @type {string}
   * @memberof VixellsShowResponse
   */
  type: VixellsShowResponseTypeEnum
  /**
   *
   * @type {VixellsShowResponseComponent}
   * @memberof VixellsShowResponse
   */
  data: VixellsShowResponseComponent
}

export const VixellsShowResponseTypeEnum = {
  VixellsShow: 'VixellsShow',
} as const

export type VixellsShowResponseTypeEnum =
  typeof VixellsShowResponseTypeEnum[keyof typeof VixellsShowResponseTypeEnum]

/**
 *
 * @export
 * @interface VixellsShowResponseComponent
 */
export interface VixellsShowResponseComponent {
  /**
   *
   * @type {Vixell}
   * @memberof VixellsShowResponseComponent
   */
  vixell: Vixell
  /**
   *
   * @type {OnsiteVacuum}
   * @memberof VixellsShowResponseComponent
   */
  latestOnsiteResult?: OnsiteVacuum
  /**
   *
   * @type {VacuumProcess}
   * @memberof VixellsShowResponseComponent
   */
  vacuumProcess?: VacuumProcess
}
/**
 *
 * @export
 * @interface WebLogin
 */
export interface WebLogin {
  /**
   * サイトID
   * @type {string}
   * @memberof WebLogin
   */
  siteId: string
  /**
   * パスワード
   * @type {string}
   * @memberof WebLogin
   */
  password: string
}
/**
 *
 * @export
 * @interface WebLoginFailureResponse
 */
export interface WebLoginFailureResponse {
  /**
   *
   * @type {string}
   * @memberof WebLoginFailureResponse
   */
  type: WebLoginFailureResponseTypeEnum
  /**
   *
   * @type {string}
   * @memberof WebLoginFailureResponse
   */
  code: WebLoginFailureResponseCodeEnum
}

export const WebLoginFailureResponseTypeEnum = {
  InvalidRequestError: 'invalid-request-error',
} as const

export type WebLoginFailureResponseTypeEnum =
  typeof WebLoginFailureResponseTypeEnum[keyof typeof WebLoginFailureResponseTypeEnum]
export const WebLoginFailureResponseCodeEnum = {
  EmailRequired: 'email-required',
  PasswordRequired: 'password-required',
  InvalidEmailOrPassword: 'invalid-email-or-password',
} as const

export type WebLoginFailureResponseCodeEnum =
  typeof WebLoginFailureResponseCodeEnum[keyof typeof WebLoginFailureResponseCodeEnum]

/**
 *
 * @export
 * @interface WebLoginResponse
 */
export interface WebLoginResponse {
  /**
   * アクセストークン(有効期限は、最終アクセスから3ヶ月)
   * @type {string}
   * @memberof WebLoginResponse
   */
  token: string
  /**
   *
   * @type {SiteLast}
   * @memberof WebLoginResponse
   */
  site: SiteLast
}
/**
 * @type WebRequest
 * @export
 */
export type WebRequest =
  | AdminDashboardRequest
  | BelongingLogsIndexRequest
  | ClientLogsIndexRequest
  | CustomersChangeSitePasswordRequest
  | CustomersCreateRequest
  | CustomersDestroyRequest
  | CustomersIndexRequest
  | IcePacksIndexRequest
  | IcePacksShowRequest
  | InspectionBoardsIndexRequest
  | InspectionBoardsShowRequest
  | InventoriesTemperatureLoggersIndexRequest
  | InventoriesTemperatureSensorTagsIndexRequest
  | InventoriesThermalStorageUnitsIndexRequest
  | InventoriesVixellsIndexRequest
  | LoginRequest
  | MeShowRequest
  | MeUpdateRequest
  | OnsiteIcePackResultUnitsIndexRequest
  | OnsiteResultsIndexRequest
  | OnsiteResultsPressureGraphRequest
  | OnsiteVacuumResultsIndexRequest
  | ProductNotesCreateRequest
  | ProductNotesIndexRequest
  | ReusableOrderCreateRequest
  | ReusableOrderDashboardRequest
  | ReusableOrderDefectiveRequest
  | ReusableOrderFetchOrderDetailRequest
  | ReusableOrderIndexRequest
  | ReusableOrderLendingRequest
  | ReusableOrderLostItemRequest
  | ReusableOrderResetOrderDetailRequest
  | ReusableOrderReturnRequest
  | ReusableOrderShipRequest
  | ReusableOrderShowRequest
  | ReusableOrderStatesIndexRequest
  | ReusableOrderStockRequest
  | ReusableOrderUpdateRequest
  | SearchWithSerialNoRequest
  | ShippingStateLogsIndexRequest
  | SiteDashboardRequest
  | SitesChangePasswordRequest
  | SitesCreateAdminRequest
  | SitesCreateRequest
  | SitesDestroyRequest
  | SitesIndexRequest
  | TemperatureLoggersIndexRequest
  | TemperatureLoggersShowRequest
  | ThermalStorageUnitsIndexRequest
  | ThermalStorageUnitsShowRequest
  | TradesIndexRequest
  | TradesShowRequest
  | TradesUpdateRequest
  | VixellsIndexRequest
  | VixellsShowRequest

/**
 * @type WebResponse
 * @export
 */
export type WebResponse =
  | AdminDashboardResponse
  | BelongingLogsIndexResponse
  | ClientLogsIndexResponse
  | CustomersChangeSitePasswordResponse
  | CustomersCreateResponse
  | CustomersDestroyResponse
  | CustomersIndexResponse
  | IcePacksIndexResponse
  | IcePacksShowResponse
  | InspectionBoardsIndexResponse
  | InspectionBoardsShowResponse
  | InventoriesTemperatureLoggersIndexResponse
  | InventoriesTemperatureSensorTagsIndexResponse
  | InventoriesThermalStorageUnitsIndexResponse
  | InventoriesVixellsIndexResponse
  | LoginResponse1
  | MeShowResponse
  | MeUpdateResponse
  | OnsiteIcePackResultUnitsIndexResponse
  | OnsiteResultsIndexResponse
  | OnsiteResultsPressureGraphResponse
  | OnsiteVacuumResultsIndexResponse
  | ProductNotesCreateResponse
  | ProductNotesIndexResponse
  | ReusableOrderCreateResponse
  | ReusableOrderDashboardResponse
  | ReusableOrderDefectiveResponse
  | ReusableOrderFetchOrderDetailResponse
  | ReusableOrderIndexResponse
  | ReusableOrderLendingResponse
  | ReusableOrderLostItemResponse
  | ReusableOrderResetOrderDetailResponse
  | ReusableOrderReturnResponse
  | ReusableOrderShipResponse
  | ReusableOrderShowResponse
  | ReusableOrderStatesIndexResponse
  | ReusableOrderStockResponse
  | ReusableOrderUpdateResponse
  | SearchWithSerialNoResponse
  | ShippingStateLogsIndexResponse
  | SiteDashboardResponse
  | SitesChangePasswordResponse
  | SitesCreateAdminResponse
  | SitesCreateResponse
  | SitesDestroyResponse
  | SitesIndexResponse
  | TemperatureLoggersIndexResponse
  | TemperatureLoggersShowResponse
  | ThermalStorageUnitsIndexResponse
  | ThermalStorageUnitsShowResponse
  | TradesIndexResponse
  | TradesShowResponse
  | TradesUpdateResponse
  | VixellsIndexResponse
  | VixellsShowResponse

/**
 *
 * @export
 * @interface WebShowOnsiteResult
 */
export interface WebShowOnsiteResult {
  /**
   *
   * @type {string}
   * @memberof WebShowOnsiteResult
   */
  pcId?: string
  /**
   *
   * @type {string}
   * @memberof WebShowOnsiteResult
   */
  processingDateTime?: string
}

/**
 * APIApi - axios parameter creator
 * @export
 */
export const APIApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary トークン有効性チェック
     * @param {string} userAgent ユーザーエージェント。設定値は空でなければ何でも良い。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTokenValidity: async (
      userAgent: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userAgent' is not null or undefined
      assertParamExists('checkTokenValidity', 'userAgent', userAgent)
      const localVarPath = `/client-app/check-token-validity`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication clientAppToken required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      if (userAgent !== undefined && userAgent !== null) {
        localVarHeaderParameter['User-Agent'] = String(userAgent)
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ログイン
     * @param {string} userAgent ユーザーエージェント。設定値は空でなければ何でも良い。
     * @param {Login} [login]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (
      userAgent: string,
      login?: Login,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'userAgent' is not null or undefined
      assertParamExists('login', 'userAgent', userAgent)
      const localVarPath = `/client-app/login`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (userAgent !== undefined && userAgent !== null) {
        localVarHeaderParameter['User-Agent'] = String(userAgent)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        login,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * APIの基本方針。 リクエストを受けた際にマスタデータが存在しないなら常に作成する。 工程関連処理も、順序が混同していたとしてもデータの更新、追加を行う。
     * @summary クライアントアプリデータ登録API
     * @param {string} xUNIQUEREQUESTID APIリクエスト毎のユニークID。このIDを事前に処理していたら 204 を返す
     * @param {string} userAgent ユーザーエージェント。設定値は空でなければ何でも良い。
     * @param {LogRequest} [logRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadLog: async (
      xUNIQUEREQUESTID: string,
      userAgent: string,
      logRequest?: LogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'xUNIQUEREQUESTID' is not null or undefined
      assertParamExists('uploadLog', 'xUNIQUEREQUESTID', xUNIQUEREQUESTID)
      // verify required parameter 'userAgent' is not null or undefined
      assertParamExists('uploadLog', 'userAgent', userAgent)
      const localVarPath = `/client-app/logs`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      if (xUNIQUEREQUESTID !== undefined && xUNIQUEREQUESTID !== null) {
        localVarHeaderParameter['X-UNIQUE-REQUEST-ID'] =
          String(xUNIQUEREQUESTID)
      }

      if (userAgent !== undefined && userAgent !== null) {
        localVarHeaderParameter['User-Agent'] = String(userAgent)
      }

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        logRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * APIApi - functional programming interface
 * @export
 */
export const APIApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = APIApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary トークン有効性チェック
     * @param {string} userAgent ユーザーエージェント。設定値は空でなければ何でも良い。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkTokenValidity(
      userAgent: string,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse2001>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.checkTokenValidity(userAgent, options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary ログイン
     * @param {string} userAgent ユーザーエージェント。設定値は空でなければ何でも良い。
     * @param {Login} [login]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(
      userAgent: string,
      login?: Login,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<LoginResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(
        userAgent,
        login,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * APIの基本方針。 リクエストを受けた際にマスタデータが存在しないなら常に作成する。 工程関連処理も、順序が混同していたとしてもデータの更新、追加を行う。
     * @summary クライアントアプリデータ登録API
     * @param {string} xUNIQUEREQUESTID APIリクエスト毎のユニークID。このIDを事前に処理していたら 204 を返す
     * @param {string} userAgent ユーザーエージェント。設定値は空でなければ何でも良い。
     * @param {LogRequest} [logRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadLog(
      xUNIQUEREQUESTID: string,
      userAgent: string,
      logRequest?: LogRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse200>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLog(
        xUNIQUEREQUESTID,
        userAgent,
        logRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * APIApi - factory interface
 * @export
 */
export const APIApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = APIApiFp(configuration)
  return {
    /**
     *
     * @summary トークン有効性チェック
     * @param {string} userAgent ユーザーエージェント。設定値は空でなければ何でも良い。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkTokenValidity(
      userAgent: string,
      options?: any
    ): AxiosPromise<InlineResponse2001> {
      return localVarFp
        .checkTokenValidity(userAgent, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ログイン
     * @param {string} userAgent ユーザーエージェント。設定値は空でなければ何でも良い。
     * @param {Login} [login]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(
      userAgent: string,
      login?: Login,
      options?: any
    ): AxiosPromise<LoginResponse> {
      return localVarFp
        .login(userAgent, login, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * APIの基本方針。 リクエストを受けた際にマスタデータが存在しないなら常に作成する。 工程関連処理も、順序が混同していたとしてもデータの更新、追加を行う。
     * @summary クライアントアプリデータ登録API
     * @param {string} xUNIQUEREQUESTID APIリクエスト毎のユニークID。このIDを事前に処理していたら 204 を返す
     * @param {string} userAgent ユーザーエージェント。設定値は空でなければ何でも良い。
     * @param {LogRequest} [logRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadLog(
      xUNIQUEREQUESTID: string,
      userAgent: string,
      logRequest?: LogRequest,
      options?: any
    ): AxiosPromise<InlineResponse200> {
      return localVarFp
        .uploadLog(xUNIQUEREQUESTID, userAgent, logRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * APIApi - object-oriented interface
 * @export
 * @class APIApi
 * @extends {BaseAPI}
 */
export class APIApi extends BaseAPI {
  /**
   *
   * @summary トークン有効性チェック
   * @param {string} userAgent ユーザーエージェント。設定値は空でなければ何でも良い。
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public checkTokenValidity(userAgent: string, options?: AxiosRequestConfig) {
    return APIApiFp(this.configuration)
      .checkTokenValidity(userAgent, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ログイン
   * @param {string} userAgent ユーザーエージェント。設定値は空でなければ何でも良い。
   * @param {Login} [login]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public login(userAgent: string, login?: Login, options?: AxiosRequestConfig) {
    return APIApiFp(this.configuration)
      .login(userAgent, login, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * APIの基本方針。 リクエストを受けた際にマスタデータが存在しないなら常に作成する。 工程関連処理も、順序が混同していたとしてもデータの更新、追加を行う。
   * @summary クライアントアプリデータ登録API
   * @param {string} xUNIQUEREQUESTID APIリクエスト毎のユニークID。このIDを事前に処理していたら 204 を返す
   * @param {string} userAgent ユーザーエージェント。設定値は空でなければ何でも良い。
   * @param {LogRequest} [logRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof APIApi
   */
  public uploadLog(
    xUNIQUEREQUESTID: string,
    userAgent: string,
    logRequest?: LogRequest,
    options?: AxiosRequestConfig
  ) {
    return APIApiFp(this.configuration)
      .uploadLog(xUNIQUEREQUESTID, userAgent, logRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * WebAdminApi - axios parameter creator
 * @export
 */
export const WebAdminApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary 代表アカウントパスワード変更
     * @param {ChangePassword1} [changePassword1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeRepresentativeSitePassword: async (
      changePassword1?: ChangePassword1,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/customers/change-password`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        changePassword1,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 顧客作成
     * @param {CreateCustomer} [createCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomer: async (
      createCustomer?: CreateCustomer,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/customers/create`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createCustomer,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 管理サイト作成
     * @param {CreateSite1} [createSite1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSite: async (
      createSite1?: CreateSite1,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/sites/create-admin`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSite1,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 顧客削除
     * @param {DestroyCustomer} [destroyCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    destroyCustomer: async (
      destroyCustomer?: DestroyCustomer,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/customers/destroy`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        destroyCustomer,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary サイト削除
     * @param {SetSiteId} [setSiteId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    destroySite: async (
      setSiteId?: SetSiteId,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/sites/destroy`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        setSiteId,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary IcePackオンサイト検査履歴
     * @param {PagingRequest1} [pagingRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    icePackOnsiteResults: async (
      pagingRequest1?: PagingRequest1,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/ice-packs/show/onsite-results`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        pagingRequest1,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary IcePack取引履歴
     * @param {PagingRequest1} [pagingRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    icePackShippingStates: async (
      pagingRequest1?: PagingRequest1,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/ice-packs/show/shipping-states`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        pagingRequest1,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary IcePack移動履歴
     * @param {PagingRequest1} [pagingRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    icePackTransitions: async (
      pagingRequest1?: PagingRequest1,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/ice-packs/show/transitions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        pagingRequest1,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary クライアントログ一覧
     * @param {IndexClientLogs} [indexClientLogs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexClientLogs: async (
      indexClientLogs?: IndexClientLogs,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/client-logs/index`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        indexClientLogs,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 顧客一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexCustomers: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/customers/index`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary IcePack一覧
     * @param {IndexIcePacks} [indexIcePacks]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexIcePacks: async (
      indexIcePacks?: IndexIcePacks,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/ice-packs/index`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        indexIcePacks,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 検査台一覧
     * @param {IndexInspectionBoards} [indexInspectionBoards]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexInspectionBoards: async (
      indexInspectionBoards?: IndexInspectionBoards,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/inspection-boards/index`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        indexInspectionBoards,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary オンサイト結果一覧
     * @param {IndexOnsiteResult} [indexOnsiteResult]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexOnsiteResults: async (
      indexOnsiteResult?: IndexOnsiteResult,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/onsite-results/index`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        indexOnsiteResult,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary サイト一覧
     * @param {IndexSites} [indexSites]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexSites: async (
      indexSites?: IndexSites,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/sites/index`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        indexSites,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 取引一覧
     * @param {IndexTrades} [indexTrades]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexTrades: async (
      indexTrades?: IndexTrades,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/trades/index`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        indexTrades,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Viell一覧
     * @param {IndexVixellRequest} [indexVixellRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexVixells: async (
      indexVixellRequest?: IndexVixellRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/vixells/index`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        indexVixellRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 検査台取引履歴
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inspectionBoardShippingStates: async (
      inlineObject?: InlineObject,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/inspection-boards/show/shipping-states`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        inlineObject,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ダッシュボード表示
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showDashboard: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/dashboard/show`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary IcePack詳細
     * @param {ShowIcePack} [showIcePack]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showIcePack: async (
      showIcePack?: ShowIcePack,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/ice-packs/show`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        showIcePack,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 検査台詳細
     * @param {ShowInspectionBoard} [showInspectionBoard]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showInspectionBoard: async (
      showInspectionBoard?: ShowInspectionBoard,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/inspection-boards/show`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        showInspectionBoard,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary オンサイト結果詳細
     * @param {ShowOnsiteResult} [showOnsiteResult]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showOnsiteResult: async (
      showOnsiteResult?: ShowOnsiteResult,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/onsite-results/show`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        showOnsiteResult,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 取引詳細
     * @param {ShowTrade} [showTrade]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showTrade: async (
      showTrade?: ShowTrade,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/trades/show`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        showTrade,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Viell詳細
     * @param {ShowVixellRequest} [showVixellRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showVixell: async (
      showVixellRequest?: ShowVixellRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/vixells/show`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        showVixellRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 取引更新
     * @param {UpdateTrade} [updateTrade]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTrade: async (
      updateTrade?: UpdateTrade,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/trades/update`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateTrade,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Vixellオンサイト検査履歴
     * @param {PagingRequest} [pagingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vixellOnsiteResults: async (
      pagingRequest?: PagingRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/vixells/show/onsite-results`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        pagingRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Vixell取引履歴
     * @param {PagingRequest} [pagingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vixellShippingStates: async (
      pagingRequest?: PagingRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/vixells/show/shipping-states`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        pagingRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary Vixell移動履歴
     * @param {PagingRequest} [pagingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vixellTransitions: async (
      pagingRequest?: PagingRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/admin/vixells/show/transitions`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        pagingRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WebAdminApi - functional programming interface
 * @export
 */
export const WebAdminApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WebAdminApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary 代表アカウントパスワード変更
     * @param {ChangePassword1} [changePassword1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeRepresentativeSitePassword(
      changePassword1?: ChangePassword1,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.changeRepresentativeSitePassword(
          changePassword1,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 顧客作成
     * @param {CreateCustomer} [createCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCustomer(
      createCustomer?: CreateCustomer,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20010>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCustomer(
        createCustomer,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 管理サイト作成
     * @param {CreateSite1} [createSite1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSite(
      createSite1?: CreateSite1,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSite(
        createSite1,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 顧客削除
     * @param {DestroyCustomer} [destroyCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async destroyCustomer(
      destroyCustomer?: DestroyCustomer,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.destroyCustomer(
        destroyCustomer,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary サイト削除
     * @param {SetSiteId} [setSiteId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async destroySite(
      setSiteId?: SetSiteId,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.destroySite(
        setSiteId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary IcePackオンサイト検査履歴
     * @param {PagingRequest1} [pagingRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async icePackOnsiteResults(
      pagingRequest1?: PagingRequest1,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20017>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.icePackOnsiteResults(
          pagingRequest1,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary IcePack取引履歴
     * @param {PagingRequest1} [pagingRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async icePackShippingStates(
      pagingRequest1?: PagingRequest1,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20013>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.icePackShippingStates(
          pagingRequest1,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary IcePack移動履歴
     * @param {PagingRequest1} [pagingRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async icePackTransitions(
      pagingRequest1?: PagingRequest1,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20018>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.icePackTransitions(
          pagingRequest1,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary クライアントログ一覧
     * @param {IndexClientLogs} [indexClientLogs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indexClientLogs(
      indexClientLogs?: IndexClientLogs,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20022>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indexClientLogs(
        indexClientLogs,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 顧客一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indexCustomers(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse2009>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indexCustomers(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary IcePack一覧
     * @param {IndexIcePacks} [indexIcePacks]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indexIcePacks(
      indexIcePacks?: IndexIcePacks,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20016>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indexIcePacks(
        indexIcePacks,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 検査台一覧
     * @param {IndexInspectionBoards} [indexInspectionBoards]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indexInspectionBoards(
      indexInspectionBoards?: IndexInspectionBoards,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20019>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.indexInspectionBoards(
          indexInspectionBoards,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary オンサイト結果一覧
     * @param {IndexOnsiteResult} [indexOnsiteResult]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indexOnsiteResults(
      indexOnsiteResult?: IndexOnsiteResult,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20021>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.indexOnsiteResults(
          indexOnsiteResult,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary サイト一覧
     * @param {IndexSites} [indexSites]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indexSites(
      indexSites?: IndexSites,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20011>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indexSites(
        indexSites,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 取引一覧
     * @param {IndexTrades} [indexTrades]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indexTrades(
      indexTrades?: IndexTrades,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20023>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indexTrades(
        indexTrades,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Viell一覧
     * @param {IndexVixellRequest} [indexVixellRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indexVixells(
      indexVixellRequest?: IndexVixellRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20012>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indexVixells(
        indexVixellRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 検査台取引履歴
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async inspectionBoardShippingStates(
      inlineObject?: InlineObject,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20013>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.inspectionBoardShippingStates(
          inlineObject,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary ダッシュボード表示
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showDashboard(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<DashboardForAdminLast>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.showDashboard(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary IcePack詳細
     * @param {ShowIcePack} [showIcePack]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showIcePack(
      showIcePack?: ShowIcePack,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ShowIcePackResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.showIcePack(
        showIcePack,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 検査台詳細
     * @param {ShowInspectionBoard} [showInspectionBoard]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showInspectionBoard(
      showInspectionBoard?: ShowInspectionBoard,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20020>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.showInspectionBoard(
          showInspectionBoard,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary オンサイト結果詳細
     * @param {ShowOnsiteResult} [showOnsiteResult]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showOnsiteResult(
      showOnsiteResult?: ShowOnsiteResult,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse2007>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.showOnsiteResult(
          showOnsiteResult,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 取引詳細
     * @param {ShowTrade} [showTrade]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showTrade(
      showTrade?: ShowTrade,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20024>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.showTrade(
        showTrade,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Viell詳細
     * @param {ShowVixellRequest} [showVixellRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showVixell(
      showVixellRequest?: ShowVixellRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<ShowVixellResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.showVixell(
        showVixellRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 取引更新
     * @param {UpdateTrade} [updateTrade]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTrade(
      updateTrade?: UpdateTrade,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateTrade(
        updateTrade,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Vixellオンサイト検査履歴
     * @param {PagingRequest} [pagingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async vixellOnsiteResults(
      pagingRequest?: PagingRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20014>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.vixellOnsiteResults(
          pagingRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Vixell取引履歴
     * @param {PagingRequest} [pagingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async vixellShippingStates(
      pagingRequest?: PagingRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20013>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.vixellShippingStates(
          pagingRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary Vixell移動履歴
     * @param {PagingRequest} [pagingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async vixellTransitions(
      pagingRequest?: PagingRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse20015>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.vixellTransitions(
          pagingRequest,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * WebAdminApi - factory interface
 * @export
 */
export const WebAdminApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = WebAdminApiFp(configuration)
  return {
    /**
     *
     * @summary 代表アカウントパスワード変更
     * @param {ChangePassword1} [changePassword1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeRepresentativeSitePassword(
      changePassword1?: ChangePassword1,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .changeRepresentativeSitePassword(changePassword1, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 顧客作成
     * @param {CreateCustomer} [createCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCustomer(
      createCustomer?: CreateCustomer,
      options?: any
    ): AxiosPromise<InlineResponse20010> {
      return localVarFp
        .createCustomer(createCustomer, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 管理サイト作成
     * @param {CreateSite1} [createSite1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSite(createSite1?: CreateSite1, options?: any): AxiosPromise<void> {
      return localVarFp
        .createSite(createSite1, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 顧客削除
     * @param {DestroyCustomer} [destroyCustomer]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    destroyCustomer(
      destroyCustomer?: DestroyCustomer,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .destroyCustomer(destroyCustomer, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary サイト削除
     * @param {SetSiteId} [setSiteId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    destroySite(setSiteId?: SetSiteId, options?: any): AxiosPromise<void> {
      return localVarFp
        .destroySite(setSiteId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary IcePackオンサイト検査履歴
     * @param {PagingRequest1} [pagingRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    icePackOnsiteResults(
      pagingRequest1?: PagingRequest1,
      options?: any
    ): AxiosPromise<InlineResponse20017> {
      return localVarFp
        .icePackOnsiteResults(pagingRequest1, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary IcePack取引履歴
     * @param {PagingRequest1} [pagingRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    icePackShippingStates(
      pagingRequest1?: PagingRequest1,
      options?: any
    ): AxiosPromise<InlineResponse20013> {
      return localVarFp
        .icePackShippingStates(pagingRequest1, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary IcePack移動履歴
     * @param {PagingRequest1} [pagingRequest1]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    icePackTransitions(
      pagingRequest1?: PagingRequest1,
      options?: any
    ): AxiosPromise<InlineResponse20018> {
      return localVarFp
        .icePackTransitions(pagingRequest1, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary クライアントログ一覧
     * @param {IndexClientLogs} [indexClientLogs]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexClientLogs(
      indexClientLogs?: IndexClientLogs,
      options?: any
    ): AxiosPromise<InlineResponse20022> {
      return localVarFp
        .indexClientLogs(indexClientLogs, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 顧客一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexCustomers(options?: any): AxiosPromise<InlineResponse2009> {
      return localVarFp
        .indexCustomers(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary IcePack一覧
     * @param {IndexIcePacks} [indexIcePacks]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexIcePacks(
      indexIcePacks?: IndexIcePacks,
      options?: any
    ): AxiosPromise<InlineResponse20016> {
      return localVarFp
        .indexIcePacks(indexIcePacks, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 検査台一覧
     * @param {IndexInspectionBoards} [indexInspectionBoards]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexInspectionBoards(
      indexInspectionBoards?: IndexInspectionBoards,
      options?: any
    ): AxiosPromise<InlineResponse20019> {
      return localVarFp
        .indexInspectionBoards(indexInspectionBoards, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary オンサイト結果一覧
     * @param {IndexOnsiteResult} [indexOnsiteResult]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexOnsiteResults(
      indexOnsiteResult?: IndexOnsiteResult,
      options?: any
    ): AxiosPromise<InlineResponse20021> {
      return localVarFp
        .indexOnsiteResults(indexOnsiteResult, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary サイト一覧
     * @param {IndexSites} [indexSites]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexSites(
      indexSites?: IndexSites,
      options?: any
    ): AxiosPromise<InlineResponse20011> {
      return localVarFp
        .indexSites(indexSites, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 取引一覧
     * @param {IndexTrades} [indexTrades]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexTrades(
      indexTrades?: IndexTrades,
      options?: any
    ): AxiosPromise<InlineResponse20023> {
      return localVarFp
        .indexTrades(indexTrades, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Viell一覧
     * @param {IndexVixellRequest} [indexVixellRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexVixells(
      indexVixellRequest?: IndexVixellRequest,
      options?: any
    ): AxiosPromise<InlineResponse20012> {
      return localVarFp
        .indexVixells(indexVixellRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 検査台取引履歴
     * @param {InlineObject} [inlineObject]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    inspectionBoardShippingStates(
      inlineObject?: InlineObject,
      options?: any
    ): AxiosPromise<InlineResponse20013> {
      return localVarFp
        .inspectionBoardShippingStates(inlineObject, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ダッシュボード表示
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showDashboard(options?: any): AxiosPromise<DashboardForAdminLast> {
      return localVarFp
        .showDashboard(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary IcePack詳細
     * @param {ShowIcePack} [showIcePack]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showIcePack(
      showIcePack?: ShowIcePack,
      options?: any
    ): AxiosPromise<ShowIcePackResponse> {
      return localVarFp
        .showIcePack(showIcePack, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 検査台詳細
     * @param {ShowInspectionBoard} [showInspectionBoard]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showInspectionBoard(
      showInspectionBoard?: ShowInspectionBoard,
      options?: any
    ): AxiosPromise<InlineResponse20020> {
      return localVarFp
        .showInspectionBoard(showInspectionBoard, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary オンサイト結果詳細
     * @param {ShowOnsiteResult} [showOnsiteResult]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showOnsiteResult(
      showOnsiteResult?: ShowOnsiteResult,
      options?: any
    ): AxiosPromise<InlineResponse2007> {
      return localVarFp
        .showOnsiteResult(showOnsiteResult, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 取引詳細
     * @param {ShowTrade} [showTrade]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showTrade(
      showTrade?: ShowTrade,
      options?: any
    ): AxiosPromise<InlineResponse20024> {
      return localVarFp
        .showTrade(showTrade, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Viell詳細
     * @param {ShowVixellRequest} [showVixellRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showVixell(
      showVixellRequest?: ShowVixellRequest,
      options?: any
    ): AxiosPromise<ShowVixellResponse> {
      return localVarFp
        .showVixell(showVixellRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 取引更新
     * @param {UpdateTrade} [updateTrade]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTrade(updateTrade?: UpdateTrade, options?: any): AxiosPromise<void> {
      return localVarFp
        .updateTrade(updateTrade, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Vixellオンサイト検査履歴
     * @param {PagingRequest} [pagingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vixellOnsiteResults(
      pagingRequest?: PagingRequest,
      options?: any
    ): AxiosPromise<InlineResponse20014> {
      return localVarFp
        .vixellOnsiteResults(pagingRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Vixell取引履歴
     * @param {PagingRequest} [pagingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vixellShippingStates(
      pagingRequest?: PagingRequest,
      options?: any
    ): AxiosPromise<InlineResponse20013> {
      return localVarFp
        .vixellShippingStates(pagingRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary Vixell移動履歴
     * @param {PagingRequest} [pagingRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vixellTransitions(
      pagingRequest?: PagingRequest,
      options?: any
    ): AxiosPromise<InlineResponse20015> {
      return localVarFp
        .vixellTransitions(pagingRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * WebAdminApi - object-oriented interface
 * @export
 * @class WebAdminApi
 * @extends {BaseAPI}
 */
export class WebAdminApi extends BaseAPI {
  /**
   *
   * @summary 代表アカウントパスワード変更
   * @param {ChangePassword1} [changePassword1]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public changeRepresentativeSitePassword(
    changePassword1?: ChangePassword1,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .changeRepresentativeSitePassword(changePassword1, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 顧客作成
   * @param {CreateCustomer} [createCustomer]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public createCustomer(
    createCustomer?: CreateCustomer,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .createCustomer(createCustomer, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 管理サイト作成
   * @param {CreateSite1} [createSite1]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public createSite(createSite1?: CreateSite1, options?: AxiosRequestConfig) {
    return WebAdminApiFp(this.configuration)
      .createSite(createSite1, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 顧客削除
   * @param {DestroyCustomer} [destroyCustomer]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public destroyCustomer(
    destroyCustomer?: DestroyCustomer,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .destroyCustomer(destroyCustomer, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary サイト削除
   * @param {SetSiteId} [setSiteId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public destroySite(setSiteId?: SetSiteId, options?: AxiosRequestConfig) {
    return WebAdminApiFp(this.configuration)
      .destroySite(setSiteId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary IcePackオンサイト検査履歴
   * @param {PagingRequest1} [pagingRequest1]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public icePackOnsiteResults(
    pagingRequest1?: PagingRequest1,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .icePackOnsiteResults(pagingRequest1, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary IcePack取引履歴
   * @param {PagingRequest1} [pagingRequest1]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public icePackShippingStates(
    pagingRequest1?: PagingRequest1,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .icePackShippingStates(pagingRequest1, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary IcePack移動履歴
   * @param {PagingRequest1} [pagingRequest1]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public icePackTransitions(
    pagingRequest1?: PagingRequest1,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .icePackTransitions(pagingRequest1, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary クライアントログ一覧
   * @param {IndexClientLogs} [indexClientLogs]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public indexClientLogs(
    indexClientLogs?: IndexClientLogs,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .indexClientLogs(indexClientLogs, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 顧客一覧
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public indexCustomers(options?: AxiosRequestConfig) {
    return WebAdminApiFp(this.configuration)
      .indexCustomers(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary IcePack一覧
   * @param {IndexIcePacks} [indexIcePacks]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public indexIcePacks(
    indexIcePacks?: IndexIcePacks,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .indexIcePacks(indexIcePacks, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 検査台一覧
   * @param {IndexInspectionBoards} [indexInspectionBoards]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public indexInspectionBoards(
    indexInspectionBoards?: IndexInspectionBoards,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .indexInspectionBoards(indexInspectionBoards, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary オンサイト結果一覧
   * @param {IndexOnsiteResult} [indexOnsiteResult]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public indexOnsiteResults(
    indexOnsiteResult?: IndexOnsiteResult,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .indexOnsiteResults(indexOnsiteResult, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary サイト一覧
   * @param {IndexSites} [indexSites]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public indexSites(indexSites?: IndexSites, options?: AxiosRequestConfig) {
    return WebAdminApiFp(this.configuration)
      .indexSites(indexSites, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 取引一覧
   * @param {IndexTrades} [indexTrades]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public indexTrades(indexTrades?: IndexTrades, options?: AxiosRequestConfig) {
    return WebAdminApiFp(this.configuration)
      .indexTrades(indexTrades, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Viell一覧
   * @param {IndexVixellRequest} [indexVixellRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public indexVixells(
    indexVixellRequest?: IndexVixellRequest,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .indexVixells(indexVixellRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 検査台取引履歴
   * @param {InlineObject} [inlineObject]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public inspectionBoardShippingStates(
    inlineObject?: InlineObject,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .inspectionBoardShippingStates(inlineObject, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ダッシュボード表示
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public showDashboard(options?: AxiosRequestConfig) {
    return WebAdminApiFp(this.configuration)
      .showDashboard(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary IcePack詳細
   * @param {ShowIcePack} [showIcePack]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public showIcePack(showIcePack?: ShowIcePack, options?: AxiosRequestConfig) {
    return WebAdminApiFp(this.configuration)
      .showIcePack(showIcePack, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 検査台詳細
   * @param {ShowInspectionBoard} [showInspectionBoard]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public showInspectionBoard(
    showInspectionBoard?: ShowInspectionBoard,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .showInspectionBoard(showInspectionBoard, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary オンサイト結果詳細
   * @param {ShowOnsiteResult} [showOnsiteResult]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public showOnsiteResult(
    showOnsiteResult?: ShowOnsiteResult,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .showOnsiteResult(showOnsiteResult, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 取引詳細
   * @param {ShowTrade} [showTrade]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public showTrade(showTrade?: ShowTrade, options?: AxiosRequestConfig) {
    return WebAdminApiFp(this.configuration)
      .showTrade(showTrade, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Viell詳細
   * @param {ShowVixellRequest} [showVixellRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public showVixell(
    showVixellRequest?: ShowVixellRequest,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .showVixell(showVixellRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 取引更新
   * @param {UpdateTrade} [updateTrade]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public updateTrade(updateTrade?: UpdateTrade, options?: AxiosRequestConfig) {
    return WebAdminApiFp(this.configuration)
      .updateTrade(updateTrade, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Vixellオンサイト検査履歴
   * @param {PagingRequest} [pagingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public vixellOnsiteResults(
    pagingRequest?: PagingRequest,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .vixellOnsiteResults(pagingRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Vixell取引履歴
   * @param {PagingRequest} [pagingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public vixellShippingStates(
    pagingRequest?: PagingRequest,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .vixellShippingStates(pagingRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary Vixell移動履歴
   * @param {PagingRequest} [pagingRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebAdminApi
   */
  public vixellTransitions(
    pagingRequest?: PagingRequest,
    options?: AxiosRequestConfig
  ) {
    return WebAdminApiFp(this.configuration)
      .vixellTransitions(pagingRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * WebCommonApi - axios parameter creator
 * @export
 */
export const WebCommonApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary ログイン
     * @param {WebLogin} [webLogin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (
      webLogin?: WebLogin,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/web/login`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        webLogin,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ログアウト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/web/logout`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary アカウント情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showMe: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/web/me/show`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary アカウント情報更新
     * @param {UpdateMe} [updateMe]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMe: async (
      updateMe?: UpdateMe,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/web/me/update`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateMe,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WebCommonApi - functional programming interface
 * @export
 */
export const WebCommonApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WebCommonApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary ログイン
     * @param {WebLogin} [webLogin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(
      webLogin?: WebLogin,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<WebLoginResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(
        webLogin,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary ログアウト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logout(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary アカウント情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showMe(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse2002>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.showMe(options)
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary アカウント情報更新
     * @param {UpdateMe} [updateMe]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateMe(
      updateMe?: UpdateMe,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateMe(
        updateMe,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * WebCommonApi - factory interface
 * @export
 */
export const WebCommonApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = WebCommonApiFp(configuration)
  return {
    /**
     *
     * @summary ログイン
     * @param {WebLogin} [webLogin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(webLogin?: WebLogin, options?: any): AxiosPromise<WebLoginResponse> {
      return localVarFp
        .login(webLogin, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ログアウト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(options?: any): AxiosPromise<void> {
      return localVarFp
        .logout(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary アカウント情報取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showMe(options?: any): AxiosPromise<InlineResponse2002> {
      return localVarFp
        .showMe(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary アカウント情報更新
     * @param {UpdateMe} [updateMe]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMe(updateMe?: UpdateMe, options?: any): AxiosPromise<void> {
      return localVarFp
        .updateMe(updateMe, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * WebCommonApi - object-oriented interface
 * @export
 * @class WebCommonApi
 * @extends {BaseAPI}
 */
export class WebCommonApi extends BaseAPI {
  /**
   *
   * @summary ログイン
   * @param {WebLogin} [webLogin]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebCommonApi
   */
  public login(webLogin?: WebLogin, options?: AxiosRequestConfig) {
    return WebCommonApiFp(this.configuration)
      .login(webLogin, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ログアウト
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebCommonApi
   */
  public logout(options?: AxiosRequestConfig) {
    return WebCommonApiFp(this.configuration)
      .logout(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary アカウント情報取得
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebCommonApi
   */
  public showMe(options?: AxiosRequestConfig) {
    return WebCommonApiFp(this.configuration)
      .showMe(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary アカウント情報更新
   * @param {UpdateMe} [updateMe]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebCommonApi
   */
  public updateMe(updateMe?: UpdateMe, options?: AxiosRequestConfig) {
    return WebCommonApiFp(this.configuration)
      .updateMe(updateMe, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * WebCustomerApi - axios parameter creator
 * @export
 */
export const WebCustomerApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary サイトのパスワード変更
     * @param {ChangePassword} [changePassword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeSitePassword: async (
      changePassword?: ChangePassword,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/web/sites/change-password`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        changePassword,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 親アカウントのみアクセス可
     * @summary サイト作成
     * @param {CreateSite} [createSite]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSite: async (
      createSite?: CreateSite,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/web/sites/create`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createSite,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 親アカウントのみアクセス可
     * @summary サイト削除
     * @param {SetSiteId} [setSiteId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    destroySite: async (
      setSiteId?: SetSiteId,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/web/sites/destroy`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        setSiteId,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 棚卸し(蓄熱剤)一覧
     * @param {IndexInventoriesIcePack} [indexInventoriesIcePack]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexInventoriesIcePack: async (
      indexInventoriesIcePack?: IndexInventoriesIcePack,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/web/inventories/ice-packs/index`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        indexInventoriesIcePack,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary 棚卸し(VIXELL)一覧
     * @param {IndexInventoriesVixell} [indexInventoriesVixell]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexInventoriesVixell: async (
      indexInventoriesVixell?: IndexInventoriesVixell,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/web/inventories/vixells/index`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        indexInventoriesVixell,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary オンサイト結果一覧
     * @param {IndexOnsiteResult} [indexOnsiteResult]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexOnsiteResults: async (
      indexOnsiteResult?: IndexOnsiteResult,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/web/onsite-results/index`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        indexOnsiteResult,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * 親アカウントのみアクセス可
     * @summary サイト一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexSites: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/web/sites/index`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary ダッシュボード表示
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showDashboard: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/web/dashboard/show`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @summary オンサイト結果詳細
     * @param {WebShowOnsiteResult} [webShowOnsiteResult]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showOnsiteResult: async (
      webShowOnsiteResult?: WebShowOnsiteResult,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/web/onsite-results/show`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        webShowOnsiteResult,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WebCustomerApi - functional programming interface
 * @export
 */
export const WebCustomerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    WebCustomerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary サイトのパスワード変更
     * @param {ChangePassword} [changePassword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async changeSitePassword(
      changePassword?: ChangePassword,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.changeSitePassword(
          changePassword,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * 親アカウントのみアクセス可
     * @summary サイト作成
     * @param {CreateSite} [createSite]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createSite(
      createSite?: CreateSite,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createSite(
        createSite,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * 親アカウントのみアクセス可
     * @summary サイト削除
     * @param {SetSiteId} [setSiteId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async destroySite(
      setSiteId?: SetSiteId,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.destroySite(
        setSiteId,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 棚卸し(蓄熱剤)一覧
     * @param {IndexInventoriesIcePack} [indexInventoriesIcePack]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indexInventoriesIcePack(
      indexInventoriesIcePack?: IndexInventoriesIcePack,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse2005>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.indexInventoriesIcePack(
          indexInventoriesIcePack,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary 棚卸し(VIXELL)一覧
     * @param {IndexInventoriesVixell} [indexInventoriesVixell]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indexInventoriesVixell(
      indexInventoriesVixell?: IndexInventoriesVixell,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse2004>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.indexInventoriesVixell(
          indexInventoriesVixell,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary オンサイト結果一覧
     * @param {IndexOnsiteResult} [indexOnsiteResult]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indexOnsiteResults(
      indexOnsiteResult?: IndexOnsiteResult,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse2006>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.indexOnsiteResults(
          indexOnsiteResult,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     * 親アカウントのみアクセス可
     * @summary サイト一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async indexSites(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse2008>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.indexSites(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary ダッシュボード表示
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showDashboard(
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse2003>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.showDashboard(
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
    /**
     *
     * @summary オンサイト結果詳細
     * @param {WebShowOnsiteResult} [webShowOnsiteResult]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async showOnsiteResult(
      webShowOnsiteResult?: WebShowOnsiteResult,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<InlineResponse2007>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.showOnsiteResult(
          webShowOnsiteResult,
          options
        )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * WebCustomerApi - factory interface
 * @export
 */
export const WebCustomerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = WebCustomerApiFp(configuration)
  return {
    /**
     *
     * @summary サイトのパスワード変更
     * @param {ChangePassword} [changePassword]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    changeSitePassword(
      changePassword?: ChangePassword,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .changeSitePassword(changePassword, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * 親アカウントのみアクセス可
     * @summary サイト作成
     * @param {CreateSite} [createSite]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createSite(createSite?: CreateSite, options?: any): AxiosPromise<void> {
      return localVarFp
        .createSite(createSite, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * 親アカウントのみアクセス可
     * @summary サイト削除
     * @param {SetSiteId} [setSiteId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    destroySite(setSiteId?: SetSiteId, options?: any): AxiosPromise<void> {
      return localVarFp
        .destroySite(setSiteId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 棚卸し(蓄熱剤)一覧
     * @param {IndexInventoriesIcePack} [indexInventoriesIcePack]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexInventoriesIcePack(
      indexInventoriesIcePack?: IndexInventoriesIcePack,
      options?: any
    ): AxiosPromise<InlineResponse2005> {
      return localVarFp
        .indexInventoriesIcePack(indexInventoriesIcePack, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary 棚卸し(VIXELL)一覧
     * @param {IndexInventoriesVixell} [indexInventoriesVixell]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexInventoriesVixell(
      indexInventoriesVixell?: IndexInventoriesVixell,
      options?: any
    ): AxiosPromise<InlineResponse2004> {
      return localVarFp
        .indexInventoriesVixell(indexInventoriesVixell, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary オンサイト結果一覧
     * @param {IndexOnsiteResult} [indexOnsiteResult]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexOnsiteResults(
      indexOnsiteResult?: IndexOnsiteResult,
      options?: any
    ): AxiosPromise<InlineResponse2006> {
      return localVarFp
        .indexOnsiteResults(indexOnsiteResult, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * 親アカウントのみアクセス可
     * @summary サイト一覧
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    indexSites(options?: any): AxiosPromise<InlineResponse2008> {
      return localVarFp
        .indexSites(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary ダッシュボード表示
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showDashboard(options?: any): AxiosPromise<InlineResponse2003> {
      return localVarFp
        .showDashboard(options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @summary オンサイト結果詳細
     * @param {WebShowOnsiteResult} [webShowOnsiteResult]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    showOnsiteResult(
      webShowOnsiteResult?: WebShowOnsiteResult,
      options?: any
    ): AxiosPromise<InlineResponse2007> {
      return localVarFp
        .showOnsiteResult(webShowOnsiteResult, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * WebCustomerApi - object-oriented interface
 * @export
 * @class WebCustomerApi
 * @extends {BaseAPI}
 */
export class WebCustomerApi extends BaseAPI {
  /**
   *
   * @summary サイトのパスワード変更
   * @param {ChangePassword} [changePassword]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebCustomerApi
   */
  public changeSitePassword(
    changePassword?: ChangePassword,
    options?: AxiosRequestConfig
  ) {
    return WebCustomerApiFp(this.configuration)
      .changeSitePassword(changePassword, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 親アカウントのみアクセス可
   * @summary サイト作成
   * @param {CreateSite} [createSite]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebCustomerApi
   */
  public createSite(createSite?: CreateSite, options?: AxiosRequestConfig) {
    return WebCustomerApiFp(this.configuration)
      .createSite(createSite, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 親アカウントのみアクセス可
   * @summary サイト削除
   * @param {SetSiteId} [setSiteId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebCustomerApi
   */
  public destroySite(setSiteId?: SetSiteId, options?: AxiosRequestConfig) {
    return WebCustomerApiFp(this.configuration)
      .destroySite(setSiteId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 棚卸し(蓄熱剤)一覧
   * @param {IndexInventoriesIcePack} [indexInventoriesIcePack]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebCustomerApi
   */
  public indexInventoriesIcePack(
    indexInventoriesIcePack?: IndexInventoriesIcePack,
    options?: AxiosRequestConfig
  ) {
    return WebCustomerApiFp(this.configuration)
      .indexInventoriesIcePack(indexInventoriesIcePack, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary 棚卸し(VIXELL)一覧
   * @param {IndexInventoriesVixell} [indexInventoriesVixell]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebCustomerApi
   */
  public indexInventoriesVixell(
    indexInventoriesVixell?: IndexInventoriesVixell,
    options?: AxiosRequestConfig
  ) {
    return WebCustomerApiFp(this.configuration)
      .indexInventoriesVixell(indexInventoriesVixell, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary オンサイト結果一覧
   * @param {IndexOnsiteResult} [indexOnsiteResult]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebCustomerApi
   */
  public indexOnsiteResults(
    indexOnsiteResult?: IndexOnsiteResult,
    options?: AxiosRequestConfig
  ) {
    return WebCustomerApiFp(this.configuration)
      .indexOnsiteResults(indexOnsiteResult, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * 親アカウントのみアクセス可
   * @summary サイト一覧
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebCustomerApi
   */
  public indexSites(options?: AxiosRequestConfig) {
    return WebCustomerApiFp(this.configuration)
      .indexSites(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary ダッシュボード表示
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebCustomerApi
   */
  public showDashboard(options?: AxiosRequestConfig) {
    return WebCustomerApiFp(this.configuration)
      .showDashboard(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @summary オンサイト結果詳細
   * @param {WebShowOnsiteResult} [webShowOnsiteResult]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebCustomerApi
   */
  public showOnsiteResult(
    webShowOnsiteResult?: WebShowOnsiteResult,
    options?: AxiosRequestConfig
  ) {
    return WebCustomerApiFp(this.configuration)
      .showOnsiteResult(webShowOnsiteResult, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * WebRequestApi - axios parameter creator
 * @export
 */
export const WebRequestApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     *
     * @summary WebAPI
     * @param {WebRequest} [webRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webRequest: async (
      webRequest?: WebRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/web/request`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = {
        method: 'POST',
        ...baseOptions,
        ...options,
      }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication token required
      await setApiKeyToObject(
        localVarHeaderParameter,
        'X-AUTHORIZATION',
        configuration
      )

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions =
        baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      }
      localVarRequestOptions.data = serializeDataIfNeeded(
        webRequest,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WebRequestApi - functional programming interface
 * @export
 */
export const WebRequestApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    WebRequestApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @summary WebAPI
     * @param {WebRequest} [webRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async webRequest(
      webRequest?: WebRequest,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebResponse>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.webRequest(
        webRequest,
        options
      )
      return createRequestFunction(
        localVarAxiosArgs,
        globalAxios,
        BASE_PATH,
        configuration
      )
    },
  }
}

/**
 * WebRequestApi - factory interface
 * @export
 */
export const WebRequestApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = WebRequestApiFp(configuration)
  return {
    /**
     *
     * @summary WebAPI
     * @param {WebRequest} [webRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    webRequest(
      webRequest?: WebRequest,
      options?: any
    ): AxiosPromise<WebResponse> {
      return localVarFp
        .webRequest(webRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * WebRequestApi - object-oriented interface
 * @export
 * @class WebRequestApi
 * @extends {BaseAPI}
 */
export class WebRequestApi extends BaseAPI {
  /**
   *
   * @summary WebAPI
   * @param {WebRequest} [webRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WebRequestApi
   */
  public webRequest(webRequest?: WebRequest, options?: AxiosRequestConfig) {
    return WebRequestApiFp(this.configuration)
      .webRequest(webRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
