import React, { Dispatch, SetStateAction, useCallback, useContext } from 'react'
import { LoginToken } from '../local-storage/login-token'
import { AxiosResponse } from 'axios'
import { HandleErrorResponse } from '../App'

export const LoginContext = React.createContext<
  [
    LoginToken | null,
    Dispatch<SetStateAction<LoginToken | null>>,
    (
      | (<T>(
          result: Promise<AxiosResponse<T>>
        ) => Promise<HandleErrorResponse<T>>)
      | null
    )
  ]
>([null, () => undefined, null])

// ログインしているのを前提としているので LoginToken | null から null を消しているので注意
export const useLogin = () => {
  const [loginToken] = useContext(LoginContext)
  return loginToken as LoginToken
}

export function useApiFunction() {
  const useApiFunction = useContext(LoginContext)[2]
  return useCallback(
    useApiFunction as <T>(
      result: Promise<AxiosResponse<T>>
    ) => Promise<HandleErrorResponse<T>>,
    [useApiFunction]
  )
}
