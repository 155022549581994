import moment from 'moment'
import { Lang } from '../../../translations/lang'

export const TableElementDatetime2Line = ({
  dateTime,
}: {
  dateTime: string
}) => (
  <div className="flex flex-col items-center">
    <div>{moment(dateTime).format(Lang.dateFormat)}</div>
    <div>{moment(dateTime).format(Lang.timeFormat)}</div>
  </div>
)
