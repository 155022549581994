import { useEffect, useState } from 'react'
import {
  ClientAppLog,
  ClientAppType,
  LogLevel,
} from '../../types/typescript-axios'
import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { VixellTable } from '../../components/part/vixell-table'
import { TableElementDatetime2Line } from '../../components/part/table-elements/table-element-datetime-2-line'
import { SquareButton } from '../../components/molecules/sqare-button'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SearchInput } from '../../components/molecules/search-input'
import { Select } from '../../components/molecules/select'
import moment from 'moment'
import { Lang } from '../../translations/lang'
import { toFormattedDatetime } from '../../utils/to-formatted-datetime'
import { downloadCsv } from '../../utils/download-csv'
import { webRequest } from '../../types/handle-api'
import { usePaging } from '../../contexts/use-paging'

const LogLevels = [
  [Lang.all, ''] as const,
  ['ERROR', 'ERROR'] as const,
  ['WARN', 'WARN'] as const,
  ['INFO', 'INFO'] as const,
]
const AppTypes = [
  [Lang.all, ''] as const,
  [Lang.appTypeName.onsite, 'onsite'] as const,
  [Lang.appTypeName.vacuumProcess, 'vacuumProcess'] as const,
  [
    Lang.appTypeName.temperatureTagCalibration,
    'temperatureTagCalibration',
  ] as const,
  [Lang.appTypeName.shippingManagement, 'shippingManagement'] as const,
]
export const AdminClientLogs = () => {
  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const [logs, setLogs] = useState<ClientAppLog[]>([])

  const [searchWord, setSearchWord] = useState<string>('')
  const [searchAppType, setSearchAppType] = useState<string>('')
  const [searchLogLevel, setSearchLogLevel] = useState<string>('')
  const [searchFrom, setSearchFrom] = useState<string>('')
  const [searchTill, setSearchTill] = useState<string>('')

  const [loadNextPageAt, setLoadNextPageAt] = useState<Date>(new Date())
  const { currentPage, setPaging, stopPageLoad } = usePaging()
  const [searchParamsString, setSearchParamsString] = useState<string>()

  useEffect(() => {
    fetchDataAndSetStates()
  }, [searchParams])

  async function fetchDataAndSetStates(loadNextPage = false) {
    const response = await fetchLogs({ loadNextPage })
    if (response.status === 200) {
      if (searchParams.toString() === searchParamsString) {
        setLogs((l) => [...l, ...response.data.logs])
      } else {
        setLogs(response.data.logs)
      }
      setPaging(response.data)
      setSearchParamsString(searchParams.toString())
    }
  }

  async function fetchLogs({ all = false, loadNextPage = false }) {
    const query = searchParams.get('search')
    const app = searchParams.get('app')
    const logLevel = searchParams.get('logLevel')
    const from = searchParams.get('from')
    const till = searchParams.get('till')

    setSearchWord((w) => w || query || '')
    setSearchAppType(app || '')
    setSearchLogLevel(logLevel || '')
    setSearchFrom(from || '')
    setSearchTill(till || '')

    return await webRequest('ClientLogsIndex', {
      ...(query && { query }),
      ...(app && { app: app as ClientAppType }),
      ...(logLevel && { logLevel: logLevel as LogLevel }),
      ...(from && { from }),
      ...(till && { till }),
      ...(all && { all: true }),
      ...(!all && loadNextPage && { page: currentPage + 1 }),
    })
  }

  async function csvDownload() {
    const response = await fetchLogs({ all: true })
    if (response.status === 200) {
      const csvData = [
        [
          Lang.admin.clientLogs.timestamp,
          Lang.customerName,
          Lang.siteName,
          Lang.admin.clientLogs.app,
          Lang.admin.clientLogs.pcId,
          Lang.admin.clientLogs.logLevel,
          Lang.admin.clientLogs.logCode,
        ],
        ...response.data.logs.map(
          ({ timestamp, customer, site, appType, pcId, logLevel, logCode }) => [
            toFormattedDatetime(timestamp),
            customer?.name,
            site?.name,
            appType,
            pcId,
            logLevel,
            logCode,
          ]
        ),
      ]
      downloadCsv(csvData, `clientLogs-${new Date().toISOString()}.csv`)
    }
  }

  function infiniteLoad() {
    if (stopPageLoad) return
    if (moment(loadNextPageAt).isAfter(moment().subtract(3, 'seconds'))) return

    setLoadNextPageAt(new Date())
    fetchDataAndSetStates(true)
  }

  function changeSearchParams(label: string, value: string) {
    if (value) searchParams.set(label, value)
    if (!value) searchParams.delete(label)
    navigate(`/client-logs?${searchParams.toString()}`)
  }

  const titleRightElement = (
    <div className="flex flex-col items-end">
      <div>
        <SearchInput
          value={searchWord}
          onChange={setSearchWord}
          onClick={() => changeSearchParams('search', searchWord)}
        />
      </div>
      <div className="flex mt-16 gap-[48px]">
        <div className="flex flex-col">
          <label>{Lang.admin.clientLogs.app}</label>
          <Select
            className="mt-8"
            value={searchAppType}
            options={AppTypes}
            onChange={(value) => changeSearchParams('app', value)}
          />
        </div>
        <div className="flex flex-col">
          <label>{Lang.admin.clientLogs.logLevel}</label>
          <Select
            className="mt-8"
            value={searchLogLevel}
            options={LogLevels}
            onChange={(value) => changeSearchParams('logLevel', value)}
          />
        </div>
        <div>
          <label>{Lang.admin.clientLogs.timestamp}</label>
          <div className="flex items-center gap-[12px]">
            <input
              type="date"
              className="w-[160px]"
              value={searchFrom}
              onChange={(e) => changeSearchParams('from', e.target.value)}
            />
            <span className="text-3xl text-gray-300">〜</span>
            <input
              type="date"
              className="w-[160px]"
              value={searchTill}
              onChange={(e) => changeSearchParams('till', e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <DefaultWithTitleLayout
      title={Lang.admin.clientLogs.title}
      description={Lang.admin.clientLogs.description}
      titleRightElement={titleRightElement}
      infiniteLoad={infiniteLoad}
      csvDownload={csvDownload}
    >
      {logs && (
        <VixellTable
          rootClassName="my-64"
          classNames={[
            'w-140',
            'w-200',
            'w-200',
            'w-200',
            'w-120',
            'w-200',
            'flex-1 !justify-end mr-[42px]',
          ]}
          headers={[
            Lang.admin.clientLogs.timestamp,
            <div className="flex flex-col items-center" key={1}>
              <div>{Lang.customerName}</div>
              <div>{Lang.siteName}</div>
            </div>,
            Lang.admin.clientLogs.app,
            Lang.admin.clientLogs.pcId,
            Lang.admin.clientLogs.logLevel,
            Lang.admin.clientLogs.logCode,
            '',
          ]}
          elements={logs.map((log, index) => [
            <TableElementDatetime2Line dateTime={log.timestamp} key={index} />,
            <div className="flex flex-col items-center" key={index}>
              <div>{log.customer?.name}</div>
              <div>{log.site?.name}</div>
            </div>,
            log.appType,
            log.pcId,
            log.logLevel,
            log.logCode,
            <SquareButton
              key={index}
              color="cyan"
              onClick={() => (window.location.href = log.logDataUrl)}
            >
              {Lang.admin.clientLogs.csvDownload}
            </SquareButton>,
          ])}
        />
      )}
    </DefaultWithTitleLayout>
  )
}
