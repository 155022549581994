import { Modal } from '../../molecules/modal'
import { Title } from '../../part/title'
import { Lang } from '../../../translations/lang'
import { Button } from '../../molecules/button'
import { UseFormReturn } from 'react-hook-form'
import { InputWithLabelAndRegister } from '../../molecules/input-with-label-and-register'

export type AdminPressureGraphFilteringModalHideArgs = {
  stockedFrom?: string
  stockedTill?: string
  vixellSerialNo?: string
  model?: string
  contractNo?: string
  yAxisUpper?: number
  yAxisLower?: number
  xAxisUpper?: number
  xAxisLower?: number
  xAxisUpperDate?: string
  xAxisLowerDate?: string
  yRuledLineRange: number
  xRuledLineRange: number
}
type Props = {
  form: UseFormReturn<AdminPressureGraphFilteringModalHideArgs>
  hide: (shouldUpdateValues: boolean) => void
}

export function AdminPressureGraphFilteringModal({ hide, form }: Props) {
  const {
    register,
    getValues,
    formState: { errors },
    clearErrors,
    setError,
  } = form

  function submit() {
    const values = getValues()
    console.log(values)
    if (values.yAxisLower && !values.yAxisUpper)
      return setError('yAxisUpper', {
        message: Lang.admin.pressureGraph.onlyLowerError,
      })
    else clearErrors('yAxisUpper')

    if (values.xAxisLower && !values.xAxisUpper)
      return setError('xAxisUpper', {
        message: Lang.admin.pressureGraph.onlyLowerError,
      })
    else clearErrors('xAxisUpper')

    if (values.xAxisLowerDate && !values.xAxisUpperDate)
      return setError('xAxisUpperDate', {
        message: Lang.admin.pressureGraph.onlyLowerError,
      })
    else clearErrors('xAxisUpperDate')

    hide(true)
  }

  return (
    <Modal>
      <Title title={Lang.filtering} />
      <div className="mt-24">
        <label>{Lang.stockedDate}</label>
        <div className="flex items-center gap-[12px] mt-12">
          <input
            type="date"
            className="w-[160px]"
            {...register('stockedFrom')}
          />
          <span className="inline-block h-[36px] text-3xl text-gray-300">
            〜
          </span>
          <input
            type="date"
            className="w-[160px]"
            {...register('stockedTill')}
          />
        </div>
      </div>

      <InputWithLabelAndRegister
        className="mt-32"
        label={Lang.vixellSerialNo}
        register={register('vixellSerialNo')}
      />

      <InputWithLabelAndRegister
        className="mt-32"
        label={Lang.model}
        register={register('model')}
      />

      <InputWithLabelAndRegister
        className="mt-32"
        label={Lang.contractNoAndManagementNo}
        register={register('contractNo')}
      />

      <div className="mt-24">
        <label>{Lang.admin.pressureGraph.yAxisRange}</label>
        <div className="flex items-center gap-[12px] mt-12">
          <input
            type="number"
            className="w-[160px]"
            {...register('yAxisLower')}
          />
          <span className="inline-block h-[36px] text-3xl text-gray-300">
            〜
          </span>
          <input
            type="number"
            className="w-[160px]"
            {...register('yAxisUpper')}
          />
        </div>
        {errors['yAxisUpper'] && (
          <div className="font-bold text-xs text-red-500">
            {errors['yAxisUpper']?.message}
          </div>
        )}
      </div>

      <div className="mt-24">
        <label>{Lang.admin.pressureGraph.xAxisRangeForElapsedDate}</label>
        <div className="flex items-center gap-[12px] mt-12">
          <input
            type="number"
            className="w-[160px]"
            {...register('xAxisLower')}
          />
          <span className="inline-block h-[36px] text-3xl text-gray-300">
            〜
          </span>
          <input
            type="number"
            className="w-[160px]"
            {...register('xAxisUpper')}
          />
        </div>
        {errors['xAxisUpper'] && (
          <div className="font-bold text-xs text-red-500">
            {errors['xAxisUpper']?.message}
          </div>
        )}
      </div>

      <div className="mt-24">
        <label>{Lang.admin.pressureGraph.xAxisRangeForProcessingDate}</label>
        <div className="flex items-center gap-[12px] mt-12">
          <input
            type="date"
            className="w-[160px]"
            {...register('xAxisLowerDate')}
          />
          <span className="inline-block h-[36px] text-3xl text-gray-300">
            〜
          </span>
          <input
            type="date"
            className="w-[160px]"
            {...register('xAxisUpperDate')}
          />
        </div>
        {errors['xAxisUpperDate'] && (
          <div className="font-bold text-xs text-red-500">
            {errors['xAxisUpperDate']?.message}
          </div>
        )}
      </div>

      <InputWithLabelAndRegister
        className="mt-24"
        label={Lang.admin.pressureGraph.yRuledLineRange}
        type="number"
        register={register('yRuledLineRange')}
      />

      <InputWithLabelAndRegister
        className="mt-24"
        label={Lang.admin.pressureGraph.xRuledLineRange}
        type="number"
        register={register('xRuledLineRange')}
      />

      <div className="flex justify-center gap-[32px] mt-40">
        <Button color="red" onClick={() => hide(false)}>
          {Lang.cancel}
        </Button>
        <Button color="blue" onClick={submit}>
          {Lang.update}
        </Button>
      </div>
    </Modal>
  )
}
