import { Button } from '../molecules/button'
import { Lang } from '../../translations/lang'
import { Cell } from '../atom/Cell'

type Props = {
  onClick?: () => void
  disabled: boolean
}
export const EditButton = ({ onClick = () => undefined, disabled }:  Props ) => {
  return disabled ? (
    <Cell
      layout='center_center'
      className='inline-block w-[204px] h-[42px] rounded-[100px] text-[#a9a9a9] font-bold border border-[#a9a9a9] bg-white'
    >
      {Lang.edit}
    </Cell>
  ):(
    <Button color="cyan" onClick={onClick}>
      <span className="flex justify-center items-center gap-[4px]">
        {Lang.edit}
      </span>
    </Button>
  )
}
