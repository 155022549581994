import { Title } from '../../part/title'
import { InputWithLabel } from '../../molecules/input-with-label'
import { FormEvent, useState } from 'react'
import { Button } from '../../molecules/button'
import { useApiFunction } from '../../../contexts/login-provider'
import { Lang } from '../../../translations/lang'
import { webRequest } from '../../../types/handle-api'

type Props = {
  hide: () => void
}
export const CreateSite = ({ hide }: Props) => {
  const handleApi = useApiFunction()
  const [siteId, setSiteId] = useState<string>('')
  const [siteName, setSiteName] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')

  const [errorStatement, setErrorStatement] = useState<string>()

  async function submit(e: FormEvent) {
    e.preventDefault()
    setErrorStatement(undefined)
    if (password && password !== passwordConfirmation)
      return setErrorStatement(Lang.me.passwordAndConfirmationIsDifferent)

    const response = await webRequest('SitesCreateAdmin', {
      siteIdentifier: siteId,
      name: siteName,
      password,
    })
    if (response.status === 200) {
      window.location.reload()
    } else {
      setErrorStatement(Lang.admin.createSite.errorTaken)
    }
  }

  return (
    <div className="fixed top-0 left-0 flex justify-center items-center w-screen h-screen bg-modal-background">
      <form
        className="rounded-10 p-42 bg-white w-[800px] h-[600px] overflow-scroll"
        onSubmit={submit}
      >
        <Title title={Lang.admin.createSite.title} />
        <InputWithLabel
          required={true}
          className="mt-24"
          label={Lang.siteId}
          value={siteId}
          pattern="^[0-9a-zA-Z\-_]{4,32}$"
          title={Lang.restrictSiteIdPattern}
          onlyCapital={true}
          onChange={setSiteId}
        />
        <InputWithLabel
          required={true}
          className="mt-24"
          label={Lang.siteName}
          value={siteName}
          autoComplete="nope"
          onChange={setSiteName}
        />
        <InputWithLabel
          type="password"
          required={true}
          className="mt-24"
          label={Lang.password}
          value={password}
          autoComplete="nope"
          onChange={setPassword}
          pattern={'^[a-zA-Z\\d]{8,32}$'}
          title={Lang.passwordFormatTitle}
        />
        <InputWithLabel
          type="password"
          required={true}
          className="mt-24"
          label={Lang.passwordConfirmation}
          value={passwordConfirmation}
          onChange={setPasswordConfirmation}
        />
        {errorStatement && (
          <p className="flex justify-center my-32 text-vixell-red text-sm">
            {errorStatement}
          </p>
        )}
        <div className="flex justify-center gap-[48px] mt-[60px]">
          <Button type="button" color="red" onClick={hide}>
            {Lang.cancel}
          </Button>
          <Button color="cyan">{Lang.create}</Button>
        </div>
      </form>
    </div>
  )
}
