import { useEffect, useState } from 'react'
import { QrCodeReader } from './components/QrCodeReader'
import { webRequest } from '../../types/handle-api'
import { useNavigate } from 'react-router-dom'
import { DefaultLayout } from '../../components/templates/detaulf-layout'

export const ProductDetail = () => {
  const navigate = useNavigate()
  const [errorStatement, setErrorStatement] = useState<string>('')
  const [qrCodes, setQrCodes] = useState<string[]>([])
  new Set(qrCodes)

  useEffect(() => {
    if (qrCodes.length === 0) return

    const [model, serialNo] = qrCodes[qrCodes.length - 1].split('_')

    const redirectProductDetailsPage = async ({
      model,
      serialNo,
    }: {
      model: string
      serialNo: string
    }) => {
      if (!model || !serialNo) return

      const response = await webRequest('SearchWithSerialNo', {
        model,
        serialNo,
      })

      if (response.status === 200) {
        const {
          type,
          data: { id },
        } = response.data

        switch (type) {
          case 'VIXELL':
            navigate(`/vixells/${id}`)
            break
          case 'THERMAL_STORAGE_UNIT':
            navigate(`/thermal-storage-units/${id}`)
            break
          case 'TEMPERATURE_LOGGER':
            // FIXME: 温度ロガーの詳細ページを追加した後に、設定する
            navigate(`/temperature-loggers/${id}`)
            break
          default:
            setErrorStatement(
              `${type} is Not Found (model: ${model}, serialNo: ${serialNo}).`
            )
            break
        }
      } else {
        // model is Not Found か、serialNo is Not Foundになる
        setErrorStatement(
          `${
            Object.keys(response.data.params)[0] || 'serialNo'
          } is Not Found (model: ${model}, serialNo: ${serialNo}).`
        )
      }
    }
    redirectProductDetailsPage({ model, serialNo })
  }, [qrCodes])

  return (
    <DefaultLayout>
      <div>
        <div className="p-[16px] border-y-[1px] border-[#009688]">
          <p className="text-[18px] font-bold text-[#009688]">
            Check Product Detail
          </p>
        </div>
        <div className="p-[16px] text-[14px]">
          <p className="font-bold">Product Serial Number</p>
          <p className="mt-[8px]">Input Serial Number</p>
          <span className="text-[red]">{errorStatement}</span>
        </div>
        <QrCodeReader
          onReadQRCode={(result) => {
            setQrCodes((codes) => {
              return Array.from(new Set([...codes, result.getText()]))
            })
          }}
        />
      </div>
    </DefaultLayout>
  )
}
