import { useEffect, useRef } from 'react'
import { Lang } from '../../translations/lang'

export const Footer = ({ infiniteLoad }: { infiniteLoad?: () => void }) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!ref.current) return

    const interval = setInterval(() => {
      if (!ref.current) return

      if (ref.current.getBoundingClientRect()?.y < window.innerHeight)
        infiniteLoad && infiniteLoad()
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [ref, infiniteLoad])

  return (
    <footer
      ref={ref}
      className="w-screen px-[140px] pt-[42px] bg-[#EEEEEE] text-vixell-gray-dark"
    >
      <div className="flex border-t border-t-vixell-gray-light w-full max-w-[1160px] mx-auto py-[32px] gap-[32px]">
        <a
          href={
            navigator.language === 'ja'
              ? 'https://www.panasonic.com/jp/privacy-policy.html'
              : 'https://www.panasonic.com/global/about/privacy-policy.html'
          }
          target="_blank"
          rel="noreferrer"
        >
          {Lang.footer.privacyPolicy}
        </a>
      </div>
      <div className="flex justify-center pb-[46px]">
        Copyright © Panasonic Corporation
      </div>
    </footer>
  )
}
