import { useState } from 'react'

export function usePaging() {
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [stopPageLoad, setStopPageLoad] = useState<boolean>(false)

  function setPaging(paging: { currentPage: number; stopPageLoad: boolean }) {
    setCurrentPage(paging.currentPage)
    setStopPageLoad(paging.stopPageLoad)
  }

  return {
    currentPage,
    setPaging,
    stopPageLoad,
  }
}
