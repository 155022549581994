import { ReactNode } from 'react'
import { Link } from 'react-router-dom'

type Props = {
  label: string
  content: string | number | undefined | ReactNode
  link?: string | false
}
export const LabeledText = ({ label, content, link }: Props) => {
  return (
    <div>
      <div className="text-sm text-[#757575] w-[240px] pr-[8px]">{label}</div>
      {link ? (
        <Link to={link}>
          <div className="mt-[8px] font-bold text-blue-500 underline">
            {content}
          </div>
        </Link>
      ) : (
        <div className="mt-[8px] font-bold">{content}</div>
      )}
    </div>
  )
}
