import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { InspectionBoard } from '../../../types/typescript-axios'
import { TabContainer } from '../../../components/part/tab-container'
import { NoPaddingLayoutWithTitle } from '../../../components/templates/no-padding-layout-with-title'
import { Lang } from '../../../translations/lang'
import { webRequest } from '../../../types/handle-api'
import { ShippingStateLogHistory } from '../../../components/templates/transactions/shipping-state-log-history'

export const AdminInspectionBoardDetail = () => {
  const { id } = useParams<{ id: string }>()
  const [board, setBoard] = useState<InspectionBoard>()

  useEffect(() => {
    async function fetchData() {
      const response = await webRequest('InspectionBoardsShow', {
        id: Number(id),
      })
      if (response.status === 200) {
        setBoard(response.data.inspectionBoard)
      }
    }

    fetchData()
  }, [id])

  return (
    <NoPaddingLayoutWithTitle
      title={Lang.admin.inspectionBoardDetail.title}
      link={{
        label: Lang.admin.inspectionBoardDetail.goBackToInspectionBoards,
        link: '/inspection-boards',
      }}
    >
      <div className="container mt-16 mb-40">
        <label>{Lang.inspectionBoardSerialNo}</label>
        <p className="mt-12 text-lg font-bold">{board?.serialNo}</p>
      </div>

      <TabContainer
        tabs={[
          {
            tabName: Lang.tradeHistory,
            element: <ShippingStateLogHistory productType="INSPECTION_BOARD" />,
          },
        ]}
      />
    </NoPaddingLayoutWithTitle>
  )
}
