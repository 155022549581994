import { QrCodeReader } from './QrCodeReader'
import { useState } from 'react'
import { DefaultLayout } from '../../../components/templates/detaulf-layout'
import { useLatestCallback } from '../../../contexts/use-latest-callback'
import { Lang } from '../../../translations/lang'
import { useApi } from '../../../contexts/api-provider'

export type Product = {
  productType: 'VIXELL' | 'THERMAL_STORAGE_UNIT' | 'TEMPERATURE_LOGGER'
  model: string | undefined
  serialNo: string
}
type Props = {
  title: string
  titleColor: string
  submit: (products: Product[]) => Promise<true | string>
  setNumOfProduct: (numOfProduct: number) => void
}
export const QrScanAndListProducts = ({
  title,
  titleColor,
  submit,
  setNumOfProduct,
}: Props) => {
  const { webRequest } = useApi()
  const [products, setProducts] = useState<Product[]>([])
  const [serialNo, setSerialNo] = useState<string>('')
  const [errorStatement, setErrorStatement] = useState<string>('')

  const addCode = useLatestCallback(
    async (modelOrModelWithSerialNo: string) => {
      setErrorStatement('')
      const [model, serialNo] = ((modelOrModelWithSerialNo: string) =>
        modelOrModelWithSerialNo.includes('_')
          ? modelOrModelWithSerialNo.split('_')
          : [undefined, modelOrModelWithSerialNo])(modelOrModelWithSerialNo)

      if (products.find((p) => serialNo === p.serialNo)) return

      const response = await webRequest('SearchWithSerialNo', {
        model,
        serialNo,
      })

      if (response.status === 200) {
        const { type, data } = response.data
        setProducts((products) => {
          if (products.find((p) => serialNo === p.serialNo)) return products

          return [
            ...products,
            { productType: type, model: data.model, serialNo: data.serialNo },
          ]
        })
      } else {
        setErrorStatement('SerialNo not found.')
      }
    },
    [products]
  )

  function removeCode(index: number) {
    setProducts((serialNos) => serialNos.filter((_, i) => i !== index))
  }

  async function onSubmit() {
    const result = await submit(products)
    if (result === true) {
      setNumOfProduct(products.length)
      setProducts([])
      setErrorStatement('')
      setSerialNo('')
    } else {
      setErrorStatement(result)
    }
  }

  return (
    <DefaultLayout>
      <div className="flex flex-col min-h-screen justify-between">
        <div>
          <div className="p-[16px]" style={{ backgroundColor: titleColor }}>
            <p className="text-[18px] font-bold text-white">{title}</p>
          </div>
          <div className="p-[16px]">
            <p className="text-[14px] font-bold">Scan QR Code</p>
          </div>
          <QrCodeReader
            onReadQRCode={(result) => {
              addCode(result.getText())
            }}
          />
          <div className="p-[16px]">
            <p>Type code by hand</p>
            <div className="mt-[12px] flex border-[1px] border-[#757575] rounded-[3px]">
              <input
                placeholder="Serial Number"
                className="h-[36px] w-full border-none"
                value={serialNo}
                onChange={(e) => {
                  setSerialNo(e.target.value)
                }}
              />
              <button
                className="cursor-pointer h-[36px] bg-[#757575] text-white font-bold text-[12px] p-[10px]"
                onClick={() => {
                  addCode(serialNo)
                  setSerialNo('')
                }}
              >
                Add
              </button>
            </div>
          </div>
          <div className="p-[16px]">
            <p>Scanned products</p>
            <div>
              {products.map((product, index) => (
                <div className="flex p-[8px] items-center" key={index}>
                  <p className="text-[12px]">
                    <span>{index + 1}.</span>
                    <span>{Lang.productName[product.productType]}</span>
                    <span className="ml-4">{product.model}</span>
                    <span className="ml-4">{product.serialNo}</span>
                  </p>
                  <button
                    className="text-[12px] ml-[10px] px-[4px]"
                    onClick={() => {
                      removeCode(index)
                    }}
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>
          {errorStatement && (
            <p className="flex justify-center my-32 text-vixell-red text-sm">
              {errorStatement}
            </p>
          )}
          <div className="p-[16px] flex justify-center">
            <button
              className="cursor-pointer w-[140px] h-[36px] rounded-[100px] bg-[#00BCD4] text-white font-bold text-[14px] shadow-vixell"
              onClick={onSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}
