import { Link } from 'react-router-dom'

type Props =
  | {
      type: 'vixells' | 'inspection-boards'
      products: {
        model: string
        count: number
      }[]
      contractRepresentativeNo: string
    }
  | {
      type: 'ice-packs'
      count: number
      contractRepresentativeNo: string
    }

export const TableElementProductCountWithLink = (props: Props) => {
  const { type, contractRepresentativeNo } = props
  const products = props.type === 'ice-packs' ? null : props.products
  const count = props.type === 'ice-packs' ? props.count : null

  return (
    <div className=" text-center">
      {products?.map((product) => (
        <div>
          <Link
            className="text-blue-500"
            to={
              type === 'vixells'
                ? `/vixells?model=${product.model}&contractNo=${contractRepresentativeNo}`
                : `/inspection-boards?search=${contractRepresentativeNo}`
            }
            onClick={(e) => e.stopPropagation()}
          >
            {product.model}
          </Link>
          : {product.count}
        </div>
      ))}
      {count && (
        <Link
          className="text-blue-500" 
          to={`/ice-packs?contractNo=${contractRepresentativeNo}`}
          onClick={(e) => e.stopPropagation()}
        >  
          {count}
        </Link>
      )}
    </div>
  )
}
