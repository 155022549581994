// title
// [ option ▼]
// みたいなコンポーネント
import { Control, FieldValues, Path, PathValue } from 'react-hook-form'
import { LabeledValue, SelectControl } from '../../molecules/select-control'
import { HTMLAttributes } from 'react'
import { Lang } from '../../../translations/lang'

type Props<
  T extends FieldValues,
  X extends Path<T>,
  Y extends PathValue<T, X> | LabeledValue<PathValue<T, X>>
> = {
  title: string
  required: boolean
  name: X
  control: Control<T>
  values: Y[]
} & HTMLAttributes<HTMLDivElement>
export const SelectWithTitle = <
  T extends FieldValues,
  X extends Path<T>,
  Y extends PathValue<T, X> | LabeledValue<PathValue<T, X>>
>({
  title,
  required,
  name,
  control,
  values,
  ...props
}: Props<T, X, Y>) => {
  return (
    <div {...props}>
      <div>
        <label>{title}</label>
        {required && <label className="alert ml-8">*{Lang.required}</label>}
      </div>
      <SelectControl
        className="mt-12 w-200"
        name={name}
        control={control}
        values={values}
      />
    </div>
  )
}
