import { Modal } from '../../molecules/modal'
import { Button } from '../../molecules/button'
import { Title } from '../../part/title'
import { Lang } from '../../../translations/lang'
import { Site } from '../../../types/typescript-axios'
import { FormEvent, useState } from 'react'
import { webRequest } from '../../../types/handle-api'
import { useForm } from 'react-hook-form'
import { InputWithLabelAndRegister } from '../../molecules/input-with-label-and-register'

type EditSitePasswordFieldValues = {
  password: string
  passwordConfirmation: string
}
type Props = {
  site: Site
  hide: () => void
}
export const EditSitePassword = ({ site, hide }: Props) => {
  const { register, getValues } = useForm<EditSitePasswordFieldValues>()

  const [errorStatement, setErrorStatement] = useState<string>()

  async function submit(e: FormEvent) {
    e.preventDefault()
    if (getValues('password') !== getValues('passwordConfirmation'))
      return setErrorStatement('パスワードが一致しません')
    const response = await webRequest('SitesChangePassword', {
      id: site.id,
      password: getValues('password'),
    })
    if (response.status === 200) window.location.reload()
  }

  return (
    <Modal>
      <form onSubmit={submit}>
        <Title title={Lang.changePassword} />
        <div className="mt-16">
          <label>{Lang.siteId}</label>
          <div>{site.siteIdentifier}</div>
        </div>
        <div className="mt-16">
          <label>{Lang.siteName}</label>
          <div>{site.name}</div>
        </div>
        <InputWithLabelAndRegister
          type="password"
          className="mt-16"
          label={Lang.password}
          required={true}
          pattern={'^[a-zA-Z\\d]{8,32}$'}
          title={Lang.passwordFormatTitle}
          register={register('password')}
        />
        <InputWithLabelAndRegister
          type="password"
          className="mt-16"
          label={Lang.passwordConfirmation}
          required={true}
          register={register('passwordConfirmation')}
        />

        {errorStatement && (
          <p className="my-16 text-vixell-red text-center">{errorStatement}</p>
        )}

        <div className="mt-32 flex justify-center gap-8">
          <Button type="button" color="red" onClick={hide}>
            キャンセル
          </Button>
          <Button color="blue">変更</Button>
        </div>
      </form>
    </Modal>
  )
}
