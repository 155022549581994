import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  IcePacksShowResponseData,
  OnsiteIcePackUnit,
} from '../../../types/typescript-axios'
import { TabContainer } from '../../../components/part/tab-container'
import { NoPaddingLayoutWithTitle } from '../../../components/templates/no-padding-layout-with-title'
import { VixellTable } from '../../../components/part/vixell-table'
import { TableElementDatetime2Line } from '../../../components/part/table-elements/table-element-datetime-2-line'
import moment from 'moment'
import { Lang } from '../../../translations/lang'
import { InfiniteLoadComponent } from '../../../components/molecules/infinite-load-component'
import { webRequest } from '../../../types/handle-api'
import { usePaging } from '../../../contexts/use-paging'
import { TemperatureTagCalibrationComponent } from '../../../components/templates/admin-ice-pack-detail/temperature-tag-calibration-component'
import { BelongingLogHistory } from '../../../components/templates/transactions/belonging-log-history'
import { ShippingStateLogHistory } from '../../../components/templates/transactions/shipping-state-log-history'

export const AdminIcePackDetail = () => {
  const { id } = useParams<{ id: string }>()
  const [icePack, setIcePack] = useState<IcePacksShowResponseData>()

  useEffect(() => {
    async function fetchData() {
      const response = await webRequest('IcePacksShow', {
        id: Number(id),
      })
      if (response.status === 200) {
        setIcePack(response.data)
      }
    }

    fetchData()
  }, [id])

  return (
    <NoPaddingLayoutWithTitle
      title={Lang.admin.icePackDetail.title}
      link={{
        label: Lang.admin.icePackDetail.goBackToIcePacks,
        link: '/ice-packs',
      }}
    >
      <div className="flex gap-[32px] container mt-16 mb-40">
        <div>
          <label>{Lang.icePackSerialNo}</label>
          <p className="mt-12 text-lg font-bold">
            {icePack?.icePack?.temperatureSensorTagEpc}
          </p>
        </div>
        <div>
          <label>{Lang.lastInspectionTime}</label>
          <p className="mt-12 text-lg font-bold">
            {icePack?.onsiteResult?.processingDateTime &&
              moment(icePack?.onsiteResult?.processingDateTime).format(
                Lang.dateTimeFormat
              )}
          </p>
        </div>
      </div>

      {icePack && (
        <div className="container my-60 p-32 rounded-[10px] bg-white shadow-vixell-dark">
          <TemperatureTagCalibrationComponent
            calibration={icePack.calibrationResult}
          />
        </div>
      )}

      <TabContainer
        tabs={[
          {
            tabName: Lang.tradeHistory,
            element: (
              <ShippingStateLogHistory productType="TEMPERATURE_SENSOR_TAG" />
            ),
          },
          { tabName: Lang.inspectionHistory, element: <OnsiteHistory /> },
          {
            tabName: Lang.transitionHistory,
            element: (
              <BelongingLogHistory productType="TEMPERATURE_SENSOR_TAG" />
            ),
          },
        ]}
      />
    </NoPaddingLayoutWithTitle>
  )
}

const OnsiteHistory = () => {
  const { id } = useParams<{ id: string }>()
  const [histories, setHistories] = useState<OnsiteIcePackUnit[]>()
  const { currentPage, setPaging, stopPageLoad } = usePaging()

  useEffect(() => {
    fetchData()
  }, [id])

  async function fetchData() {
    const response = await webRequest('OnsiteIcePackResultUnitsIndex', {
      icePackId: Number(id),
      page: currentPage + 1,
    })
    if (response.status === 200) {
      setHistories([
        ...(histories || []),
        ...response.data.onsiteIcePackResultUnits,
      ])
      setPaging(response.data)
    }
  }

  function infiniteLoad() {
    console.log('currentPage')
    console.log(currentPage)
    if (stopPageLoad) return

    fetchData()
  }

  return (
    <div className="my-40 container">
      {histories && (
        <VixellTable
          classNames={['w-200', 'w-200', 'w-200', 'w-200']}
          headers={[
            Lang.inspectionTime,
            Lang.admin.icePackDetail.icePackTemperature,
            Lang.inspectionResult,
            Lang.icePackErrorDetail,
          ]}
          elements={histories.map((history) => [
            <TableElementDatetime2Line
              dateTime={history.processingDateTime}
              key={history.temperatureSensorTagEpc}
            />,
            '' + (history.icePackTemperature || ''),
            history.inspectionResult,
            history.errorDetail,
          ])}
        />
      )}
      <InfiniteLoadComponent
        infiniteLoad={infiniteLoad}
        nextLoadDelayMilliSec={3000}
      />
    </div>
  )
}
