import { FC, HTMLInputTypeAttribute } from "react";

type Props = {
  label: string
  value: string | number
  onChange: (input: string) => void
  className?: string
  required?: boolean
  placeholder?: string
  type?: HTMLInputTypeAttribute
  pattern?: string
  title?: string
  autoComplete?: string | undefined
  onlyCapital?: boolean
}
export const InputWithLabel: FC<Props> = ({
                                            label, value, onChange, className,
                                            required = false, placeholder, type = 'text',
                                            autoComplete,
                                            pattern, title,
                                            onlyCapital = false,
                                          }) => (
  <div className={className}>
    <label>{label}</label>
    <div className='mt-12'>
      <input
        type={type}
        value={value}
        onChange={(e) => {
          const value = onlyCapital ? e.target.value.toUpperCase() : e.target.value
          onChange(value)
        }}
        required={required}
        autoComplete={autoComplete}
        placeholder={placeholder}
        {...{...(pattern && {pattern}), ...(title && {title})}}
      />
    </div>
  </div>
)

