import { ThermalStorageUnitLast } from '../../../types/typescript-axios'
import { Lang } from '../../../translations/lang'

export const TableElementThermalStorageUnit = ({
  unit,
}: {
  unit: ThermalStorageUnitLast
}) => (
  <div className="flex flex-col items-center">
    <div>{unit.productCode}</div>
    <div>{`${Lang.shipping}:${unit.shippingCount}`}</div>
    <div>{`${Lang.return}:${unit.returnCount}`}</div>
    <div></div>
  </div>
)
