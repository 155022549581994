import { useEffect, useState } from 'react'
import {
  IndexInventoriesIcePackStatusEnum,
  TemperatureLoggerInventory,
} from '../../types/typescript-axios'
import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { VixellTable } from '../../components/part/vixell-table'
import { TableElementDatetime2Line } from '../../components/part/table-elements/table-element-datetime-2-line'
import { AlreadyIcon } from '../../components/molecules/already-icon'
import { NotYetIcon } from '../../components/molecules/not-yet-icon'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { SearchInput } from '../../components/molecules/search-input'
import { Select } from '../../components/molecules/select'
import { Lang } from '../../translations/lang'
import { downloadCsv } from '../../utils/download-csv'
import { toFormattedDatetime } from '../../utils/to-formatted-datetime'
import { webRequest } from '../../types/handle-api'
import { usePaging } from '../../contexts/use-paging'
import moment from 'moment'
import { TableHeaderContractAndManagementNo } from '../../components/part/table-headers/table-header-contract-and-management-no'
import { TableElementFullContractNoAndManagementNo } from '../../components/part/table-elements/table-element-full-contract-no-and-management-no'

const InventoryStates = [
  [Lang.all, ''] as const,
  [Lang.inventoryState.notYet, 'not-yet'] as const,
]

export const TemperatureLoggerInventories = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [inventories, setInventories] = useState<TemperatureLoggerInventory[]>(
    []
  )

  const [searchWord, setSearchWord] = useState<string>('')
  const [searchInventoryState, setSearchInventoryState] = useState<string>('')
  const [searchFrom, setSearchFrom] = useState<string>('')
  const [searchTill, setSearchTill] = useState<string>('')

  const [loadNextPageAt, setLoadNextPageAt] = useState<Date>(new Date())
  const [searchParamsString, setSearchParamsString] = useState<string>()
  const { currentPage, stopPageLoad, setPaging } = usePaging()

  useEffect(() => {
    fetchDataAndSetStates()
  }, [searchParams])

  async function fetchDataAndSetStates(loadNextPage = false) {
    const response = await fetchInventories({ loadNextPage })
    if (response.status === 200) {
      if (searchParams.toString() === searchParamsString) {
        setInventories((i) => [...i, ...response.data.temperatureLoggers])
      } else {
        setInventories(response.data.temperatureLoggers)
      }
      setPaging(response.data)
      setSearchParamsString(searchParams.toString())
    }
  }

  async function fetchInventories({ all = false, loadNextPage = false }) {
    const query = searchParams.get('search')
    const state = searchParams.get('state')
    const from = searchParams.get('from')
    const till = searchParams.get('till')

    setSearchWord((w) => w || query || '')
    setSearchInventoryState(state || '')
    setSearchFrom(from || '')
    setSearchTill(till || '')

    return await webRequest('InventoriesTemperatureLoggersIndex', {
      ...(query && { query }),
      ...(state && { status: state as IndexInventoriesIcePackStatusEnum }),
      ...(from && { from }),
      ...(till && { till }),
      ...(all && { all: true }),
      ...(!all && loadNextPage && { page: currentPage + 1 }),
    })
  }

  async function csvDownload() {
    const response = await fetchInventories({ all: true })
    if (response.status === 200) {
      const csvData = [
        [
          Lang.temperatureLoggerSerialNo,
          Lang.model,
          Lang.belongedSite,
          Lang.contractNo,
          Lang.managementNo,
          Lang.shippingState,
          Lang.shippingStateUpdatedDate,
          Lang.vixellInventories.inventoryState,
          Lang.inspectionTime,
        ],
        ...response.data.temperatureLoggers.map(
          ({
            serialNo,
            model,
            siteName,
            processingDateTime,
            settled,
            fullContractNo,
            managementNo,
            shippingState,
            shippingStateUpdatedDateTime,
          }) => [
            serialNo,
            model,
            siteName,
            fullContractNo,
            managementNo,
            shippingState && Lang.shippingStateName[shippingState],
            toFormattedDatetime(shippingStateUpdatedDateTime),
            settled ? Lang.already : Lang.notYet,
            toFormattedDatetime(processingDateTime),
          ]
        ),
      ]
      downloadCsv(
        csvData,
        `temperatureLoggerInventories-${new Date().toISOString()}.csv`
      )
    }
  }

  function infiniteLoad() {
    if (stopPageLoad) return
    if (moment(loadNextPageAt).isAfter(moment().subtract(3, 'seconds'))) return

    setLoadNextPageAt(new Date())
    fetchDataAndSetStates(true)
  }

  function changeSearchParams(label: string, value: string) {
    if (value) searchParams.set(label, value)
    if (!value) searchParams.delete(label)
    navigate(`/inventories/temperature-loggers?${searchParams.toString()}`)
  }

  const titleRightElement = (
    <div className="flex flex-col items-end">
      <div>
        <SearchInput
          value={searchWord}
          onChange={setSearchWord}
          onClick={() => changeSearchParams('search', searchWord)}
        />
      </div>
      <div className="flex items-end mt-16 gap-[48px]">
        <div className="flex flex-col">
          <label>{Lang.vixellInventories.inventoryState}</label>
          <Select
            className="mt-8"
            value={searchInventoryState}
            options={InventoryStates}
            onChange={(value) => changeSearchParams('state', value)}
          />
        </div>
        <div>
          <label>{Lang.lastInspectionTime}</label>
          <div className="flex items-center gap-[12px]">
            <input
              type="date"
              className="w-[160px]"
              value={searchFrom}
              onChange={(e) => changeSearchParams('from', e.target.value)}
            />
            <span className="inline-block h-[36px] text-3xl text-gray-300">
              〜
            </span>
            <input
              type="date"
              className="w-[160px]"
              value={searchTill}
              onChange={(e) => changeSearchParams('till', e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <DefaultWithTitleLayout
      title={Lang.temperatureLoggerInventories.title}
      description={Lang.temperatureLoggerInventories.description}
      titleRightElement={titleRightElement}
      infiniteLoad={infiniteLoad}
      csvDownload={csvDownload}
    >
      {inventories && (
        <VixellTable
          rootClassName="my-64"
          classNames={[
            'w-200',
            'w-200',
            'w-200',
            'w-200',
            'w-200',
            'w-120',
            'w-200',
          ]}
          headers={[
            Lang.temperatureLoggerSerialNo,
            Lang.model,
            Lang.belongedSite,
            <TableHeaderContractAndManagementNo key={1} />,
            Lang.shippingState,
            Lang.shippingStateUpdatedDate,
            Lang.vixellInventories.inventoryState,
            Lang.updatedDateTime,
          ]}
          elements={inventories.map((inventory) => [
            inventory.serialNo,
            inventory.model,
            inventory.siteName,
            <TableElementFullContractNoAndManagementNo
              fullContractNo={inventory.fullContractNo}
              managementNo={inventory.managementNo}
            />,
            inventory.shippingState &&
              Lang.shippingStateName[inventory.shippingState],
            inventory.shippingStateUpdatedDateTime && (
              <TableElementDatetime2Line
                dateTime={inventory.shippingStateUpdatedDateTime}
              />
            ),
            inventory.settled ? <AlreadyIcon /> : <NotYetIcon />,
            inventory.processingDateTime && (
              <TableElementDatetime2Line
                dateTime={inventory.processingDateTime}
              />
            ),
          ])}
        />
      )}
    </DefaultWithTitleLayout>
  )
}
