import { FC } from 'react'

type Props = {
  color: 'cyan' | 'red' | 'blue' | 'green'
  onClick?: () => void
  type?: 'button' | 'submit' | 'reset'
  className?: string
}

export const Button: FC<Props> = ({
  children,
  color,
  onClick = () => undefined,
  type = 'submit',
  className = '',
}) => (
  <button
    type={type}
    className={[
      'inline-block w-[204px] h-[42px] rounded-[100px] bg-white border hover:bg-gray-50 font-bold',
      color === 'cyan'
        ? 'text-vixell-cyan border-vixell-cyan'
        : color === 'red'
        ? 'text-vixell-red border-vixell-red'
        : color === 'green'
        ? 'text-vixell-green border-vixell-green'
        : 'text-vixell-blue border-vixell-blue',
      className,
    ].join(' ')}
    onClick={onClick}
  >
    {children}
  </button>
)
