export const inventories = {
  vixellInventories: {
    title: 'Inventory status (VIXELL)',
    description: 'Displays the inventory status of all vixells.',
    inventoryState: 'Inventory status',
  },
  icePackInventories: {
    title: 'Inventory status (PCM)',
    description: 'Displays the inventory status of all ice packs.',
    inventoryState: 'Inventory status',
  },
  thermalStorageUnitInventories: {
    title: 'Inventory status(Unit)',
    description: 'Displays the inventory status of all units.',
    inventoryState: 'Inventory status',
  },
  temperatureLoggerInventories: {
    title: 'Inventory status(Logger)',
    description: 'Displays the inventory status of all loggers.',
    inventoryState: 'Inventory status',
  },
}
