import { useEffect, useState } from 'react'
import { DashboardForAdmin, DashboardForAdminDashboards } from '../../types/typescript-axios'
import { Title } from '../../components/part/title'
import { LineColorProps, VixellTable } from '../../components/part/vixell-table'
import { Lang } from '../../translations/lang'
import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { webRequest } from '../../types/handle-api'

export const AdminDashboard = () => {
  const [dashboard, setDashboard] = useState<DashboardForAdmin>()
  const [sortedDashboards, setSortedDashboards] = useState<DashboardForAdminDashboards[]>()
  const [lineColors, setLineColors] = useState<LineColorProps>()

  useEffect(() => {
    async function fetchData() {
      const response = await webRequest('AdminDashboard')
      if (response.status === 200) {
        const dashboard = response.data
        setDashboard(dashboard)

        const sortedDashboards = dashboard?.dashboards
        ?.filter((d) => d.customerId)
        .sort((a, b) => {
          if (!a.customerName) return 1
          if (!b.customerName) return -1
    
          if (a.customerName > b.customerName) {
            return 1
          } else if (a.customerName < b.customerName) {
            return -1
          } else {
            if (a.siteName > b.siteName) return 1
    
            return -1
          }
        })
        setSortedDashboards(sortedDashboards)

        const colors: LineColorProps = {}
        sortedDashboards?.map((dashboard, index) => {
          const sitename = dashboard.siteName
          const color =
            sitename.includes('200') ||
            sitename.includes('400') ||
            sitename.includes('600')
            ? 'gray'
            : null

          if (color !== null) {
            colors[index] = color
          }
          // console.log('accumulator', accumulator)
        }, [])
        // console.log('colors', colors)
        setLineColors(colors)
      }
    }

    fetchData()
  }, [])

  // console.log('sortedDashboards', sortedDashboards)



  return (
    <DefaultWithTitleLayout title={Lang.header.dashboard}>
      {dashboard && (
        <>
          <div className="py-42 px-60 bg-white rounded-[10px]">
            <div className="flex justify-between">
              <Title title={Lang.admin.dashboard.title} />
              {/*{*/}
              {/*  dashboard.processingDateTime && (*/}
              {/*    <div>*/}
              {/*      {Lang.admin.dashboard.batchDateTime}:*/}
              {/*      {moment(dashboard.processingDateTime).format(Lang.dateTimeFormat)}*/}
              {/*    </div>*/}
              {/*  )*/}
              {/*}*/}
            </div>
            <div className="flex gap-[56px] mt-24">
              <div>
                <label>{Lang.admin.dashboard.totalShipping}</label>
                <div>
                  {dashboard.vixells.active &&
                    dashboard.vixells?.active + dashboard.vixells?.disposed}
                </div>
              </div>
              <div>
                <label>{Lang.admin.dashboard.totalActive}</label>
                <div>{dashboard.vixells.active}</div>
              </div>
              <div>
                <label>{Lang.admin.dashboard.totalDisposed}</label>
                <div>{dashboard.vixells?.disposed}</div>
              </div>
            </div>
          </div>
          <div className="flex mt-[52px]">
            {sortedDashboards && (
              <VixellTable
                rootClassName="flex-1"
                classNames={['flex-1', 'flex-1', 'flex-1', 'w-480']}
                headers={[
                  <div
                    className="flex justify-center items-center h-[100px] font-bold"
                    key={1}
                  >
                    {Lang.admin.dashboard.customer}
                  </div>,
                  '',
                  <span className="font-bold" key={1}>
                    {Lang.admin.dashboard.stockedCount}
                  </span>,
                  <div className="w-full" key={1}>
                    <div className="flex justify-center items-center w-full h-50 border-b border-b-[#B0BEC5] font-bold">
                      {Lang.admin.dashboard.onsiteVacuum}
                    </div>
                    <div className="flex justify-center items-center w-full h-50 font-bold">
                      <div className="flex flex-col items-center w-1/2">
                        <div className="text-sm h-[25px]">
                          {Lang.admin.dashboard.last30days}
                        </div>
                        <div className="flex text-sm h-[25px] w-full">
                          <div className="flex justify-center w-1/2 text-vixell-green font-bold">
                            OK
                          </div>
                          <div className="flex justify-center w-1/2 text-vixell-red font-bold">
                            NG
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col items-center w-1/2">
                        <div className="text-sm h-[25px]">
                          {Lang.admin.dashboard.last12months}
                        </div>
                        <div className="flex text-sm h-[25px] w-full">
                          <div className="flex justify-center w-1/2 text-vixell-green font-bold">
                            OK
                          </div>
                          <div className="flex justify-center w-1/2 text-vixell-red font-bold">
                            NG
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>,
                ]}
                elements={sortedDashboards.map((d, index) => [
                  index > 0 &&
                  sortedDashboards[index - 1].customerId === d.customerId
                    ? ''
                    : d.customerName,
                  d.siteName,
                  d.stockedCount,
                  <div className="flex w-full" key={index}>
                    <div className="flex justify-center w-1/4">
                      {d.onsite['30days'].ok || 0}
                    </div>
                    <div className="flex justify-center w-1/4">
                      {d.onsite['30days'].ng || 0}
                    </div>
                    <div className="flex justify-center w-1/4">
                      {d.onsite['12months'].ok || 0}
                    </div>
                    <div className="flex justify-center w-1/4">
                      {d.onsite['12months'].ng || 0}
                    </div>
                  </div>,
                ])}
                options={{
                  lineColors: lineColors,
                }}
              />
            )}
            <div className="w-[40px]" />
            <VixellTable
              rootClassName="w-[260px]"
              classNames={['w-260']}
              headers={[
                <div className="w-full" key={1}>
                  <div className="flex justify-center items-center h-50 border-b border-b-[#B0BEC5] font-bold">
                    {Lang.admin.dashboard.clientAppError}
                  </div>
                  <div className="flex items-center h-50 w-full">
                    <div className="flex justify-center w-1/2 font-bold">
                      {Lang.admin.dashboard.last30days}
                    </div>
                    <div className="flex justify-center w-1/2 font-bold">
                      {Lang.admin.dashboard.last12months}
                    </div>
                  </div>
                </div>,
              ]}
              elements={
                sortedDashboards
                  ? sortedDashboards.map(({ clientAppLog }, index) => [
                      <div className="flex w-full" key={index}>
                        <div className="flex justify-center w-1/2">
                          {clientAppLog['30days'] || 0}
                        </div>
                        <div className="flex justify-center w-1/2">
                          {clientAppLog['12months'] || 0}
                        </div>
                      </div>,
                    ])
                  : []
              }
              options={{ lineColors: lineColors }}
            />
          </div>
        </>
      )}
    </DefaultWithTitleLayout>
  )
}
