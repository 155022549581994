import { ContractWithManagementNo } from '../../../types/typescript-axios'
import { FC } from 'react'

type Props = {
  info: Partial<ContractWithManagementNo>
}
export const TableElementContractAndManagementNo: FC<Props> = ({ info }) => (
  <div className="flex flex-col items-center">
    {info.contractRepresentativeNo && (
      <>
        <div>
          <span>{info.contractRepresentativeNo}</span>
          {info.contractNo && <span>-{info.contractNo}</span>}
        </div>
        <div>{info.managementNo}</div>
      </>
    )}
  </div>
)
