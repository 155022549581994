type Props = {
  value: string | number
  options: Array<string | readonly [string | number, string]> // [label, value]
  onChange: (value: string) => void
  className?: string
}
export const Select = ({ value, options, onChange, className }: Props) => (
  <select
    className={
      'w-[140px] h-[36px] rounded-[3px] shadow-vixell-dark-more px-[8px] ' +
      className
    }
    value={value}
    onChange={(e) => onChange(e.target.value)}
  >
    {options.map((option, index) => {
      const label = option instanceof Array ? option[0] : option
      const value = option instanceof Array ? option[1] : option
      return (
        <option key={index} value={value}>
          {label}
        </option>
      )
    })}
  </select>
)
