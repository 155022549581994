import { useEffect, useState } from 'react'

import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { LineColorProps, VixellTable } from '../../components/part/vixell-table'
import { Lang } from '../../translations/lang'
import { webRequest } from '../../types/handle-api'
import { NewButton } from '../../components/part/new-button'
import { useNavigate } from 'react-router-dom'
import { useLogin } from '../../contexts/login-provider'
import { LoginToken } from '../../local-storage/login-token'
import {
  ReusableOrderIndexRequestComponent,
  ReusableOrderIndexResponseComponent,
  SiteType,
} from '../../types/typescript-axios'
import { toUsDate } from '../../utils/to-us-date'
import { usePaging } from '../../contexts/use-paging'
import { SearchButton } from '../../components/part/search-button'
import { ReusableOrdersSearchModal } from '../../components/templates/reusable-orders/ReusableOrdersSearchModal'
import { FormProvider, useForm } from 'react-hook-form'
import _ from 'lodash'
import { Cell } from '../../components/atom/Cell'
import { TableElementWithTooltip } from '../../components/part/table-elements/table-element-with-tooltip'

export type ReusableOrdersSearchFieldValues = ReusableOrderIndexRequestComponent

export const ReusableOrders = () => {
  const navigate = useNavigate()
  const loginToken = useLogin()
  const [reusableOrders, setReusableOrders] = useState<
    ReusableOrderIndexResponseComponent[]
  >([])
  const { currentPage, stopPageLoad, setPaging } = usePaging()
  const [showSearchModal, setShowSearchModal] = useState(false)
  const [lineColors, setLineColors] = useState<LineColorProps>()

  const form = useForm<ReusableOrdersSearchFieldValues>({
    defaultValues: {
      orderStatus:
        loginToken.siteType === 'REUSABLE_SHIPPER' ||
        loginToken.siteType === 'REUSABLE_RETURN'
          ? ['CONFIRMED']
          : ['CONFIRMED', 'NON_CONFIRMED'],
      box: ['S', 'L'],
      unit: ['R', 'C', 'F', 'S', 'D'],
      preCooling: [false, true],
      hasLogger: [false, true],
      shippingStatus:
        loginToken.siteType === 'REUSABLE_SHIPPER'
          ? ['NON_SHIPPED']
          : loginToken.siteType === 'REUSABLE_RETURN'
          ? ['SHIPPED']
          : ['SHIPPED', 'NON_SHIPPED', 'RETURNED'],
    },
  })

  useEffect(() => {
    fetchData()
  }, [])

  function infiniteLoad() {
    if (stopPageLoad) return

    fetchData(true)
  }

  async function fetchData(loadNextPage = false) {
    const defaultOrderBy =
      loginToken.siteType === 'REUSABLE_SHIPPER'
        ? 'deliveryDateToConsignor'
        : loginToken.siteType === 'REUSABLE_RETURN'
        ? 'deliveryDateToConsignor'
        : 'orderNumber'

    const response = await webRequest('ReusableOrderIndex', {
      ..._.omitBy(form.getValues(), _.isEmpty),
      orderBy: defaultOrderBy,
      ...(loadNextPage && { page: currentPage + 1 }),
    })

    if (response.status === 200) {
      const currentTime = new Date()
      const colors: LineColorProps = {}
      response.data.reusableOrders.map((list, index) => {
        const timeToConsignor = new Date(list.deliveryDateToConsignor)
        const timeDiff =
          (currentTime.getTime() - timeToConsignor.getTime()) /
          (24 * 60 * 60 * 1000)
        const color =
          list.state === 'CANCELLED'
            ? 'gray'
            : timeDiff >= 30 &&
              //出荷数が0より大きい
              list.lendCount > 0 &&
              //返却数が出荷数に満たない
              list.lendCount > list.collectedCount
            ? 'yellow'
            : null

        if (color !== null) {
          if (loadNextPage) {
            colors[currentPage * 20 + index] = color
          } else {
            colors[index] = color
          }
        }
      }, [])

      if (loadNextPage) {
        setReusableOrders((orders) => [
          ...orders,
          ...response.data.reusableOrders,
        ])
        setLineColors((colorIndex) => ({
          ...colorIndex,
          ...colors,
        }))
      } else {
        setReusableOrders(response.data.reusableOrders)
        setLineColors(colors)
      }

      setPaging(response.data)
    }
  }

  const titleRightElement = [
    SiteType.ReusableAdmin,
    SiteType.ReusableContract,
  ].some((type) => type === loginToken.siteType) ? (
    <div className="flex gap-[8px]">
      <NewButton onClick={() => navigate(`/reusable-orders/new`)} />
      <SearchButton onClick={() => setShowSearchModal(true)} />
    </div>
  ) : (
    <SearchButton onClick={() => setShowSearchModal(true)} />
  )

  const siteDescription = () => {
    const { siteType } = loginToken as LoginToken

    if (
      siteType === 'REUSABLE_ADMIN' ||
      siteType === 'REUSABLE_CONTRACT' ||
      siteType === 'REUSABLE_NON_RESIDENT'
    ) {
      return {
        title: Lang.reusable.reusableAdmin.title,
        description: Lang.reusable.reusableAdmin.description,
        headerTitle: Lang.reusable.reusableAdmin.headerTitle,
        state: {
          CONFIRMED: Lang.reusable.state.CONFIRMED,
          NON_CONFIRMED: Lang.reusable.state.NON_CONFIRMED,
          CANCELLED: Lang.reusable.state.CANCELLED,
        },
      }
    } else if (siteType === 'REUSABLE_SHIPPER') {
      return {
        title: Lang.reusable.reusableShipper.title,
        description: Lang.reusable.reusableShipper.description,
        headerTitle: Lang.reusable.reusableShipper.headerTitle,
        state: {
          CONFIRMED: Lang.reusable.state.CONFIRMED,
          NON_CONFIRMED: Lang.reusable.state.NON_CONFIRMED,
          CANCELLED: Lang.reusable.state.CANCELLED,
        },
      }
    } else if (siteType === 'REUSABLE_RETURN') {
      return {
        title: Lang.reusable.reusableReturn.title,
        description: Lang.reusable.reusableReturn.description,
        headerTitle: Lang.reusable.reusableReturn.headerTitle,
        state: {
          CONFIRMED: Lang.reusable.state.CONFIRMED,
          NON_CONFIRMED: Lang.reusable.state.NON_CONFIRMED,
          CANCELLED: Lang.reusable.state.CANCELLED,
        },
      }
    } else {
      return {
        title: 'ログインし直してください',
        description: 'ログインし直してください',
        headerTitle: ['', '', '', '', '', '', '', '', '', '', ''],
        state: {
          CONFIRMED: '',
          NON_CONFIRMED: '',
          CANCELLED: '',
        },
      }
    }
  }

  const PrintButton = ({
    bgColor,
    disabled,
    text,
    customClass,
    href,
  }: {
    bgColor: string
    disabled: boolean
    text: string
    customClass?: string
    href: string
  }) => {
    return disabled ? (
      <Cell
        layout="center_center"
        className={`w-[60px] h-[32px] bg-gray-300 font-bold text-white rounded-[10px] text-[14px] ${customClass}`}
      >
        {text}
      </Cell>
    ) : (
      <a
        href={href}
        className={`w-[60px] h-[32px] ${bgColor} ${
          customClass ? customClass : ''
        } font-bold text-white rounded-[10px] flex items-center justify-center text-[14px]
        `}
        onClick={(e) => e.stopPropagation()}
        target="_blank"
      >
        {text}
      </a>
    )
  }

  return (
    <FormProvider {...form}>
      <DefaultWithTitleLayout
        title={siteDescription().title}
        description={siteDescription().description}
        titleRightElement={titleRightElement}
        infiniteLoad={infiniteLoad}
      >
        {reusableOrders && (
          <VixellTable
            rootClassName="my-64"
            classNames={[
              'min-w-[80px] max-w-[80px]',
              'min-w-[180px] max-w-[180px]',
              'min-w-[130px] max-w-[130px]',
              'min-w-[130px] max-w-[130px]',
              'min-w-[120px] max-w-[120px]',
              'min-w-[160px] max-w-[160px]',
              'min-w-[80px] max-w-[80px]',
              'min-w-[80px] max-w-[80px]',
              'min-w-[80px] max-w-[80px]',
              'min-w-[160px] max-w-[160px]',
              'min-w-[240px] max-w-[240px]',
            ]}
            headers={siteDescription().headerTitle}
            elements={reusableOrders.map((reusableOrder) => [
              // 注文No
              <div className="text-center" key={reusableOrder.id}>
                <p>{reusableOrder.id}</p>
                <p>
                  (
                  {
                    siteDescription().state[
                      reusableOrder.state as
                        | 'CONFIRMED'
                        | 'NON_CONFIRMED'
                        | 'CANCELLED'
                    ]
                  }
                  )
                </p>
              </div>,

              // 送った先の会社名
              // TODO: 複数行対応したい場合はTailwindのプラグインを追加する
              // https://tailwindcss.com/blog/multi-line-truncation-with-tailwindcss-line-clamp
              <TableElementWithTooltip
                toolTipText={reusableOrder.customerCompanyName}
              >
                <p className="truncate" key={reusableOrder.id}>
                  {reusableOrder.customerCompanyName}
                </p>
              </TableElementWithTooltip>,

              // 送った日
              <TableElementWithTooltip
                toolTipText={
                  reusableOrder.deliveryDateMeridiemIndicatorToConsignor +
                  ' ' +
                  toUsDate(reusableOrder.deliveryDateToConsignor) +
                  '\n' +
                  reusableOrder.countryOfConsignor +
                  '\n' +
                  reusableOrder.stateOfConsignor
                }
              >
                <div key={reusableOrder.id} className="ml-[6px] mr-[6px]">
                  <p className="truncate">
                    <span>
                      {toUsDate(reusableOrder.deliveryDateToConsignor)}
                    </span>
                    <span className="ml-[5px]">
                      {reusableOrder.deliveryDateMeridiemIndicatorToConsignor}
                    </span>
                  </p>
                  <p className="truncate">
                    <span>{reusableOrder.countryOfConsignor}</span>
                    <span className="ml-[5px]">
                      {reusableOrder.stateOfConsignor}
                    </span>
                  </p>
                </div>
              </TableElementWithTooltip>,

              // 受け取る日
              <TableElementWithTooltip
                toolTipText={
                  toUsDate(reusableOrder.deliveryDateToConsignee) +
                  '\n' +
                  reusableOrder.countryOfConsignee +
                  '\n' +
                  reusableOrder.stateOfConsignee
                }
              >
                <div key={reusableOrder.id} className="ml-[6px] mr-[6px]">
                  <p>
                    {reusableOrder.deliveryDateToConsignee &&
                      toUsDate(reusableOrder.deliveryDateToConsignee)}
                  </p>
                  <p className="truncate">
                    <span>{reusableOrder.countryOfConsignee}</span>
                    <span className="ml-[5px]">
                      {reusableOrder.stateOfConsignee}
                    </span>
                  </p>
                </div>
              </TableElementWithTooltip>,

              // 商品名
              // FIXME: 型版に対応する略称を書く
              // 例えば、AE-V06C5Rなら、C. AE-V06F4Rなら、Fとか。
              <div key={reusableOrder.id}>
                {reusableOrder.vixellModel && (
                  <p>{reusableOrder.vixellModel}</p>
                )}
                {reusableOrder.thermalStorageUnitModel && (
                  <p>{reusableOrder.thermalStorageUnitModel}</p>
                )}
                {reusableOrder.temperatureLoggerModel && (
                  <p>{reusableOrder.temperatureLoggerModel}</p>
                )}
              </div>,

              // 商品設定
              <div key={reusableOrder.id}>
                <p>pre-cool: {reusableOrder.preCooling ? 'Yes' : 'No'}</p>
                <p>Logger: {reusableOrder.hasLogger ? 'Yes' : 'No'}</p>
              </div>,

              // 明細数
              <span key={reusableOrder.id}>{reusableOrder.detailCount}</span>,

              // 出荷数
              <span key={reusableOrder.id}>{reusableOrder.lendCount}</span>,

              // 返却数
              <span key={reusableOrder.id}>
                {reusableOrder.collectedCount}
              </span>,

              <div className="my-[4px]" key={reusableOrder.id}>
                <div className="flex">
                  <PrintButton
                    disabled={false}
                    bgColor="bg-[#2196f3]"
                    text={Lang.reusable.orderList.print.shippingInstruction}
                    href={`/reusable-orders/shipping-instructions/${reusableOrder.id}`}
                  />
                  <PrintButton
                    disabled={
                      reusableOrder.detailCount !== reusableOrder.lendCount
                    }
                    bgColor="bg-[#4caf50]"
                    text={Lang.reusable.orderList.print.deliverySlip}
                    customClass="ml-[8px]"
                    href={`/reusable-orders/delivery-slips/${reusableOrder.id}`}
                  />
                </div>
                <div className="flex mt-[8px]">
                  <PrintButton
                    disabled={
                      reusableOrder.detailCount !== reusableOrder.lendCount
                    }
                    bgColor="bg-[#d81b60]"
                    text={Lang.reusable.orderList.print.deliveryList}
                    href={`/reusable-orders/delivery-lists/${reusableOrder.id}`}
                  />
                  <PrintButton
                    disabled={
                      reusableOrder.detailCount !== reusableOrder.lendCount
                    }
                    bgColor="bg-[#009688]"
                    text={Lang.reusable.orderList.print.deliveryReceipt}
                    customClass="ml-[8px]"
                    href={`/reusable-orders/delivery-receipts/${reusableOrder.id}`}
                  />
                </div>
              </div>,

              // 受注連絡欄
              // TODO: 複数行対応したい場合はTailwindのプラグインを追加する
              // https://tailwindcss.com/blog/multi-line-truncation-with-tailwindcss-line-clamp
              <TableElementWithTooltip
                toolTipText={reusableOrder.notesForDistributionCenter}
              >
                <p
                  className="truncate ml-[5px] mr-[5px]"
                  key={reusableOrder.id}
                >
                  {reusableOrder.notesForDistributionCenter}
                </p>
              </TableElementWithTooltip>,
            ])}
            onLineClick={(index) =>
              navigate(`/reusable-orders/${reusableOrders[index].id}`)
            }
            options={{ lineColors: lineColors }}
          />
        )}
        {showSearchModal && (
          <ReusableOrdersSearchModal
            search={fetchData}
            hide={() => setShowSearchModal(false)}
          />
        )}
      </DefaultWithTitleLayout>
    </FormProvider>
  )
}
