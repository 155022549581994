import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { ThermalStorageUnit } from '../../../types/typescript-axios'
import { TabContainer } from '../../../components/part/tab-container'
import { NoPaddingLayoutWithTitle } from '../../../components/templates/no-padding-layout-with-title'
import { Lang } from '../../../translations/lang'
import { webRequest } from '../../../types/handle-api'
import { ReusableOrderHistory } from '../../../components/templates/transactions/reusable-order-history'
import { BelongingLogHistory } from '../../../components/templates/transactions/belonging-log-history'
import { ShippingStateLogHistory } from '../../../components/templates/transactions/shipping-state-log-history'
import { ProductNoteHistory } from '../../../components/templates/transactions/product-note-history'

export const AdminThermalStorageUnitDetail = () => {
  const { id } = useParams<{ id: string }>()
  const [thermalStorageUnit, setThermalStorageUnit] =
    useState<ThermalStorageUnit>()

  useEffect(() => {
    async function fetchData() {
      const response = await webRequest('ThermalStorageUnitsShow', {
        id: Number(id),
      })
      if (response.status === 200) {
        setThermalStorageUnit(response.data.thermalStorageUnit)
      }
    }

    fetchData()
  }, [id])

  return (
    <NoPaddingLayoutWithTitle
      title={Lang.admin.thermalStorageUnitDetail.title}
      link={{
        label: Lang.admin.thermalStorageUnitDetail.goBackToInspectionBoards,
        link: '/thermal-storage-units',
      }}
    >
      <div className="flex container mt-16 mb-40 gap-16">
        <div>
          <label>{Lang.thermalStorageUnitSerialNo}</label>
          <p className="mt-12 text-lg font-bold">
            {thermalStorageUnit?.serialNo}
          </p>
        </div>
        <div>
          <label>{Lang.model}</label>
          <p className="mt-12 text-lg font-bold">{thermalStorageUnit?.model}</p>
        </div>
      </div>

      <TabContainer
        tabs={[
          {
            tabName: Lang.tradeHistory,
            element: (
              <ShippingStateLogHistory productType="THERMAL_STORAGE_UNIT" />
            ),
          },
          {
            tabName: Lang.transitionHistory,
            element: <BelongingLogHistory productType="THERMAL_STORAGE_UNIT" />,
          },
          {
            tabName: Lang.reusableOrderHistory,
            element: (
              <ReusableOrderHistory productType="THERMAL_STORAGE_UNIT" />
            ),
          },
          {
            tabName: Lang.notesHistory,
            element: <ProductNoteHistory productType="THERMAL_STORAGE_UNIT" />,
          },
        ]}
      />
    </NoPaddingLayoutWithTitle>
  )
}
