import { Control, Controller, FieldValues, Path } from 'react-hook-form'
import { HTMLAttributes } from 'react'

type SelectValue = number | string | undefined | null
export type LabeledValue<T extends SelectValue> = {
  label: string
  value: T
}

function isLabeled<T extends SelectValue>(
  v: T | LabeledValue<T>
): v is LabeledValue<T> {
  return v !== null && typeof v === 'object' && 'label' in v ? true : false
}

type Props<
  T extends FieldValues,
  X extends Path<T>,
  Y extends T[X] | LabeledValue<T[X]>
> = {
  name: X
  control: Control<T>
  values: Y[]
} & HTMLAttributes<HTMLSelectElement>

export function SelectControl<
  T extends FieldValues,
  X extends Path<T>,
  Y extends T[X] | LabeledValue<T[X]>
>({ values, control, name, ...props }: Props<T, X, Y>) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value: v, onChange } }) => (
        <select {...props} value={v} onChange={onChange}>
          {values.map((value, index) => {
            if (isLabeled(value)) {
              return (
                <option key={index} value={value.value}>
                  {value.label}
                </option>
              )
            } else {
              return <option key={index}>{value}</option>
            }
          })}
        </select>
      )}
    />
  )
}
