import { Link } from 'react-router-dom'
import { DefaultLayout } from '../../components/templates/detaulf-layout'
import { useLogin } from '../../contexts/login-provider'
import { PageItems } from '../../constants/pages'
import { Lang } from '../../translations/lang'

type Menu = {
  name: string
  url: string
  color: string
  colorStyle: 'background' | 'border'
  onlyJapanese?: boolean
}
const lang = Lang.reusable.sp.menu
const Menus: Menu[] = [
  {
    name: lang.stock,
    url: '/reusable-orders/stock',
    color: '#FF9800',
    colorStyle: 'background',
  },
  {
    name: lang.ship,
    url: '/reusable-orders/ship',
    color: '#4CAF50',
    colorStyle: 'background',
    onlyJapanese: true,
  },
  {
    name: lang.lend,
    url: '/reusable-orders/lend',
    color: '#2196F3',
    colorStyle: 'background',
  },
  {
    name: lang.return,
    url: '/reusable-orders/return',
    color: '#9C27B0',
    colorStyle: 'background',
  },
  {
    name: lang.defective,
    url: '/reusable-orders/defective',
    color: '#E91E63',
    colorStyle: 'background',
  },
  {
    name: lang.lostItem,
    url: '/reusable-orders/lost-item',
    color: '#757575',
    colorStyle: 'background',
  },
  {
    name: lang.productDetail,
    url: '/reusable-orders/check-product-detail',
    color: '#009688',
    colorStyle: 'border',
  },
]

export const Menu = () => {
  const loginToken = useLogin()

  const accessPageList = PageItems.filter((i) =>
    i.siteTypes.some((t) => t === loginToken.siteType)
  )

  console.log(accessPageList)

  return (
    <DefaultLayout>
      <div className="p-[16px]">
        <p className="text-[18px] font-bold">VIXELL Cloud Menu</p>
      </div>
      <div className="pb-[20px]">
        {Menus.map(({ name, url, color, colorStyle, onlyJapanese }) => {
          if (!accessPageList.some((p) => p.url === url)) return
          console.log(name, url, color, onlyJapanese)
          if (onlyJapanese && navigator.language !== 'ja') return

          return (
            <Link to={url}>
              <div
                className="flex flex-col justify-center h-[38px] mx-[16px] mt-[16px] pl-[8px] rounded-[3px]"
                style={{
                  ...(colorStyle === 'background'
                    ? { background: color }
                    : { borderColor: color, borderWidth: 1 }),
                }}
              >
                <p
                  className="text-[14px] font-bold font-roboto"
                  style={{
                    ...(colorStyle === 'background'
                      ? { color: 'white' }
                      : { color }),
                  }}
                >
                  {name}
                </p>
              </div>
            </Link>
          )
        })}
      </div>
    </DefaultLayout>
  )
}
