import { useEffect, useState } from 'react'
import {
  ReusableOrder,
  ReusableOrderDetail,
  SiteType,
} from '../../types/typescript-axios'

import { DefaultWithTitleLayout } from '../../components/templates/default-with-title-layout'
import { webRequest } from '../../types/handle-api'
import { useNavigate, useParams } from 'react-router-dom'
import { EditButton } from '../../components/part/edit-button'
import { useLogin } from '../../contexts/login-provider'
import { LabeledText } from '../../components/molecules/labeled-text'
import { Lang } from '../../translations/lang'
import { toFormattedDate } from '../../utils/to-formatted-date'
import { RightArrowSvg } from '../../components/svg/right-arrow-svg'
import { boolToString } from '../../utils/bool-to-string'
import { toFormattedDatetime } from '../../utils/to-formatted-datetime'
import { Cell } from '../../components/atom/Cell'
import { canAccessPage } from '../../constants/pages'

const lang = Lang.reusable.orderDetail

export const ReusableOrderDetailPage = () => {
  const navigate = useNavigate()
  const { id } = useParams<{ id: string }>()
  const loginToken = useLogin()

  const [reusableOrder, setReusableOrder] = useState<ReusableOrder>()
  const lendCount =
    reusableOrder?.reusableOrderDetails
      ?.map((detail) => detail.vixell?.serialNo)
      ?.filter(Boolean)?.length || 0
  const showReport = lendCount > 0
  // console.log('lendCount', lendCount)
  // console.log('showReport', showReport)

  useEffect(() => {
    async function fetchData() {
      const response = await webRequest('ReusableOrderShow', {
        id: Number(id),
      })
      if (response.status === 200) {
        setReusableOrder(response.data)
      }
    }

    fetchData()
  }, [])

  const titleRightElement = [
    SiteType.ReusableAdmin,
    SiteType.ReusableContract,
  ].some((type) => type === loginToken.siteType) ? (
    <div>
      <EditButton
        disabled={showReport}
        onClick={() => navigate(`/reusable-orders/edit/${id}`)}
      />
    </div>
  ) : (
    <div />
  )

  return (
    <DefaultWithTitleLayout
      title={lang.title}
      description={lang.description}
      titleRightElement={titleRightElement}
    >
      {reusableOrder && (
        <>
          <div className="flex justify-center mt-[50px]">
            <div className="medium-container">
              <div className="white-wall-with-padding">
                <h3>{lang.print.title}</h3>
                <div className="flex justify-around mt-[32px]">
                  <LinkButton
                    disabled={false}
                    className="bg-reusable-lend"
                    onClick={() =>
                      window.open(
                        `/reusable-orders/shipping-instructions/${id}`
                      )
                    }
                  >
                    {Lang.reusable.orderDetail.print.shippingInstruction}
                  </LinkButton>
                  <LinkButton
                    disabled={!showReport}
                    className="bg-[#4CAF50]"
                    onClick={() =>
                      window.open(`/reusable-orders/delivery-slips/${id}`)
                    }
                  >
                    {Lang.reusable.orderDetail.print.deliverySlip}
                  </LinkButton>
                  <LinkButton
                    disabled={!showReport}
                    className="bg-[#E91E63]"
                    onClick={() =>
                      window.open(`/reusable-orders/delivery-lists/${id}`)
                    }
                  >
                    {Lang.reusable.orderDetail.print.deliveryList}
                  </LinkButton>
                  <LinkButton
                    disabled={!showReport}
                    className="bg-[#009688]"
                    onClick={() =>
                      window.open(`/reusable-orders/delivery-receipts/${id}`)
                    }
                  >
                    {Lang.reusable.orderDetail.print.deliveryReceipt}
                  </LinkButton>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center mt-[50px]">
            <div className="medium-container">
              <ContractInfo reusableOrder={reusableOrder} />
              <div className="white-wall-with-padding mt-[40px] flex gap-[40px] items-center">
                <ConsignorInfo reusableOrder={reusableOrder} />
                <RightArrowSvg />
                <ConsigneeInfo reusableOrder={reusableOrder} />
              </div>
              <ShipperAndReturnSite reusableOrder={reusableOrder} />
              <div className="white-wall-with-padding mt-[40px]">
                <h3>{lang.productInfo.title}</h3>
                {reusableOrder.reusableOrderDetails.map((orderDetail) => (
                  <ProductInfo key={orderDetail.id} orderDetail={orderDetail} />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </DefaultWithTitleLayout>
  )
}

const ContractInfo = ({ reusableOrder }: { reusableOrder: ReusableOrder }) => (
  <div className="white-wall-with-padding">
    <h3>{lang.contractInfo.title}</h3>
    <div className="mt-[16px] flex flex-col gap-[16px]">
      <LabeledText
        label={Lang.reusable.reusableOrder.orderNumber}
        content={reusableOrder.id}
      />
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.status}
          content={Lang.reusable.state[reusableOrder.state]}
        />
      </div>
      <div className="flex">
        <LabeledText
          label={Lang.reusable.reusableOrder.customerCompanyName}
          content={reusableOrder.customerCompanyName}
        />
        <LabeledText
          label={Lang.reusable.reusableOrder.customerCompanyDivision}
          content={reusableOrder.customerCompanyDivision}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.customerPhone}
          content={reusableOrder.customerPhone}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.notesForDistributionCenter}
          content={reusableOrder.notesForDistributionCenter}
        />
      </div>
    </div>
  </div>
)

const ConsignorInfo = ({ reusableOrder }: { reusableOrder: ReusableOrder }) => (
  <div className="w-[260px] border-vixell-gray-light border rounded-[10px] p-[24px]">
    <h3>{Lang.reusable.reusableOrder.consignorInfo}</h3>
    <div className="flex flex-col gap-[16px] mt-[16px]">
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.deliveryDateToConsignor}
          content={`${toFormattedDate(reusableOrder.deliveryDateToConsignor)} ${
            reusableOrder.deliveryDateMeridiemIndicatorToConsignor
          }`}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.countryOfConsignor}
          content={reusableOrder.countryOfConsignor}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.stateOfConsignor}
          content={reusableOrder.stateOfConsignor}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.addressOfConsignor}
          content={reusableOrder.addressOfConsignor}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.zipCodeOfConsignor}
          content={reusableOrder.zipCodeOfConsignor}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.companyNameOfConsignor}
          content={reusableOrder.companyNameOfConsignor}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.divisionOfConsignor}
          content={reusableOrder.divisionOfConsignor}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.phoneOfConsignor}
          content={reusableOrder.phoneOfConsignor}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.notesForConsignor}
          content={reusableOrder.notesForConsignor}
        />
      </div>
    </div>
  </div>
)

const ConsigneeInfo = ({ reusableOrder }: { reusableOrder: ReusableOrder }) => (
  <div className="w-[260px] border-vixell-gray-light border rounded-[10px] p-[24px] self-start">
    <h3>{Lang.reusable.reusableOrder.consigneeInfo}</h3>
    <div className="flex flex-col gap-[16px] mt-[16px]">
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.deliveryDateToConsignee}
          content={toFormattedDate(reusableOrder.deliveryDateToConsignee)}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.countryOfConsignee}
          content={reusableOrder.countryOfConsignee}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.stateOfConsignee}
          content={reusableOrder.stateOfConsignee}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.addressOfConsignee}
          content={reusableOrder.addressOfConsignee}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.zipCodeOfConsignee}
          content={reusableOrder.zipCodeOfConsignee}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.companyNameOfConsignee}
          content={reusableOrder.companyNameOfConsignee}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.divisionOfConsignee}
          content={reusableOrder.divisionOfConsignee}
        />
      </div>
      <div>
        <LabeledText
          label={Lang.reusable.reusableOrder.phoneOfConsignee}
          content={reusableOrder.phoneOfConsignee}
        />
      </div>
    </div>
  </div>
)

const ShipperAndReturnSite = ({
  reusableOrder,
}: {
  reusableOrder: ReusableOrder
}) => {
  return (
    <div className="mt-[40px] white-wall-with-padding">
      <h3>{lang.siteInCharge.title}</h3>
      <div className="flex mt-[16px]">
        <LabeledText
          label={lang.siteInCharge.shipper}
          content={reusableOrder.lenderSite.name}
        />
        <LabeledText
          label={lang.siteInCharge.returnee}
          content={reusableOrder.collectorSite.name}
        />
      </div>
    </div>
  )
}

const ProductInfo = ({ orderDetail }: { orderDetail: ReusableOrderDetail }) => {
  const { siteType } = useLogin()
  return (
    <div className="mt-[16px] border-vixell-gray-light border rounded-[10px] p-[24px] flex flex-col gap-[16px] w-min">
      <h4>{Lang.vixell}</h4>
      <div className="flex">
        <LabeledText label={Lang.model} content={orderDetail.vixellModel} />
        <LabeledText
          label={Lang.serialNo}
          link={
            orderDetail.vixell &&
            canAccessPage('/vixells/:id', siteType) &&
            `/vixells/${orderDetail.vixell?.id}`
          }
          content={
            orderDetail.vixell?.serialNo && orderDetail.vixellCollectedDate ? (
              <div>
                <div>{orderDetail.vixell.serialNo}</div>
                <div>
                  (Returned:
                  {toFormattedDate(orderDetail.vixellCollectedDate)})
                </div>
              </div>
            ) : orderDetail.vixell?.serialNo ? (
              `${orderDetail.vixell.serialNo} (Lend)`
            ) : (
              ''
            )
          }
        />
      </div>
      <h4 className="mt-[20px]">{Lang.thermalStorageUnit}</h4>
      <div className="flex">
        <LabeledText
          label={Lang.model}
          content={orderDetail.thermalStorageUnitModel}
        />
        <LabeledText
          label={Lang.serialNo}
          link={
            orderDetail.thermalStorageUnit &&
            canAccessPage('/thermal-storage-units/:id', siteType) &&
            `/thermal-storage-units/${orderDetail.thermalStorageUnit?.id}`
          }
          content={
            orderDetail.thermalStorageUnit?.serialNo &&
            orderDetail.thermalStorageUnitCollectedDate ? (
              <div>
                <div>{orderDetail.thermalStorageUnit.serialNo}</div>
                <div>
                  (Returned:
                  {toFormattedDate(orderDetail.thermalStorageUnitCollectedDate)}
                  )
                </div>
              </div>
            ) : orderDetail.thermalStorageUnit?.serialNo ? (
              `${orderDetail.thermalStorageUnit.serialNo} (Lend)`
            ) : (
              ''
            )
          }
        />
      </div>
      <div className="flex">
        <LabeledText
          label={Lang.reusable.reusableOrder.preCooling}
          content={boolToString(orderDetail.preCooling)}
        />
        <LabeledText
          label={Lang.reusable.reusableOrder.preCoolingNotes}
          content={orderDetail.preCoolingNotes}
        />
      </div>
      <h4 className="mt-[20px]">{Lang.temperatureLogger}</h4>
      <div className="flex">
        <LabeledText
          label={Lang.reusable.reusableOrder.hasLogger}
          content={boolToString(orderDetail.hasLogger)}
        />
        {orderDetail.hasLogger && (
          <LabeledText
            label={Lang.serialNo}
            link={
              orderDetail.temperatureLogger &&
              canAccessPage('/temperature-loggers/:id', siteType) &&
              `/temperature-loggers/${orderDetail.temperatureLogger?.id}`
            }
            content={
              orderDetail.temperatureLogger?.serialNo &&
              orderDetail.temperatureLoggerCollectedDate ? (
                <div>
                  <div>{orderDetail.temperatureLogger.serialNo}</div>
                  <div>
                    (Returned:
                    {toFormattedDate(
                      orderDetail.temperatureLoggerCollectedDate
                    )}
                    )
                  </div>
                </div>
              ) : orderDetail.temperatureLogger?.serialNo ? (
                `${orderDetail.temperatureLogger.serialNo} (Lend)`
              ) : (
                ''
              )
            }
          />
        )}
      </div>
      {orderDetail.hasLogger && (
        <>
          <div className="flex">
            <LabeledText
              label={Lang.reusable.reusableOrder.loggerModel}
              content={orderDetail.loggerModel}
            />
            <LabeledText
              label={Lang.reusable.reusableOrder.loggerSettingBy}
              content={
                orderDetail.loggerSettingBy &&
                Lang.reusable.reusableOrder.loggerSettingByEnum[
                  orderDetail.loggerSettingBy
                ]
              }
            />
          </div>
          {orderDetail.loggerSettingBy === 'PANASONIC' && (
            <>
              <div className="flex">
                <LabeledText
                  label={
                    Lang.reusable.reusableOrder.loggerAlertUpperTemperatureLimit
                  }
                  content={orderDetail.loggerAlertUpperTemperatureLimit}
                />
                <LabeledText
                  label={
                    Lang.reusable.reusableOrder.loggerAlertLowerTemperatureLimit
                  }
                  content={orderDetail.loggerAlertLowerTemperatureLimit}
                />
              </div>
              <div className="flex">
                <LabeledText
                  label={Lang.reusable.reusableOrder.loggerAlertCapacity}
                  content={orderDetail.loggerAlertCapacity}
                />
                <LabeledText
                  label={
                    Lang.reusable.reusableOrder.loggingStartReservationType
                  }
                  content={
                    orderDetail.loggingStartReservationType &&
                    Lang.reusable.loggingStartReservationType[
                      orderDetail.loggingStartReservationType
                    ]
                  }
                />
              </div>
              <div className="flex">
                <LabeledText
                  label={
                    Lang.reusable.reusableOrder.loggingStartReservationTime
                  }
                  content={toFormattedDatetime(
                    orderDetail.loggingStartReservationTime
                  )}
                />
              </div>
              <div className="flex">
                <LabeledText
                  label={Lang.reusable.reusableOrder.loggerFilePath}
                  content={orderDetail.loggerFilePath}
                />
              </div>
            </>
          )}
        </>
      )}
      <div className="flex">
        <LabeledText
          label={Lang.reusable.reusableOrder.notesForDelivery}
          content={orderDetail.notesForDelivery}
        />
      </div>
    </div>
  )
}

const LinkButton = ({
  className,
  disabled,
  children,
  ...props
}: { disabled: boolean } & React.HTMLAttributes<HTMLButtonElement>) => {
  return disabled ? (
    <Cell
      layout="center_center"
      className={`w-[180px] h-[40px] rounded-[180px] text-white font-bold bg-gray-300 ${className}`}
    >
      {children}
    </Cell>
  ) : (
    <button
      {...props}
      className={`w-[180px] h-[40px] rounded-[180px] text-white font-bold ${className}`}
    >
      {children}
    </button>
  )
}
