import { useParams } from 'react-router-dom'
import { FormEvent, useEffect, useState } from 'react'
import { ProductNote } from '../../../types/typescript-axios'
import { usePaging } from '../../../contexts/use-paging'
import { webRequest } from '../../../types/handle-api'
import { VixellTable } from '../../part/vixell-table'
import { Lang } from '../../../translations/lang'
import { TableElementDatetime2Line } from '../../part/table-elements/table-element-datetime-2-line'
import { InfiniteLoadComponent } from '../../molecules/infinite-load-component'
import { Button } from '../../molecules/button'

type Props = {
  productType: 'VIXELL' | 'THERMAL_STORAGE_UNIT' | 'TEMPERATURE_LOGGER'
}
export const ProductNoteHistory = ({ productType }: Props) => {
  const { id } = useParams<{ id: string }>()
  const [histories, setHistories] = useState<ProductNote[]>()
  const { currentPage, setPaging, stopPageLoad } = usePaging()
  const [newNote, setNewNote] = useState<string>('')

  useEffect(() => {
    fetchData()
  }, [id])

  async function fetchData(loadNextPage = false) {
    const response = await webRequest('ProductNotesIndex', {
      productType: productType,
      productId: Number(id),
      page: loadNextPage ? currentPage + 1 : 1,
    })
    if (response.status === 200) {
      if (loadNextPage)
        setHistories([...(histories || []), ...response.data.productNotes])
      else setHistories(response.data.productNotes)
      setPaging(response.data)
    }
  }

  async function createNewNote(e: FormEvent) {
    e.preventDefault()
    if (newNote.length === 0) return

    await webRequest('ProductNotesCreate', {
      productType,
      productId: Number(id),
      text: newNote,
    })
    setNewNote('')
    fetchData()
  }

  function infiniteLoad() {
    if (stopPageLoad) return

    fetchData(true)
  }

  return (
    <div className="my-40 container">
      {histories && (
        <VixellTable
          classNames={['flex-1', 'w-200']}
          headers={[Lang.notesHistory, Lang.createdDate]}
          elements={histories.map((history) => [
            <div className="whitespace-pre-line flex justify-center w-full px-5 py-2">
              {history.text}
            </div>,
            <TableElementDatetime2Line
              dateTime={history.processingDateTime}
              key={history.id}
            />,
          ])}
        />
      )}
      <form
        className="flex flex-col justify-center items-center mt-24"
        onSubmit={createNewNote}
      >
        <div className="flex flex-col justify-start w-[1200px]">
          <label>{Lang.notes.newNote}</label>
          <textarea
            className="w-full h-[100px]"
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
          />
        </div>
        <div className="mt-24">
          <Button color="cyan">{Lang.create}</Button>
        </div>
      </form>
      <InfiniteLoadComponent
        infiniteLoad={infiniteLoad}
        nextLoadDelayMilliSec={3000}
      />
    </div>
  )
}
