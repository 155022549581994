import { Lang } from '../../../translations/lang'
import { Button } from '../../molecules/button'
import React, { FormEvent, useEffect, useState } from 'react'
import { RightArrowSvg } from '../../svg/right-arrow-svg'
import { webRequest } from '../../../types/handle-api'
import type {
  ReusableOrder,
  ReusableOrderDetail,
  Site,
} from '../../../types/typescript-axios'
import { SiteType } from '../../../types/typescript-axios'
import { FormProvider, useForm } from 'react-hook-form'
import _ from 'lodash'
import { toIsoDate } from '../../../utils/to-iso-date'
import { ReusableOrderFormDetail } from './ReusableOrderFormDetail'
import { InputWithLabelAndRegister } from '../../molecules/input-with-label-and-register'

// 契約情報
const ReusableOrderStateSelectValues = [
  { label: Lang.reusable.state.CONFIRMED, value: 'CONFIRMED' },
  { label: Lang.reusable.state.NON_CONFIRMED, value: 'NON_CONFIRMED' },
  { label: Lang.reusable.state.CANCELLED, value: 'CANCELLED' },
] as const

const MeridiemIndicatorSelectValues = ['AM', 'PM'] as const

export type ReusableOrdersNewFieldValues = Omit<
  ReusableOrder,
  'lenderSite' | 'collectorSite'
> & {
  reusableOrderDetails: Omit<ReusableOrderDetail, 'reusableOrder'>[]
}
type Props = {
  defaultValues?: Partial<ReusableOrdersNewFieldValues>
  submit: (fieldValues: ReusableOrdersNewFieldValues) => Promise<boolean>
}
export const ReusableOrderForm = ({ defaultValues, submit }: Props) => {
  const formMethods = useForm<ReusableOrdersNewFieldValues>({
    defaultValues,
  })
  const { register, getValues, setValue, watch, reset } = formMethods
  const [orderDetailCount, setOrderDetailCount] = useState<number>(
    defaultValues?.reusableOrderDetails?.length || 1
  )
  // 削除ボタンが押されても、データとしては保持しておき見えなくするだけにする
  const [deletedOrderDetailIndex, setDeletedOrderDetailsIndex] = useState<
    number[]
  >([])

  useEffect(() => {
    ;(async () => {
      const response = await webRequest('SitesIndex', {
        siteType: SiteType.ReusableShipper,
      })
      if (response.status === 200) {
        setLenderSites(response.data.sites)
        if (!getValues('lenderSiteId'))
          setValue('lenderSiteId', response.data.sites[0].id)
      }
    })()
    ;(async () => {
      const response = await webRequest('SitesIndex', {
        siteType: SiteType.ReusableReturn,
      })
      if (response.status === 200) {
        setCollectorSites(response.data.sites)
        if (!getValues('collectorSiteId'))
          setValue('collectorSiteId', response.data.sites[0].id)
      }
    })()
  }, [])

  const [lenderSites, setLenderSites] = useState<Site[]>([])
  const [collectorSites, setCollectorSites] = useState<Site[]>([])

  async function onSubmit(e: FormEvent) {
    e.preventDefault()

    const nonNullValues = _.omitBy(
      getValues(),
      _.isNull
    ) as ReusableOrdersNewFieldValues

    const result = await submit({
      ...nonNullValues,
      reusableOrderDetails: getValues('reusableOrderDetails')
        .filter((_, index) => !deletedOrderDetailIndex.includes(index))
        .map((v) => _.omitBy(v, _.isNull) as ReusableOrderDetail)
        .map((v) =>
          _.omit(v, 'vixell', 'thermalStorageUnit', 'temperatureLogger')
        ),
    })
    if (result) reset(defaultValues)
  }
  const langForm = Lang.reusable.orderDetail
  const langReusable = Lang.reusable.reusableOrder

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={onSubmit}>
        <div className="container rounded-[10px] bg-white p-32 shadow-vixell mt-42 min-h-[464px]">
          <div>
            <div className="text-lg font-bold">
              {langForm.contractInfo.title}
            </div>
            <div className="mt-16">
              <div>
                <label>{langForm.contractInfo.status}</label>
                <label className="alert ml-8">*{Lang.required}</label>
              </div>
              <select
                className="mt-8 w-[200px]"
                {...register('state', { required: true })}
              >
                {ReusableOrderStateSelectValues.map((state, index) => {
                  return (
                    <option value={state.value} key={index}>
                      {state.label}
                    </option>
                  )
                })}
              </select>
            </div>
            <InputWithLabelAndRegister
              label={langReusable.customerCompanyName}
              alertLabel={`*${Lang.required}`}
              register={register('customerCompanyName', { required: true })}
              className="mt-16"
              required={true}
            />
            <InputWithLabelAndRegister
              label={langReusable.customerCompanyDivision}
              alertLabel={`*${Lang.required}`}
              register={register('customerCompanyDivision', {
                required: true,
              })}
              className="mt-16"
              required={true}
            />
            <InputWithLabelAndRegister
              label={langReusable.customerPhone}
              alertLabel={`*${Lang.required}`}
              register={register('customerPhone', { required: true })}
              className="mt-16"
              required={true}
            />
            <div className="mt-16">
              <div>
                <label>{langReusable.notesForDistributionCenter}</label>
              </div>
              <textarea
                className="mt-8"
                rows={4}
                {...register('notesForDistributionCenter')}
              />
            </div>
          </div>
          <div className="mt-42">
            <div className="text-lg font-bold">
              {langForm.siteInCharge.title}
            </div>
            <div className="mt-24 text-xs">
              <div className="flex">
                <div>
                  <div>
                    <label>{langForm.siteInCharge.shipper}</label>
                    <label className="alert ml-8">{`*${Lang.required}`}</label>
                  </div>
                  <select
                    value={watch('lenderSiteId')}
                    className="mt-8 w-200"
                    {...register('lenderSiteId', { valueAsNumber: true })}
                  >
                    {lenderSites.map((lenderSite, index) => {
                      return (
                        <option key={index} value={lenderSite.id}>
                          {lenderSite.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className="ml-32">
                  <div>
                    <label>{langForm.siteInCharge.returnee}</label>
                    <label className="alert ml-8">{`*${Lang.required}`}</label>
                  </div>
                  <select
                    className="mt-8 w-200"
                    value={watch('collectorSiteId')}
                    {...register('collectorSiteId', { valueAsNumber: true })}
                  >
                    {collectorSites.map((collectorSite, index) => {
                      return (
                        <option key={index} value={collectorSite.id}>
                          {collectorSite.name}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="flex mt-42">
            <div>
              <div className="text-lg font-bold">
                {langReusable.consignorInfo}
              </div>
              <div className="mt-16">
                <div>
                  <label>{langReusable.deliveryDateToConsignor}</label>
                  <label className="alert ml-8">*{Lang.required}</label>
                </div>
                <div className="flex mt-12">
                  <input
                    className="w-[258px]"
                    type="date"
                    value={toIsoDate(watch('deliveryDateToConsignor'))}
                    {...register('deliveryDateToConsignor', {
                      required: true,
                    })}
                    required={true}
                  />
                  <select
                    className="ml-12 w-[78px]"
                    {...register('deliveryDateMeridiemIndicatorToConsignor', {
                      required: true,
                    })}
                  >
                    {MeridiemIndicatorSelectValues.map((mi) => (
                      <option key={mi} value={mi}>
                        {mi}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <InputWithLabelAndRegister
                label={langReusable.countryOfConsignor}
                alertLabel={`*${Lang.required}`}
                register={register('countryOfConsignor', { required: true })}
                className="mt-16"
                required={true}
              />
              <InputWithLabelAndRegister
                label={langReusable.stateOfConsignor}
                alertLabel={`*${Lang.required}`}
                register={register('stateOfConsignor', { required: true })}
                className="mt-16"
                required={true}
              />
              <InputWithLabelAndRegister
                label={langReusable.addressOfConsignor}
                alertLabel={`*${Lang.required}`}
                register={register('addressOfConsignor', { required: true })}
                className="mt-16"
                required={true}
              />
              <InputWithLabelAndRegister
                label={langReusable.zipCodeOfConsignor}
                alertLabel={`*${Lang.required}`}
                register={register('zipCodeOfConsignor', { required: true })}
                className="mt-16"
                required={true}
              />
              <InputWithLabelAndRegister
                label={langReusable.companyNameOfConsignor}
                alertLabel={`*${Lang.required}`}
                register={register('companyNameOfConsignor', {
                  required: true,
                })}
                className="mt-16"
                required={true}
              />
              <InputWithLabelAndRegister
                label={langReusable.divisionOfConsignor}
                alertLabel={`*${Lang.required}`}
                register={register('divisionOfConsignor', { required: true })}
                className="mt-16"
                required={true}
              />
              <InputWithLabelAndRegister
                label={langReusable.phoneOfConsignor}
                alertLabel={`*${Lang.required}`}
                register={register('phoneOfConsignor', { required: true })}
                className="mt-16"
                required={true}
              />
              <div className="mt-16">
                <div>
                  <label>{langReusable.notesForConsignor}</label>
                </div>
                <textarea
                  className="mt-8"
                  rows={4}
                  {...register('notesForConsignor')}
                />
              </div>
            </div>
            <div className="flex items-center mx-16">
              <RightArrowSvg />
            </div>
            <div>
              <div className="text-lg font-bold">
                {langReusable.consigneeInfo}
              </div>
              <InputWithLabelAndRegister
                label={langReusable.deliveryDateToConsignee}
                type="date"
                value={toIsoDate(watch('deliveryDateToConsignee'))}
                register={register('deliveryDateToConsignee')}
                className="mt-16"
              />
              <InputWithLabelAndRegister
                label={langReusable.countryOfConsignee}
                alertLabel={`*${Lang.required}`}
                register={register('countryOfConsignee', { required: true })}
                className="mt-16"
                required={true}
              />
              <InputWithLabelAndRegister
                label={langReusable.stateOfConsignee}
                alertLabel={`*${Lang.required}`}
                register={register('stateOfConsignee', { required: true })}
                className="mt-16"
                required={true}
              />
              <InputWithLabelAndRegister
                label={langReusable.addressOfConsignee}
                register={register('addressOfConsignee')}
                className="mt-16"
              />
              <InputWithLabelAndRegister
                label={langReusable.zipCodeOfConsignee}
                register={register('zipCodeOfConsignee')}
                className="mt-16"
              />
              <InputWithLabelAndRegister
                label={langReusable.companyNameOfConsignee}
                register={register('companyNameOfConsignee')}
                className="mt-16"
              />
              <InputWithLabelAndRegister
                label={langReusable.divisionOfConsignee}
                register={register('divisionOfConsignee')}
                className="mt-16"
              />
              <InputWithLabelAndRegister
                label={langReusable.phoneOfConsignee}
                register={register('phoneOfConsignee')}
                className="mt-16"
              />
            </div>
          </div>
          {Array.from({ length: orderDetailCount }).map((_, index) =>
            deletedOrderDetailIndex.some((i) => i === index) ? (
              <></>
            ) : (
              <ReusableOrderFormDetail
                key={index}
                index={index}
                orderDetailNumber={
                  index +
                  1 -
                  deletedOrderDetailIndex.filter((i) => i < index).length
                }
                deleteMe={() =>
                  setDeletedOrderDetailsIndex((last) => [...last, index])
                }
              />
            )
          )}
          <div className="mt-32">
            <Button
              color="cyan"
              className="w-120"
              type="button"
              onClick={() => setOrderDetailCount((c) => c + 1)}
            >
              {langForm.productInfo.add}
            </Button>
          </div>
          <div className="flex justify-center my-32">
            <Button color="cyan">{Lang.register}</Button>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
