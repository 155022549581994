import { FC, HTMLInputTypeAttribute } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form'

type Props = {
  label: string
  alertLabel?: string
  className?: string
  required?: boolean
  placeholder?: string
  type?: HTMLInputTypeAttribute
  pattern?: string
  title?: string
  autoComplete?: string | undefined
  onlyCapital?: boolean
  register: UseFormRegisterReturn
  value?: string
}
// TODO: onlyCapitalの処理をどこかに入れる（消したので）
export const InputWithLabelAndRegister: FC<Props> = ({
  label,
  alertLabel,
  className,
  required = false,
  placeholder,
  type = 'text',
  autoComplete,
  pattern,
  title,
  register,
  value,
}) => (
  <div className={className}>
    <label>{label}</label>
    <label className="text-vixell-red ml-8 font-normal">{alertLabel}</label>
    <div className="mt-12">
      <input
        type={type}
        {...register}
        {...(value && { value })}
        required={required || register.required}
        autoComplete={autoComplete}
        placeholder={placeholder}
        {...{ ...(pattern && { pattern }), ...(title && { title }) }}
      />
    </div>
  </div>
)
