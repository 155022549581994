import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  OnsiteVacuum,
  VixellsShowResponseComponent,
} from '../../../types/typescript-axios'
import { TabContainer } from '../../../components/part/tab-container'
import { NoPaddingLayoutWithTitle } from '../../../components/templates/no-padding-layout-with-title'
import { VixellTable } from '../../../components/part/vixell-table'
import { TableElementDatetime2Line } from '../../../components/part/table-elements/table-element-datetime-2-line'
import moment from 'moment'
import { VacuumProcessComponent } from '../../../components/templates/vixell-detail/vacuum-process-component'
import { Lang } from '../../../translations/lang'
import { InfiniteLoadComponent } from '../../../components/molecules/infinite-load-component'
import { webRequest } from '../../../types/handle-api'
import { usePaging } from '../../../contexts/use-paging'
import { ReusableOrderHistory } from '../../../components/templates/transactions/reusable-order-history'
import { BelongingLogHistory } from '../../../components/templates/transactions/belonging-log-history'
import { ShippingStateLogHistory } from '../../../components/templates/transactions/shipping-state-log-history'
import { ProductNoteHistory } from '../../../components/templates/transactions/product-note-history'

export const AdminVixellDetail = () => {
  const { id } = useParams<{ id: string }>()
  const [vixell, setVixell] = useState<VixellsShowResponseComponent>()

  useEffect(() => {
    async function fetchData() {
      console.log('id')
      console.log(id)
      const response = await webRequest('VixellsShow', {
        id: Number(id),
      })
      if (response.status === 200) {
        setVixell(response.data)
      }
    }

    fetchData()
  }, [id])

  return (
    <NoPaddingLayoutWithTitle
      title={Lang.admin.vixellDetail.title}
      link={{
        label: Lang.admin.vixellDetail.goBackToVixells,
        link: '/vixells',
      }}
    >
      <div className="flex gap-[32px] container mt-16 mb-40">
        <div>
          <label>{Lang.vixellSerialNo}</label>
          <p className="mt-12 text-lg font-bold">{vixell?.vixell?.serialNo}</p>
        </div>
        <div>
          <label>{Lang.model}</label>
          <p className="mt-12 text-lg font-bold">{vixell?.vixell?.model}</p>
        </div>
        <div>
          <label>{Lang.admin.vixells.IDm}</label>
          <p className="mt-12 text-lg font-bold">{vixell?.vixell?.IDm}</p>
        </div>
        <div>
          <label>{Lang.lastInspectionTime}</label>
          <p className="mt-12 text-lg font-bold">
            {vixell?.latestOnsiteResult?.processingDateTime &&
              moment(vixell?.latestOnsiteResult?.processingDateTime).format(
                Lang.dateTimeFormat
              )}
          </p>
        </div>
      </div>

      {vixell && (
        <div className="container my-60 p-32 rounded-[10px] bg-white shadow-vixell-dark">
          <VacuumProcessComponent vacuumProcess={vixell.vacuumProcess} />
        </div>
      )}

      <TabContainer
        tabs={[
          {
            tabName: Lang.tradeHistory,
            element: <ShippingStateLogHistory productType="VIXELL" />,
          },
          { tabName: Lang.inspectionHistory, element: <OnsiteHistory /> },
          {
            tabName: Lang.transitionHistory,
            element: <BelongingLogHistory productType="VIXELL" />,
          },
          {
            tabName: Lang.reusableOrderHistory,
            element: <ReusableOrderHistory productType="VIXELL" />,
          },
          {
            tabName: Lang.notesHistory,
            element: <ProductNoteHistory productType="VIXELL" />,
          },
        ]}
      />
    </NoPaddingLayoutWithTitle>
  )
}

const OnsiteHistory = () => {
  const { id } = useParams<{ id: string }>()
  const [histories, setHistories] = useState<OnsiteVacuum[]>()
  const { currentPage, setPaging, stopPageLoad } = usePaging()

  useEffect(() => {
    fetchData()
  }, [id])

  async function fetchData() {
    const response = await webRequest('OnsiteVacuumResultsIndex', {
      vixellId: Number(id),
      page: currentPage + 1,
    })
    if (response.status === 200) {
      setHistories([...(histories || []), ...response.data.onsiteVacuumResults])
      setPaging(response.data)
    }
  }

  function infiniteLoad() {
    if (stopPageLoad) return

    fetchData()
  }

  return (
    <div className="my-40 container">
      {histories && (
        <VixellTable
          classNames={['w-200', 'w-200', 'w-200', 'w-200']}
          headers={[
            Lang.inspectionTime,
            Lang.admin.vixellDetail.sensorPressure,
            Lang.admin.vixellDetail.sensorVoltage,
            Lang.inspectionResult,
            Lang.admin.vixellDetail.onsiteTiming,
          ]}
          elements={histories.map((history) => [
            <TableElementDatetime2Line
              dateTime={history.processingDateTime}
              key={history.vixellSerialNo}
            />,
            '' +
              (history.sensorPressure === undefined
                ? ''
                : history.sensorPressure),
            '' +
              (history.sensorVoltage === undefined
                ? ''
                : history.sensorVoltage),
            history.inspectionResult,
            history.timing && Lang.admin.vixellDetail.timing[history.timing],
          ])}
        />
      )}
      <InfiniteLoadComponent
        infiniteLoad={infiniteLoad}
        nextLoadDelayMilliSec={3000}
      />
    </div>
  )
}
