import { FC, ReactElement } from 'react'
import { DefaultLayout } from './detaulf-layout'
import { TitleWithDescriptionAndCSV } from '../part/title-with-description-and-c-s-v'

type Props = {
  title: string
  description?: string
  titleRightElement?: ReactElement
  titleBottomElement?: ReactElement
  infiniteLoad?: () => void
  csvDownload?: () => void
}
export const DefaultWithTitleLayout: FC<Props> = ({
  title,
  description,
  children,
  titleRightElement,
  titleBottomElement,
  csvDownload,
  infiniteLoad,
}) => (
  <DefaultLayout infiniteLoad={infiniteLoad}>
    <div className="flex justify-between">
      <TitleWithDescriptionAndCSV
        title={title}
        description={description}
        csvDownload={csvDownload}
      >
        {titleBottomElement}
      </TitleWithDescriptionAndCSV>
      {titleRightElement}
    </div>
    {children}
  </DefaultLayout>
)
